// Copyright © 2021 Move Closer

import {
  ICollection,
  Injectable,
  IResponse,
  MappingConfig,
  Repository,
  ResourceActionFailed
} from '@movecloser/front-core'

import { Node } from '../models/node'
import { NodeData, NodeModel } from '../contracts/models'
import { INodesRepository } from '../contracts/repositories'

import { nodeAdapterMap } from '../models/node.adapter'
import { resolveFromStatus } from '../../shared/exceptions/connector-errors'
import { Query } from '../../shared/contracts/query'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Injectable()
export class NodesRepository extends Repository<NodeData, Node> implements INodesRepository {
  protected useAdapter: boolean = true
  protected map: MappingConfig = nodeAdapterMap

  public async loadTree (query: Query, types: string[] = []): Promise<ICollection<NodeModel>> {
    const response: IResponse = await this.connector.call(
      'content',
      'nodes',
      {},
      {
        ...(types.length > 0 ? { type: types.join(',or:') } : {}),
        ...query
      }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeCollection(
      response.data.data,
      Node,
      response.data.meta
    )
  }
}
