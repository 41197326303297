


























import { Component, PropSync } from 'vue-property-decorator'

import { FormInput } from '../../shared/components/form'

import { ConfigForm } from './_abstract/ConfigForm.vue'
import { GeneralSettings, InitSearchSettings } from '../contracts'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<InitSearchForm>({
  name: 'InitSearchForm',
  components: { FormInput }
})
export class InitSearchForm extends ConfigForm {
  @PropSync('formData', { type: Object, required: true })
  public payload!: InitSearchSettings

  public readonly formName = 'initSearch'

  public loading: boolean = false

  public onSubmit () {
    this.$emit('submit', { reproject: true, global: false })
  }
}

export default InitSearchForm
