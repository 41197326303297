// Copyright © 2021 Move Closer

import { Container, RoutesFactory } from '@movecloser/front-core'

import { Permission } from '../../auth/contracts/permissions'

import Acceptance from '../views/Acceptance.vue'
import AcceptanceList from '../views/AcceptanceList.vue'
import Create from '../views/Create.vue'
import EditBasics from '../views/EditBasics.vue'
import EditContent from '../views/EditContent.vue'
import List from '../views/List.vue'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const routesFactory: RoutesFactory = (container: Container) => {
  return [
    {
      path: '/content/acceptance/:type/:id',
      name: 'acceptance.show',
      component: Acceptance,
      meta: {
        permissions: [
          Permission.AcceptContents
        ]
      }
    },
    {
      path: '/content/acceptance/:type',
      name: 'acceptance.list',
      component: AcceptanceList,
      meta: {
        permissions: [
          Permission.AcceptContents
        ]
      }
    },
    {
      path: '/content/:type/:id/basics',
      name: 'edit.basics',
      component: EditBasics,
      meta: {
        permissions: [
          Permission.EditContents
        ]
      }
    },
    {
      path: '/content/:type/:id/content',
      name: 'edit.content',
      component: EditContent,
      meta: {
        permissions: [
          Permission.EditContents
        ]
      }
    },
    {
      path: '/content/:type/create',
      name: 'create',
      component: Create,
      meta: {
        permissions: [
          Permission.CreateContents
        ]
      }
    },
    {
      path: '/content/:type',
      name: 'list',
      component: List,
      meta: {
        permissions: [
          Permission.ViewContents
        ]
      }
    },
    {
      path: '/content',
      redirect: '/content/articles'
    }
  ]
}
