// Copyright © 2021 Move Closer

import {
  Collection,
  ICollection,
  Injectable,
  IResponse,
  MappingConfig, QueryParams,
  Repository,
  ResourceActionFailed
} from '@movecloser/front-core'

import { Identifier } from '../../../backoffice'
import { resolveFromStatus } from '../../shared/exceptions/connector-errors'

import { IMeRepository } from '../contracts/repositories'
import { meAdapterMap } from '../models/me.adapter'
import { MeData, MeModel, SessionData } from '../contracts/models'
import { Me } from '../models/me'

@Injectable()
export class MeRepository extends Repository<MeData> implements IMeRepository {
  protected useAdapter = true
  protected map: MappingConfig = meAdapterMap

  public async deleteAllSession (): Promise<void> {
    const response: IResponse = await this.connector.call(
      'me', 'deleteSession', { id: 'all' }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response),
        {}
      )
    }
  }

  public async deleteSession (sessionId: Identifier): Promise<void> {
    const response: IResponse = await this.connector.call(
      'me', 'deleteSession', { id: sessionId }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response),
        {}
      )
    }
  }

  public async editAvatar (avatar: File): Promise<void> {
    const formData: FormData = new FormData()
    formData.append('file', avatar)

    const response: IResponse = await this.connector.call(
      'me', 'updateAvatar', {}, formData
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response),
        {}
      )
    }
  }

  public async editBasicInformation (firstName?: string, lastName?: string, nickname?: string): Promise<void> {
    const response: IResponse = await this.connector.call(
      'me',
      'editBasicInformation',
      {},
      { firstName, lastName, nickname }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }

  public async editPassword (actual: string, password: string, passwordConfirmation: string): Promise<void> {
    const response: IResponse = await this.connector.call(
      'me',
      'editPassword',
      {},
      { actual, password, passwordConfirmation }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }

  public async loadMeProfile (): Promise<MeModel> {
    const response: IResponse = await this.connector.call(
      'me',
      'load'
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeModel(response.data.me, Me)
  }

  public async loadMySessions (query?: QueryParams): Promise<ICollection<SessionData>> {
    const response: IResponse = await this.connector.call(
      'me',
      'sessions',
      {},
      query
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return new Collection<SessionData>(response.data.data, response.data.meta)
  }
}
