// Copyright © 2021 Move Closer

import { computed, ComputedRef, defineComponent, PropType } from '@vue/composition-api'

import { FormCheckboxProps } from './Checkbox.contracts'
import { FormFieldAbstract, FormFieldAbstractSetup } from '../Abstract'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
export const FormCheckbox = defineComponent({
  name: 'FormCheckbox',
  extends: FormFieldAbstract,
  template: `
    <div :class="classContainer">

      <label :class="classLabel" :for="name" v-if="displayLabel" :required="required">{{ label }}</label>

      <DsCheck v-for="(item, index) in items" :key="index"
                :name="name" :id="name + '-' + index"
                :label="item.label"
                :model="getSingleValue(item.key)"
                :type="type"
                @update:model="setSingleValue($event, item.key)"
                :class="[ classInput, classObject ]">
      </DsCheck>

      <div class="invalid-feedback animated fadeIn" v-if="hasErrors">{{ errors[0] }}</div>
      <small class="text-muted" v-if="helper">{{ helper }}</small>

    </div>
  `,
  props: {
    /**
     * Container class
     */
    classContainer: {
      type: String,
      required: false
    },

    /**
     * Input class
     */
    classInput: {
      type: Object,
      required: false,
      default: () => { return {} }
    },

    /**
     * Label class
     */
    classLabel: {
      type: String,
      required: false
    },

    /**
     * Defines checkboxes labels
     */
    items: {
      type: Array,
      required: false,
      default: false
    },

    /**
     * Determines whether the control should be disabled.
     */
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    },

    /**
     * Text label associated with the control.
     */
    label: {
      type: String,
      required: false,
      default: ''
    },

    /**
     * Type of the control.
     */
    type: {
      type: String as PropType<'checkbox'|'radio'>,
      required: false,
      default: 'checkbox'
    }
  },
  emits: ['input'],

  setup (props: FormCheckboxProps, { emit }) {
    const {
      errors,
      displayLabel,
      hasErrors,
      value
    } = FormFieldAbstractSetup(props, emit)

    const classObject: ComputedRef<object> = computed(() => {
      return {
        'is-invalid': hasErrors.value
      }
    })

    function getSingleValue (key: string) {
      return value.value?.includes(key)
    }

    /**
     * Handles a single checkbox value change
     * @param newValue
     * @param key
     */
    function setSingleValue (newValue: unknown, key: string) {
      // FIXME: radio buttons form modules dosen't work
      if (props.type === 'radio' && value.value) {
        if (key) {
          if (!value.value.includes(key)) {
            value.value.splice(0, value.value.length)
            value.value.push(key)
          }
        } else {
          if (value.value.includes(key)) {
            value.value.splice(0, value.value.length)
          }
        }
      } else if (value.value) {
        // If true, add key to the value array
        if (newValue) {
          // Make sure its not already there
          if (!value.value.includes(key)) {
            value.value.push(key)
            value.value.sort()
          }
        // If false, delete key from value array
        } else {
          if (value.value.includes(key)) {
            value.value.splice(value.value.indexOf(key), 1)
          }
        }

        emit('checked', value.value) // FIXME: Rewrite this component.
      }
    }

    return {
      classObject,
      errors,
      displayLabel,
      hasErrors,
      value,
      getSingleValue,
      setSingleValue
    }
  }
})
export default FormCheckbox
