// Copyright © 2021 Move Closer

import { mapModel, MappingConfig, MappingTypes } from '@movecloser/front-core'

import { ownerAdapterMap } from '../../root/models/owner.adapter'

import { variantAdapterMap } from './variant.adapter'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const acceptanceAdapterMap: MappingConfig = {
  asIs: {
    type: MappingTypes.Function,
    value: item => {
      if (!item.asIs) {
        return undefined
      }

      return mapModel(item.asIs, variantAdapterMap)
    }
  },
  acceptedAt: 'acceptedAt',
  acceptedBy: {
    type: MappingTypes.Function,
    value: item => {
      if (item.acceptedBy) {
        return mapModel(item.acceptedBy, ownerAdapterMap)
      }
      return null
    }
  },
  content: {
    type: MappingTypes.Function,
    value: item => item.toBe ? item.toBe.contentId || 'unknown' : undefined
  },
  contentStatus: 'status',
  contentType: 'contentType',
  editor: {
    type: MappingTypes.Function,
    value: item => mapModel(item.editor, ownerAdapterMap)
  },
  id: 'id',
  lockedAt: 'lockedAt',
  publishedAt: {
    type: MappingTypes.Function,
    value: item => item.publishedAt || '2021-06-20 12:00:00'
  },
  status: 'status',
  title: 'title',
  toBe: {
    type: MappingTypes.Adapter,
    map: variantAdapterMap,
    value: 'toBe'
  },
  updatedAt: 'updatedAt'
}
