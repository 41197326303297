// Copyright © 2022 Move Closer

import { Component } from 'vue-property-decorator'

import { IUserAware, UserAware } from '../../auth/shared/user-aware.abstract'

import {
  canAcceptContents,
  canCreateContents,
  canDeleteContents,
  canEditContents,
  canPublishContents,
  canViewContents
} from '../config/guards'

export interface IContentPermissions extends IUserAware {
  canCreateContents: boolean
  canEditContents: boolean
  canDeleteContents: boolean
  canPublishContents: boolean
  canViewContents: boolean
}

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component
export class ContentPermissions extends UserAware implements IContentPermissions {
  /**
   * Contents permissions
   */
  public get canAcceptContents () {
    return canAcceptContents(this.domain, this.authService.user)
  }

  public get canCreateContents () {
    return canCreateContents(this.domain, this.authService.user)
  }

  public get canEditContents () {
    return canEditContents(this.domain, this.authService.user)
  }

  public get canDeleteContents () {
    return canDeleteContents(this.domain, this.authService.user)
  }

  public get canPublishContents () {
    return canPublishContents(this.domain, this.authService.user)
  }

  public get canViewContents () {
    return canViewContents(this.domain, this.authService.user)
  }
}
