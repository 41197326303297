


























import { Component } from 'vue-property-decorator'
import { DashmixSelectItem } from '@movecloser/ui-core'

import { PossibleStaticRoutes } from '../../../backoffice'

import { AbstractAddon, AddonErrors } from '../../content/shared'

import { Addon } from '../config/content-types'
import { StaticRoutingAddonData } from '../contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component({
  name: 'StaticRoutingAddon',
  components: { AddonErrors }
})
export class StaticRoutingAddon extends AbstractAddon {
  public hasWildcard: boolean = false
  public isSpa: boolean = false
  public route: string = ''

  public get routeOptions (): DashmixSelectItem[] {
    return PossibleStaticRoutes.map(r => ({ label: this.$t(r.label).toString(), value: r.path }))
      .sort((a, b) => a.label.localeCompare(b.label))
  }

  public syncWithVariant (): void {
    const addonData: StaticRoutingAddonData = {
      hasWildcard: this.hasWildcard,
      isSpa: this.isSpa,
      route: this.route
    }

    this.variant.setProperty(Addon.StaticRouting, addonData)
  }

  protected getVariantProperty (): void {
    const staticPage = this.variant.getProperty<StaticRoutingAddonData>(Addon.StaticRouting)
    if (!staticPage) {
      return
    }

    this.hasWildcard = staticPage.hasWildcard || false
    this.isSpa = staticPage.isSpa
    this.route = staticPage.route
  }
}

export default StaticRoutingAddon
