




























import { Component, Mixins, Prop } from 'vue-property-decorator'
import { DashmixIconName } from '@movecloser/ui-core'
import { Fragment } from 'vue-fragment'

import {
  ISettingsPermissions,
  SettingsPermissions
} from '../config/permissions'
import { NavigationData } from '../contracts'

@Component({
  name: 'NavigationTableRow',
  components: { Fragment }
})
export class NavigationTableRow extends Mixins<ISettingsPermissions>(SettingsPermissions) {
  @Prop({ type: Object, required: true })
  protected data!: NavigationData

  public Icons = DashmixIconName
}

export default NavigationTableRow
