
































































































import {
  DashmixAccordionItem,
  DashmixAlertTheme,
  DashmixBreadcrumbsProps,
  DashmixIconName
} from '@movecloser/ui-core'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { DateTimeType, IDateTime, IModal, ModalType } from '@movecloser/front-core'
import { MetaInfo } from 'vue-meta'

import { Identifier, Inject, IRelatedService, logger, RelatedServiceType } from '../../../backoffice'
import { Loader } from '../../shared/components/Loader'
import { PageBuilder } from '../../shared/components/PageBuilder'
import { Query } from '../../shared/contracts/query'

import { AcceptancePreview } from '../components/AcceptancePreview.vue'
import { AcceptanceAddons } from '../components/AcceptanceAddons.vue'
import {
  AcceptanceModel,
  AcceptanceRepositoryType,
  IAcceptanceRepository,
  IVariantsRepository,
  VariantsRepositoryType
} from '../contracts'
import { ConfirmAcceptanceModalMode } from '../contracts/components'
import { ContentModals } from '../config/modals'
import { ContentTypeValidator, IContentTypeAware } from '../mixins'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
@Component<Acceptance>({
  name: 'Acceptance',
  components: { AcceptanceAddons, Loader, PageBuilder },
  metaInfo (this: Acceptance): MetaInfo {
    return {
      title: `${this.$t('content.acceptance.listTitle')}`
    }
  }
})
export class Acceptance extends Mixins<IContentTypeAware>(ContentTypeValidator) {
  @Prop({ type: Object, required: false })
  public accordionItem!: DashmixAccordionItem

  @Inject(AcceptanceRepositoryType)
  protected acceptanceRepository!: IAcceptanceRepository

  @Inject(DateTimeType)
  protected dateTime!: IDateTime

  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(RelatedServiceType)
  protected readonly relatedService!: IRelatedService

  @Inject(VariantsRepositoryType)
  protected variantsRepository!: IVariantsRepository

  public alertTheme = DashmixAlertTheme.Warning
  public DashmixIconName = DashmixIconName
  public isLoading: boolean = true
  public isPending: boolean = false
  public model: AcceptanceModel = {} as AcceptanceModel
  public pendingName: string = ''
  public rightItemToToggle: number = -1
  public leftItemToToggle: number = -1

  protected activeAddonTab: string = ''
  protected error: string | null = null

  public get breadcrumbs (): DashmixBreadcrumbsProps {
    const base = {
      items: [
        {
          label: this.$tc(this.contentTypeManager.getLabel(this.contentType), 2).toString(),
          target: { name: 'content.list', params: { type: this.contentType } }
        }, {
          label: `${this.$t('content.acceptance.listTitle')}`,
          target: { name: 'content.acceptance.list' }
        }
      ],
      root: {
        label: `${this.$t('root.views.root')}`,
        target: { name: 'root.dashboard' }
      }
    }

    if (this.model) {
      base.items.push({
        label: this.model.title,
        target: { name: 'content.acceptance.list' }
      })
    }

    return base
  }

  public get modulesAsIs () {
    return {
      items: [
        {
          id: 'content-as',
          label: `${this.$t('content.acceptance.content')}`,
          component: AcceptancePreview,
          props: {
            content: this.model.content,
            contentType: this.model.contentType,
            model: this.model.asIs,
            isOpen: true,
            relatedService: this.relatedService
          }
        },
        {
          id: 'addons-as',
          label: `${this.$t('content.acceptance.addons')}`,
          component: AcceptanceAddons,
          props: {
            active: this.activeAddonTab,
            model: this.model.asIs,
            onActiveChange: this.onActiveAddonTabChange,
            relatedService: this.relatedService
          }
        }
      ]
    }
  }

  public get modulesToBe () {
    return {
      items: [
        {
          id: 'content-be',
          label: `${this.$t('content.acceptance.content')}`,
          component: AcceptancePreview,
          props: {
            content: this.model.content,
            contentType: this.model.contentType,
            model: this.model.toBe,
            isOpen: true,
            relatedService: this.relatedService
          }
        },
        {
          id: 'addons-be',
          label: `${this.$t('content.acceptance.addons')}`,
          component: AcceptanceAddons,
          props: {
            active: this.activeAddonTab,
            model: this.model.toBe,
            onActiveChange: this.onActiveAddonTabChange,
            relatedService: this.relatedService
          }
        }
      ]
    }
  }

  public get queryParams (): Query {
    return this.$route.query
  }

  public acceptAction (): void {
    this.modalConnector.open(ContentModals.ConfirmAcceptance, {
      mode: ConfirmAcceptanceModalMode.Accept,
      onConfirm: () => {
        this.modalConnector.close()
        this.accept()
      },
      onClose: () => this.modalConnector.close()
    })
  }

  public editAction (): void {
    this.modalConnector.open(ContentModals.ConfirmAcceptance, {
      mode: ConfirmAcceptanceModalMode.Edit,
      onConfirm: () => {
        this.modalConnector.close()
        this.edit()
      },
      onClose: () => this.modalConnector.close()
    })
  }

  public onLeftAccordionToggle (event: { itemIndex: number }): void {
    this.rightItemToToggle = -1
    this.$nextTick(() => {
      this.rightItemToToggle = event.itemIndex
    })
  }

  public onRightAccordionToggle (event: { itemIndex: number }): void {
    this.leftItemToToggle = -1
    this.$nextTick(() => {
      this.leftItemToToggle = event.itemIndex
    })
  }

  public rejectAction (): void {
    this.modalConnector.open(ContentModals.ConfirmAcceptance, {
      mode: ConfirmAcceptanceModalMode.Reject,
      onConfirm: (message: string) => {
        this.modalConnector.close()
        this.reject(message)
      },
      onClose: () => this.modalConnector.close(),
      textArea: true
    })
  }

  public returnAction (): void {
    this.modalConnector.open(ContentModals.ConfirmAcceptance, {
      mode: ConfirmAcceptanceModalMode.Return,
      onConfirm: (message: string) => {
        this.modalConnector.close()
        this.return(message)
      },
      onClose: () => this.modalConnector.close(),
      textArea: true
    })
  }

  private async accept (): Promise<void> {
    this.setPending('accept')

    try {
      await this.acceptanceRepository.accept(
        this.model.id
      )

      await this.$router.push({ name: 'content.acceptance.list', params: { type: this.contentType } })
    } catch (e) {
      logger(e, 'error')
    }

    this.setPending('')
  }

  private async edit (): Promise<void> {
    this.setPending('edit')

    try {
      await this.variantsRepository.lock(
        this.model.content,
        this.model.id
      )

      await this.$router.push({
        name: 'content.edit.content',
        params: { id: `${this.model.toBe.content}`, type: this.model.contentType }
      })
    } catch (e) {
      logger(e, 'error')
    }
  }

  private async loadData (id: Identifier): Promise<void> {
    this.isLoading = true

    try {
      this.model = await this.acceptanceRepository.load(id)
    } catch (e) {
      logger(e, 'error')
    }

    this.isLoading = false
  }

  private onActiveAddonTabChange (tab: string): void {
    this.activeAddonTab = tab
  }

  protected async onPositiveTypeValidation (): Promise<void> {
    await this.loadData(this.$route.params.id)
  }

  protected async onTypeValidationFail (defaultType: string): Promise<void> {
    await this.$router.push({
      name: 'content.acceptance.list', params: { type: defaultType }
    })
  }

  private async reject (message: string): Promise<void> {
    this.setPending('reject')

    try {
      await this.acceptanceRepository.reject(
        this.model.id,
        message
      )

      await this.$router.push({ name: 'content.acceptance.list', params: { type: this.contentType } })
    } catch (e) {
      logger(e, 'error')
    }

    this.setPending('')
  }

  private async return (message: string): Promise<void> {
    this.setPending('return')

    try {
      await this.acceptanceRepository.return(
        this.model.id,
        message
      )

      await this.$router.push({ name: 'content.acceptance.list', params: { type: this.contentType } })
    } catch (e) {
      logger(e, 'error')
    }

    this.setPending('')
  }

  private setPending (name: string): void {
    this.isPending = name !== ''
    this.pendingName = name
  }
}

export default Acceptance
