





















import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { DashmixIconName, DashmixSelectItem } from '@movecloser/ui-core'

import { Identifier, Inject } from '../../../backoffice'
import { ListGroup, ListGroupItem } from '../../shared/components/ListGroup'

import { ContentStatus, ContentType, ContentVariantTabEvent, ContentVariantTabToggle, IVariantsRepository, SimpleVariantModel, VariantsCounts, VariantModel, VariantsRepositoryType } from '../contracts'
import { VariantSelectionItem } from './VariantSelectionItem.vue'
import { variantStatusGroups } from '../maps/variant'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component({
  name: 'VariantsSelection',
  components: { VariantSelectionItem, ListGroup }
})
export class VariantsSelection extends Vue {
  @PropSync('active', { type: String, required: true })
  protected activeTab!: string

  @Prop({ type: String, required: true })
  public contentType!: ContentType

  @PropSync('isDisabled', { type: Boolean, required: false })
  public isSaving!: boolean

  @PropSync('items', { type: Array, required: true })
  protected selectedVariants!: VariantModel[]

  @Prop({ type: Array, required: true })
  protected variants!: SimpleVariantModel[]

  @Prop({ type: Object, required: true })
  protected variantsCount!: VariantsCounts

  @Inject(VariantsRepositoryType)
  protected variantsRepository!: IVariantsRepository

  public icons = DashmixIconName
  public statusSelected: ContentStatus = ContentStatus.Published

  public get filteredVariants (): ListGroupItem[] {
    return this.variants.filter(v => variantStatusGroups[this.statusSelected as string].includes.includes(v.status))
      .map(v => ({ data: v }))
  }

  public get statusOptions (): DashmixSelectItem[] {
    return Object.values(variantStatusGroups).map(g => {
      return {
        label: this.$tc(`content.statuses.${g.group}`, 2, {
          count: this.countVariants(g.includes)
        }),
        value: g.group
      }
    })
  }

  mounted () {
    if (!this.variants.some(v => v.status === ContentStatus.Published)) {
      if (this.variantsCount[ContentStatus.Accepted] > 0) {
        this.statusSelected = ContentStatus.Accepted
      } else if (this.variantsCount[ContentStatus.Draft] > 0) {
        this.statusSelected = ContentStatus.Draft
      }
    }
  }

  public toggleTab (payload: ContentVariantTabEvent): void {
    payload.action === ContentVariantTabToggle.Pin ? this.pinVariant(payload.id) : this.unpinVariant(payload.id)
  }

  protected countVariants (toInclude: ContentStatus[]): number {
    return this.variants.filter(v => toInclude.includes(v.status)).length
  }

  protected pinVariant (id: Identifier): void {
    this.variantsRepository.load(this.$route.params.id as unknown as Identifier, id).then(model => {
      this.selectedVariants.push(model)
      this.activeTab = `${id}`
    })
  }

  protected unpinVariant (id: Identifier): void {
    if (this.selectedVariants.length === 1) return

    for (let i = 0; i < this.selectedVariants.length; ++i) {
      if (this.selectedVariants[i].id === id) {
        this.selectedVariants.splice(i, 1)
      }
    }

    if (this.activeTab === `${id}`) {
      this.activeTab = `${this.selectedVariants[0].id}`
    }
  }
}

export default VariantsSelection
