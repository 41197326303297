// Copyright © 2021 Move Closer

import { ICollection, IResponse } from '@movecloser/front-core'

import { AnyModule, ContainerModule, Identifier } from '../../../backoffice'
import { Query } from '../../shared/contracts/query'

import {
  AcceptanceData,
  AcceptanceModel,
  ArticleFilterModel,
  ContentData,
  ContentInfoData,
  ContentModel,
  NodeModel,
  RegistryPayload,
  SimpleContentModel,
  VariantData,
  VariantModel
} from './models'

import { CanDetectStatus } from '../services/variantStatus'
import {
  ContentCreatePayload,
  IContentOptionsPayload,
  IPublishContentPayload,
  ValidationType,
  VariantUpdatePayload
} from '../contracts/data'
import { IEditContentIntention } from './intentions'
import { SaveVariantMode } from './components'

export const AcceptanceRepositoryType = Symbol.for('IAcceptanceRepository')

export const ContentRepositoryType = Symbol.for('IContentRepository')

export interface IAcceptanceRepository {
  accept (id: AcceptanceData['id']): Promise<void>
  load (id: AcceptanceData['id']): Promise<AcceptanceModel>
  loadCollection (types: string | string[], query: Query): Promise<ICollection<AcceptanceModel>>
  reject (id: AcceptanceData['id'], message: string): Promise<void>
  return (id: AcceptanceData['id'], message: string): Promise<void>
}

export interface IContentRepository {
  create (payload: ContentCreatePayload): Promise<Identifier>
  changeOrder (parent: ContentData['id'], children: Identifier[]): Promise<ContentModel>
  delete (id: ContentData['id']): Promise<void>
  load (id: ContentData['id']): Promise<ContentModel>
  loadCollection (types: string | string[], query: Query): Promise<ICollection<ContentModel>>
  loadContentInfo (): Promise<ContentInfoData>
  loadNode (types: string | string[], id: ContentData['id'], query: Query): Promise<ContentModel>
  loadTree (query: Query): Promise<ICollection<SimpleContentModel>>
  options (id: ContentData['id'], payload: IContentOptionsPayload): Promise<void>
  publish (id: ContentData['id'], payload: IPublishContentPayload): Promise<void>
  registry (slug: string): Promise<RegistryPayload>
  update (id: ContentData['id'], payload: IEditContentIntention): Promise<IResponse>
}

export interface IArticleFiltersRepository {
  list (): Promise<ICollection<ArticleFilterModel>>
}

export interface INodesRepository {
  loadTree (query: Query, types?: string[]): Promise<ICollection<NodeModel>>
}

export interface IVariantsRepository extends CanDetectStatus {
  create (content: ContentData['id']): Promise<VariantModel>
  clone (content: ContentData['id'], from: VariantData['id']): Promise<VariantModel>
  delete (content: ContentData['id'], id: VariantData['id']): Promise<void>
  load (content: ContentData['id'], id: VariantData['id']): Promise<VariantModel>
  lock (content: ContentData['id'], id: VariantData['id']): Promise<void>
  preview (content: ContentData['id'], id: VariantData['id']): Promise<string>
  unpublish (content: ContentData['id'], id: VariantData['id']): Promise<void>
  update (
    content: ContentData['id'],
    id: VariantData['id'],
    payload: VariantUpdatePayload,
    postSave?: SaveVariantMode
  ): Promise<void>
  updateAndPreview (content: ContentData['id'], id: VariantData['id'], payload: VariantUpdatePayload): Promise<string>
  validate (payload: AnyModule | ContainerModule, type: ValidationType): Promise<any>
}

export const ArticleFiltersRepositoryType = Symbol.for('IArticleFiltersRepository')
export const NodesRepositoryType = Symbol.for('INodesRepository')
export const VariantsRepositoryType = Symbol.for('IVariantsRepository')
