// Copyright © 2021 Move Closer

import { Domain } from '../../root/contracts/models'
import { UserModel } from '../../auth/contracts/models'
import { Permission } from '../../auth/contracts/permissions'

export const canViewProducts = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.ViewProducts)
}

export const canEditProducts = (domain: Domain, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.EditProducts)
}
