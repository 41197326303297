// Copyright © 2021 Move Closer

import { Container, RoutesFactory } from '@movecloser/front-core'

import LoginPage from '../views/LoginPage.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import MySessionsView from '../views/MySessionsView.vue'
import NoAccessView from '../views/NoAccessView.vue'
import ResetPassword from '../views/ResetPassword.vue'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const routesFactory: RoutesFactory = (container: Container) => {
  return [
    {
      path: '/access/forgot-password',
      name: 'forgotPassword',
      component: ForgotPassword,
      meta: {
        layout: 'FullScreenLayout',
        requiresAuth: false,
        noSiteId: true
      }
    },
    {
      path: '/access/login',
      name: 'login',
      component: LoginPage,
      meta: {
        layout: 'FullScreenLayout',
        requiresAuth: false,
        noSiteId: true
      }
    },
    {
      path: '/access/reset-password/:token',
      name: 'resetPassword',
      component: ResetPassword,
      meta: {
        layout: 'FullScreenLayout',
        requiresAuth: false,
        noSiteId: true
      }
    },
    {
      path: '/access/no-access',
      name: 'no-access',
      component: NoAccessView,
      meta: {
        layout: 'FullScreenLayout'
      }
    },
    {
      path: '/me/sessions',
      name: 'my-sessions',
      component: MySessionsView,
      meta: {
        requiresAuth: false,
        noSiteId: true
      }
    }
  ]
}
