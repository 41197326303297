// Copyright © 2021 Move Closer

import { Permission } from '../../auth/contracts/permissions'

import { PossibleRelatedType, SetType } from '../../../backoffice'
import { Route } from 'vue-router'

export const permissionsFactory = (to: Route): Permission[] => {
  const setType = to.params.type as unknown as SetType

  switch (setType) {
    case PossibleRelatedType.Articles:
      if (to.name === 'articles.edit') {
        return [Permission.EditArticleSet]
      } else if (to.name === 'articles.create') {
        return [Permission.CreateArticleSet]
      } else if (to.name === 'articles.list') {
        return [Permission.ViewArticleSet]
      }
      return []
    case PossibleRelatedType.Products:
      if (to.name === 'products.edit') {
        return [Permission.EditProductSet]
      } else if (to.name === 'products.create') {
        return [Permission.CreateProductSet]
      } else if (to.name === 'products.list') {
        return [Permission.ViewProductSet]
      }
      return []
    default:
      return []
  }
}
