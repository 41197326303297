// Copyright © 2021 Move Closer

/**
 * Helper function to generate slugs from name string
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 * @param name - string
 */
export const generateSlug = (name: string): string => {
  return name.toLowerCase()
    .normalize('NFD')
    .replace(/[\u0142]/g, 'l')
    .replace(/ /g, '-')
    .replace(/[-]+/g, '-')
    .replace(/[^\w-]+/g, '')
}
