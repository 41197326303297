


















import { Component, Mixins, Watch } from 'vue-property-decorator'
import {
  DashmixBoxTabItem,
  DashmixBreadcrumbsProps,
  FiltersConfig,
  GroupsConfig
} from '@movecloser/ui-core'
import { VueConstructor } from 'vue'
import { MetaInfo } from 'vue-meta'

import { DropdownActions } from '../../shared/contracts/content'
import { Identifier, Inject, logger } from '../../../backoffice'
import { InteractiveTable } from '../../shared/components/InteractiveTable/InteractiveTable.vue'
import { Query } from '../../shared/contracts/query'

import {
  acceptanceAcceptedTableHead,
  AcceptanceActions,
  acceptancePendingTableHead,
  acceptanceRejectedTableHead,
  acceptanceRowActions
} from '../maps/acceptance'
import { AcceptanceTableRow } from '../components/AcceptanceTableRow.vue'
import {
  AcceptanceData,
  AcceptanceModel,
  AcceptanceRepositoryType,
  AcceptanceStatus,
  AcceptanceTableRowElement,
  IAcceptanceRepository
} from '../contracts'
import {
  acceptanceFiltersConfig,
  AcceptanceStatusFiltersConfig
} from '../models/acceptance.filters'
import { ContentTypeValidator, IContentTypeAware } from '../mixins'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component<AcceptanceList>({
  name: 'AcceptanceList',
  components: { InteractiveTable },
  metaInfo (this: AcceptanceList): MetaInfo {
    return {
      title: `${this.$t('content.acceptance.listTitle')}`
    }
  },

  updated () {
    this.validateQuery()
  }
})
export class AcceptanceList extends Mixins<IContentTypeAware>(ContentTypeValidator) {
  @Inject(AcceptanceRepositoryType)
  protected acceptanceRepository!: IAcceptanceRepository

  public isLoading: boolean = true
  public itemActions = acceptanceRowActions
  public model: AcceptanceModel = {} as AcceptanceModel
  public itemActionsDefinitions: DropdownActions = {
    [AcceptanceActions.Accept]: {
      callback: (data: unknown) => {
        const model = data as AcceptanceData
        return this.accept(model.id)
      }
    },
    [AcceptanceActions.Preview]: {
      callback: (data: unknown) => {
        this.$router.push({
          name: 'content.acceptance.show',
          params: { type: (data as AcceptanceData).contentType, id: `${(data as AcceptanceData).id}` }
        })
      }
    }
  }

  public tabs: DashmixBoxTabItem[] = [
    {
      tab: {
        id: AcceptanceStatus.Pending,
        label: `${this.$t('content.acceptanceList.pending')}`
      }
    },
    {
      tab: {
        id: AcceptanceStatus.Accepted,
        label: `${this.$t('content.acceptanceList.accepted')}`
      }
    },
    {
      tab: {
        id: AcceptanceStatus.Published,
        label: `${this.$t('content.acceptanceList.published')}`
      }
    },
    {
      tab: {
        id: AcceptanceStatus.Rejected,
        label: `${this.$t('content.acceptanceList.rejected')}`
      }
    }
  ]

  public tableItems: AcceptanceTableRowElement[] = []
  public tableRow: VueConstructor = AcceptanceTableRow

  public totalItems: number = 0
  public totalPages: number = 0

  public get breadcrumbs (): DashmixBreadcrumbsProps {
    return {
      items: [
        {
          label: this.$tc(this.contentTypeManager.getLabel(this.contentType), 2).toString(),
          target: { name: 'content.list', params: { type: this.contentType } }
        }, {
          label: `${this.$t('content.acceptance.listTitle')}`,
          target: { name: 'content.acceptance.list' }
        }
      ],
      root: {
        label: `${this.$t('root.views.root')}`,
        target: { name: 'root.dashboard' }
      },
      showOnMobile: false
    }
  }

  public get queryParams (): Query {
    return this.$route.query
  }

  public get status (): AcceptanceStatus {
    return this.queryParams.status as AcceptanceStatus
  }

  public get tableHead () {
    switch (this.status) {
      case AcceptanceStatus.Pending:
        return acceptancePendingTableHead
      case AcceptanceStatus.Rejected:
        return acceptanceRejectedTableHead
      default:
        return acceptanceAcceptedTableHead
    }
  }

  public get tabFilters (): FiltersConfig {
    const tabFilters: GroupsConfig = {}

    if (!this.status) {
      return {
        groups: tabFilters,
        ignore: [],
        override: {}
      }
    }

    for (const filterKey of AcceptanceStatusFiltersConfig[this.status]) {
      tabFilters[filterKey] = acceptanceFiltersConfig.groups[filterKey]
    }

    return {
      ...acceptanceFiltersConfig,
      groups: tabFilters
    }
  }

  public switchTab (value: string) {
    this.$router.push({
      name: 'content.acceptance.list',
      params: { content: this.contentType },
      query: { status: value }
    })
  }

  protected accept (id: Identifier) {
    return this.acceptanceRepository.accept(id).then(() => {
      this.tableItems = this.tableItems.filter(rowData => rowData.id !== id.toString())
    })
  }

  protected async loadList (query: Query = {}): Promise<void> {
    this.isLoading = true

    try {
      const types = [
        this.contentType,
        ...this.contentTypeManager.getContentListConfig(this.contentType).loadWith
      ]

      const collection = await this.acceptanceRepository.loadCollection(
        types,
        { ...query, status: this.status || AcceptanceStatus.Pending }
      )

      this.tableItems = [...collection].map(model => {
        return { id: `${model.id}`, selectable: true, data: model }
      })

      this.totalPages = Math.ceil(collection.meta.total / collection.meta.per_page)
      this.totalItems = collection.meta.total
    } catch (e) {
      logger(e, 'error')
    }

    this.isLoading = false
  }

  protected async onPositiveTypeValidation (): Promise<void> {
    await this.validateQuery()
    await this.loadList()
  }

  @Watch('queryParams', { deep: true })
  protected onQueryChange (query: Query): void {
    this.loadList(query)
  }

  protected async onTypeValidationFail (defaultType: string): Promise<void> {
    await this.$router.push({
      name: 'content.acceptance.list', params: { type: defaultType }
    })
  }

  protected async validateQuery (): Promise<void> {
    if (
      typeof this.queryParams.status === 'string' &&
      Object.values(AcceptanceStatus).includes(this.status)
    ) {
      return
    }

    await this.$router.push({
      name: 'content.acceptance.list',
      params: { content: this.contentType },
      query: { status: AcceptanceStatus.Pending }
    })
  }
}

export default AcceptanceList
