// Copyright © 2021 Move Closer

import { AbstractIntention, MappingTypes } from '@movecloser/front-core'

import { ICreateContentIntention } from '../contracts/intentions'

export class AddContentIntention extends AbstractIntention<ICreateContentIntention> {
  protected map = {
    parent: {
      type: MappingTypes.Function,
      value: (model: ICreateContentIntention) => {
        if (!model.parent) {
          return
        }
        return model.parent?.id
      },
      target: 'parentId'
    },
    title: 'title',
    type: 'type',
    slug: 'slug',
    properties: 'properties'
  }
}
