// Copyright © 2021 Move Closer

import { AbstractIntention, MappingTypes } from '@movecloser/front-core'

import { PermissionsListItemProps } from '../contracts/components'

export interface CreateRolePayload {
  name: string
  permissions: PermissionsListItemProps[]
}

export class CreateRoleIntention extends AbstractIntention<CreateRolePayload> {
  protected map = {
    name: 'name',
    permissions: {
      type: MappingTypes.Function,
      value: (intention: CreateRolePayload) => {
        return intention.permissions
          .filter((permission: PermissionsListItemProps) => permission.checked)
          .map((permission: PermissionsListItemProps) => permission.value)
      },
      target: 'permissions'
    }
  }
}
