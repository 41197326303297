// Copyright © 2021 Move Closer

import { containerFormComponent, containerUiComponent, modulesFormRegistry, modulesUiRegistry } from '@core'

import {
  ModuleComponentsRegistry,
  ModuleFactoriesRegistry,
  ModuleResolversRegistry,
  ModuleSourceComponentsRegistry,
  ModulesRenderRegistry,
  ModulesVersionsRegistry,
  PageBuilderConfig
} from '@component/PageBuilder'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
function composeBuilderConfig (): PageBuilderConfig {
  const componentFactories: ModuleFactoriesRegistry = {}
  const componentResolvers: ModuleResolversRegistry = {}
  const formComponents: ModulesRenderRegistry = {}
  const moduleRegistry: ModuleComponentsRegistry = {}
  const sourceComponents: ModuleSourceComponentsRegistry = {}
  const uiComponents: ModulesRenderRegistry = {}
  const versionsRegistry: ModulesVersionsRegistry = {}

  for (const [key, entry] of Object.entries(modulesUiRegistry)) {
    componentResolvers[key] = entry.resolver
    uiComponents[key] = entry.component
  }

  for (const [key, entry] of Object.entries(modulesFormRegistry)) {
    componentFactories[key] = entry.factory
    formComponents[key] = entry.component
    sourceComponents[key] = entry.sourceComponent ?? null
    moduleRegistry[key] = {
      image: entry.icon,
      label: entry.label,
      priority: entry.priority || 0,
      restrictions: {
        allowedTypes: entry.restrictions?.allowedTypes || ['*'],
        forbiddenTypes: entry.restrictions?.forbiddenTypes || []
      }
    }

    if (
      'versions' in entry && typeof entry.versions === 'object' &&
      entry.versions !== null && Object.keys(entry.versions).length > 0
    ) {
      versionsRegistry[key] = entry.versions
    }
  }

  return {
    componentFactories,
    containerComponent: containerUiComponent,
    containerForm: containerFormComponent,
    formComponents,
    moduleRegistry,
    sourceComponents,
    uiComponents,
    versionsRegistry
  }
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const builder: PageBuilderConfig = composeBuilderConfig()
