// Copyright © 2021 Move Closer

import { Permission } from '../../auth/contracts/permissions'
import { UserModel } from '../../auth/contracts/models'

/**
 * Users
 */
export const canCreateUsers = (domain: string, user: UserModel | null): boolean => {
  if (!user) {
    return false
  }

  return user.canPerform(domain, Permission.CreateUsers)
}

export const canEditUsers = (domain: string, user: UserModel | null): boolean => {
  if (!user) {
    return false
  }

  return user.canPerform(domain, Permission.EditUsers)
}

export const canViewUsers = (domain: string, user: UserModel | null): boolean => {
  if (!user) {
    return false
  }

  return user.canPerform(domain, Permission.ViewUsers)
}

export const canDeleteUsers = (domain: string, user: UserModel | null): boolean => {
  if (!user) {
    return false
  }

  return user.canPerform(domain, Permission.DeleteUsers)
}

/**
 * Roles
 */
export const canCreateRoles = (domain: string, user: UserModel | null): boolean => {
  if (!user) {
    return false
  }

  return user.canPerform(domain, Permission.CreateRoles)
}

export const canEditRoles = (domain: string, user: UserModel | null): boolean => {
  if (!user) {
    return false
  }

  return user.canPerform(domain, Permission.EditRoles)
}

export const canDeleteRoles = (domain: string, user: UserModel | null): boolean => {
  if (!user) {
    return false
  }

  return user.canPerform(domain, Permission.DeleteRoles)
}

export const canViewRoles = (domain: string, user: UserModel | null): boolean => {
  if (!user) {
    return false
  }

  return user.canPerform(domain, Permission.ViewRoles)
}

/**
 * Authors
 */

export const canCreateAuthors = (domain: string, user: UserModel | null): boolean => {
  if (!user) {
    return false
  }

  return user.canPerform(domain, Permission.CreateAuthors)
}

export const canDeleteAuthors = (domain: string, user: UserModel | null): boolean => {
  if (!user) {
    return false
  }

  return user.canPerform(domain, Permission.DeleteAuthors)
}

export const canEditAuthors = (domain: string, user: UserModel | null): boolean => {
  if (!user) {
    return false
  }

  return user.canPerform(domain, Permission.EditAuthors)
}

export const canViewAuthors = (domain: string, user: UserModel | null): boolean => {
  if (!user) {
    return false
  }

  return user.canPerform(domain, Permission.ViewAuthors)
}
