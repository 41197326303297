// Copyright © 2021 Move Closer

import { Component } from 'vue-property-decorator'

import { IUserAware, UserAware } from '../../auth/shared/user-aware.abstract'

import {
  canCreateArticleSet,
  canCreateProductSet,
  canDeleteArticleSet,
  canDeleteProductSet,
  canEditArticleSet,
  canEditProductSet,
  canViewArticleSet,
  canViewProductSet
} from './guards'

export interface ISetsPermissions extends IUserAware {
  canCreateArticles: boolean
  canViewArticles: boolean
  canDeleteArticles: boolean
  canEditArticles: boolean
  canCreateProducts: boolean
  canViewProducts: boolean
  canDeleteProducts: boolean
  canEditProducts: boolean
}

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
@Component<SetsPermissions>({})
export class SetsPermissions extends UserAware implements ISetsPermissions {
  /**
   * Articles permissions
   */
  public get canViewArticles () {
    return canViewArticleSet(this.domain, this.authService.user)
  }

  public get canCreateArticles () {
    return canCreateArticleSet(this.domain, this.authService.user)
  }

  public get canEditArticles () {
    return canEditArticleSet(this.domain, this.authService.user)
  }

  public get canDeleteArticles () {
    return canDeleteArticleSet(this.domain, this.authService.user)
  }

  /**
   * Products permissions
   */
  public get canViewProducts () {
    return canViewProductSet(this.domain, this.authService.user)
  }

  public get canCreateProducts () {
    return canCreateProductSet(this.domain, this.authService.user)
  }

  public get canEditProducts () {
    return canEditProductSet(this.domain, this.authService.user)
  }

  public get canDeleteProducts () {
    return canDeleteProductSet(this.domain, this.authService.user)
  }
}
