// Copyright © 2022 Move Closer

import { Component, Mixins, Watch } from 'vue-property-decorator'
import { IModal, ModalType } from '@movecloser/front-core'
import { TableRowElement } from '@movecloser/ui-core'

import { Inject } from '../../../backoffice'
import { ModelListHeaderProps } from '../../shared/components/ModelListHeader'
import { Query } from '../../shared/contracts/query'

import { ContentListConfig } from '../services/content-type-manager'
import {
  ContentRepositoryType,
  IContentRepository,
  IVariantsRepository,
  VariantsRepositoryType
} from '../contracts'
import { ContentPermissions, ContentTypeAware, IContentPermissions, IContentTypeAware } from '../mixins'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component<AbstractList>({
  name: 'AbstractList',
  mounted () {
    this.isLoading = true

    this.loadList(this.queryParams).finally(() => {
      this.isLoading = false
    })
  }
})
export class AbstractList extends Mixins<IContentPermissions, IContentTypeAware>(
  ContentPermissions,
  ContentTypeAware
) {
  @Inject(ContentRepositoryType)
  protected contentRepository!: IContentRepository

  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(VariantsRepositoryType)
  protected variantsRepository!: IVariantsRepository

  public isLoading = false
  public itemsTotal: number = 0
  public tableData: TableRowElement[] = []

  public get contentListConfig (): ContentListConfig {
    return this.contentTypeManager.getContentListConfig(this.contentType)
  }

  public get isSearching (): boolean {
    return typeof this.queryParams.q === 'string' && this.queryParams.q.length > 0
  }

  public get listHeader () {
    const payload: Partial<ModelListHeaderProps> = {
      title: this.$tc(this.contentTypeManager.getLabel(this.contentType), 2).toString()
    }

    if (this.canCreateContents) {
      payload.addLabel = this.$t(`${this.contentType}.add`).toString()
      payload.addTarget = { name: 'content.create', params: { type: this.contentType } }
    }

    return payload
  }

  public get queryParams (): Query {
    return this.$route.query
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected async loadList (query: Query): Promise<void> {
    throw new Error('[AbstractList]: Method loadList has to be implemented.')
  }

  @Watch('queryParams', { deep: true })
  protected onQueryChange (query: Query): void {
    this.isLoading = true

    this.loadList(query).finally(() => {
      this.isLoading = false
    })
  }
}
