




















import { Component, PropSync } from 'vue-property-decorator'

import { FormInput } from '../../shared/components/form'

import { ConfigForm } from './_abstract/ConfigForm.vue'

@Component<ProductCategoriesLevelZeroSettingsForm>({
  name: 'ProductCategoriesLevelZeroSettingsForm',
  components: { FormInput },
  created () {
    this.disabledCategories = this.payload.toString()
  }
})
export class ProductCategoriesLevelZeroSettingsForm extends ConfigForm {
  @PropSync('formData', { type: Array, required: true })
  public payload!: number[]

  public readonly formName = 'generalSettings'

  public disabledCategories = ''

  public loading: boolean = false

  public onSubmit () {
    this.payload = this.disabledCategories.split(',')
      .map(disabledCategory => Number(disabledCategory)) as number[]
    this.$emit('submit', { reproject: true, global: false })
  }
}

export default ProductCategoriesLevelZeroSettingsForm
