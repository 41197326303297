// Copyright © 2021 Move Closer

import { SortDirectionMap } from '@movecloser/ui-core'

export interface SortConfig {
  field: string
  direction: SortDirectionMap
}

export function SortToString (sorting: CurrentSorting) {
  if (sorting === null) {
    return ''
  }
  return (sorting.direction === SortDirectionMap.Asc ? '+' : '-') + sorting.field
}

export function StringToSort (url: string) {
  return {
    field: url.substring(1),
    direction: url[0] === '+' ? SortDirectionMap.Asc : SortDirectionMap.Desc
  }
}

export type CurrentSorting = SortConfig | null
