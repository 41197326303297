// Copyright © 2022 Move Closer

import { Component, Vue } from 'vue-property-decorator'

import { Inject } from '../../../backoffice'

import { ContentTypeManagerType, IContentTypeManager } from '../services/content-type-manager'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface IContentTypeAware {
  contentType: string
  contentTypeManager: IContentTypeManager
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component<ContentTypeAware>({
  name: 'ContentTypeAware'
})
export class ContentTypeAware extends Vue implements IContentTypeAware {
  @Inject(ContentTypeManagerType)
  public readonly contentTypeManager!: IContentTypeManager

  public get contentType (): string {
    return this.$route.params.type || 'unknown'
  }
}
