// Copyright © 2021 Move Closer

import {
  AbstractBreadcrumbsItem
} from '@movecloser/ui-core'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const rootBreadcrumb: AbstractBreadcrumbsItem = {
  label: 'Home',
  target: { name: 'root.dashboard' }
}

export const initBreadcrumbs = {
  items: [],
  root: rootBreadcrumb
}
