






















import { Component, Prop, Vue } from 'vue-property-decorator'
import { DateTimeType, IDateTime } from '@movecloser/front-core'
import { Fragment } from 'vue-fragment'

import { Inject } from '../../../backoffice'

import { AcceptanceModel, AcceptanceStatus, ContentStatus } from '../contracts'
import {
  ContentTypeManagerType,
  IContentTypeManager
} from '../services/content-type-manager'

@Component({
  name: 'AcceptanceTableRowShortened',
  components: { Fragment }
})
export class AcceptanceTableRowShortened extends Vue {
  @Prop({ type: Object, required: true })
  public data!: AcceptanceModel

  @Inject(ContentTypeManagerType)
  public readonly contentTypeManager!: IContentTypeManager

  @Inject(DateTimeType)
  protected dateTime!: IDateTime

  public get acceptanceStatus (): AcceptanceStatus {
    return this.$route.query.status as AcceptanceStatus
  }

  public get status (): string {
    return this.data.status || `${ContentStatus.Unpublished}`
  }

  public get typeLabel (): string {
    return this.$tc(this.contentTypeManager.getLabel(this.data.contentType), 1).toString()
  }
}

export default AcceptanceTableRowShortened
