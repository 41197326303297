






















import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { Link } from '../../shared/contracts/data'
import { FormInput } from '../../shared/components/form'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
@Component({
  name: 'LinkPickerExternal',
  components: {
    FormInput
  }
})
export class LinkPickerExternal extends Vue {
  @PropSync('model', { type: Object, required: false })
  public data?: Link

  @Prop({ type: Function, required: true })
  public onUpdateName!: (data: string) => void

  @Prop({ type: Function, required: true })
  public onUpdateUrl!: (data: string) => void

  @Prop({ type: String, required: false, default: 'linkPicker' })
  public form!: string
}

export default LinkPickerExternal
