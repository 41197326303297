// Copyright © 2022 Move Closer

// Copyright © 2022 Move Closer

import { SiteModel } from '../../../../backoffice'

import { ContentTypeConfig } from './service.contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const defaultContentType: string = 'page'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const defaultEditor: string = 'pageBuilder'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const defaultListing: string = 'treeNodes'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function defaultContentTypeConfig (site?: SiteModel): ContentTypeConfig {
  return {
    actions: null,
    editor: defaultEditor,
    filters: null,
    label: 'content.contentTypes.page',
    listing: defaultListing,
    loadAs: null,
    loadWith: [],
    hasParent: true,
    hasPreview: true,
    parentType: null,
    properties: [
      {
        name: 'hideInNav',
        label: 'page.propertiesList.nav'
      }
      // {
      //   name: 'skipAuth',
      //   label: 'page.propertiesList.skipAuth',
      //   guard: (site) => !!site.getProperty('requireAuth', false)
      // }
    ]
  }
}
