


















import { Component, Vue } from 'vue-property-decorator'

import { DashmixBreadcrumbsProps } from '@movecloser/ui-core'
import { EditModeLayout } from '../../shared/components/EditModeLayout'
import { Inject } from '../../../backoffice'

import { initBreadcrumbs } from '../../content/helpers'
import { ISiteResolver, SiteResolverType } from '../../root/services/site-resolver'

import { IUserRepository, UserRepositoryType } from '../contracts/repositories'
import { EditUserIntention } from '../intentions/EditUserIntention'
import UserForm from '../components/UserForm.vue'
import { UserPayload } from '../contracts/data'
import { Avatar, Roles, SimpleRole } from '../contracts/models'
import RolesSideBar from '../components/RolesSideBar.vue'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'EditUser',
  components: { UserForm, EditModeLayout, RolesSideBar }
})
export class EditUser extends Vue {
  @Inject(UserRepositoryType)
  protected userRepository!: IUserRepository

  @Inject(SiteResolverType)
  protected siteResolver!: ISiteResolver

  public formName: string = 'editUser'
  public isSaving: boolean = false
  public isLoading: boolean = false
  public payload: UserPayload = {
    avatar: null,
    email: '',
    firstName: '',
    lastName: '',
    nickname: '',
    isEditor: false,
    isActive: false,
    isBlocked: false,
    roles: {} as Roles
  }

  public get breadcrumbs (): DashmixBreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t('users.listTitle')}`,
          target: { name: 'users.list' }
        },
        {
          label: `${this.$t('users.addUser')}`,
          target: { name: 'users.create' }
        }
      ],
      root: initBreadcrumbs.root
    }
  }

  public get siteLogos () {
    return [...this.siteResolver.getDictionary()]
      .map(site => {
        return {
          [site.domain]: site.logo.light
        }
      })
      .reduce((o, logo) => ({ ...o, ...logo }), {})
  }

  public get userId () {
    return Number(this.$route.params.id)
  }

  created () {
    this.isLoading = true

    this.userRepository.load(this.userId).then(model => {
      this.payload = {
        ...this.payload,
        ...model
      }

      if (!this.payload.roles) {
        this.payload.roles = this.getRoles()
      } else if (this.payload.roles && Object.keys(this.payload.roles).length === 0) {
        this.payload.roles = this.getRoles()
      }
    }).catch(error => console.log(error)
    ).finally(() => { this.isLoading = false })
  }

  public editUser (): void {
    this.isSaving = true
    const intention = new EditUserIntention(this.payload)
    this.userRepository.edit(this.userId, intention.toRequest())
      .then(() => {
        this.$router.push({ name: 'users.list' })
      })
      .catch(error => console.debug(error))
      .finally(() => {
        this.isSaving = false
      })
  }

  public propagateRoles (selected: SimpleRole[]) {
    if (!this.payload.roles) {
      return
    }
    this.payload.roles = Object.keys(this.payload.roles)
      .reduce((o, key) => ({ ...o, [key]: [...selected] }), {}) as unknown as Roles
  }

  public setAvatar (newAvatar: Avatar) {
    this.payload = {
      ...this.payload,
      avatar: newAvatar
    }
  }

  protected getRoles (): Roles {
    return [...this.siteResolver.getDictionary()]
      .map(site => site.domain)
      .reduce((o, key) => ({ ...o, [key]: [] }), {}) as unknown as Roles
  }
}

export default EditUser

