// Copyright © 2022 Move Closer

import { Related } from '../../../backoffice'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export function composeSelectedArray (selected: Related | Related[], isMultiple: boolean): number[] {
  const toMap: Related[] = isMultiple && Array.isArray(selected) ? selected : [selected as Related]

  return toMap.map(i => typeof i.value === 'string' ? parseInt(i.value) : i.value)
}
