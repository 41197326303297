// Copyright © 2021 Move Closer

import {
  ICollection, IncorrectValueError,
  Injectable,
  IResponse,
  MappingConfig,
  Repository,
  ResourceActionFailed
} from '@movecloser/front-core'

import { Identifier } from '../../../backoffice'
import { Query } from '../../shared/contracts/query'
import { resolveFromStatus } from '../../shared/exceptions/connector-errors'

import { AddGalleryItemIntention } from '../intentions/AddGalleryItemIntention'
import { ChangeItemsOrderIntention } from '../intentions/ChangeItemsOrderIntention'
import { CreateGalleryIntention, GalleryConstantPayload } from '../intentions/CreateGalleryIntention'
import { EditGalleryIntention } from '../intentions/EditGalleryIntention'
import { GalleryData, GalleryModel, GalleryType, IGalleryRepository } from '../contracts'
import { Gallery } from '../models/gallery'
import { galleryAdapterMap } from '../models/gallery.adapter'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
@Injectable()
export class GalleryRepository extends Repository<GalleryData, Gallery> implements IGalleryRepository {
  protected map: MappingConfig = galleryAdapterMap
  protected useAdapter = true

  public async addItems (id: Identifier, intentions: AddGalleryItemIntention[]): Promise<void> {
    const response = await this.connector.call(
      'gallery',
      'addItems',
      { id },
      {
        ids: intentions.map((i) => +i.toRequest().id),
        position: intentions.map((i) => +i.toRequest().position)[0]
      }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }

  public async changeOrder (id: Identifier, intention: ChangeItemsOrderIntention): Promise<void> {
    const response = await this.connector.call(
      'gallery',
      'reorderItems',
      { id },
      intention.toRequest()
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }

  public async create (intention: CreateGalleryIntention): Promise<Identifier> {
    const response = await this.connector.call(
      'gallery',
      'create',
      {},
      {
        ...intention.toRequest(),
        ...GalleryConstantPayload
      }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return response.data?.data?.id
  }

  public async delete (id: Identifier): Promise<void> {
    const response: IResponse = await this.connector.call(
      'gallery',
      'delete',
      { id: id }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }

  public async load (id: Identifier): Promise<GalleryModel> {
    const response: IResponse = await this.connector.call(
      'gallery',
      'get',
      { id }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    if (response.data.data.type !== GalleryType.Gallery) {
      throw new IncorrectValueError(
        'Object with given ID is not a gallery'
      )
    }

    return this.composeModel(response.data.data, Gallery)
  }

  public async loadCollection (query: Query): Promise<ICollection<GalleryModel>> {
    const response: IResponse = await this.connector.call(
      'gallery',
      'list',
      {},
      {
        type: GalleryType.Gallery,
        ...query
      }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeCollection(
      response.data.data,
      Gallery,
      response.data.meta
    )
  }

  public async removeItem (id: Identifier, itemId: Identifier): Promise<void> {
    const response: IResponse = await this.connector.call(
      'gallery',
      'removeItem',
      { id: id, itemId: itemId }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }

  public async update (id: Identifier, intention: EditGalleryIntention): Promise<void> {
    const response: IResponse = await this.connector.call(
      'gallery',
      'update',
      { id },
      {
        ...intention.toRequest(),
        ...GalleryConstantPayload
      }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }
}
