// Copyright © 2021 Move Closer

import { computed, ComputedRef, defineComponent, ref } from '@vue/composition-api'

import { FormFieldAbstract, FormFieldAbstractSetup } from '../Abstract'
import { SearchPreviewProps } from './SearchPreview.contracts'
import { AnyObject } from '@movecloser/front-core'

/**
 * @author Piotr Niewczas <piotr.niewcza@movecloser.pl>
 */
export const SearchPreview = defineComponent({
  name: 'SearchPreview',
  extends: FormFieldAbstract,
  template: `
    <div>

    <label :for="name" v-if="displayLabel">{{ label }}</label>

    <slot name="beforeInput" />
    <br>
    Wpisz słowo kluczowe
    <br>
    <input type="text" v-model="search">
    <pre>{{ filtered }}</pre>

    <div class="invalid-feedback animated fadeIn" v-if="hasErrors">{{ errors[0] }}</div>
    <small class="text-muted" v-if="helper">{{ helper }}</small>

    </div>
  `,
  props: {
    /**
     * TextArea rows count - determines how long component will be
     */
    width: {
      type: Number,
      required: false
    },
    /**
     * TextArea rows count - determines how long component will be
     */
    height: {
      type: Number,
      required: false
    }
  },
  setup (props: SearchPreviewProps, { emit }) {
    const {
      errors,
      displayLabel,
      hasErrors,
      value
    } = FormFieldAbstractSetup(props, emit)
    const search = ref<string>('')
    const classObject: ComputedRef<object> = computed(() => {
      return {
        'is-invalid': hasErrors.value
      }
    })

    const filtered = computed(() => {
      const list: [string, string][] = Object.entries(props.model)
      return list.map((value) => {
        return `${value[0]}: ${value[1]}`
      }).filter((item) => {
        return item.indexOf(search.value) > -1
      }).join('\n')
    })

    return {
      classObject,
      errors,
      displayLabel,
      hasErrors,
      value,
      search,
      filtered
    }
  }
})
export default SearchPreview
