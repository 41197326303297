// Copyright © 2021 Move Closer

import { INotyfOptions } from 'notyf'

export interface IToastService<C, O> {
  error (content: C, title?: string, options?: O): void

  info (content: C, title?: string, options?: O): void

  push (content: C, type: ToastType, title?: string, options?: O): void

  success (content: C, title?: string, options?: O): void

  warning (content: C, title?: string, options?: O): void
}

export type IToastOptions = INotyfOptions
export const ToastMiddlewareType = Symbol.for('ToastMiddleware')
export const ToastServiceType = Symbol.for('IToastService')

export enum ToastType {
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Error = 'error'
}
