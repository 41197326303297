





















import { Component, PropSync, Vue } from 'vue-property-decorator'

import { FormFieldset } from '../FormFieldset'

/**
 * Component capable to add custom class to the module Ui
 *
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<ClassForm>({
  name: 'ClassForm',
  components: {
    FormFieldset
  },
  mounted () {
    if (this._customClass.length > 0) {
      this.useCustomClass = true
    }
  }
})
export class ClassForm extends Vue {
  @PropSync('customClass', { type: Array, required: false, default: () => [] })
  public _customClass!: Array<string>

  /**
   * Property determines whether customClass is used or not
   */
  public useCustomClass: boolean = false

  /**
   * Determines whether to use customClass in module
   */
  public onUseCustomClass (): void {
    this.useCustomClass = !this.useCustomClass

    if (!this.useCustomClass) {
      this._customClass = []
    }
  }

  /**
   * Update customClass
   * @param value
   */
  public updateClass (value: string): void {
    this._customClass = value.split(/[, ]+/)
  }
}

export default ClassForm
