// Copyright © 2021 Move Closer

import { UserModel } from '../../auth/contracts/models'
import { Permission } from '../../auth/contracts/permissions'

/**
 * Contents
 */
export const canAcceptContents = (domain: string, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.AcceptContents)
}

export const canCreateContents = (domain: string, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.CreateContents)
}

export const canDeleteContents = (domain: string, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.DeleteContents)
}

export const canEditContents = (domain: string, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.EditContents)
}

export const canPublishContents = (domain: string, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.PublishContents)
}

export const canViewContents = (domain: string, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.ViewContents)
}
