















































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { DashmixIconName } from '@movecloser/ui-core'
import { DateTimeType, IDateTime } from '@movecloser/front-core'
import { Fragment } from 'vue-fragment'

import { Inject } from '../../../backoffice'

import { ContentModel, ContentStatusBadge } from '../contracts'
import {
  ContentPermissions,
  ContentTypeAware,
  IContentPermissions,
  IContentTypeAware
} from '../mixins'
import { EditorsStack } from './EditorsStack.vue'

// import { resolveVariantsToLoad } from '../helpers'

@Component({
  name: 'TableCollectionRow',
  components: { EditorsStack, Fragment }
})
export class TableCollectionRow extends Mixins<IContentPermissions, IContentTypeAware>(
  ContentPermissions,
  ContentTypeAware
) {
  @Prop({ type: Object, required: true })
  public data!: ContentModel

  @Inject(DateTimeType)
  protected dateTime!: IDateTime

  public ContentStatusBadge = ContentStatusBadge
  public Icons = DashmixIconName

  public get hasParent (): boolean {
    return !!this.data.parent
  }

  public get parentId (): number | null {
    return this.hasParent ? this.data.parent.id : null
  }

  public get parentLabel (): string {
    return this.hasParent ? this.data.parent.name : ''
  }

  public get typeLabel (): string {
    return this.$tc(this.contentTypeManager.getLabel(this.data.type), 1).toString()
  }
}

export default TableCollectionRow
