// Copyright © 2021 Move Closer

import { Permission } from '../auth/contracts/permissions'
import { UserModel } from '../auth/contracts/models'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (edited)
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl> (original)
 */
export const canCreateLayout = (domain: string, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.CreateLayout)
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (edited)
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl> (original)
 */
export const canDeleteLayout = (domain: string, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.DeleteLayout)
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (edited)
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl> (original)
 */
export const canEditLayout = (domain: string, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.EditLayout)
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (edited)
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl> (original)
 */
export const canViewLayout = (domain: string, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.ViewLayout)
}
