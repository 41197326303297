// Copyright © 2021 Move Closer

import { ComponentObjectPropsOptions } from '@movecloser/ui-core'
import { PropType } from '@vue/composition-api'

import { ListGroupItem, ListGroupProps } from './ListGroup.contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const listGroupProps: ComponentObjectPropsOptions<ListGroupProps> = {
  items: {
    type: Array as PropType<ListGroupItem[]>,
    required: true
  },
  hasTarget: {
    type: Boolean,
    required: false,
    default: false
  }
}
