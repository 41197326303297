// Copyright © 2021 Move Closer

// TODO: Content contracts
import { AllowedImageRatio as ImageRatio } from '../../../backoffice'

import { FileSize } from '../contracts'

export const AspectRatioSizesMap: Record<ImageRatio, FileSize | null> = {
  // [ImageRatio.HeroDesktop]: { width: 1440, height: 520 },
  // [ImageRatio.HeroMobile]: { width: 375, height: 540 },
  [ImageRatio.Original]: null,
  [ImageRatio.Square]: { width: 1100, height: 1100 }
}

/**
 * @author Jan Dobrowoslki <jan.dobrowolski@movecloser.pl>
 */
export function fileSize (bites: number) {
  const sufix = ['B', 'KB', 'MB', 'GB']
  let i = 0
  let size = bites
  while (size >= 1024) {
    size = Math.ceil(size / 1024)
    i++
  }
  return `${size} ${sufix[i]}`
}
