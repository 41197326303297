// Copyright © 2021 Move Closer

import { defineComponent } from '@vue/composition-api'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const FullScreenLayout = defineComponent({
  name: 'FullScreenLayout',
  props: { logo: String },
  template: `
    <main id="main-container">
      <div class="row no-gutters bg-image">
        <div class="hero-static d-flex align-items-center w-100 justify-content-center">
          <div class="bg-white py-4 login card shadow row no-gutters ">

            <div class="col-12 mb-3">
              <img :src='logo' alt="logo" class="img-fluid mb-3">
            </div>
            <slot/>
          </div>
        </div>
      </div>
    </main>
  `
})

export default FullScreenLayout
