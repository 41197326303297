// TODO: uncomment after pytanie not done
// Copyright © 2021 Move Closer

import { ConjunctionOperator, IModel, MagicModel } from '@movecloser/front-core'

import { HasIdentifier, Identifiable } from '../../shared/contracts/data'

import { OwnerModel } from '../../root/contracts/models'
import { Identifier, SetType } from '../../../backoffice'

export interface ISet extends Identifiable, IModel<SetData> {
  authorName (): string
  getItemCount (): number
}

export interface ISetItem extends Identifiable, IModel<SetItemData> {
  toSetItem (): any
}

export interface SetData extends HasIdentifier {
  editor: OwnerModel
  createdAt?: string
  childrenCount: number
  properties: SetProperties
  strategy: SetStrategy
  title: string
  type: SetType
  updatedAt?: string
}

export interface SetItem {
  id: Identifier
  newlyAdded?: boolean
  position: number
  travelDistance?: number
}

export type SetModel = MagicModel<SetData, ISet>

export interface SetProperties {
  conjunction: ConjunctionOperator
  src: SetSource[]
  locked: SetItemModel[]
}

export interface SetSource {
  type: string
  value: string
  label?: string
}

export enum SetStrategy {
  Related = 'related',
  Automatic = 'automatic',
  Manual = 'manual'
}

export interface SetItemData extends HasIdentifier {
  position: number
  data: unknown
  locked?: boolean
}

export type SetItemModel = MagicModel<SetItemData, ISetItem>
