









































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DashmixIconName, DashmixSelectItem, DashmixTheme, Hint } from '@movecloser/ui-core'
import debounce from 'lodash/debounce'
import { ICollection, IModel, Intersected, ResourceActionFailed } from
  '@movecloser/front-core'

import { DictData } from '../../root/contracts/models'
import { FormCheckbox } from '../../shared/components/form'
import { FormSelect } from '../../shared/components/form/Select'
import { FormTypeahead } from '../../shared/components/FromTypeahead/FormTypeahead'
import { Inject, PossibleRelatedType, SetType } from '../../../backoffice'
import { Loader } from '../../shared/components/Loader/Loader'

import { DictionaryRepositoryType, IDictionaryRepository } from '../../root/contracts/repositories'

import { SetSource } from '../contracts'
import { SourceType } from '../contracts/data'

/**
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'SetSourceForm',
  components: {
    FormTypeahead,
    Loader,
    FormSelect,
    FormCheckbox
  }
})
export class SetSourceForm extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  public readonly disabled!: boolean

  @Prop({ type: String, required: true })
  public readonly formName!: string

  @Prop({ type: Number, required: true })
  public readonly index!: number

  @Prop({ type: String, required: true })
  public readonly mode!: string

  @Prop({ type: String, required: true })
  public readonly setType!: SetType

  @Prop({ type: Object, required: true })
  public readonly source!: SetSource

  @Inject(DictionaryRepositoryType)
  private dictionaryRepository!: IDictionaryRepository

  public isLoading: boolean = false
  public category: Hint[] = []

  // Helpers
  public buttonTheme = DashmixTheme
  public Icons = DashmixIconName

  public get getTypeaheadOption (): Hint[] {
    if (this.source.value) {
      return [
        {
          value: this.source.value,
          label: this.source.label || this.source.value
        }
      ]
    }
    return []
  }

  public get options () {
    switch (this.mode) {
      case SourceType.Category:
        return this.category
      default:
        return []
    }
  }

  public get sourceLabels () {
    return {
      label: this.$t(`sets.create.form.${this.mode}.label`),
      placeholder: this.$t(`sets.create.form.${this.mode}.placeholder`)
    }
  }

  public get sourceTypeOptions (): DashmixSelectItem[] {
    switch (this.setType) {
      case PossibleRelatedType.Articles:
        return [
          {
            label: String(this.$t('sets.source.type.category')),
            value: SourceType.Category
          }
        ]
      default:
        return []
    }
  }

  created () {
    if (this.setType === PossibleRelatedType.Articles && !this.source.type) {
      this.source.type = SourceType.Category
    }

    this.$nextTick(() => {
      this.loadOptions()
    })
  }

  public loadDefaultOptions () {
    this.$nextTick(() => {
      this.loadOptions()
    })
  }

  public async loadOptions (searchParams?: string) {
    this.isLoading = true

    const logErrors = (error: ResourceActionFailed) => {
      console.log(error)
      this.isLoading = false
    }

    switch (this.mode) {
      case SourceType.Category:
        await this.loadCategory(searchParams).catch(logErrors)
        break
    }
    this.isLoading = false
  }

  public async loadCategory (searchParams?: string) {
    this.isLoading = true
    this.category = SetSourceForm.categoryToSelectOptions(
      await this.dictionaryRepository.loadCategoryDictionary(
        { q: searchParams || '', sort: '+name' }
      )
    )
    this.isLoading = false
  }

  public onClear () {
    this.loadOptions()
  }

  public onDelete () {
    this.source.value = ''
    this.source.label = ''
  }

  public onSearch (searchedParams: string) {
    this.loadOptions(searchedParams)
  }

  public onSelect (selectedHint: Hint) {
    this.source.value = `${selectedHint.value}`
    this.source.label = selectedHint.label
  }

  protected deleteSource () {
    this.$emit('deleted', this.index)
  }

  @Watch('source', { deep: true })
  protected onSourceChange = debounce(() => {
    this.onSourceChange.cancel()

    this.$emit('changed', this.index, this.source)
  }, 500)

  @Watch('source.type', { deep: false })
  protected onSourceTypeChange (newType: string, oldType: string) {
    if (newType !== oldType) {
      this.source.value = ''
      this.source.label = ''
    }
  }

  private static categoryToSelectOptions (
    collection: ICollection<Intersected<IModel<DictData>, DictData>>
  ): Hint[] {
    return [...collection].map(
      (dictionary: Intersected<IModel<DictData>, DictData>) => {
        return {
          value: dictionary.id,
          label: dictionary.name
        }
      }
    )
  }
}

export default SetSourceForm
