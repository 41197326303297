// Copyright © 2021 Move Closer

import { TableHead } from '@movecloser/ui-core'

export const rolesTableHead: TableHead = [
  {
    column: 'name',
    label: 'roles.list.name',
    sortable: false,
    isSorted: false,
    width: '0.5fr'
  },
  {
    column: 'updatedAt',
    label: 'roles.list.updatedAt',
    sortable: false,
    isSorted: false,
    width: '1fr'
  }
]

export enum RolesListActions {
  Delete = 'delete',
  Edit = 'edit'
}
