
















import { Component, Mixins, Prop } from 'vue-property-decorator'

import { Inject, IRelatedService } from '../../../backoffice'

import { AcceptanceRepositoryType, IAcceptanceRepository, VariantData } from '../contracts'
import { ContentTypeAware, IContentTypeAware } from '../mixins'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
@Component<AcceptanceAddons>({
  name: 'AcceptanceAddons',
  mounted () {
    if (this.items.length) {
      this.onActiveChange(this.items[0].id)
    }
  }
})
export class AcceptanceAddons extends Mixins<IContentTypeAware>(ContentTypeAware) {
  @Prop({ type: String, required: true })
  public active!: string

  @Prop({ type: Object, required: true })
  public model!: VariantData

  @Prop({ type: Function, required: true })
  protected onActiveChange!: (tab: string) => void

  @Prop({ type: Object, required: true })
  protected readonly relatedService!: IRelatedService

  @Inject(AcceptanceRepositoryType)
  protected acceptanceRepository!: IAcceptanceRepository

  public get items () {
    return this.contentTypeManager.getAddons(this.model.addons)
      .map(i => {
        return {
          label: this.$t(i.label).toString(),
          id: i.name,
          component: i.component,
          props: { disabled: true, variant: this.model, relatedService: this.relatedService }
        }
      })
  }
}

export default AcceptanceAddons
