





































































import { Component, PropSync } from 'vue-property-decorator'
import { DashmixSelectItem, SizeMap } from '@movecloser/ui-core'

import { FormInput, FormSelect } from '../../shared/components/form'

import ProductGalleryItem from '../../products/components/ProductGalleryItem.vue'
import { Iterator } from '../../../modules/partials/Iterator'
import {
  BannerData,
  DescriptionOfLink,
  PossibleRelatedPicker,
  PossibleRelatedType,
  Related
} from '../../../contexts'
import { mediaTypes } from '../../media/contracts'

import { BannerInput } from '../contracts'
import { ConfigForm } from './_abstract/ConfigForm.vue'

@Component<BannersForm>({
  name: 'BannersForm',
  components: { FormInput, FormSelect, Iterator, ProductGalleryItem },
  mounted (): void {
    // transform php object to array
    this.banners = Object.values(this.payload)
    this.initData()
  }
})
export class BannersForm extends ConfigForm {
  @PropSync('formData', { type: Object, required: true })
  public payload!: Record<number, BannerData>

  public readonly formName = 'banners'

  public banners: BannerInput[] = []
  public editable: Record<string, boolean[]> = { image: [], imageMobile: [] }
  public errors: Record<number, string> = {}
  public links: (DescriptionOfLink | null)[] = []
  public loading: boolean = false

  public availableWidths: DashmixSelectItem[] = [
    {
      label: this.$t('settings.form.banners.width.2').toString(),
      value: '2'
    },
    {
      label: this.$t('settings.form.banners.width.4').toString(),
      value: '4'
    }
  ]

  public get isReady (): boolean {
    let ready = true

    this.banners.forEach(b => {
      ready = ready && !!b.link && !!b.image && b.index !== undefined && b.index !== ''
    })

    return ready
  }

  public addBanner (): BannerInput {
    return {
      index: undefined,
      image: undefined,
      imageMobile: undefined,
      link: undefined
    }
  }

  public deleteImage (type: keyof BannerData, index: number) {
    this.updateBanner(type, undefined, index)
    this.editable[type][index] = true
  }

  public updateBanner (field: keyof BannerData, data: Related | undefined, index: number) {
    console.info(field, data)
    this.banners = this.banners.map((b, i) => {
      if (i === index) {
        return { ...b, [field]: data }
      }

      return b
    })
  }

  public updateImage (data: Related, type: keyof BannerData = 'image', index: number) {
    this.editable[type][index] = false

    this.updateBanner(type, data, index)
  }

  public async updateLink (data: Related, index: number) {
    this.links[index] = await this.relatedService.describe<DescriptionOfLink>(
      { type: PossibleRelatedType.Link, value: data.value }
    )

    this.updateBanner('link', data, index)
  }

  public openImagePicker (type: keyof BannerData, index: number) {
    this.modalConnector.open(PossibleRelatedPicker.File, {
      multiple: false,
      onSelection: (data: Related) => this.updateImage(data, type, index),
      onClose: () => this.modalConnector.close()
    }, { size: SizeMap.XLarge })
  }

  public openLinkPicker (index: number) {
    this.modalConnector.open(PossibleRelatedPicker.Link, {
      multiple: false,
      onSelection: (data: Related) => this.updateLink(data, index),
      onClose: () => this.modalConnector.close(),
      config: {
        allowedMediaType: mediaTypes.Image
      }
    }, { size: SizeMap.XLarge })
  }

  public onSubmit () {
    if (!this.isReady || !this.validate()) {
      return
    }

    this.payload = this.banners as BannerData[]
    this.$emit('submit', { reproject: false, global: false })
  }

  public showImageButton (show: boolean, type: keyof BannerData, index: number) {
    if (this.editable[type][index] === show) {
      return
    }

    this.editable[type] = this.editable[type].map((e, i) => (i === index ? show : e))
  }

  public validate (): boolean {
    const errors: Record<number, string> = {}

    this.banners.forEach((b, i) => {
      const index = b.index
        ? typeof b.index === 'number'
          ? b.index
          : parseInt(b.index, 10)
        : undefined

      if (index === undefined) {
        errors[i] = 'empty index'
      }

      // FIXME: Please correct me if the client wants to add the functionality of validating placing banners
      // if (b.width === '4' && index % 4 !== 1) {
      //   errors[i] = 'Błędna pozycja: banner 4-kolumnowy musi się zaczynać w pierwszej kolumnie'
      // }
      //
      // if (b.width === '2' && index % 4 === 0) {
      //   errors[i] = 'Błędna pozycja: banner 2-kolumnowy musi się zaczynać w 1, 2 lub 3 kolumnie'
      // }
    })

    this.errors = errors
    return Object.keys(this.errors).length === 0
  }

  private async initData () {
    this.editable.image = this.banners.map(b => !b.image)
    this.editable.imageMobile = this.banners.map(b => !b.imageMobile)

    this.links = await Promise.all(this.banners.map(b => {
      if (!b.link) {
        return null
      }

      return this.relatedService.describe<DescriptionOfLink>(
        { type: PossibleRelatedType.Link, value: b.link.value }
      )
    }))
  }
}

export default BannersForm
