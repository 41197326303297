














































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { DateTimeType, IDateTime } from '@movecloser/front-core'
import { DashmixIconName } from '@movecloser/ui-core'
import { Fragment } from 'vue-fragment'
import { Popover } from '../../shared/components/Popovers/Popover'

import { Inject } from '../../../backoffice'

import { IProductsPermissions, ProductsPermissions } from '../config/permissions.mixin'
import { ProductData } from '../contracts'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
@Component({
  name: 'ProductTableRow',
  components: { Fragment, Popover }
})
export class ProductTableRow extends Mixins<IProductsPermissions>(ProductsPermissions) {
  @Prop({ type: Object, required: true })
  protected data!: ProductData

  @Inject(DateTimeType)
  protected dateTime!: IDateTime

  public Icons = DashmixIconName
  public isOpen: boolean = false

  public isActiveLabel (activity: boolean): string {
    return this.$t(`products.isActive.tooltip.${activity ? 'true' : 'false'}`).toString()
  }

  public isVariantActiveLabel (activity: boolean): string {
    return this.$t(`products.variants.isActive.tooltip.${activity ? 'true' : 'false'}`).toString()
  }

  public doesVariantHasGallery (gallery: boolean): string {
    return this.$t(`products.variants.hasGallery.tooltip.${gallery ? 'true' : 'false'}`).toString()
  }

  public getDotClass (green: boolean): string {
    return green ? 'bg-success' : 'bg-danger'
  }

  public toggleVariantList () {
    this.isOpen = !this.isOpen
  }
}

export default ProductTableRow
