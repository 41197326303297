// Copyright © 2021 Move Closer

export interface PopoverProps {
  position: PopoverPositions
  triggerMode: Triggers
  popoverClass?: string
}

export enum PopoverPositions {
  Auto = 'auto',
  AutoStart = 'auto-start',
  AutoEnd = 'auto-end',
  Top = 'top',
  TopStart = 'top-start',
  TopEnd = 'top-end',
  Right = 'right',
  RightStart = 'right-start',
  RightEnd = 'right-end',
  Bottom = 'bottom',
  BottomStart = 'bottom-start',
  BottomEnd = 'bottom-end',
  Left = 'left',
  LeftStart = 'left-start',
  LeftEnd = 'left-end',
}

export enum Triggers {
  Hover = 'hover',
  Click = 'click',
  Focus = 'focus',
  Manual = 'manual'
}
