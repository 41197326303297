




























import { Component, Prop } from 'vue-property-decorator'
import { DashmixIconName } from '@movecloser/ui-core'
import { IModal, ModalType } from '@movecloser/front-core'

import { Identifier, Inject, IRelatedService, logger, RelatedServiceType } from '../../../backoffice'

import { AbstractModal } from '../../shared/components/Modal'
import { Link, LinkPickerPayload } from '../../shared/contracts/data'

import { ContentModel, ContentRepositoryType, ContentType, IContentRepository } from '../contracts'
import { ContentTypeManagerType, IContentTypeManager } from '../services/content-type-manager'

import { ContentPickerPage } from './ContentPickerPage.vue'
import { LinkPickerExternal } from './LinkPickerExternal.vue'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
@Component<LinkPickerModal>({
  name: 'LinkPickerModal',
  components: {
    ContentPickerPage
  },

  mounted () {
    // TODO fix - see src/modules/content/components/AddSetItemModal.vue:117
    (document.querySelector('.modal-content') as HTMLDivElement).style.overflow = 'visible'

    if (this.payload.selected) {
      // FIXME - find some way to check if link is external, article or page
      this.externalLink = { ...this.payload.selected }
    }
  }
})
export class LinkPickerModal extends AbstractModal {
  @Prop({ type: Object, required: true })
  public readonly payload!: LinkPickerPayload

  @Inject(ContentRepositoryType)
  private readonly contentRepository!: IContentRepository

  @Inject(ContentTypeManagerType)
  public readonly contentTypeManager!: IContentTypeManager

  @Inject(ModalType)
  protected readonly modalConnector!: IModal

  @Inject(RelatedServiceType)
  protected readonly relatedService!: IRelatedService

  public icons = DashmixIconName

  public activeTab: string = this.tabs[0]?.tab?.id || ''
  public isLoading: boolean = true
  protected initialSelected: ContentModel | null = null
  protected selected: ContentModel | null = null
  protected externalLink: Link = { name: this.payload.config?.name || '', url: '' }

  public get isReady (): boolean {
    return (this.externalLink.url !== '' && this.activeTab === this.tabs[0].tab.id) ||
      (!!this.selected && this.activeTab !== this.tabs[0].tab.id)
  }

  public get tabs () {
    return [
      {
        tab: {
          id: 'link',
          label: this.$t('content.picker.link')
        },
        component: LinkPickerExternal,
        props: {
          model: this.externalLink,
          onUpdateName: (newName: string) => {
            this.externalLink.name = newName
          },
          onUpdateUrl: (newUrl: string) => {
            this.externalLink.url = newUrl
          }
        }
      }, {
        tab: {
          id: ContentType.Page,
          label: this.$t('content.picker.page')
        },
        component: ContentPickerPage,
        props: {
          selectedId: this.initialSelected?.id,
          onSelect: this.select
        }
      }
    ]
  }

  public apply (): void {
    if (this.activeTab !== this.tabs[0].tab.id && this.selected) {
      this.payload.onSelection({
        name: this.selected.title,
        url: this.selected.fullSlug()
      })
    } else if (this.externalLink.url !== '') {
      this.payload.onSelection(this.externalLink)
    }

    this.modalConnector.close()
  }

  public close (): void {
    if (this.payload.onClose && typeof this.payload.onClose === 'function') {
      this.payload.onClose()
    }
    this.modalConnector.close()
  }

  public async select (id: Identifier, isInit?: boolean): Promise<void> {
    const types: string[] = this.contentTypeManager.getSiblingTypes(ContentType.Page)

    try {
      const model = await this.contentRepository.loadNode(types, id, {})
      this.selected = model

      if (isInit) {
        this.initialSelected = model
      }

      if (this.activeTab !== this.tabs[0].tab.id) {
        if (!this.externalLink.name) {
          this.externalLink.name = this.selected.title
        }

        this.externalLink.url = this.selected.fullSlug()
      }
    } catch (e) {
      logger(e, 'error')
    }
  }
}

export default LinkPickerModal
