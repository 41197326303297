// Copyright © 2021 Move Closer

import { AnyObject, Model } from '@movecloser/front-core'

import { Identifier } from '../../../backoffice'

import { IProduct, ProductData } from '../contracts'
import { productAdapterMap } from '../models/product.adapter'
import { EditProductIntentionPayload } from '../intentions/EditProductIntention'

export class Product extends Model<ProductData> implements IProduct {
  protected useAdapter = true
  protected map = productAdapterMap
  protected boot (): void {
    this.initialValues = {}

    this.modelProperties = [
      'createdAt',
      'specification',
      'name',
      'sku',
      'id',
      'category',
      'isVisible',
      'updatedAt',
      'variants',
      'meta',
      'url'
    ]
  }

  public displayName (): string {
    return this._data.name
  }

  public getItemCount (): number {
    return this._data.properties.items.length
  }

  public identifier (): Identifier {
    return this._data.id
  }

  public toEditPayload (): EditProductIntentionPayload {
    return {
      variants: this._data.variants.map((variant: AnyObject) => {
        return {
          sku: variant.sku,
          identifier: variant.identifier,
          name: variant.name
        }
      })
    }
  }
}
