












import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { DashmixSelectItem } from '@movecloser/ui-core'
import { TranslateResult } from 'vue-i18n'
import { ModuleMode } from '../../../../../contexts'

@Component({
  name: 'ModeSwitch'
})
export class ModeSwitch extends Vue {
  @Prop({ type: String, required: false })
  protected readonly id!: string

  @Prop({ type: [String, Object], required: false, default: null })
  protected readonly label!: TranslateResult | string

  @Prop({ type: Array, required: true })
  protected readonly options!: ModuleMode[]

  @Prop({ type: String, required: true })
  protected readonly module!: string

  @PropSync('mode', { type: String, required: false, default: ModuleMode.Default })
  public _mode!: string

  protected elementId: string = 'mode-switch'
  protected elementLabel: string = 'modulesForms._common.mode.label'

  public get availableModes (): DashmixSelectItem[] {
    return this.options.map<DashmixSelectItem>((option) => ({
      label: this.$t(`modulesForms._common.mode.${option}`).toString(),
      value: option
    }))
  }

  public get isDynamic (): boolean {
    return this._mode === ModuleMode.Dynamic
  }

  public get resolvedId (): string {
    return this.id ? `${this.elementId}-${this.id}` : this.elementId
  }

  public get resolvedLabel (): string {
    if (this.label && typeof this.label === 'object') {
      return this.label.toString()
    }

    return this.$t(this.label ?? this.elementLabel).toString()
  }
}

export default ModeSwitch
