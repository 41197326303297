














import { defineComponent, PropType } from '@vue/composition-api'
import { AnyModule } from '../../../../../backoffice'

import { ModulesRegistryEntry, ModuleComponentsRegistry } from '../../../components'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const ModuleLabel = defineComponent({
  name: 'ModuleLabel',
  inheritAttrs: false,
  props: {
    data: {
      type: Object as PropType<AnyModule>,
      required: true
    }
  },
  computed: {
    definition (): ModulesRegistryEntry {
      const registry: ModuleComponentsRegistry = this.$root.$options.configuration?.byKey('builder.moduleRegistry') || {}
      if (!registry[this.data.driver]) {
        throw new Error(`ModuleLabel: Unknown module given [${this.data.driver}]`)
      }

      return registry[this.data.driver]
    }
  }
})

export default ModuleLabel
