// Copyright © 2022 Move Closer

import {
  ApiConnectorType,
  AppModule,
  Container,
  IConfiguration,
  IConnector,
  Interfaces,
  Module,
  IModal,
  ModalType
} from '@movecloser/front-core'

import { ContentTypeManagerType, IContentTypeManager } from '../content/shared'

import {
  categoryContentType,
  categoryContentTypeConfig,
  productContentType,
  productContentTypeConfig,
  productsAddons
} from './config/content-types'
import {
  IProductFiltersRepository,
  IProductsRepository,
  ProductFiltersRepositoryType,
  ProductsRepositoryType
} from './contracts'
import { productModals } from './config/modals'
import { ProductFiltersRepository } from './repositories/filters'
import { ProductsRepository } from './repositories/products'
import { resources } from './config/resources'
import { routesFactory } from './config/routes'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@AppModule({
  name: 'products',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  providers: (config: IConfiguration) => {
    return (bind: Interfaces.Bind) => {
      bind<IProductsRepository>(ProductsRepositoryType).to(ProductsRepository)
      bind<IProductFiltersRepository>(ProductFiltersRepositoryType).to(ProductFiltersRepository)
    }
  },
  boot (container: Container) {
    const connector: IConnector = container.get(ApiConnectorType)
    connector.useResources(resources)

    const manager: IContentTypeManager = container.get(ContentTypeManagerType)
    manager.registerAddons(productsAddons)
    manager.registerType(categoryContentType, categoryContentTypeConfig)
    manager.registerType(productContentType, productContentTypeConfig)

    const modalConnector: IModal = container.get(ModalType)
    modalConnector.register(productModals)
  },

  routes: routesFactory
})

export class ProductsModule extends Module {}
