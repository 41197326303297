






























import { Component, Prop, Vue } from 'vue-property-decorator'
import { DashmixIconName } from '@movecloser/ui-core'
import { IModal, ModalType } from '@movecloser/front-core'

import { Identifier, Inject } from '../../../backoffice'

import { FileCreateModalPayload } from '../contracts/components'
import { FileCreateForm } from './FileCreateForm.vue'
import { FileCreatePayload } from '../contracts'
import { FilePond, FilePondFile } from 'filepond'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
@Component({
  name: 'FileCreateModal',
  components: { FileCreateForm }
})
export class FileCreateModal extends Vue {
  @Prop({ type: Object, required: true })
  public payload!: FileCreateModalPayload

  @Inject(ModalType)
  protected modalConnector!: IModal

  public duplicateDetected: Identifier | null = null
  public files: FilePondFile[] = []
  public fileMode: string | null = null
  public forceCreate: boolean = false
  public forms: FileCreatePayload[] = []
  public icons = DashmixIconName
  public isLoading: boolean = false

  public get isFileModePublic (): boolean {
    return this.fileMode === 'public'
  }

  public get fileChoosen (): boolean {
    return this.files.length > 0
  }

  public get filepond (): FilePond {
    return (this.$refs.fileCreateForm as FileCreateForm).filepond as unknown as FilePond
  }

  public get targetDirectoryId (): Identifier {
    return this.payload.parentDirectory.id
  }

  public clickSave (mode: string): void {
    this.isLoading = true
    if (!this.isFileUploaded(this.files[0]) && !this.duplicateDetected) {
      this.fileMode = mode
      this.filepond.processFile()
      return
    }
    this.sendForm()
  }

  public close (): void {
    this.$emit('close')
  }

  public get isSaveDisabled (): boolean {
    return !this.fileChoosen || this.isLoading ||
      (this.duplicateDetected && !this.forceCreate) ||
      (this.isFileUploaded(this.files[0]) && !!this.duplicateDetected)
  }

  public isFileUploaded (file: FilePondFile): boolean {
    return !!file?.serverId
  }

  public sendForm (): void {
    if (!this.duplicateDetected && !this.files[0]?.serverId) {
      this.isLoading = false
      return
    }

    this.payload.createFile(this.duplicateDetected ?? parseInt(this.files[0]?.serverId), this.forms[0],
      this.files[0], !!this.duplicateDetected).then((success) => {
      if (!success || this.files.length === 0) {
        this.isLoading = false
        return
      }
      if (this.duplicateDetected) {
        this.duplicateDetected = null
        this.forceCreate = false
      }

      this.filepond.removeFile()
      if (this.files.length === 1) {
        this.isLoading = false
        this.modalConnector.close()
        return
      }
      this.filepond.processFile(this.files[1])
    })
  }
}

export default FileCreateModal
