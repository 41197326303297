







import { Component, Prop, Vue } from 'vue-property-decorator'
import { DashmixIconName } from '@movecloser/ui-core'

import { OnAddCallback } from './FormAddBtn.contracts'

@Component({ name: 'FormAddBtn' })
export class FormAddBtn extends Vue {
  @Prop({ type: String, required: false })
  public readonly label?: string

  @Prop({ type: Function, required: true })
  protected readonly onAdd!: OnAddCallback

  @Prop({ type: Boolean, required: false, default: true })
  protected readonly showIcon!: boolean

  public icons = DashmixIconName
  public isAdding: boolean = false

  public get icon (): DashmixIconName | undefined {
    return this.showIcon ? DashmixIconName.PlusSolid : undefined
  }

  public add (): void {
    this.isAdding = true

    const result = this.onAdd()
    if (result instanceof Promise) {
      result.then(() => {
        this.isAdding = false
      })
    } else {
      this.isAdding = false
    }
  }
}

export default FormAddBtn
