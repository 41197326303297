

























































import {
  Authentication, AuthServiceType, DateTimeType, ICollection,
  IDateTime, IModal,
  IValidation, ModalType, ValidationType
} from '@movecloser/front-core'
import { Component, Mixins } from 'vue-property-decorator'
import { DashmixBreadcrumbsProps, DashmixIconName, DashmixTheme, SizeMap, TableHead, TableRowAction } from '@movecloser/ui-core'

import { DropdownActions } from '../../shared/contracts/content'
import { EmptyTable } from '../../shared/components/InteractiveTable/partials/EmptyTable'
import { Identifier, Inject } from '../../../backoffice'
import { UserModel } from '../../auth/contracts/models'
import { initBreadcrumbs } from '../../root/helpers/breadcrumbs'

import {
  DirectoryData,
  DirectoryRepositoryType,
  FileModel, FileRepositoryType,
  IDirectoryRepository, IFileRepository
} from '../contracts'
import { IMediaPermissions, MediaPermissions } from '../config/permissions.mixin'
import { MediaActions, mediaTableHead, mediaWaitingListRowActionsFactory } from '../maps/media'
import { MediaTableRow } from '../components/MediaTableRow.vue'
import { MediaView } from '../components/MediaView.vue'
import { MediaModal } from '../config/modals'
import { TilesGrid } from '../components/TilesGrid.vue'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'MediaWaitingList',
  components: { EmptyTable, MediaTableRow, TilesGrid, MediaView }
})
export class MediaWaitingList extends Mixins<IMediaPermissions>(MediaPermissions) {
  @Inject(AuthServiceType)
  protected authService!: Authentication<UserModel>

  @Inject(DateTimeType)
  protected dateTime!: IDateTime

  @Inject(DirectoryRepositoryType)
  protected directoryRepository!: IDirectoryRepository

  @Inject(FileRepositoryType)
  protected fileRepository!: IFileRepository

  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(ValidationType)
  protected validation!: IValidation

  public actions: DropdownActions = {
    [MediaActions.EditFile]: {
      callback: (data: unknown) => {
        const model = data as FileModel
        return this.openEditFileModal(model.id)
      }
    },
    [MediaActions.DeleteFile]: {
      callback: (data: unknown) => {
        const model = data as FileModel
        return this.deleteFile(model.id)
      },
      confirmation: {
        header: 'actions.delete.header',
        contentText: 'actions.delete.contentText',
        theme: DashmixTheme.Danger,
        buttonLabel: 'atoms.delete'
      }
    }
  }

  public currentDirectory: DirectoryData | null = null

  public get breadcrumbs (): DashmixBreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t('media.list.header')}`,
          target: { name: 'media.list' }
        },
        {
          label: `${this.$t('media.waitingList.header')}`,
          target: { name: 'media.waiting-list' }
        }
      ],
      root: initBreadcrumbs.root
    }
  }

  public icons = DashmixIconName
  public isLoading = false
  public get rowActions (): TableRowAction[] {
    return mediaWaitingListRowActionsFactory(this.domain, this.user)
  }

  public tableHead: TableHead = mediaTableHead
  public tileMode = true

  public get currentDateTime (): string {
    return this.dateTime.nowToFormat('YYYY-MM-DD HH:mm:ss')
  }

  public get searchMode (): boolean {
    return !!this.$route?.query?.q
  }

  public async deleteFile (id: Identifier): Promise<void> {
    this.isLoading = true
    return this.fileRepository.deleteFile(id).then(() => {
      this.removeFileFromFileList(id)
    }).catch(error => {
      console.error(error)
    }).finally(() => {
      this.isLoading = false
    })
  }

  public openEditFileModal (id: Identifier) {
    this.modalConnector.open(
      MediaModal.FileEdit,
      {
        abandonedMode: true,
        file: id,
        onDelete: () => this.removeFileFromFileList(id),
        onUpdate: () => {
          this.removeFileFromFileList(id)
          this.modalConnector.close()
        }
      },
      { size: SizeMap.XLarge }
    )
  }

  protected removeFileFromFileList (id: Identifier): void {
    if (this.currentDirectory) {
      this.currentDirectory = {
        ...this.currentDirectory,
        files: this.currentDirectory.files.filter(rowData =>
          rowData.id !== id) as ICollection<FileModel>
      }
    }
  }
}

export default MediaWaitingList
