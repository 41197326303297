// Copyright © 2021 Move Closer

import { AnyObject, IModel, MagicModel } from '@movecloser/front-core'

import { AnyModule, HasIdentifier, Identifier, Related } from '../../../backoffice'
import { BannerData } from '../../../contexts'

export type BannerInput = Partial<BannerData>

export interface GeneralSettings extends AnyObject {
  noIndexNoFollow: boolean
  linkedInId: string
}

export interface SearchConfigElement {
  label: string
}

export interface SearchElementData {
  phrase: string
  scope: string
}

export interface InitSearchSettings {
  config: Record<string, SearchConfigElement>
  initialData: {
    elements: Array<SearchElementData>
  }
}

export type INavigation = IModel<NavigationData>

export interface NavigationData extends HasIdentifier {
  driver: NavigationDriver
  from: Identifier | null
  // isActive: boolean
  links?: NavigationItem[]
  name: string
  // slot: NavigationSlot
}

export enum NavigationDriver {
  Content = 'content',
  Manual = 'manual'
}

export interface NavigationItem {
  additionalClass?: string
  customColor?: string
  children: NavigationItem[]
  icon?: string
  label: string
  strategy: OpenStrategy
  hideInNav?: boolean
  secondaryLabel?: string
  snippet?: Related
  hasParent?: boolean
  target: NavigationTarget | null
  setVertical?: boolean
}

export type NavigationModel = MagicModel<NavigationData, INavigation>

export enum NavigationSlot {
  FooterTop = 'footer_top',
  FooterMain = 'footer_main',
  FooterBottom = 'footer_bottom',
  FooterAdditional = 'footer_additional',
  IconNavigation = 'icon_nav',
  Main = 'main',
  Top = 'top',
  TopAdditional = 'top_additional',
  SocialMedia = 'social_media'
}

export interface NavigationTarget {
  link: string
  type: Target
}

export enum OpenStrategy {
  Blank = 'blank',
  Self = 'self'
}

export enum Target {
  External = 'external',
  Internal = 'internal'
}

export enum TagType {
  Label = 'label',
  Tag = 'tag'
}

export interface SettingData extends SettingOptions {
  type: string
  value: AnyModule | string
}

export interface SettingOptions {
  reproject?: boolean
  global?: boolean
}
