// Copyright © 2021 Move Closer

import { Model } from '@movecloser/front-core'

import { MeModel } from '../contracts/models'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
export class Me extends Model<MeModel> {
  protected boot (): void {
    this.initialValues = {}

    this.modelProperties = [
      'avatar',
      'email',
      'firstName',
      'lastName',
      'nickname'
    ]
  }
}
