// Copyright © 2021 Move Closer

import {
  AnyObject, Collection,
  ICollection, IMeta,
  Injectable, mapCollection, MappingConfig, MappingError,
  ModelPayload,
  QueryParams,
  Repository,
  ResourceActionFailed
} from '@movecloser/front-core'

import { resolveFromStatus } from '../../shared/exceptions/connector-errors'

import { AddSetItemIntention } from '../intentions/AddSetItemIntention'
import { ChangeItemsOrderIntention } from '../intentions/ChangeItemsOrderIntention'
import {
  ISetItemsRepository,
  SetData,
  SetItemModel,
  SetItemData
} from '../contracts'
import { Identifier, PossibleRelatedType, SetType } from '../../../backoffice'
import { AbstractSetItem } from '../models/set-item'
import { itemsModelConfig } from '../maps/sets'

/**
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Injectable()
export class SetItemsRepository extends Repository<SetItemData, AbstractSetItem> implements ISetItemsRepository {
  public async add (setId: SetData['id'], intention: AddSetItemIntention): Promise<void> {
    const response = await this.connector.call(
      'setItems',
      'push',
      { setId },
      intention.toRequest()
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }

  public async changeOrder (setId: SetData['id'], intention: ChangeItemsOrderIntention) {
    const response = await this.connector.call(
      'setItems',
      'reorder',
      { setId },
      intention.toRequest()
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }

  public async loadCollection<SetItem extends SetItemModel> (
    setId: SetData['id'], setType: SetType, query?: QueryParams, siteId?: Identifier): Promise<ICollection<SetItem>> {
    let payload: AnyObject = {}

    if (query) {
      payload = { ...query }
    }
    if (siteId) {
      payload.siteId = siteId
    }
    const response = await this.connector.call(
      'setItems',
      'list',
      { setId },
      payload
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return SetItemsRepository.composeItemsCollection(setType, response.data.data, response.data.meta)
  }

  public async loadAvailableItems<SetItem extends SetItemModel>
  (setType: SetType, query?: QueryParams): Promise<ICollection<SetItem>> {
    let action: string

    switch (setType) {
      case PossibleRelatedType.Articles:
        action = 'articlesSet'
        break
      case PossibleRelatedType.Products:
        action = 'productsSet'
        break
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!action) {
      throw new Error(`No set action for type [${setType}]`)
    }

    const response = await this.connector.call(
      'setItems',
      action,
      {},
      query
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return SetItemsRepository.composeItemsCollection<SetItem>(setType, response.data.data, response.data.meta)
  }

  public async remove (setId: SetData['id'], itemId: Identifier): Promise<void> {
    const response = await this.connector.call(
      'setItems',
      'remove',
      { setId, itemId }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }

  public async lock (setId: SetData['id'], itemId: Identifier): Promise<void> {
    const response = await this.connector.call(
      'setItems',
      'lock',
      { setId, itemId }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }

  public async unlock (setId: SetData['id'], itemId: Identifier): Promise<void> {
    const response = await this.connector.call(
      'setItems',
      'unlock',
      { setId, itemId }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }

  static composeItemsCollection<SetItem> (setType: SetType, data: unknown, meta: IMeta): ICollection<SetItem> {
    if (!Array.isArray(data)) {
      throw new Error('Wrong response structure in [ISetItemsRepository.composeOffersCollection]')
    }

    if (!itemsModelConfig[setType] || typeof itemsModelConfig[setType].model === 'undefined') {
      throw new Error(`No itemsModelConfig set for [${setType}]`)
    }

    if (itemsModelConfig[setType].useAdapter &&
        (typeof itemsModelConfig[setType].adapterMap === 'undefined' ||
          Object.keys(itemsModelConfig[setType].adapterMap as MappingConfig).length === 0)) {
      throw new MappingError(`Mapping config must be provided when adapter is turned on. [SetItem for type: ${setType}]`)
    }

    const filteredData = data.filter((i: ModelPayload) => i !== null)

    const rowCollection = itemsModelConfig[setType].useAdapter
      ? mapCollection(filteredData, itemsModelConfig[setType].adapterMap as MappingConfig) : filteredData

    return new Collection<SetItem>(
      rowCollection.map((item: any) => {
        return itemsModelConfig[setType].model.hydrate(item)
      }),
      meta
    )
  }
}
