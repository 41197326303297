// Copyright © 2021 Move Closer

import { Model } from '@movecloser/front-core'
import { DictData } from '../contracts/models'

export class Dict extends Model<DictData> {
  protected boot (): void {
    this.initialValues = {}
    this.modelProperties = ['name', 'slug', 'value', 'fullName', 'id']
  }
}
