























import { Component } from 'vue-property-decorator'
import { TableHead } from '@movecloser/ui-core'

import { HeaderInterface } from '../../shared/components/InteractiveTable/InteractiveTable.contracts'
import { ModelListHeader } from '../../shared/components/ModelListHeader'
import { Inject, logger } from '../../../backoffice'

import { AbstractWidget } from '../../root/shared/AbstractWidget'
import { UserModel } from '../../auth/contracts/models'

import { canViewContents } from '../config/guards'
import { ContentRepositoryType, ContentTableRowElement, IContentRepository } from '../contracts'
import { ContentTypeManagerType, IContentTypeManager } from '../services/content-type-manager'
import { nodesTableHeadShortened } from '../maps/listing'
import { TableNodeRowShortened } from '../components/TableNodeRowShortened.vue'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<LatestPagesWidget>({
  name: 'LatestPagesWidget',
  components: { ModelListHeader }
})
export class LatestPagesWidget extends AbstractWidget {
  @Inject(ContentRepositoryType)
  protected contentRepository!: IContentRepository

  @Inject(ContentTypeManagerType)
  public readonly contentTypeManager!: IContentTypeManager

  public isLoading: boolean = false
  public tableHead: TableHead = nodesTableHeadShortened
  public perPage: number = 6
  public rowComponent = TableNodeRowShortened
  public tableData: ContentTableRowElement[] = []

  public get header (): HeaderInterface {
    const payload: Partial<HeaderInterface> = {
      title: `${this.$t('content.article.listTitle')}`
    }

    return payload as HeaderInterface
  }

  protected fetchData (): void {
    if (this.isWidgetVisable) {
      this.loadList()
    }
  }

  private async loadList (): Promise<void> {
    this.isLoading = true

    try {
      const types: string[] = this.contentTypeManager.getContentListConfig('page').loadWith
      const collection = await this.contentRepository.loadCollection(
        types,
        { perPage: `${this.perPage}` }
      )

      this.tableData = [...collection].map(model => {
        return {
          id: `${model.id}`,
          selectable: true,
          data: model
        }
      })
    } catch (e) {
      logger(e, 'warn')
    }

    this.isLoading = false
  }

  protected checkPermission (domain: string, user: UserModel | null): boolean {
    return canViewContents(domain, user)
  }
}

export default LatestPagesWidget
