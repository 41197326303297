


































import { Component, Prop, Vue } from 'vue-property-decorator'
import { DashmixIconName } from '@movecloser/ui-core'

import { PropertyItem } from '../../shared/contracts/content'
import { Property } from '../../shared/components/Property'

import { ContentModel, ContentType } from '../contracts'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component({
  name: 'ContentBasicInformations',
  components: { Property }
})
export class ContentBasicsInformation extends Vue {
  @Prop({ type: String, required: true })
  public contentType!: ContentType

  @Prop({ type: Boolean, required: true })
  public isDeleting!: boolean

  @Prop({ type: Boolean, required: true })
  public isSaving!: boolean

  @Prop({ type: Object, required: true })
  public model!: ContentModel

  @Prop({ type: Array, required: true })
  public properties!: PropertyItem[]

  public icons = DashmixIconName
}
// TODO: Translation in template.
export default ContentBasicsInformation
