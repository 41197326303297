// Copyright © 2021 Move Closer

import { Identifier } from '../../../backoffice'

import { UserModel } from '../../auth/contracts/models'

import { ContentStatus, SimpleVariantModel, VariantModel } from '../contracts/models'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export function resolveVariantToFocus (collection: VariantModel[], user: UserModel | null): string {
  for (const v of collection) {
    if (v.status === ContentStatus.Draft && v.editor?.id === user?.id) {
      return `${v.id}`
    }
  }

  return `${collection[0].id}`
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export function resolveVariantsToLoad (collection: SimpleVariantModel[], user: UserModel | null): SimpleVariantModel[] {
  const toLoad: SimpleVariantModel[] = []

  if (!collection.length) {
    throw new Error('Unresolvable variants. The collection is empty.')
  }

  if (user) {
    toLoad.push(
      ...collection.filter(v => v.status === ContentStatus.Draft && v.isEditor(user.id as Identifier))
    )
  }

  toLoad.unshift(
    ...collection.filter(v => v.status === ContentStatus.Published)
  )

  if (!toLoad.length) {
    collection.sort((first: SimpleVariantModel, second: SimpleVariantModel) => {
      return new Date(second.updatedAt).getTime() - new Date(first.updatedAt).getTime()
    })

    const found = collection.find(v => v.status === ContentStatus.Accepted) ||
      collection.find(v => v.status === ContentStatus.Draft)

    if (!found) {
      return [collection[0]]
    }

    toLoad.push(found)
  }

  return toLoad
}
