

















import { Component } from 'vue-property-decorator'
import debounce from 'lodash/debounce'
import { DebouncedFunc } from 'lodash'

import { AbstractAddon, AddonErrors } from '../../../content/shared'

import { Addon } from '../../config/content-types'

// interface DebouncedFunc<T extends (...args: any[]) => any> {
//   (...args: Parameters<T>): ReturnType<T> | undefined
// }

/**
 * @author  Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'LeadAddon',
  components: { AddonErrors }
})
export class LeadAddon extends AbstractAddon {
  public lead: string = ''

  public setLead (lead: string) {
    this.lead = lead
    this.setNewLead(this.lead)
  }

  protected getVariantProperty (): void {
    if (this.variant) {
      const leadFromModel = this.variant.getProperty<{ lead: string }>(Addon.Lead)

      if (!leadFromModel) {
        this.lead = ''
        return
      }

      this.lead = leadFromModel.lead
    }
  }

  protected setNewLead: DebouncedFunc<(lead: string) => void> =
    debounce((lead: string) => {
      this.setNewLead.cancel()

      this.variant.setProperty<{ lead: string }>(Addon.Lead, { lead: lead })

      this.onChange(this.variant)
    }, 1000)
}

export default LeadAddon
