// Copyright © 2021 Move Closer

import { UserModel } from '../../auth/contracts/models'
import { Permission } from '../../auth/contracts/permissions'

/**
 * Artciles
 */
export const canCreateArticleSet = (domain: string, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.CreateArticleSet)
}

export const canViewArticleSet = (domain: string, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.ViewArticleSet)
}

export const canDeleteArticleSet = (domain: string, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.DeleteArticleSet)
}

export const canEditArticleSet = (domain: string, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.EditArticleSet)
}

/**
 * Products
 */
export const canCreateProductSet = (domain: string, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.CreateProductSet)
}

export const canViewProductSet = (domain: string, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.ViewProductSet)
}

export const canDeleteProductSet = (domain: string, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.DeleteProductSet)
}

export const canEditProductSet = (domain: string, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.EditProductSet)
}
