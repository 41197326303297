// Copyright © 2021 Move Closer

import { AnyObject, MappingConfig, MappingTypes } from '@movecloser/front-core'

export const setAdapterMap: MappingConfig = {
  editor: 'editor',
  childrenCount: 'childrenCount',
  createdAt: 'createdAt',
  id: 'id',
  strategy: 'strategy',
  title: 'title',
  updatedAt: 'updatedAt',
  properties: {
    type: MappingTypes.Function,
    value: (item) => {
      if (!item.properties) {
        return null
      }

      let locked = []

      if (item.properties.locked && Array.isArray(item.properties.locked)) {
        locked = item.properties.locked.map((locked: unknown) => {
          if (typeof locked === 'object' && locked !== null) {
            return {
              position: (locked as AnyObject).position,
              data: (locked as AnyObject).item,
              id: (locked as AnyObject).item.id
            }
          }
        })
      }
      return {
        ...item.properties,
        locked
      }
    }
  }
}
