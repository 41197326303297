















import { Component, Prop, Vue } from 'vue-property-decorator'

import { Property } from '../../shared/components/Property'

import { Permission, splitPermission } from '../../auth/contracts/permissions'

/**
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 */
@Component({
  name: 'PermissionsPopover',
  components: { Property }
})
export class PermissionsPopover extends Vue {
  @Prop({ type: Array, required: true })
  public permissions!: Permission[]

  public get permissionLabels (): string[] {
    return this.permissions.map(
      (permission: Permission) => {
        const { resource, action } = splitPermission(permission)

        return this.$t(`permissions.action.label.${action}`) + ' ' + this.$t(`permissions.resource.label.${resource}`)
      }
    )
  }

  public get noPermissions (): string {
    return `${this.$t('permissions.list.no_permissions')}`
  }
}

export default PermissionsPopover
