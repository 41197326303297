

































import { DashmixIconName } from '@movecloser/ui-core'
import { defineComponent, SetupContext } from '@vue/composition-api'

import { snippetSelectorProps, useSnippetSelector } from './SnippetSelector.hooks'
import { SnippetSelectorProps } from '../PageBuilder.contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const SnippetSelector = defineComponent({
  name: 'SnippetSelector',
  props: snippetSelectorProps,
  setup: (props: SnippetSelectorProps, ctx: SetupContext) => ({
    ...useSnippetSelector(props, ctx.emit),
    iconsSet: DashmixIconName
  })
})

export default SnippetSelector
