// Copyright © 2021 Move Closer

import { AnyModule, ContainerModule } from '../../../backoffice'
import { IResponse, mapModel, MappingConfig, Repository, ResourceActionFailed } from '@movecloser/front-core'

import { retrieveResult } from '../../root/helpers/process'
import { resolveFromStatus } from '../../shared/exceptions/connector-errors'

import { ContentData, IVariant, VariantData, VariantModel } from '../contracts/models'
import { IVariantsRepository } from '../contracts/repositories'
import { Variant } from '../models/variant'
import { variantAdapterMap } from '../models/variant.adapter'
import { variantStatusAdapterMap, VariantStatusPayload } from '../services/variantStatus'
import { ModuleValidationResult, SaveVariantMode, ValidationType, VariantUpdatePayload } from '../contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 */
export class VariantsRepository extends Repository<VariantData, IVariant> implements IVariantsRepository {
  protected map: MappingConfig = variantAdapterMap
  protected useAdapter = true

  public async create (content: ContentData['id']): Promise<VariantModel> {
    const response: IResponse = await this.connector.call('contentVariants', 'create', { content })

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response),
        response.data.data
      )
    }

    const result = retrieveResult(response)

    return await this.load(content, result.id)
  }

  public async clone (content: ContentData['id'], from: VariantData['id']): Promise<VariantModel> {
    const response: IResponse = await this.connector.call('contentVariants', 'create', { content }, { fromVariant: from })

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response),
        response.data.data
      )
    }

    const result = retrieveResult(response)

    return await this.load(content, result.id)
  }

  public async delete (content: ContentData['id'], id: VariantData['id']): Promise<void> {
    const response: IResponse = await this.connector.call('contentVariants', 'delete', { content, id })

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response),
        response.data.data
      )
    }
  }

  public async getStatus (id: VariantData['id']): Promise<VariantStatusPayload> {
    const response: IResponse = await this.connector.call('contentVariants', 'status', { id })

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response),
        response.data.data
      )
    }

    return mapModel<VariantStatusPayload>(response.data.data, variantStatusAdapterMap)
  }

  public async load (content: ContentData['id'], id: VariantData['id']): Promise<VariantModel> {
    const response: IResponse = await this.connector.call('contentVariants', 'get', { content, id })

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeModel(response.data.data, Variant)
  }

  public async lock (content: ContentData['id'], id: VariantData['id']): Promise<void> {
    const response: IResponse = await this.connector.call('contentVariants', 'lock', { content, id })

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }

  public async preview (content: ContentData['id'], id: VariantData['id']): Promise<string> {
    const response: IResponse = await this.connector.call('contentVariants', 'preview', { content, id })

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return retrieveResult(response).url
  }

  public async unpublish (content: ContentData['id'], id: VariantData['id']): Promise<void> {
    const response: IResponse = await this.connector.call('contentVariants', 'unpublish', { content, id })

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response),
        response.errors?.errors
      )
    }
  }

  public async update (
    content: ContentData['id'],
    id: VariantData['id'],
    payload: VariantUpdatePayload,
    postSave: SaveVariantMode = SaveVariantMode.None
  ): Promise<void> {
    const response: IResponse = await this.connector.call(
      'contentVariants',
      'update',
      { content, id },
      {
        ...payload,
        postSave
      }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response),
        response.errors?.errors
      )
    }
  }

  public async updateAndPreview (content: ContentData['id'], id: VariantData['id'], payload: VariantUpdatePayload): Promise<string> {
    await this.update(content, id, payload)

    return await this.preview(content, id)
  }

  public async validate (payload: AnyModule | ContainerModule, type: ValidationType): Promise<ModuleValidationResult> {
    const response: IResponse = await this.connector.call('contentVariants', 'validate', {}, { [type]: payload })

    if (!response.isSuccessful()) {
      if (response.status !== 422) {
        throw new ResourceActionFailed(
          response.errors?.message,
          resolveFromStatus(response),
          response.errors?.errors
        )
      }

      const errors: { [key: string]: string[] } = response.errors!.errors

      return {
        isValid: false,
        errors: Object.values(errors).reduce(
          (acc: string[], errors: string[]) => {
            acc.push(...errors)
            return acc
          },
          []
        )
      }
    }

    return { isValid: true, errors: [] }
  }
}
