










import { Component, Prop, Vue } from 'vue-property-decorator'
import { DashmixAlertTheme } from '@movecloser/ui-core'

/**
 * @author  Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'AddonErrors'
})
export class AddonErrors extends Vue {
  @Prop({ type: Array, required: true })
  public readonly errors!: string[]

  public alertTheme: DashmixAlertTheme = DashmixAlertTheme.Danger
}

export default AddonErrors
