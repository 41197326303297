














import { DashmixBreadcrumbsProps } from '@movecloser/ui-core'
import { Component, Mixins } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'

import { Loader } from '../../shared/components/Loader/Loader'

import { ContentListConfig } from '../services/content-type-manager'
import { ContentTypeValidator, IContentTypeAware } from '../mixins'
import { initBreadcrumbs } from '../helpers'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component<List>({
  name: 'List',
  components: { Loader },

  metaInfo (this: List): MetaInfo {
    return {
      title: this.$tc(this.contentTypeManager.getLabel(this.contentType), 2).toString()
    }
  }
})
export class List extends Mixins<IContentTypeAware>(ContentTypeValidator) {
  public isPreviewLoading: boolean = false
  public isValidated: boolean = false

  public get breadcrumbs (): DashmixBreadcrumbsProps {
    return {
      items: [
        {
          label: this.$tc(this.contentTypeManager.getLabel(this.contentType), 2).toString(),
          target: { name: 'content.list', params: { type: this.contentType } }
        }
      ],
      root: initBreadcrumbs.root
    }
  }

  public get contentListConfig (): ContentListConfig {
    return this.contentTypeManager.getContentListConfig(this.contentType)
  }

  protected async onPositiveTypeValidation (): Promise<void> {
    if (
      typeof this.contentListConfig.loadAs === 'string' &&
      this.contentListConfig.loadAs.length > 0
    ) {
      await this.$router.push({
        name: 'content.list',
        params: { type: this.contentListConfig.loadAs }
      })
    }

    this.isValidated = true
  }

  protected async onTypeValidationFail (defaultType: string): Promise<void> {
    await this.$router.push({
      name: 'content.list', params: { type: defaultType }
    })
  }
}

export default List
