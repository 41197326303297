// Copyright © 2022 Move Closer

import { CSRFService as Abstract } from '@movecloser/front-core/lib/services/csrf/service'
import { FoundResource, Headers, Injectable, Payload } from '@movecloser/front-core'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Injectable()
export class CSRFService extends Abstract {
  /**
   * Compose payload that will be send.
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected composePayload (resource: FoundResource, headers: Headers, body: Payload): Payload {
    let apiAddress: string = `${process.env.VUE_APP_HTTP_BNP_API_BASEURL}`
    if (apiAddress.endsWith('/')) {
      apiAddress = apiAddress.substr(0, apiAddress.length - 1)
    }

    return { target: [apiAddress, resource.url].join('/') }
  }

  /**
   * Retrieve token from response.
   */
  protected retrieveTokenFromResponse (data: Payload): string {
    return data.data.token
  }
}
