














import { Component } from 'vue-property-decorator'

import { AbstractSetItem } from './AbstractSetItem'
import { defaultDateFormat } from '../../../content/helpers/formatting'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({ name: 'ArticlesSetItem' })
export class ArticlesSetItem extends AbstractSetItem<any> {
  public get publicationDate (): string {
    if (this.data.publicationDate === '' || !this.data.publicationDate) {
      return String(this.$t('articles.item.hiddenPublicationDate'))
    }
    return this.dateTime.parseToFormat(this.data.publicationDate, defaultDateFormat)
  }

  public get author (): string {
    if (!this.data.author || typeof this.data.author.fullName !== 'function') {
      return '--'
    }
    return this.data.author.fullName()
  }
}

export default ArticlesSetItem
