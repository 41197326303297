// Copyright © 2021 Move Closer

import { AxiosDriver } from '@movecloser/front-core/lib/services/http/axios-driver'
import { IHttpConnectorConfig } from '@movecloser/front-core'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const http: IHttpConnectorConfig = {
  drivers: {
    xCore: () => {
      const defaultLocale = process.env.VUE_APP_HTTP_API_LOCALE || 'en-GB'

      return new AxiosDriver({
        baseURL: process.env.VUE_APP_HTTP_API_BASEURL,
        headers: {
          Accept: 'application/json',
          'Accept-Language': defaultLocale,
          'X-Client': process.env.VUE_APP_HTTP_API_CLIENT
        }
      }, (process.env.VUE_APP_HTTP_DEBUG === 'true'))
    }
  },
  default: 'xCore'
}
