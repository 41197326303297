

















































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { DateTimeType, IDateTime } from '@movecloser/front-core'
import { Fragment } from 'vue-fragment'
import { RawLocation } from 'vue-router'

import { Inject } from '../../../backoffice'
import { Query } from '../../shared/contracts/query'

import { ContentModel, ContentStatus } from '../contracts'
import {
  ContentPermissions,
  ContentTypeAware,
  IContentPermissions,
  IContentTypeAware
} from '../mixins'
import { ContentStatusBadge } from '../contracts/models'
import { EditorsStack } from './EditorsStack.vue'

@Component({
  name: 'TableNodeRow',
  components: { EditorsStack, Fragment }
})
export class TableNodeRow extends Mixins<IContentPermissions, IContentTypeAware>(
  ContentPermissions,
  ContentTypeAware
) {
  @Prop({ type: Object, required: true })
  public data!: ContentModel

  @Prop({ type: Boolean, required: false, default: false })
  public isParent!: boolean

  @Inject(DateTimeType)
  protected dateTime!: IDateTime

  public ContentStatus = ContentStatus
  public ContentStatusBadge = ContentStatusBadge

  public get isClickable (): boolean {
    return (this.isParent && this.data.parent !== null) || (!this.isParent && !!this.data.childrenCount)
  }

  public get isSearching (): boolean {
    return typeof this.queryParams.q === 'string' && this.queryParams.q.length > 0
  }

  public get nodeTarget (): RawLocation {
    return this.isClickable
      ? {
        name: 'content.list',
        query: { parent: this.isParent ? this.data.parent.id.toString() : this.data.id.toString() }
      }
      : ''
  }

  public get queryParams (): Query {
    return this.$route.query
  }

  public get typeLabel (): string {
    return this.$tc(this.contentTypeManager.getLabel(this.data.type), 1).toString()
  }
}

export default TableNodeRow
