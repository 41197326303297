// Copyright © 2022 Move Closer

import { Methods, ResourcesRegistry } from '@movecloser/front-core'

// TODO: Change csrf to `true` when API is deployed.
export const resources: ResourcesRegistry = {
  navigations: {
    prefix: 'api/management',
    methods: {
      delete: {
        url: 'navigations/{navigationId}',
        params: ['navigationId'],
        method: Methods.Delete,
        auth: true,
        meta: { toast: true, csrf: true }
      },
      get: {
        url: 'navigations/{navigationId}',
        params: ['navigationId'],
        method: Methods.Get,
        auth: true
      },
      list: {
        url: 'navigations',
        method: Methods.Get,
        auth: true
      },
      create: {
        url: 'navigations',
        method: Methods.Post,
        auth: true,
        shorthand: 'editNavigation',
        meta: { csrf: true, toast: true }
      },
      update: {
        url: 'navigations/{navigationId}',
        params: ['navigationId'],
        method: Methods.Put,
        auth: true,
        shorthand: 'editNavigation',
        meta: { csrf: true, toast: true }
      }
    }
  },
  settings: {
    prefix: 'api/management',
    methods: {
      get: {
        url: 'settings/{type}',
        params: ['type'],
        method: Methods.Get,
        auth: true
      },
      list: {
        url: 'settings/',
        method: Methods.Get,
        auth: true
      },
      update: {
        url: 'settings/{type}',
        params: ['type'],
        method: Methods.Post,
        auth: true,
        meta: { csrf: true, toast: true }
      }
    }
  }
}
