// Copyright © 2021 Move Closer

import { ICollection } from '@movecloser/front-core'

import { Query } from '../../shared/contracts/query'
import {
  DictModel,
  SiteModel
} from './models'
import { Identifier } from '../../../backoffice'

export interface IDictionaryRepository {
  loadDepartmentsDictionary (query: Query): Promise<ICollection<DictModel>>
  loadCategoryDictionary (query: Query): Promise<ICollection<DictModel>>
  loadLayoutsDictionary (query: Query): Promise<ICollection<DictModel>>
  loadRolesDictionary (query: Query): Promise<ICollection<DictModel>>
  loadUser (id: Identifier): Promise<DictModel>
  loadUsersDictionary (query: Query): Promise<ICollection<DictModel>>
}

export const DictionaryRepositoryType = Symbol.for('IDictionaryRepository')

export interface ISitesRepository {
  loadCollection (): Promise<ICollection<SiteModel>>
}

export const SitesRepositoryType = Symbol.for('ISitesRepository')
