import { AdvertiseBoxModule, AdvertiseBoxModuleContent } from './AdvertiseBox.contract'
import { ModuleBase, ModuleFactory } from '../../contexts'
import { abstractModuleFactory, getDefaultModuleAlignment } from '../abstract/form'
import { AvailableModuleDriver } from '../allowed'

/**
 * @author Magda Pietruszewska <magda.pietruszewska@movecloser.pl>
 */
export const advertiseBoxContentFactory = (): AdvertiseBoxModuleContent => ({
  items: []
})

/**
 * @author Magda Pietruszewska <magda.pietruszewska@movecloser.pl>
 */
export const advertiseBoxFactory: ModuleFactory<AdvertiseBoxModule> = {
  ...abstractModuleFactory,
  createModule (): ModuleBase<AdvertiseBoxModule> {
    return {
      alignment: getDefaultModuleAlignment(),
      content: advertiseBoxContentFactory(),
      driver: AvailableModuleDriver.AdvertiseBox
    }
  }
}
