// Copyright © 2022 Move Closer

import { AddonConfig, ContentTypeConfig } from '../../content/services/content-type-manager'

import StaticRoutingAddon from '../components/StaticRoutingAddon.vue'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export enum Addon {
  StaticRouting = 'routing'
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const staticAddons: AddonConfig[] = [
  { component: StaticRoutingAddon, label: 'static.addonsLabels.staticPage', name: Addon.StaticRouting, priority: 1 }
]

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const staticEditor = 'staticEditor'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const staticContentType: string = 'static-page'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const staticContentTypeConfig: Partial<ContentTypeConfig> = {
  editor: staticEditor,
  label: 'static.contentTypes.staticPage',
  loadAs: 'page',
  hasParent: true,
  hasPreview: true,
  parentType: 'page'
}
