// Copyright © 2022 Move Closer

import { AnyObject } from '@movecloser/front-core'
import { computed, ref } from '@vue/composition-api'
import { Emit } from '@movecloser/ui-core'

import { FormFieldAbstractSetup, FormProps } from '../Abstract'

export function useColorPicker (props: FormProps, emit: Emit) {
  const {
    errors,
    displayLabel,
    hasErrors,
    value
  } = FormFieldAbstractSetup(props, emit)

  const displayPicker = ref<boolean>(false)
  const pickerValue = computed<AnyObject>({
    get: () => {
      return { hex: value.value }
    },
    set: (chosen: AnyObject) => {
      value.value = chosen.hex
    }
  })

  function togglePicker (value?: boolean): void {
    if (props.disabled) {
      return
    }

    displayPicker.value = typeof value !== 'undefined' ? value : !displayPicker.value
  }

  function closePicker (): void {
    togglePicker(false)
  }

  function openPicker (): void {
    togglePicker(true)
  }

  return { closePicker, displayLabel, displayPicker, errors, hasErrors, openPicker, pickerValue, togglePicker, value }
}
