// Copyright © 2021 Move Closer

import throttle from 'lodash/throttle'
import { DirectiveBinding } from 'vue/types/options'
import { Vue } from 'vue-property-decorator'

/**
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 *
 * Directive to emit scroll events with throttling and a defined scroll percentage threshold.
 *
 * @param [binding.value]
 * @param [binding.value.callback] {(e: Event) => void} - throttled scroll event callback
 * @param [binding.value.throttleInterval] {number} - milliseconds
 * @param [binding.value.threshold] {number} - percentage of container height
 */
export function scrollBinding (el: HTMLElement, binding: DirectiveBinding) {
  const throttleInterval = binding.value.throttleInterval || 300
  const threshold = binding.value.threshold || 90

  const throttledCallback = throttle(binding.value.callback, throttleInterval)

  const handleScroll = (e: Event) => {
    const element = (e.target as HTMLDivElement)

    if (((element.scrollTop + element.offsetHeight) / element.scrollHeight * 100) > threshold) {
      throttledCallback.cancel()

      throttledCallback(e)
    }
  }

  el.addEventListener('scroll', handleScroll)
}

export const ScrollHandle = Vue.directive('scroll', { bind: scrollBinding })
