// Copyright © 2022 Move Closer

import { Model, ModelPayload } from '@movecloser/front-core'

import { Identifier } from '../../../backoffice'
import { Owner } from '../../root/models/owner'

import {
  ContentStatus,
  ISimpleVariant,
  IVariant,
  SimpleVariantData,
  SimpleVariantModel,
  VariantData,
  VariantUpdatePayload
} from '../contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export class SimpleVariant extends Model<SimpleVariantData> implements ISimpleVariant {
  protected boot (): void {
    this.initialValues = {}

    this.modelProperties = [
      'id',
      'author',
      'editor',
      'name',
      'addons',
      'labels',
      'status',
      'createdAt',
      'updatedAt'
    ]
  }

  public isEditor (user: Identifier | null): boolean {
    return this._data.editor === null || this._data.editor.id === user
  }

  protected relatesToEditor (value: ModelPayload) {
    return this.hasOne(Owner, value)
  }
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export class Variant extends Model<VariantData> implements IVariant {
  protected boot (): void {
    this.initialValues = {}

    this.modelProperties = [
      'addons',
      'containers',
      'content',
      'createdAt',
      'author',
      'editor',
      'grid',
      'id',
      'lockedAt',
      'modules',
      'name',
      'properties',
      'status',
      'structure',
      'title',
      'updatedAt',
      'url'
    ]
  }

  public getProperty<T> (propertyKey: string): T | null {
    if (!this._data.properties || !this._data.properties[propertyKey]) {
      return null
    }
    return this._data.properties[propertyKey]
  }

  public isDraft (): boolean {
    return this._data.status === ContentStatus.Draft
  }

  public isEditable (user: Identifier | null): boolean {
    return this.isDraft() && this.isEditor(user)
  }

  public isEditor (user: Identifier | null): boolean {
    return this._data.editor === null || this._data.editor.id === user
  }

  public isLive (): boolean {
    return this._data.addons.includes('liveFeed')
  }

  public isRemovable (user: Identifier | null): boolean {
    return [ContentStatus.Draft, ContentStatus.InAcceptance].includes(this._data.status as ContentStatus) &&
      this.isEditor(user)
  }

  public toSimpleVariant (): SimpleVariantModel {
    return SimpleVariant.hydrate<SimpleVariantData, ISimpleVariant>(this._data)
  }

  public toUpdatePayload (): VariantUpdatePayload {
    return {
      addons: this._data.addons,
      containers: this._data.containers,
      modules: this._data.modules,
      name: this._data.name,
      properties: this._data.properties,
      structure: this._data.structure,
      title: this._data.title
    }
  }

  public setProperty<T> (propertyKey: string, payload: T): void {
    this._data.properties[propertyKey] = payload
  }

  protected relatesToEditor (value: ModelPayload) {
    return this.hasOne(Owner, value)
  }
}
