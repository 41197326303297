













import { Component, Prop, Vue } from 'vue-property-decorator'
import { DashmixIconName } from '@movecloser/ui-core'

import { trimText } from '../../shared/helpers/trimText'

import { ContentVariantTabToggle, SimpleVariantData, VariantModel } from '../contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component({
  name: 'VariantSelectionItem'
})
export class VariantSelectionItem extends Vue {
  @Prop({ type: Object, required: true })
  public item!: SimpleVariantData

  @Prop({ type: Array, required: true })
  protected selected!: VariantModel[]

  public trimText: (text: string, maxLength: number, breakWords: boolean) => string = trimText

  public get actionIcon (): DashmixIconName {
    return this.isPinned ? DashmixIconName.TimesSolid : DashmixIconName.PaperclipSolid
  }

  public get isPinned (): boolean {
    return typeof this.selected.find(v => v.id === this.item.id) !== 'undefined'
  }

  public get variantNameToDisplay (): string {
    if (!this.item.name) {
      return this.item.createdAt
    }

    return this.item.name
  }

  public toggleTabPin (): void {
    this.$emit('togglePinned', {
      id: this.item.id,
      action: this.isPinned ? ContentVariantTabToggle.Unpin : ContentVariantTabToggle.Pin
    })
  }
}

export default VariantSelectionItem
