// Copyright © 2021 Move Closer

import { ContainersRegistry, ContentStructure, ModulesRegistry } from '@movecloser/page-builder'
import { IModel, MagicModel } from '@movecloser/front-core'

import { Identifier } from '../../../backoffice'

import { HasIdentifier } from '../../shared/contracts/data'

import { OwnerModel } from '../../root/contracts/models'

export type ILayout = IModel<LayoutData>

export interface LayoutData extends HasIdentifier {
  containers: ContainersRegistry
  createdAt?: string
  editor?: OwnerModel
  isActive: boolean
  modules: ModulesRegistry
  name: string
  slots: LayoutSlotData
  theme: string
  updatedAt?: string
}

export type LayoutModel = MagicModel<LayoutData, ILayout>

export enum LayoutSlot {
  Bottom = 'bottom',
  Top = 'top'
}

export type LayoutSlotData = {
  [value in LayoutSlot]: ContentStructure
}
