// Copyright © 2021 Move Closer

import { Model } from '@movecloser/front-core'

import { Identifier } from '../../../backoffice'

import { ISet, SetData } from '../contracts'

export class Set extends Model<SetData> implements ISet {
  protected boot (): void {
    this.initialValues = {}

    this.modelProperties = [
      'editor',
      'childrenCount',
      'createdAt',
      'id',
      'properties',
      'strategy',
      'title',
      'type',
      'updatedAt'
    ]
  }

  public authorName (): string {
    return this._data.author.fullName()
  }

  public displayName (): string {
    return this._data.title
  }

  public getItemCount (): number {
    return this._data.properties.items.length
  }

  public identifier (): Identifier {
    return this._data.id
  }
}
