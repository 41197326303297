// Copyright © 2021 Move Closer

import { Roles } from '../contracts/models'
import { Identifier } from '../../../backoffice'
import { Permission } from '../../auth/contracts/permissions'

export interface PermissionsListItemProps {
  checked: boolean
  label: string
  description: string
  value: Permission
}

export enum RoleFormMode {
  Create = 'create',
  Edit = 'edit'
}

export interface EditUsersRolesPayload {
  userId: Identifier
  onSave: (newRoles: Roles) => void
}
