import { render, staticRenderFns } from "./GalleryDraggableList.vue?vue&type=template&id=24f9dcc0&scoped=true&"
import script from "./GalleryDraggableList.vue?vue&type=script&lang=ts&"
export * from "./GalleryDraggableList.vue?vue&type=script&lang=ts&"
import style0 from "./GalleryDraggableList.vue?vue&type=style&index=0&id=24f9dcc0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24f9dcc0",
  null
  
)

export default component.exports