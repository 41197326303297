


































import { Component, Prop, Vue } from 'vue-property-decorator'
import { DashmixIconName } from '@movecloser/ui-core'
import { IModal, ModalType } from '@movecloser/front-core'

import { FormInput } from '../../shared/components/form'
import { Inject } from '../../../backoffice'

import { DirectoryMoveModalPayload } from '../contracts/components'
import { DirectorySelect } from './DirectorySelect.vue'
import { MediaMovePayload, DirectoryParentData } from '../contracts'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
@Component({
  name: 'DirectoryMoveModal',
  components: { DirectorySelect, FormInput }
})
export class DirectoryMoveModal extends Vue {
  @Prop({ type: Object, required: true })
  public payload!: DirectoryMoveModalPayload

  @Inject(ModalType)
  protected modalConnector!: IModal

  public choosenDirectory: DirectoryParentData | null = null
  public formName: string = 'moveDirectory'
  public icons = DashmixIconName
  public isLoading: boolean = false

  public get form (): MediaMovePayload {
    return {
      directory: this.choosenDirectory?.id || null
    }
  }

  mounted () {
    this.choosenDirectory = this.payload.directory.parent
  }

  public close (): void {
    this.$emit('close')
  }

  public sendForm (): void {
    this.payload.moveDirectory(this.payload.directory.id, this.form)
  }
}

export default DirectoryMoveModal
