



















































































import { Component, Mixins, Prop, PropSync, Watch } from 'vue-property-decorator'
import { DashmixIconName } from '@movecloser/ui-core'
import { FormCheckbox, FormInput } from '../../shared/components/form'

import { FormSelect } from '../../shared/components/form/Select'
import { Item } from '../../shared/components/TreeViewer'
import { ISiteResolver, SiteResolverType } from '../../root/services/site-resolver'
import { Inject } from '../../../backoffice'
import { SelectItem } from '../../shared/contracts/form'

import { ContentCreatePayload, ContentEditPayload } from '../contracts/data'
import { ContentTypeAware, IContentTypeAware } from '../mixins'
import { FormMode, OnDirtyCallback } from '../contracts'
import { generateSlug } from '../helpers/slugs'
import { mapNodeToParent, mapParentToNode, mapParentToSlug } from '../helpers'
import { NodeSelect } from './NodeSelect.vue'
import { ParentData } from '../contracts/models'
import { ContentConfig } from '../services/content-type-manager'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component<ContentBasicsForm>({
  name: 'ContentBasicsForm',
  components: { FormSelect, FormCheckbox, FormInput, NodeSelect },

  mounted () {
    this.nodes = mapParentToNode(this.payload.parent)
    this.urlPrefix = this.buildUrl(this.payload.parent)
  }
})
export class ContentBasicsForm extends Mixins<IContentTypeAware>(ContentTypeAware) {
  @Prop({ type: String, required: true })
  public form!: string

  @Prop({ type: Boolean, required: false, default: false })
  public isEditMode!: boolean

  @Prop({ type: Boolean, required: false })
  public isSaving!: boolean

  @Prop({ type: Function, required: false, default: () => { return () => false } })
  protected onDirty!: OnDirtyCallback

  @PropSync('formData', { type: Object, required: true })
  public payload!: ContentCreatePayload | ContentEditPayload

  @Inject(SiteResolverType)
  protected siteResolver!: ISiteResolver

  public nodes: Item[] = []
  public urlPrefix: string = ''
  private useDefaultSlug: boolean = true

  public Icons = DashmixIconName
  public PossibleModes = FormMode

  public get contentConfig (): ContentConfig {
    return this.contentTypeManager.getContentConfig(this.contentType)
  }

  public get contentTypeOptions (): SelectItem[] {
    return this.contentTypeManager.getSiblingTypes(this.contentType).map(t => ({
      label: this.$tc(this.contentTypeManager.getLabel(t), 1).toString(),
      value: t
    }))
  }

  public get possibleParentTypes (): string [] {
    return this.contentTypeManager.getParentTypes(this.contentType)
  }

  public get slug () {
    return this.payload.slug
  }

  public set slug (value: string) {
    this.payload.slug = value
  }

  public get slugToDisplay (): string {
    const toDisplay: string[] = [
      this.urlPrefix.replace(/^\/|\/$/g, ''),
      this.useDefaultSlug ? this.payload.slug ? this.payload.slug : '' : ''
    ]

    return toDisplay.filter(s => s !== '/' && s !== '').join('/')
  }

  public routeToList () {
    this.$router.push({ name: `content.${this.contentType}s.list` })
  }

  public selectNodes (selectedItemsList: Item[]): void {
    const nodesCount: number = this.nodes.length
    this.nodes = selectedItemsList
    if (nodesCount > 0) {
      this.onDirty(true)
    }
  }

  protected blurUserSlug () {
    this.payload.slug = generateSlug(this.payload.slug)
  }

  protected buildUrl (p: ParentData | null): string {
    let domain = this.siteResolver.getSiteAddress()

    if (domain[domain.length - 1] === '/') {
      domain = domain.slice(0, -1)
    }

    return [
      domain,
      mapParentToSlug(p)
    ].filter(s => s !== '').join('/')
  }

  @Watch('nodes', { deep: true })
  protected onNodesChange (nodes: Item[]) {
    this.payload.parent = mapNodeToParent(nodes)
    this.urlPrefix = this.buildUrl(this.payload.parent)
  }

  @Watch('payload.title', { deep: false })
  protected onTitleChange (title: string): void {
    this.updateGeneratedSlug(title)
    this.onDirty(true)
  }

  protected toggleUseDefaultSlug () {
    this.useDefaultSlug = !this.useDefaultSlug
    this.updateGeneratedSlug(this.payload.title)
    this.onDirty(true)
  }

  protected updateGeneratedSlug (title: string) {
    if (this.useDefaultSlug) {
      this.payload.slug = generateSlug(title)
    }
  }
}

export default ContentBasicsForm
