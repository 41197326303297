// Copyright © 2021 Move Closer

import { AbstractIntention } from '@movecloser/front-core'

import { SetItem } from '../contracts'

export class AddSetItemIntention extends AbstractIntention<SetItem> {
  protected map = {
    id: 'id',
    position: 'position'
  }
}
