




























import { Component, Prop } from 'vue-property-decorator'
import { Cropper } from 'vue-advanced-cropper'
import { DashmixIconName, DashmixAlertTheme } from '@movecloser/ui-core'
import { ResourceActionFailed } from '@movecloser/front-core'

import { AbstractModal } from '../../shared/components/Modal'
import { ConnectorErrors } from '../../shared/exceptions/connector-errors'
import { Inject } from '../../../backoffice'
import { Loader } from '../../shared/components/Loader'

import { AspectRatioSizesMap } from '../helpers'
import { CropImageModalPayload, CropperEvent, FileRepositoryType, IFileRepository } from '../contracts'

@Component({
  name: 'CropImageModal',
  components: { Loader, Cropper }
})
export class CropImageModal extends AbstractModal {
  @Prop({ type: Object, required: true })
  public payload!: CropImageModalPayload

  @Inject(FileRepositoryType)
  protected readonly filesRepository!: IFileRepository

  public acting: boolean = false
  public alertTheme: DashmixAlertTheme = DashmixAlertTheme.Danger
  public error: string | null = null
  public icons = DashmixIconName
  protected instructions: CropperEvent | null = null
  public isLoading: boolean = true

  public get variantLabel (): string {
    const selectedSize = AspectRatioSizesMap[this.payload.ratio]

    if (selectedSize === null) {
      return ''
    }

    const parts: (string)[] = [
      `${selectedSize.width}px`,
      selectedSize.height ? `${selectedSize.height}px` : `${this.$t('media.file.crop.autoHeight')}`
    ]

    return parts.join(' x ')
  }

  public clearError (): void {
    this.error = null
  }

  public cropImage (): void {
    if (!this.instructions) {
      return
    }

    this.acting = true

    this.filesRepository.cropFile(
      this.payload.id,
      { coordinates: this.instructions.coordinates, ratio: this.payload.ratio }
    ).then(() => {
      this.escapePressed()
    }).catch((error: ResourceActionFailed) => {
      switch (error.status) {
        case ConnectorErrors.Conflict:
        case ConnectorErrors.Validation:
          this.alertTheme = DashmixAlertTheme.Warning
          this.error = error.message
          break
        default:
          this.alertTheme = DashmixAlertTheme.Danger
          this.error = error.message
      }
    }).finally(() => {
      this.acting = false
    })
  }

  public escapePressed (): void {
    this.$emit('back')
  }

  public setCrop (data: CropperEvent): void {
    this.instructions = data
  }

  protected onImageError (): void {
    this.alertTheme = DashmixAlertTheme.Danger
    this.error = `${this.$t('media.file.crop.failedLoading')}`
  }

  protected onImageReady (): void {
    this.isLoading = false
  }
}

export default CropImageModal
