// Copyright © 2021 Move Closer

import { AvailableModuleDriver } from '../../allowed'

/**
 * Extracts name of the module
 *
 * It's kind of a workaround. Instead of creating map of `ModuleDriver` with applicable string
 * name , we just used enum's key as its name.
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export function nameOfModule (driver: string): string {
  return Object.keys(AvailableModuleDriver)[
    Object.values(AvailableModuleDriver).indexOf(driver as AvailableModuleDriver)
  ]
}

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
type ModuleVersionMap = Record<string, string>

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export type ModuleVersions<Map extends string> = Record<Map, ModuleVersionMap>
