// Copyright © 2021 Move Closer

import { computed, ComputedRef, defineComponent } from '@vue/composition-api'

import { FormDateProps } from './DateTimePicker.contracts'
import { FormFieldAbstract, FormFieldAbstractSetup } from '../Abstract'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
export const FormDateTimePicker = defineComponent({
  name: 'FormDateTimePicker',
  extends: FormFieldAbstract,
  template: `
    <div>

      <label :for="name" v-if="displayLabel" :require="required">{{ label }}</label>

      <DsDateTimePicker :model.sync="value"
              v-bind="{
                name, autocomplete, disabled, format,
                hourStep, minuteStep, secondStep,
                showSecond, placeholder, range,
                readonly, type, valueType, disabledDate
              }"
              v-focus="autofocus"
              :class="classObject" />

      <div class="invalid-feedback animated fadeIn" v-if="hasErrors">{{ errors[0] }}</div>
      <small class="text-muted" v-if="helper">{{ helper }}</small>

    </div>

  `,
  props: {
    autocomplete: {
      type: String,
      required: false,
      default: 'off'
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false
    },
    disabledDate: {
      type: Function,
      required: false
    },
    format: {
      type: String,
      required: false
    },
    hourStep: {
      type: Number,
      required: false
    },
    minuteStep: {
      type: Number,
      required: false,
      default: 10
    },
    secondStep: {
      type: Number,
      required: false
    },
    showSecond: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    range: {
      type: Boolean,
      required: false,
      default: false
    },
    type: {
      type: String,
      required: false,
      default: 'date'
    },
    valueType: {
      type: String,
      required: false,
      default: 'format'
    }
  },
  emits: ['input'],

  setup (props: FormDateProps, { emit }) {
    const {
      errors,
      displayLabel,
      hasErrors,
      value
    } = FormFieldAbstractSetup(props, emit)

    const classObject: ComputedRef<object> = computed(() => {
      return {
        'is-invalid': hasErrors.value
      }
    })

    return {
      classObject,
      errors,
      displayLabel,
      hasErrors,
      value
    }
  }
})
export default FormDateTimePicker
