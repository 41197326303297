














import { Component, Prop, Vue } from 'vue-property-decorator'

import { PageBuilder } from '../../shared/components'
import { Inject, logger, IRelatedService } from '../../../backoffice'

import {
  ContentData,
  IVariantsRepository,
  VariantData,
  VariantsRepositoryType
} from '../contracts'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'AcceptancePreview',
  components: { PageBuilder }
})
export class AcceptancePreview extends Vue {
  @Prop({ required: true })
  public content!: ContentData['id']

  @Prop({ type: String, required: true })
  public contentType!: string

  @Prop({ type: Object, required: true })
  public model!: VariantData

  @Prop({ type: Object, required: true })
  protected readonly relatedService!: IRelatedService

  @Inject(VariantsRepositoryType)
  protected variantsRepository!: IVariantsRepository

  public isGeneratingPreview: boolean = false

  public async openPreview (): Promise<void> {
    this.isGeneratingPreview = true

    try {
      const url: string = await this.variantsRepository.preview(
        this.content,
        this.model.id
      )

      window.open(url)
    } catch (e) {
      logger(e, 'warn')
    }

    this.isGeneratingPreview = false
  }
}

export default AcceptancePreview
