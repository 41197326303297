
















import { AnyObject, AuthServiceType, ConjunctionOperator } from '@movecloser/front-core'
import { AuthService } from '@movecloser/front-core/lib/services/authorization'
import { Component, Vue } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'
import { DashmixBreadcrumbsProps } from '@movecloser/ui-core'

import { EditModeLayout } from '../../shared/components/EditModeLayout/EditModeLayout'
import { Inject, PossibleRelatedType, SetType } from '../../../backoffice'
import { Loader } from '../../shared/components/Loader'

import { initBreadcrumbs } from '../../root/helpers/breadcrumbs'

import { CreateSetIntention, CreateSetIntentionPayload } from '../intentions/CreateSetIntention'
import { ISetsRepository, SetsRepositoryType, SetStrategy } from '../contracts'
import { Set } from '../models/set'
import { SetForm } from '../components/SetForm.vue'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'CreateSet',
  components: { EditModeLayout, SetForm, Loader },
  metaInfo (this: CreateSet): MetaInfo {
    return {
      title: `${this.$t('sets.create.title')}`
    }
  }
})
export class CreateSet extends Vue {
  @Inject(AuthServiceType)
  private authService!: AuthService

  @Inject(SetsRepositoryType)
  private setsRepository!: ISetsRepository

  public formName: string = 'createSet'
  public isLoading: boolean = false
  public payload!: CreateSetIntentionPayload

  public get breadcrumbs (): DashmixBreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t(`sets.listTitle.${this.setType}`)}`,
          target: { name: 'sets.list' }
        },
        {
          label: 'Dodaj listę',
          target: { name: 'sets.create' }
        }
      ],
      root: initBreadcrumbs.root,
      showOnMobile: false
    }
  }

  public get setType (): SetType {
    if (!this.$route.params.type) {
      return PossibleRelatedType.Articles
    }

    return this.$route.params.type
  }

  created () {
    const setPayload: AnyObject = {
      author: this.authService.getUserId(),
      type: this.setType,
      title: '',
      childrenCount: 0,
      properties: {
        src: [],
        conjunction: ConjunctionOperator.And
      }
    }

    switch (this.setType) {
      case PossibleRelatedType.Articles:
        setPayload.strategy = SetStrategy.Automatic
        break
      case PossibleRelatedType.Products:
        setPayload.strategy = SetStrategy.Manual
        break
      default:
        console.warn(`[CreateSet]: No supporter set type! Given type: ${this.setType}`)
        this.$router.push({ name: 'sets.list', params: { type: PossibleRelatedType.Articles } })
    }

    this.payload = {
      ...new Set(setPayload).toObject(),
      author: Number(this.authService.user?.id)
    }
  }

  public createSet () {
    this.isLoading = true

    this.setsRepository.create(new CreateSetIntention(this.payload)).then(result => {
      this.$router.push({ name: 'sets.edit', params: { id: result.id } })
    }).catch((error) => {
      console.debug(error)
    }).finally(() => {
      this.isLoading = false
    })
  }

  protected handleSourcesUpdate (newPayload: CreateSetIntentionPayload) {
    this.payload = newPayload
  }

  protected handleTitleUpdate (newTitle: string) {
    this.payload.title = newTitle
  }
}

export default CreateSet

