// Copyright © 2021 Move Closer

import { AnyObject, IModal, ModalType } from '@movecloser/front-core'
import { SizeMap } from '@movecloser/ui-core'

import { PickerSelectionCallback, Related, resolve } from '../../../../../backoffice'

import { UseModuleDependenciesProvides } from '../PageBuilder.contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export function useModuleDependencies (): UseModuleDependenciesProvides {
  const modalConnector: IModal = resolve(ModalType)

  /**
   * Open related data picker.
   */
  function pickRelated (
    picker: string,
    onSelection: PickerSelectionCallback,
    selected?: Related,
    options?: AnyObject
  ): void {
    modalConnector.open(
      picker,
      { onSelection, selected, options },
      { size: SizeMap.Large }
    )
  }

  return { pickRelated }
}
