// Copyright © 2021 Move Closer

import { Component, Prop } from 'vue-property-decorator'

import { PickerPayload } from '../../../content/contracts'

import { PossibleRelatedType } from '../../../../backoffice'

import { SetModel } from '../../contracts'
import AbstractSetPickerModal from './AbstractSetPickerModal.vue'

@Component({ name: 'ArticlesPickerModal' })
export class ArticlesPickerModal extends AbstractSetPickerModal {
  @Prop({ type: Object, required: true })
  public payload!: PickerPayload<SetModel>

  public setType = PossibleRelatedType.Articles
}

export default ArticlesPickerModal
