



































import { Component, Prop, Vue } from 'vue-property-decorator'
import { DashmixIconName } from '@movecloser/ui-core'
import { ICollection, IModal, ModalType } from '@movecloser/front-core'

import {
  Identifier,
  Inject,
  IRelatedService,
  logger,
  PossibleRelatedType,
  Related,
  RelatedServiceType
} from '../../../../backoffice'
import { Loader } from '../../../shared/components/Loader'

import { ProductFilterModel, ProductFiltersRepositoryType, IProductFiltersRepository } from '../../contracts'
import ProductFilterPickerItem from './ProductFilterPickerItem.vue'
import { PickerPayload } from '../../../content/contracts'

@Component<ProductFilterPickerModal>({
  name: 'ProductFilterPickerModal',
  components: { ProductFilterPickerItem, Loader },
  created () {
    this.loadFiltersList()
  }
})
export class ProductFilterPickerModal extends Vue {
  @Prop({ type: Object, required: true })
  public payload!: PickerPayload<ProductFilterModel>

  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(ProductFiltersRepositoryType)
  private filtersRepository!: IProductFiltersRepository

  @Inject(RelatedServiceType)
  protected relatedService!: IRelatedService

  public icons = DashmixIconName

  protected filtersList: ProductFilterModel[] = []
  protected selected: ProductFilterModel[] = []

  private isLoading: boolean = true

  public get isMultiple (): boolean {
    return this.payload.multiple ?? false
  }

  public get isReady (): boolean {
    return !!this.selected && !!this.selected.length
  }

  public apply (): void {
    if (this.payload.onSelection && typeof this.payload.onSelection === 'function') {
      const result = this.selected.map(item => (
         { type: PossibleRelatedType.ProductFilter, value: item.id } as Related
      ))

      this.payload.onSelection(this.isMultiple ? result : result[0])
    }

    this.modalConnector.close()
  }

  public close (): void {
    if (this.payload.onClose && typeof this.payload.onClose === 'function') {
      this.payload.onClose()
    }
    this.modalConnector.close()
  }

  public isSelected (id: Identifier): boolean {
    return !!this.selected && this.selected.filter(item => item.id === id).length > 0
  }

  public loadFiltersList (): void {
    this.isLoading = true

    this.filtersRepository.list()
      .then((filtersList: ICollection<ProductFilterModel>) => {
        this.filtersList = filtersList
      })
      .catch(error => {
        logger(error as Error, 'error')
      })
      .finally(() => {
        this.isLoading = false
      })
  }

  public toggleSelection (filter: ProductFilterModel): void {
    if (this.isSelected(filter.id)) {
      this.selected = this.selected.filter(item => item.id !== filter.id)
    } else {
      this.selected.push(filter)
    }
  }

  public updateSelected (filter: ProductFilterModel): void {
    if (this.isMultiple) {
      this.toggleSelection(filter)
    } else {
      this.selected = [filter]
    }
  }
}

export default ProductFilterPickerModal
