

































import { Component, Mixins, Vue } from 'vue-property-decorator'
import { DashmixAlertTheme } from '@movecloser/ui-core'
import { EventbusType, IEventbus, IModal, ModalType } from '@movecloser/front-core'
import { MetaInfo } from 'vue-meta'

import { EditModeLayout, EditModeTabsProps } from '../../shared/components/EditModeLayout'
import { FormCheckbox } from '../../shared/components/form'
import { Identifier, Inject } from '../../../backoffice'
import { Loader } from '../../shared/components/Loader'
import { PropertyItem } from '../../shared/contracts/content'

import { ISiteResolver, SiteResolverType } from '../../root/services/site-resolver'

import {
  ContentEditPayload,
  ContentModel,
  ContentRepositoryType,
  IContentRepository,
  Properties
} from '../contracts'
import { ContentModals } from '../config/modals'
import { createBreadcrumbsFromContent, initBreadcrumbs, mapOptionsToSelected } from '../helpers'
import { EditContentIntention } from '../intentions/EditContentIntention'
import { ContentBasicsForm } from '../components/ContentBasicsForm.vue'
import { ContentBasicsInformation } from '../components/ContentBasicsInformations.vue'
import { ContentTypeValidator, IContentTypeAware } from '../mixins'
import { ContentConfig } from '../services/content-type-manager'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component<EditBasics>({
  name: 'EditBasics',
  components: { ContentBasicsForm, ContentBasicsInformation, EditModeLayout, FormCheckbox, Loader },
  metaInfo (this: EditBasics): MetaInfo {
    return {
      title: `${this.$t('content.editTabs.meta')}`
    }
  }
})
export class EditBasics extends Mixins<IContentTypeAware>(ContentTypeValidator) {
  @Inject(ContentRepositoryType)
  protected contentRepository!: IContentRepository

  @Inject(EventbusType)
  protected eventBus!: IEventbus

  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(SiteResolverType)
  protected siteResolver!: ISiteResolver

  public alertTheme = DashmixAlertTheme.Warning
  public breadcrumbs = initBreadcrumbs
  protected error: string | null = null
  public formName: string = 'createUpdate'
  protected isDirty: boolean = false
  public isLoading: boolean = true
  public isSaving: boolean = false
  public isDeleting: boolean = false
  public model: ContentModel | null = null
  public payload: ContentEditPayload = {} as ContentEditPayload
  public modelProperties: string[] = []

  public get contentId (): Identifier {
    return this.$route.params.id
  }

  public get contentOptions (): PropertyItem[] {
    return this.contentConfig.properties.map(p => ({
      key: p.name,
      translationKey: p.label
    }))
  }

  public get contentConfig (): ContentConfig {
    return this.contentTypeManager.getContentConfig(this.contentType)
  }

  public get modeTabs (): EditModeTabsProps {
    return {
      items: [
        {
          label: 'content.editTabs.content',
          route: {
            name: 'content.edit.content',
            params: { id: this.contentId.toString(), type: this.contentType }
          },
          guard: (id: Identifier): boolean => {
            return this.preventLosingData(id)
          }
        },
        {
          label: 'content.editTabs.meta',
          route: {
            name: 'content.edit.basics',
            params: { id: this.contentId.toString(), type: this.contentType }
          }
        }
      ],
      initTab: 1
    }
  }

  public get optionsDictionary () {
    if (!this.contentOptions.length || !this.model) {
      return []
    }

    return this.contentOptions.map(option => {
      return {
        label: this.$t(option.translationKey).toString(),
        key: option.key
      }
    })
  }

  public get properties (): string[] {
    return this.modelProperties
  }

  public async deleteArticle (): Promise<void> {
    this.isDeleting = true

    try {
      await this.contentRepository.delete(this.contentId)
      await this.$router.push({ name: 'content.list' })
    } catch (e) {
      this.error = (e as Error).message
    }

    this.isDeleting = false
  }

  public async saveArticleChanges (): Promise<void> {
    if (!this.model) return
    this.isSaving = true

    const intention = new EditContentIntention({ ...this.payload })
    try {
      await this.contentRepository.update(this.contentId, intention.toRequest())

      this.model.set('properties', this.payload.properties)
      this.model.set('title', this.payload.title)
      this.model.set('slug', this.payload.slug)
      this.model.set('parent', this.payload.parent)

      this.isDirty = false
    } catch (e) {
      this.error = (e as Error).message
    }

    this.isSaving = false
  }

  protected async loadContent (id: Identifier): Promise<void> {
    this.isLoading = true

    try {
      const model: ContentModel = await this.contentRepository.load(id)

      this.breadcrumbs.items = [
        {
          label: this.$tc(this.contentTypeManager.getLabel(this.contentType), 2).toString(),
          target: { name: 'content.list', params: { type: this.contentType } }
        },
        ...createBreadcrumbsFromContent(
          model,
          'content.list',
          'parent'
        )
      ]

      this.model = model
      this.payload = model.toEditPayload()
      this.isLoading = false
      this.modelProperties = mapOptionsToSelected(
        model.properties,
        this.contentOptions.map(o => o.key)
      )
      this.setProperties(this.modelProperties)
    } catch (e) {
      this.error = (e as Error).message
    }
  }

  protected onLosingAccept (id: Identifier): void {
    this.eventBus.emit('ui:edit-mode.force', id)
    this.modalConnector.close()
  }

  protected async onPositiveTypeValidation (): Promise<void> {
    await this.loadContent(this.$route.params.id)
  }

  protected onPropertiesChange (options: string[]) {
    this.setProperties(options)

    this.toggleDirty(true)
  }

  protected async onTypeValidationFail (defaultType: string): Promise<void> {
    await this.$router.push({
      name: 'content.edit.basics', params: { id: this.contentId.toString(), type: defaultType }
    })
  }

  protected preventLosingData (id: Identifier): boolean {
    if (!this.isDirty) {
      return true
    }

    this.modalConnector.open(ContentModals.PreventLoosingData, {
      onConfirm: () => this.onLosingAccept(id),
      onClose: () => this.modalConnector.close()
    })

    return false
  }

  protected setProperties (options: string[]): void {
    this.modelProperties = options
    this.payload.properties = this.contentOptions.map(o => o.key)
      .reduce((agr: Properties, o: string) => {
        agr[o] = options.includes(o)
        return agr
      }, {} as Properties)
  }

  protected toggleDirty (isDirty: boolean = true): void {
    this.isDirty = isDirty
  }
}

export default EditBasics
