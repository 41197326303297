





































import { Component, PropSync, Vue } from 'vue-property-decorator'
import { DashmixSelectItem } from '@movecloser/ui-core'

import {
  AllowedHAlignment,
  AllowedVAlignment,
  CanBeAlignedInGrid,
  isAlignment
} from '../../../../../contexts'

import { FormFieldset } from '../../../FormFieldset'
import { getDefaultModuleAlignment } from '../../../../abstract/form'

/**
 * Component capable to render AlignmentSelector
 * This alignment is considered as global and is used only in `ModuleSettings`
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<ContentAlignment>({
  name: 'ContentAlignment',
  components: { FormFieldset },

  created (): void {
    // Call stack order is important here!
    this.validateData()
    this.checkControlsVisibility()
  }
})
export class ContentAlignment extends Vue {
  /**
   * Alignment of the `module`
   */
  @PropSync('alignment', { type: Object, required: false, default: () => ({}) })
  public _alignment!: CanBeAlignedInGrid['alignment'] | undefined

  public showOptions: boolean = false

  /**
   * Horizontal Alignment options
   */
  public get xOptions (): DashmixSelectItem[] {
    return [...Object.entries(AllowedHAlignment).map(([label, value]) => {
      return {
        label: String(this.$t(`modulesForms._helpers.alignment.options.${label}`)),
        value
      }
    })]
  }

  /**
   * Horizontal Alignment options
   */
  public get yOptions (): DashmixSelectItem[] {
    return [...Object.entries(AllowedVAlignment).map(([label, value]) => {
      return {
        label: String(this.$t(`modulesForms._helpers.alignment.options.${label}`)),
        value
      }
    })]
  }

  /**
   * Checks whether the form controls should be shown or hidden.
   */
  private checkControlsVisibility (): void {
    if (this.isOverridden) {
      this.showOptions = true
    }
  }

  /**
   * Checks whether alignment is present
   */
  private get hasAlignment (): boolean {
    return isAlignment(this._alignment)
  }

  /**
   * Checks whether the current settings are identical with the default ones.
   */
  private get isOverridden (): boolean {
    return JSON.stringify(this._alignment) !== JSON.stringify(getDefaultModuleAlignment())
  }

  /**
   * Validates the data under the `_alignment` property.
   *
   * @see _alignment
   * @see isAlignment()
   */
  private validateData (): void {
    if (!this.hasAlignment) {
      this._alignment = getDefaultModuleAlignment()
    }
  }
}
export default ContentAlignment
