// Copyright © 2021 Move Closer

import { AbstractIntention } from '@movecloser/front-core'

import { Identifier } from '../../../backoffice'

import { SetProperties, SetStrategy } from '../contracts'

export interface EditSetIntentionPayload {
  author?: Identifier
  title?: string
  strategy: SetStrategy
  properties: SetProperties
}

export class EditSetIntention extends AbstractIntention<EditSetIntentionPayload> {
  protected map = {
    author: 'author',
    title: 'title',
    strategy: 'strategy',
    properties: 'properties'
  }
}
