// Copyright © 2021 Move Closer

import { computed, ComputedRef, defineComponent } from '@vue/composition-api'

import { FormFieldAbstract, FormFieldAbstractSetup } from '../Abstract'
import { PreEditableProps } from './PreEditable.contracts'

/**
 * @author Piotr Niewczas <piotr.niewcza@movecloser.pl>
 */
export const PreEditable = defineComponent({
  name: 'PreEditable',
  extends: FormFieldAbstract,
  template: `
    <div>

    <label :for="name" v-if="displayLabel" :require="required">{{ label }}</label>

    <slot name="beforeInput" />

    <pre contenteditable @blur="update" @paste="paste"
         v-bind="{ name }" v-text="value" v-focus="autofocus"
         class="PreEditable__input" :class="classObject" />
    
    <div class="invalid-feedback animated fadeIn" v-if="hasErrors">{{ errors[0] }}</div>
    <small class="text-muted" v-if="helper">{{ helper }}</small>

    </div>

  `,
  props: {
    /**
     * TextArea autocomplete
     */
    autocomplete: {
      type: String,
      required: false
    },

    /**
     * TextArea autofocus
     */
    autofocus: {
      type: Boolean,
      required: false,
      default: false
    },

    /**
     * TextArea placeholder
     */
    placeholder: {
      type: String,
      required: false,
      default: ''
    },

    /**
     * TextArea rows count - determines how long component will be
     */
    width: {
      type: Number,
      required: false
    },
    /**
     * TextArea rows count - determines how long component will be
     */
    height: {
      type: Number,
      required: false
    }
  },
  emits: ['input'],

  setup (props: PreEditableProps, { emit }) {
    const {
      errors,
      displayLabel,
      hasErrors,
      value
    } = FormFieldAbstractSetup(props, emit)

    const classObject: ComputedRef<object> = computed(() => {
      return {
        'is-invalid': hasErrors.value
      }
    })

    const update = function (event: Event) {
      if (event && 'target' in event && event.target) {
        errors.value.pop()
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        try {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const val = JSON.parse(event.target.innerText)
          value.value = val
        } catch (e) {
          errors.value.push('JSON wrong format')
        }
      }
    }

    const paste = function (event: ClipboardEvent) {
      event.preventDefault()
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const text = event.clipboardData.getData('text/plain')
      window.document.execCommand('insertText', false, text)
    }

    return {
      classObject,
      errors,
      displayLabel,
      hasErrors,
      update,
      paste,
      value
    }
  }
})
export default PreEditable
