// Copyright © 2021 Move Closer

import { defaultIgnoredQueryParams, FiltersConfig, FilterType } from '@movecloser/ui-core'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const setsFiltersConfig: FiltersConfig = {
  groups: {
    sku: { label: 'Numer SKU', key: 'sku', type: FilterType.Number },
    name: { label: 'Nazwa', key: 'name', type: FilterType.String },
    category: { label: 'Kategorie', key: 'category', type: FilterType.String },
    updatedAt: { label: 'Modyfikowany', key: 'updatedAt', type: FilterType.Date }
  },
  ignore: [...defaultIgnoredQueryParams],
  override: { page: '1' },
  leave: ['perPage']
}
