























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DashmixIconName } from '@movecloser/ui-core'
import { Fragment } from 'vue-fragment'

import { NavigationItem } from '../contracts'
import { NavigationItemEdit } from './NavigationItemEdit.vue'
import { NavigationList } from './NavigationList.vue'

@Component<NavigationManualForm>({
  name: 'NavigationManualForm',
  components: {
    Fragment,
    NavigationList,
    NavigationItemEdit
  },
  created (): void {
    this.linksList = this.links.map((item) => {
      return {
        ...item,
        isParent: true
      }
    })
  }
})
export class NavigationManualForm extends Vue {
  @Prop({ type: Array, required: true })
  public links!: NavigationItem[]

  public Icons = DashmixIconName

  public editedItem: NavigationItem | null = null
  public linksList: NavigationItem[] = []
  private path: number[] | null = null

  public cancel () {
    if (!this.editedItem?.label) {
      this.deleteItem()
    }

    this.editedItem = null
    this.path = null
    this.$emit('isEdited', false)
  }

  public openEdit (payload: { item: NavigationItem; path: number[] }) {
    this.editedItem = { ...payload.item, hasParent: payload.path.length > 1 }
    this.path = payload.path

    this.$emit('isEdited', true)
  }

  public updateItem (item: NavigationItem) {
    if (!this.path) {
      return
    }

    try {
      this.linksList = this.update([...this.linksList], this.path, (links, index) => {
        links[index] = { ...links[index], ...item }
        return links
      })
      this.editedItem = null
      this.path = null

      this.$emit('update', this.linksList)
      this.$emit('isEdited', false)
    } catch (error) {
      console.error(error)
    }
  }

  private deleteItem () {
    if (!this.path) {
      return
    }

    try {
      this.linksList = this.update([...this.linksList], this.path, (links, index) => {
        links.splice(index, 1)

        return links
      })

      this.$emit('update', this.linksList)
      this.$emit('isEdited', false)
    } catch (error) {
      console.error(error)
    }
  }

  @Watch('links')
  private init (links: NavigationItem[]) {
    this.linksList = links.map((item) => {
      return {
        ...item,
        isParent: true
      }
    })
  }

  private update (links: NavigationItem[], path: number[], callback: (links: NavigationItem[], index: number) => NavigationItem[]): NavigationItem[] {
    const index = path.pop()
    if (typeof index === 'undefined') {
      throw (new Error('path and tree mismatch'))
    }

    if (!path.length) {
      links = callback(links, index)
      return links
    }

    const children = links[index].children
    if (!children) {
      throw (new Error('path and tree mismatch'))
    }

    links[index].children = this.update([...children], path, callback)
    return links
  }
}

export default NavigationManualForm
