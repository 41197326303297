















import { Component, Prop, Vue } from 'vue-property-decorator'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({ name: 'ProductFormPartial' })
export class ProductFormPartial extends Vue {
  @Prop({ type: String, required: false })
  public readonly heading?: string

  public isOpen: boolean = true

  public toggleIsOpen (): void {
    this.isOpen = !this.isOpen
  }
}
export default ProductFormPartial
