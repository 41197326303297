// Copyright © 2022 Move Closer

import { Methods, ResourcesRegistry } from '@movecloser/front-core'

// TODO: Change csrf to `true` when API is deployed.
export const resources: ResourcesRegistry = {
  sets: {
    prefix: 'api/management',
    methods: {
      create: {
        url: 'sets',
        method: Methods.Post,
        auth: true,
        shorthand: 'createSet',
        meta: { csrf: true, toast: true }
      },
      delete: {
        url: 'sets/{id}',
        method: Methods.Delete,
        params: ['id'],
        auth: true,
        meta: { csrf: true }
      },
      item: {
        url: 'sets/{id}',
        method: Methods.Get,
        params: ['id'],
        auth: true
      },
      list: {
        url: 'sets',
        method: Methods.Get,
        auth: true
      },
      update: {
        url: 'sets/{id}',
        method: Methods.Put,
        params: ['id'],
        auth: true,
        shorthand: 'editSet',
        meta: { csrf: true, toast: true }
      },
      updateItem: {
        url: 'sets/{setId}/items/{itemId}/meta',
        method: Methods.Post,
        params: ['setId', 'itemId'],
        auth: true,
        meta: { csrf: true, toast: true }
      },
      regenerate: {
        url: 'sets/{id}/regenerate',
        method: Methods.Post,
        params: ['id'],
        auth: true,
        shorthand: 'regenerateSet',
        meta: { csrf: true, toast: true }
      },
      refresh: {
        url: 'sets/{id}/refresh',
        method: Methods.Post,
        params: ['id'],
        auth: true,
        shorthand: 'refreshSet',
        meta: { csrf: true, toast: true }
      }
    }
  },
  setItems: {
    prefix: 'api/management',
    methods: {
      list: {
        url: 'sets/{setId}/items',
        method: Methods.Get,
        params: ['setId'],
        auth: true
      },
      articlesSet: {
        url: 'content?type=article&scope=typeahead&status=published',
        method: Methods.Get,
        auth: true
      },
      productsSet: {
        url: 'products',
        method: Methods.Get,
        auth: true
      },
      push: {
        url: 'sets/{setId}/items',
        method: Methods.Post,
        params: ['setId'],
        auth: true,
        meta: { csrf: true, toast: true }
      },
      reorder: {
        url: 'sets/{setId}/items',
        method: Methods.Put,
        params: ['setId'],
        auth: true,
        meta: { csrf: true, toast: true }
      },
      remove: {
        url: 'sets/{setId}/items/{itemId}',
        method: Methods.Delete,
        params: ['setId', 'itemId'],
        auth: true,
        meta: { csrf: true, toast: true }
      },
      lock: {
        url: 'sets/{setId}/items/{itemId}/lock',
        method: Methods.Post,
        params: ['setId', 'setId'],
        auth: true,
        meta: { csrf: true }
      },
      unlock: {
        url: 'sets/{setId}/items/{itemId}/unlock',
        method: Methods.Post,
        params: ['setId', 'setId'],
        auth: true,
        meta: { csrf: true }
      }
    }
  }
}
