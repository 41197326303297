import { render, staticRenderFns } from "./TableNodeRow.vue?vue&type=template&id=38b96d45&scoped=true&"
import script from "./TableNodeRow.vue?vue&type=script&lang=ts&"
export * from "./TableNodeRow.vue?vue&type=script&lang=ts&"
import style0 from "./TableNodeRow.vue?vue&type=style&index=0&id=38b96d45&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38b96d45",
  null
  
)

export default component.exports