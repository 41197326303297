// Copyright © 2021 Move Closer

import { Model, ModelPayload } from '@movecloser/front-core'

import { Identifier } from '../../../backoffice'

import { Identifiable, PendingState } from '../../shared/contracts/data'

import { Owner } from '../../root/models/owner'
import { Directory } from '../models/directory'
import { FileData, FileEditPayload, IFile } from '../contracts'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl >
 * @author Michał Rossian
 */
export class File extends Model<FileData> implements IFile, Identifiable {
  protected boot (): void {
    this.initialValues = {}
    this.modelProperties = [
      'id',
      'addedBy',
      'alt',
      'author',
      'archiveDescriptions',
      'caption',
      'createdAt',
      'deletedAt',
      'description',
      'directory',
      'disk',
      'display',
      'file',
      'mime',
      'note',
      'original',
      'pending',
      'preset',
      'public',
      'position',
      'size',
      'title',
      'name',
      'updatedAt',
      'url'
    ]
  }

  public addedByFullName (): string {
    return this._data.addedBy.fullName()
  }

  public displayName (): string {
    return this._data.file.name
  }

  public fullRoute (): string {
    return this._data.directory != null
      ? this._data.directory.fullRoute() + '/' + this._data.name
      : this._data.name
  }

  public identifier (): Identifier {
    return this._data.id
  }

  public isPending (): PendingState {
    return this._data.pending
  }

  public toEditPayload (): FileEditPayload {
    return {
      title: this._data.title || '',
      author: this._data.author || '',
      alt: this._data.alt || '',
      caption: this._data.caption || ''
    }
  }

  protected relatesToDirectory (value: ModelPayload) {
    return this.hasOne(Directory, value)
  }

  protected relatesToAddedBy (value: ModelPayload) {
    return this.hasOne(Owner, value)
  }
}
