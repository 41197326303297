// Copyright © 2021 Move Closer

import { ICollection, QueryParams } from '@movecloser/front-core'
import { Identifier, SetType } from '../../../backoffice'

import { Query } from '../../shared/contracts/query'

import {
  SetData,
  SetModel,
  SetItem, SetItemModel
} from './models'

import { AddSetItemIntention } from '../intentions/AddSetItemIntention'
import { ChangeItemsOrderIntention } from '../intentions/ChangeItemsOrderIntention'

import { CreateSetIntention } from '../intentions/CreateSetIntention'
import { EditSetIntention } from '../intentions/EditSetIntention'

export interface ISetsRepository {
  // TODO fix this any type when task manager is ready
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  create (intention: CreateSetIntention): Promise<any>
  delete (id: SetData['id']): Promise<void>
  load (id: SetData['id'], siteId?: Identifier): Promise<SetModel>
  loadCollection (contentType: SetType, query: Query, siteId?: Identifier): Promise<ICollection<SetModel>>
  update (setId: SetData['id'], intention: EditSetIntention): Promise<void>
  updateItem (setId: SetData['id'], itemId: SetItem['id'], meta: { title: string; description: string }): Promise<void>
  reset (id: SetData['id']): Promise<void>
  refresh (id: SetData['id']): Promise<void>
}

export interface ISetItemsRepository {
  add (setId: SetData['id'], intention: AddSetItemIntention): Promise<void>
  changeOrder (setId: SetData['id'], intention: ChangeItemsOrderIntention): Promise<void>
  loadCollection<SetItem extends SetItemModel> (
    setId: SetData['id'],
    setType: SetType,
    query?: QueryParams,
    sitetId?: Identifier
  ): Promise<ICollection<SetItem>>
  loadAvailableItems<SetItem extends SetItemModel> (
    setType: SetType,
    query?: QueryParams
  ): Promise<ICollection<SetItem>>
  remove (setId: SetData['id'], itemId: Identifier): Promise<void>
  lock (setId: SetData['id'], itemId: Identifier): Promise<void>
  unlock (setId: SetData['id'], itemId: Identifier): Promise<void>
}

export const SetItemsRepositoryType = Symbol.for('ISetItemsRepository')
export const SetsRepositoryType = Symbol.for('ISetsRepository')
