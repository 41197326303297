// Copyright © 2022 Move Closer

import { AddonConfig, ContentTypeConfig, defaultEditor, List } from '../../content/shared'

import AuthorAddon from '../components/addons/AuthorAddon.vue'
import CoverAddon from '../components/addons/CoverAddon.vue'
import LeadAddon from '../components/addons/LeadAddon.vue'
import TagsAddon from '../components/addons/TagsAddon.vue'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export enum Addon {
  Author = 'author',
  Cover = 'cover',
  Lead = 'lead',
  Tags = 'tags'
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const articlesAddons: AddonConfig[] = [
  { component: AuthorAddon, label: 'articles.addonsLabels.author', name: Addon.Author, priority: 5 },
  { component: CoverAddon, label: 'articles.addonsLabels.cover', name: Addon.Cover, priority: 1 },
  { component: LeadAddon, label: 'articles.addonsLabels.lead', name: Addon.Lead, priority: 1 },
  { component: TagsAddon, label: 'articles.addonsLabels.tags', name: Addon.Tags, priority: 3 }
]

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const articleContentType: string = 'article'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const articleContentTypeConfig: Partial<ContentTypeConfig> = {
  editor: defaultEditor,
  label: 'articles.contentTypes.article',
  listing: List.Collection,
  hasParent: true,
  hasPreview: true,
  parentType: 'page',
  properties: []
}
