// Copyright © 2021 Move Closer

import { Identifier } from '../../../../backoffice'

import { ContentStatus, VariantData } from '../../contracts'

export enum AdditionalVariantStatus {
  Deleted = 'deleted',
  EditorChange = 'editorChange',
  Locked = 'locked'
}

export interface CanDetectStatus {
  /**
   * Method to get variant status
   */
  getStatus (id: VariantData['id']): Promise<VariantStatusPayload>
}

export interface IVariantStatusService {
  /**
   * Method to check if subscription is active
   */
  hasActiveSubscription: (id: Identifier, currentEditor: Identifier) => boolean
  /**
   * Method to subscribe variant status changes
   * @param id: Id of variant to observe
   * @param onStatusChange: callback function call when status is change
   * @param currentEditor: variant editor id
   */
  subscribeStatus: (id: Identifier, onStatusChange: onStatusChange, currentEditor: Identifier) => void
  /**
   * Method to subscribe variant status changes
   */
  unsubscribe: () => void
}

export type onStatusChange = (status: VariantStatus, editorId?: Identifier) => void

export interface VariantStatusPayload {
  editorId: Identifier
  editorFullname?: string
  updatedAt: string
  deletedAt: string | null
  status: ContentStatus
}

export type VariantStatus = ContentStatus | AdditionalVariantStatus

export const VariantStatusServiceType = Symbol.for('IVariantStatusService')
