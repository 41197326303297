






















import { Component } from 'vue-property-decorator'
import { TableHead } from '@movecloser/ui-core'

import { Inject, logger } from '../../../backoffice'

import { HeaderInterface } from '../../shared/components/InteractiveTable/InteractiveTable.contracts'
import { ModelListHeader } from '../../shared/components/ModelListHeader'

import { AbstractWidget } from '../../root/shared/AbstractWidget'
import { UserModel } from '../../auth/contracts/models'

import {
  AcceptanceRepositoryType,
  AcceptanceStatus,
  AcceptanceTableRowElement,
  IAcceptanceRepository
} from '../contracts'
import { acceptancePendingTableHeadShortened } from '../maps/acceptance'
import { AcceptanceTableRowShortened } from '../components/AcceptanceTableRowShortened.vue'
import { canAcceptContents } from '../config/guards'
import { ContentTypeManagerType, IContentTypeManager } from '../services/content-type-manager'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<AcceptanceWidget>({
  name: 'AcceptanceWidget',
  components: { ModelListHeader }
})
export class AcceptanceWidget extends AbstractWidget {
  @Inject(AcceptanceRepositoryType)
  protected acceptanceRepository!: IAcceptanceRepository

  @Inject(ContentTypeManagerType)
  public readonly contentTypeManager!: IContentTypeManager

  public isLoading: boolean = false
  public tableHead: TableHead = acceptancePendingTableHeadShortened
  public tableData: AcceptanceTableRowElement[] = []
  public perPage: number = 6
  public rowComponent = AcceptanceTableRowShortened

  public get header (): HeaderInterface {
    const payload: Partial<HeaderInterface> = {
      title: `${this.$t('content.article.listTitle')}`
    }

    return payload as HeaderInterface
  }

  protected fetchData (): void {
    if (this.isWidgetVisable) {
      this.loadList()
    }
  }

  protected checkPermission (domain: string, user: UserModel | null): boolean {
    return canAcceptContents(domain, user)
  }

  private async loadList (): Promise<void> {
    this.isLoading = true

    try {
      const types = [
        'page',
        ...this.contentTypeManager.getContentListConfig('page').loadWith
      ]

      const collection = await this.acceptanceRepository.loadCollection(
        types,
        { status: AcceptanceStatus.Pending, perPage: `${this.perPage}` }
      )

      this.tableData = [...collection].map(model => {
        return { id: `${model.id}`, selectable: true, data: model }
      })
    } catch (e) {
      logger(e, 'error')
    }

    this.isLoading = false
  }
}

export default AcceptanceWidget
