// Copyright © 2021 Move Closer

import { Component } from 'vue-property-decorator'

import { IUserAware, UserAware } from '../../auth/shared/user-aware.abstract'

import { canViewProducts, canEditProducts } from './guards'

export interface IProductsPermissions extends IUserAware {
  canViewProducts: boolean
  canEditProducts: boolean
}

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component<ProductsPermissions>({})
export class ProductsPermissions extends UserAware implements IProductsPermissions {
  /**
   * Jobs Feed permissions
   */
  public get canViewProducts () {
    return canViewProducts(this.domain, this.authService.user)
  }

  public get canEditProducts () {
    return canEditProducts(this.domain, this.authService.user)
  }
}
