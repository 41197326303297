import { MappingConfig } from '@movecloser/front-core'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const userAdapterMap: MappingConfig = {
  id: 'id',
  avatar: 'avatar',
  firstName: 'firstName',
  lastName: 'lastName',
  nickname: 'nickname',
  deletedAt: 'deletedAt',
  email: 'email',
  hasActiveSubscription: 'hasActiveSubscription',
  isActive: 'isActive',
  isEditor: 'isEditor',
  isSubscriber: 'isSubscriber',
  isBlocked: 'isBlocked',
  lastLogin: 'lastLogin',
  roles: 'roles',
  subscriptionId: 'subscriptionId',
  registeredAt: 'registeredAt'
}
