// Copyright © 2021 Move Closer

import { AnyObject, ICollection, IDateTime, IModel, MagicModel } from '@movecloser/front-core'
import { DashmixTheme } from '@movecloser/ui-core'
import { ContainersRegistry, ContentStructure, ModulesRegistry } from '@movecloser/page-builder'

import { AllowedContentType as ContentType, DescriptionOfBaseFilter, Identifier } from '../../../backoffice'
import { HasIdentifier } from '../../shared/contracts/data'

import { OwnerModel, IOwner, OwnerData } from '../../root/contracts/models'

import { ContentEditPayload, VariantUpdatePayload } from '../contracts/data'
import { Node } from '../models/node'
import { VariantLabel } from '../maps/variant'

export { AllowedContentType as ContentType } from '../../../backoffice'

export interface AcceptanceData extends HasIdentifier {
  asIs: VariantData
  acceptedAt: string | null
  acceptedBy: OwnerModel | null
  content: ContentData['id']
  contentType: string
  editor: OwnerModel
  lockedAt: string
  publishedAt: string
  status: ContentStatus
  title: string
  toBe: VariantData
  updatedAt: string
}

export type AcceptanceModel = MagicModel<AcceptanceData, IAcceptance>

export enum AcceptanceStatus {
  Accepted = 'accepted',
  Pending = 'pending',
  Published = 'published',
  Rejected = 'rejected'
}

export interface Attachments {
  id: Identifier
  [key: string]: unknown
}

export interface ContentData extends HasIdentifier {
  children?: ContentModel[]
  childrenCount?: number
  createdAt: string
  editors: OwnerModel
  parent: ParentData
  properties: Properties
  status: ContentStatus
  slug: string
  site: SiteData
  title: string
  type: ContentType
  updatedAt: string
  url: string | null
  variants?: SimpleVariantModel[]
  variantsCount: VariantsCounts
  order: number
}

export type ContentModel = MagicModel<ContentData, IContent>

export interface ContentInfoData {
  articleCount: number
  articleDraftCount: number
  latestArticleCount: number
}

export enum ContentStatus {
  Accepted = 'accepted',
  Archived = 'archived',
  Draft = 'draft',
  InAcceptance = 'in-acceptance',
  Published = 'published',
  Rejected = 'rejected',
  Unpublished = 'unpublished'
}

export const ContentStatusBadge = {
  [ContentStatus.Accepted]: DashmixTheme.Warning,
  [ContentStatus.Archived]: DashmixTheme.Danger,
  [ContentStatus.Draft]: DashmixTheme.Dark,
  [ContentStatus.InAcceptance]: DashmixTheme.Warning,
  [ContentStatus.Published]: DashmixTheme.Success,
  [ContentStatus.Rejected]: DashmixTheme.Danger,
  [ContentStatus.Unpublished]: DashmixTheme.Danger
}

export interface DelayedDates {
  from?: string
  to?: string
}

export interface ArticleFilterData extends DescriptionOfBaseFilter {
  id: Identifier
}

export type ArticleFilterModel = MagicModel<ArticleFilterData, IArticleFilter>

export interface IAcceptance extends IModel<AcceptanceData> {
  isAcceptable (): boolean
  isRejectable (): boolean
}

export interface IContent extends IModel<ContentData> {
  fullSlug (): string
  isLive (): boolean
  propertyByKey (key: string): any
  toEditPayload (): ContentEditPayload
  variantsInTotal (): number
}

export type IArticleFilter = IModel<ArticleFilterData>

export type ISimpleContent = IModel<SimpleContentData>

export interface ISimpleVariant extends IModel<SimpleVariantData> {
  isEditor (user: Identifier | null): boolean
}

export interface ISneakPeek extends IModel<SneakPeekData> {
  toSetItem (): any
}

export interface IVariant extends IModel<VariantData> {
  getProperty<T> (propertyKey: string): T | null
  setProperty<T> (propertyKey: string, payload: T): void
  isDraft (): boolean
  isEditable (user: Identifier | null): boolean
  isEditor (user: Identifier | null): boolean
  isLive (): boolean
  isRemovable (user: Identifier | null): boolean
  toSimpleVariant (): SimpleVariantModel
  toUpdatePayload (): VariantUpdatePayload
}

export interface Meta extends Record<MetaKeys, any> {
  keywords: string
  description: string
  ogDescription: string
  ogImage: Attachments
  ogTitle: string
  robots: RobotsValues
  title: string
  twitterDescription: string
  twitterImage: Attachments
  twitterImageAlt: string
  twitterTitle: string
}

export enum MetaKeys {
  Keywords = 'keywords',
  Description = 'description',
  ogDescription = 'ogDescription',
  OgImage = 'ogImage',
  OgTitle = 'ogTitle',
  Title = 'title',
  TwitterDescription = 'twitterDescription',
  TwitterImage = 'twitterImage',
  TwitterImageAlt = 'TwitterImageAlt',
  TwitterTitle = 'twitterTitle'
}

export interface NodeData {
  id: string
  name: string
  slug?: string
  title?: string
  hideInNav?: boolean
  nodes: ICollection<NodeModel>
}

export type NodeModel = MagicModel<NodeData, Node>

export interface ParentData extends HasIdentifier {
  id: number
  name: string
  slug: string
  parent: ParentData | null
}

export interface Properties {
  [key: string]: boolean | string | unknown[]
}

export type Preferences = AnyObject

export enum RobotsValues {
  FullLimitation = 'noindex, nofollow, noydir, noodp',
  PartLimitation = 'noindex, nofollow',
  NoLimitations = 'index, follow',
  OnlyIndex = 'index, nofollow',
  OnlyFollow = 'noindex, follow'
}

export interface RelatedAddonData extends Attachments {
  name: string
}

export interface RegistryPayload {
  data: AnyObject
  meta: AnyObject
  related: AnyObject
}

export interface SimpleContentData extends HasIdentifier {
  order: number
  slug: string
  title: string
}

export type SimpleContentModel = MagicModel<SimpleContentData, ISimpleContent>

export interface SimpleVariantData extends HasIdentifier {
  addons: string[]
  createdAt: string
  editor: OwnerModel
  labels: Label[]
  name: string
  status: ContentStatus
  updatedAt: string
}

export interface Label extends HasIdentifier {
  name: string
  slug: string
}

export type SimpleVariantModel = MagicModel<SimpleVariantData, ISimpleVariant>

export interface SiteData {
  address: string
  domain: string
  name: string
}

export interface SneakPeekData extends AnyObject, HasIdentifier {
  parent: ParentData | null
  publicationDate: string
  properties: AnyObject
  slug: string
  title: string
  position: number
  locked: boolean
}

export type SneakPeekModel = MagicModel<SneakPeekData, ISneakPeek>

export interface VariantBadge {
  id: Identifier
  name?: VariantLabel | string
  color?: string
}

export interface VariantData extends HasIdentifier {
  addons: string[]
  containers: ContainersRegistry
  content: ContentData['id']
  createdAt: IDateTime
  editor: OwnerModel | null
  lockedAt: string
  name: string | null
  modules: ModulesRegistry
  preferences: Preferences
  properties: object | null
  reference: VariantData['id'] | null
  status: ContentStatus
  structure: ContentStructure
  title: string
  updatedAt: IDateTime
  url: string | null
}

export type VariantsCounts = Record<string, number>

export type VariantModel = MagicModel<VariantData, IVariant>

export interface Video {
  url: string
  playOnTile: boolean
}

export type IAuthor = IOwner
export type AuthorData = OwnerData
export type AuthorModel = MagicModel<AuthorData, IAuthor>
