


























































































import { AnyObject, IModal, ModalType } from '@movecloser/front-core'
import { Component } from 'vue-property-decorator'
import {
  DashmixButtonVariantMap,
  DashmixIconName,
  DashmixTheme,
  SizeMap
} from '@movecloser/ui-core'
import { DebouncedFunc } from 'lodash'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'

import { AbstractAddon, AddonErrors, getImageTypeFromUrl } from '../../../content/shared'
import { Figure } from '../../../shared/components/Figure/Figure'
import { Inject, PossibleRelatedPicker, PossibleRelatedType, Related } from '../../../../backoffice'

import { Addon } from '../../config/content-types'
import { Attachments, CoverAddonData } from '../../contracts'

// interface DebouncedFunc<T extends (...args: any[]) => any> {
//   (...args: Parameters<T>): ReturnType<T> | undefined
// }

/**
 * @author  Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'CoverAddon',
  components: { AddonErrors, Figure }
})
export class CoverAddon extends AbstractAddon {
  @Inject(ModalType)
  protected modalConnector!: IModal

  public coverData: CoverAddonData = {
    video: { url: '', playOnTile: false },
    extraDistinction: false,
    hideDescription: false
  }

  public galleryTitle: string = ''
  public image: Attachments | null = null
  public useGallery: boolean = false
  public useVideo: boolean = false
  public changeImageData: boolean = false

  // Helpers
  public buttonTheme = DashmixTheme
  public buttonVariant = DashmixButtonVariantMap
  public icons = DashmixIconName
  public isEmpty: (value?: Attachments) => boolean = isEmpty

  public get extraDistinction (): boolean {
    return this.coverData.extraDistinction || false
  }

  public set extraDistinction (value: boolean) {
    this.coverData.extraDistinction = value
    this.setNewData(this.coverData, this.useVideo)
  }

  public get hasImage () {
    return !isEmpty(this.mainPhoto)
  }

  public get imgType (): string | undefined {
    if (!this.coverData.image || !this.coverData.image.value) {
      return undefined
    }

    if (!this.image) {
      return undefined
    }

    return getImageTypeFromUrl(`${this.image.url}`) || undefined
  }

  public get hasGallery (): boolean {
    return this.useGallery && !!this.coverData.gallery && !!this.coverData.gallery.value
  }

  public get mainPhoto (): Attachments {
    return this.image || {} as Attachments
  }

  public get playOnTile (): boolean {
    return this.coverData.video?.playOnTile || false
  }

  public set playOnTile (value: boolean) {
    if (typeof this.coverData.video !== 'object') {
      this.coverData.video = { url: '', playOnTile: false }
    }

    this.coverData.video.playOnTile = value

    this.setNewData(this.coverData, this.useVideo)
  }

  public deleteGallery () {
    this.coverData.gallery = null

    this.setNewData(this.coverData, this.useVideo)
  }

  public deleteMainPhoto () {
    const newData = { ...this.coverData }
    delete newData.image
    this.coverData = newData
    this.image = null

    this.setNewData(this.coverData, this.useVideo)
  }

  public openFilePicker () {
    let selected
    if (this.mainPhoto.id) {
      selected = {
        value: this.mainPhoto.id,
        type: PossibleRelatedType.File
      }
    }
    this.modalConnector.open(PossibleRelatedPicker.File, {
      config: { type: 'photo' },
      onClose: () => this.modalConnector.close(),
      onSelection: this.setMainPhoto,
      selected: selected
    }, { size: SizeMap.XLarge })
  }

  public openGalleryPicker () {
    this.modalConnector.open(PossibleRelatedPicker.Gallery, {
      config: {},
      onClose: () => this.modalConnector.close(),
      onSelection: this.setGallery,
      selected: this.coverData.gallery
    }, { size: SizeMap.XLarge })
  }

  public updateAlt (value: string | undefined) {
    this.coverData = {
      ...this.coverData,
      alt: value
    }

    this.setNewData(this.coverData, this.useVideo)
  }

  public updateCaption (value: string | undefined) {
    this.coverData = {
      ...this.coverData,
      caption: value
    }

    this.setNewData(this.coverData, this.useVideo)
  }

  public updateImageAuthor (value: string | undefined) {
    this.coverData = {
      ...this.coverData,
      imageAuthor: value
    }

    this.setNewData(this.coverData, this.useVideo)
  }

  public updateVideoLink (newLink: string) {
    if (typeof this.coverData.video === 'undefined') {
      this.coverData.video = { url: '', playOnTile: false }
    }
    this.coverData.video.url = newLink

    this.setNewData(this.coverData, this.useVideo)
  }

  public toggleHideDescription (value: boolean) {
    this.coverData.hideDescription = value

    this.setNewData(this.coverData, this.useVideo)
  }

  public toggleImageDataOverride (value: boolean): void {
    this.changeImageData = value

    this.coverData = {
      ...this.coverData,
      override: value
    }

    if (!value) {
      this.updateAlt(undefined)
      this.updateCaption(undefined)
      this.updateImageAuthor(undefined)
    }

    this.setNewData(this.coverData, this.useVideo)
  }

  public toggleUseGallery (value: boolean): void {
    this.useGallery = value
    if (value) {
      this.useVideo = false
    } else {
      this.coverData.gallery = null
      this.setNewData(this.coverData, this.useVideo)
    }
  }

  public toggleUseVideo (value: boolean): void {
    this.useVideo = value
    if (value) {
      this.useGallery = false
      this.coverData.video = { url: '', playOnTile: false }
    } else {
      delete this.coverData.video
      this.setNewData(this.coverData, this.useVideo)
    }
  }

  protected async getGalleryTitle (galleryData: Related) {
    const gallery: AnyObject = await this.relatedService.describe(galleryData)

    return gallery.title || ''
  }

  protected getVariantProperty (): void {
    if (this.variant) {
      const dataFromModel = this.variant.getProperty<CoverAddonData>(Addon.Cover)

      if (!dataFromModel) {
        this.coverData = {
          video: { url: '', playOnTile: false },
          extraDistinction: false
        }
        return
      }

      if (typeof dataFromModel?.override === 'undefined') {
        this.toggleImageDataOverride(
          !!dataFromModel.imageAuthor || !!dataFromModel.caption || !!dataFromModel.alt
        )
      }

      this.changeImageData = dataFromModel.override!
      this.useVideo = !!dataFromModel.video && !!dataFromModel.video.url

      if (!!dataFromModel.gallery && !!dataFromModel.gallery.value) {
        this.useGallery = true
        this.getGalleryTitle(dataFromModel.gallery).then(title => {
          this.galleryTitle = title
        }).catch(e => console.debug(e))
      }

      if (!!dataFromModel.image && !!dataFromModel.image.value) {
        this.setImage(dataFromModel.image)
      }

      this.coverData = { ...this.coverData, ...dataFromModel }
      this.setNewData(this.coverData, this.useVideo)
    }
  }

  protected async setImage (photoData: Related): Promise<void> {
    const file: AnyObject = await this.relatedService.describe(photoData)

    this.image = {
      id: file.id,
      caption: file.caption,
      url: file.url
    }
  }

  protected async setGallery (galleryData: Related) {
    this.coverData = {
      ...this.coverData,
      gallery: galleryData
    }

    this.setNewData(this.coverData, this.useVideo)

    this.galleryTitle = await this.getGalleryTitle(galleryData)
  }

  protected async setMainPhoto (photoData: Related) {
    this.coverData = {
      ...this.coverData,
      image: photoData
    }

    this.setImage(photoData)

    this.setNewData(this.coverData, this.useVideo)
  }

  protected setNewData: DebouncedFunc<(dates: CoverAddonData, useVideo: boolean) => void> =
    debounce((dates: CoverAddonData, useVideo: boolean) => {
      this.setNewData.cancel()
      const dataCopy = JSON.parse(JSON.stringify(dates))

      if (!useVideo) {
        dataCopy.video = { url: '', playOnTile: false }
      }

      for (const key of Object.keys(dataCopy)) {
        if (typeof dataCopy[key] === 'undefined') {
          delete dataCopy[key]
        }
      }

      this.variant.setProperty<CoverAddonData>(Addon.Cover, dataCopy)

      this.onChange(this.variant)
    }, 1000)
}

export default CoverAddon
