// Copyright © 2022 Move Closer

import { HasType } from '../../contexts'

import {
  AllowedContainerColumnGap,
  AllowedContainerMargin,
  AllowedContainerPadding,
  AllowedContainerRowGap,
  AllowedContainerWidth,
  ContainerContent
} from './Container.contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export function createContainerContent (): ContainerContent & HasType {
  return {
    background: {
      color: 'transparent',
      variantColor: 'default'
    },
    color: '',
    gridGap: {
      column: AllowedContainerColumnGap.None,
      row: AllowedContainerRowGap.None
    },
    margin: {
      bottom: AllowedContainerMargin.None,
      top: AllowedContainerMargin.None
    },
    padding: {
      bottom: AllowedContainerPadding.Spacer3,
      top: AllowedContainerPadding.Spacer3
    },
    type: '',
    width: AllowedContainerWidth.Normal
  }
}
