// Copyright © 2021 Move Closer

import { ApiConnectorType, AppModule, Container, IConnector, Interfaces, Module } from '@movecloser/front-core'

import { ILayoutRepository, LayoutRepositoryType } from './contracts'
import { LayoutRepository } from './repositories/layouts'
import { resources } from './resources'
import { routesFactory } from './routes'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (edited)
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl> (original)
 */
@AppModule({
  name: 'layouts',
  providers: () => {
    return (bind: Interfaces.Bind) => {
      bind<ILayoutRepository>(LayoutRepositoryType).to(LayoutRepository)
    }
  },
  boot (container: Container) {
    const connector: IConnector = container.get(ApiConnectorType)
    connector.useResources(resources)
  },
  routes: routesFactory
})
export class LayoutsModule extends Module {}
