















import { Component, Prop, Vue } from 'vue-property-decorator'
import { ICollection } from '@movecloser/front-core'

import { Inject, logger } from '../../../backoffice'
import { Item, Node, TreeViewer } from '../../shared/components/TreeViewer'
import { Loader } from '../../shared/components/Loader'

import { ContentStatus, NodeModel } from '../contracts/models'
import { INodesRepository, NodesRepositoryType } from '../contracts/repositories'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component<NodeSelect>({
  name: 'NodeSelect',
  components: { Loader, TreeViewer },
  mounted () {
    this.loadNodes()
  }
})
export class NodeSelect extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  public onlyPublished?: boolean

  @Prop({ type: Array, required: true })
  public selectedNodesList!: Item[]

  @Prop({ type: Boolean, required: false, default: true })
  public showHidden!: boolean

  @Prop({ type: Boolean, required: false, default: true })
  public skippedRoot?: boolean

  @Prop({ type: Array, required: false, default: () => ([]) })
  public types!: string[]

  @Inject(NodesRepositoryType)
  protected nodesRepository!: INodesRepository

  public isLoading = false
  public nodesList: Node[] = []

  public async loadNodes (): Promise<void> {
    this.isLoading = true

    try {
      const nodeList = this.mapNodesToTree(
        await this.nodesRepository.loadTree(
          this.onlyPublished ? { status: ContentStatus.Published } : {},
          this.types
        )
      )

      if (!this.showHidden) {
        // TODO implement filtering when API will return 'properties'
        this.nodesList = nodeList[0].children
      } else {
        this.nodesList = nodeList[0].children
      }
      if (this.selectedNodesList.length === 0) {
        this.$emit('selectedNodes', nodeList)
      }
    } catch (e) {
      logger(e, 'warn')
    }

    this.isLoading = false
  }

  public selectNode (selectedItemsList: Item[]) {
    this.$emit('selectedNodes', selectedItemsList)
  }

  protected mapNodesToTree (dataTree: ICollection<NodeModel>): Node[] {
    if (!dataTree) {
      return []
    }

    return [...dataTree].map(node => {
      return {
        label: node.name,
        value: node.id,
        slug: node.slug,
        children: this.mapNodesToTree(node.nodes)
      }
    })
  }
}

export default NodeSelect
