// Copyright © 2021 Move Closer

import { Model, ModelPayload } from '@movecloser/front-core'

import { Identifier } from '../../../backoffice'

import { Identifiable } from '../../shared/contracts/data'

import { DirectoryData, IDirectory } from '../contracts'
import { File } from '../models/file'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
export class Directory extends Model<DirectoryData> implements IDirectory, Identifiable {
  protected boot (): void {
    this.initialValues = {}
    this.modelProperties = [
      'id',
      'createdAt',
      'directories',
      'elements',
      'files',
      'name',
      'parent',
      'nodes', // tmp
      'updatedAt'
    ]
  }

  public displayName (): string {
    return this._data.name
  }

  public childrenCount (): number {
    return this.fileCount() + this.directoryCount()
  }

  public directoryCount (): number {
    return this._data.directories.length
  }

  public fileCount (): number {
    return this._data.files.length
  }

  public fullRoute (): string {
    let route: string = this._data.name
    let parent = this._data.parent
    while (parent && parent.id) {
      route = parent.name + '/' + route
      parent = parent.parent
    }
    return route
  }

  public identifier (): Identifier {
    return this._data.id
  }

  protected relatesToDirectories (values: ModelPayload[]) {
    return this.hasMany(Directory, values)
  }

  protected relatesToFiles (values: ModelPayload[]) {
    return this.hasMany(File, values)
  }
}
