// Copyright © 2021 Move Closer

import { DashmixIconName, DashmixTheme, TableSearchBarAction, TableHead } from '@movecloser/ui-core'

import { UserModel as AuthUserModel } from '../../auth/contracts/models'

import { canDeleteUsers, canEditUsers } from '../config/guards'

export const usersTableHead: TableHead = [
  {
    column: 'avatar',
    label: '',
    sortable: false,
    isSorted: false,
    width: '50px'
  },
  {
    column: 'name',
    label: 'users.table.name',
    sortable: true,
    isSorted: false,
    width: '1.2fr'
  },
  {
    column: 'role',
    label: 'users.table.access',
    sortable: false,
    isSorted: false,
    width: '0.75fr'
  },
  {
    column: 'createdDate',
    label: 'users.table.createdDate',
    sortable: true,
    isSorted: false,
    width: '0.75fr'
  },
  {
    column: 'lastLogin',
    label: 'users.table.lastLogin',
    sortable: true,
    isSorted: false,
    width: '0.75fr'
  }
]

export const usersTableHeadShortened: TableHead = [
  {
    column: 'avatar',
    label: '',
    sortable: false,
    isSorted: false,
    width: '50px'
  },
  {
    column: 'name',
    label: 'users.table.name',
    sortable: false,
    isSorted: false,
    width: '1.7fr'
  },
  {
    column: 'role',
    label: 'users.table.access',
    sortable: false,
    isSorted: false,
    width: '1fr'
  }
]

export enum UserListsActions {
  Unlock = 'unlock',
  Edit = 'edit',
  Delete = 'delete',
  Block = 'block',
  ResetPassword = 'resetPassword'
}

export const userRowActionsFactory = (domain: string, user: AuthUserModel | null): TableSearchBarAction[] => {
  const actions = []

  if (canEditUsers(domain, user)) {
    actions.push(
      ...[
        {
          label: 'Edytuj użytkownika',
          icon: DashmixIconName.PenSolid,
          isBulkAction: false,
          action: UserListsActions.Edit
        },
        {
          label: 'Zablokuj',
          icon: DashmixIconName.UserTimesSolid,
          isBulkAction: true,
          action: UserListsActions.Block,
          guard: (data: unknown): boolean => {
            const model = data as AuthUserModel
            return !model.isBlocked
          }
        },
        {
          label: 'Odblokuj',
          icon: DashmixIconName.UserCheckSolid,
          isBulkAction: true,
          action: UserListsActions.Unlock,
          guard: (data: unknown): boolean => {
            const model = data as AuthUserModel
            return model.isBlocked
          }
        },
        {
          label: 'Resetuj hasło',
          icon: DashmixIconName.UnlockSolid,
          isBulkAction: true,
          action: UserListsActions.ResetPassword
        }
      ]
    )
  }

  if (canDeleteUsers(domain, user)) {
    actions.push(
      {
        label: 'Usuń',
        icon: DashmixIconName.TrashAltSolid,
        needsDoubleClick: true,
        isBulkAction: true,
        action: UserListsActions.Delete,
        theme: DashmixTheme.Danger
      }
    )
  }

  return actions
}
