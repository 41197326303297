









import { Component, Prop, Vue } from 'vue-property-decorator'
import { VueConstructor } from 'vue'

/**
 * Simple component to display a single option of BootstrapIcon.
 * Used as a partial in IconSelect
 *
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({ name: 'IconSelectItem' })
export class IconSelectItem extends Vue {
  /**
   * Icon VueConstructor - allows user see how icon look like directly in select
   * @see IconDefinition.component
   */
  @Prop({ type: Object, required: false })
  public icon?: VueConstructor

  /**
   * Icon label, visible to the User
   * @see IconDefinition.label
   */
  @Prop({ type: String, required: true })
  public label!: string
}

export default IconSelectItem
