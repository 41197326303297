// Copyright © 2021 Move Closer

import { Collection, Headers, ICollection, Injectable } from '@movecloser/front-core'

import { ISiteResolver } from './contracts'
import { SiteModel } from '../../contracts/models'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Injectable()
export class SiteResolver implements ISiteResolver {
  private dictionary?: ICollection<SiteModel>
  private readonly headerName: string
  private site: SiteModel|null = null
  private readonly storageSite: string|null
  private readonly storageKey: string

  public constructor (headerName: string = 'X-Site', initValue: string|null = null, storageKey: string = 'site_storage') {
    this.headerName = headerName
    this.storageKey = storageKey
    this.storageSite = this.resolveInitSite(initValue)
  }

  public boot (): void {
    this.site = this.findSiteFromParam(this.storageSite)
  }

  public findByDomain (domain: string): SiteModel|null {
    if (!this.dictionary) {
      return null
    }

    return this.dictionary.find(s => s.domain === domain) || null
  }

  public findBySiteId (siteId: string): SiteModel|null {
    if (!this.dictionary) {
      return null
    }

    return this.dictionary.find(s => s.routePrefix === siteId) || null
  }

  public getDictionary (): ICollection<SiteModel> {
    if (!this.dictionary) {
      return new Collection([])
    }

    return this.dictionary
  }

  public getSite (): SiteModel|null {
    return this.site
  }

  public getSiteId<T extends true|false> (force?: T): T extends true ? string : string | undefined {
    if (force) {
      return this.site?.routePrefix || this.site?.name || '_'
    }

    return this.site?.routePrefix as T extends true ? string : string | undefined
  }

  public getSiteAddress (): string {
    return this.site?.address || ''
  }

  public getSiteHeader (): Headers {
    if (!this.hasSiteSet()) {
      return {}
    }

    const headers: Headers = {}
    headers[this.headerName] = `${this.site?.id}`

    return headers
  }

  public hasSiteSet (): boolean {
    return this.site !== null
  }

  public setSite (site: SiteModel): void {
    this.site = site
    this.setStorage(site)
  }

  public setupDictionary (collection: ICollection<SiteModel>): void {
    this.dictionary = collection
  }

  public findSiteFromParam (param?: string|null): SiteModel {
    if (!this.dictionary || !this.dictionary.length) {
      throw new Error('Missing required [Sites] dictionary')
    }

    let found = this.findBySiteId(param ?? '')
    if (!found) {
      found = this.dictionary[0]
      this.setStorage(found)
    }

    return found
  }

  public setSiteFromParam (param?: string|null): SiteModel {
    const found = this.findSiteFromParam(param)

    this.setSite(found)

    return found
  }

  private resolveInitSite (initValue: string|null): string|null {
    const value = localStorage.getItem(this.storageKey)

    return value || initValue
  }

  private setStorage (site: SiteModel): void {
    localStorage.setItem(this.storageKey, site.routePrefix)
  }
}
