























import { defineComponent, SetupContext } from '@vue/composition-api'
import { Chrome } from 'vue-color'

import { FormFieldAbstract, FormProps } from '../Abstract'

import { useColorPicker } from './ColorPicker.hook'

export const FormColorPicker = defineComponent({
  name: 'FormColorPicker',
  components: { Chrome },
  extends: FormFieldAbstract,
  props: {
    placeholder: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup (props: FormProps, ctx: SetupContext) {
    return useColorPicker(props, ctx.emit)
  }
})

export default FormColorPicker
