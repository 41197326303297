





































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { DateTimeType, IDateTime } from '@movecloser/front-core'
import { DashmixIconName } from '@movecloser/ui-core'
import { Fragment } from 'vue-fragment'

import { Inject, PossibleRelatedType, SetType } from '../../../backoffice'

import { ISetsPermissions, SetsPermissions } from '../config/permissions.mixin'
import { SetData } from '../contracts'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
@Component({
  name: 'SetTableRow',
  components: { Fragment }
})
export class SetTableRow extends Mixins<ISetsPermissions>(SetsPermissions) {
  @Prop({ type: Object, required: true })
  protected data!: SetData

  @Inject(DateTimeType)
  protected dateTime!: IDateTime

  public Icons = DashmixIconName

  public get setType (): SetType {
    if (!this.$route.params.type) {
      return PossibleRelatedType.Articles
    }
    return this.$route.params.type as unknown as SetType
  }

  public get canEditSets (): boolean {
    switch (this.setType) {
      case PossibleRelatedType.Articles:
        return this.canEditArticles
      case PossibleRelatedType.Products:
        return this.canEditProducts
      default:
        return false
    }
  }
}

export default SetTableRow
