// Copyright © 2022 Move Closer

import {
  ICollection,
  Injectable,
  IResponse,
  Repository,
  ResourceActionFailed
} from '@movecloser/front-core'

import { resolveFromStatus } from '../../shared/exceptions/connector-errors'

import { ProductFilterData, ProductFilterModel } from '../contracts/models'
import { IProductFiltersRepository } from '../contracts/repositories'
import { ProductFilter } from '../models/filters'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Injectable()
export class ProductFiltersRepository extends Repository<ProductFilterData, ProductFilter> implements IProductFiltersRepository {
  protected useAdapter: boolean = false
  // protected map: MappingConfig = nodeAdapterMap

  public async list (): Promise<ICollection<ProductFilterModel>> {
    const response: IResponse = await this.connector.call(
      'productFilters',
      'list',
      {},
      {}
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeCollection(
      response.data.data,
      ProductFilter,
      response.data.meta
    )
  }
}
