// Copyright © 2021 Move Closer

import { IForm, InputType } from '@module/settings/contracts/form'

/**
 * @author Piotr Niewczas <piotr.niewczas@movecloser.pl>
 */
export const settings: IForm[] = [
  {
    name: 'general',
    type: InputType.Fieldset,
    label: '',
    header: 'settings.form.general.header',
    submitLabel: 'settings.save',
    fields: [
      {
        type: InputType.H5,
        name: 'forAllPages'
      },
      {
        name: 'noIndexNoFollow',
        type: InputType.Checkbox
      },
      {
        name: 'productUrl',
        type: InputType.Text
      },
      {
        type: InputType.H5,
        name: 'other'
      },
      {
        name: 'linkedInId',
        type: InputType.Text
      },
      {
        name: 'facebookPageUrl',
        type: InputType.Text
      },
      {
        class: 'col-12 mb-4',
        label: 'settings.form.general.group.label',
        name: 'group',
        type: InputType.Fieldset,
        fields: [
          {
            name: 'field1',
            type: InputType.Text
          },
          {
            name: 'field2',
            type: InputType.Text
          },
          {
            name: 'field3',
            type: InputType.Text
          }
        ]
      },
      {
        class: 'col-12 mb-4',
        type: InputType.Fieldset,
        label: 'settings.form.general.benefitBar.label',
        name: 'benefitBarGroup',
        fields: [
          {
            name: 'benefit1',
            type: InputType.Text
          },
          {
            name: 'benefit2',
            type: InputType.Text
          },
          {
            name: 'benefit3',
            type: InputType.Text
          }
        ]
      },
      {
        class: 'col-12 mb-4',
        label: 'settings.form.general.shippingTimerConfiguration.label',
        name: 'shippingTimer',
        type: InputType.Fieldset,
        fields: [
          {
            class: 'col-12 my-4 mt-2',
            name: 'showTimer',
            label: 'settings.form.general.shippingTimerConfiguration.show.label',
            type: InputType.Checkbox
          },
          {
            name: 'limitHours',
            label: 'settings.form.general.shippingTimerConfiguration.limitHours.label',
            type: InputType.Number
          },
          {
            class: 'col-12 mb-4 w-75',
            name: 'firstDisabledDay',
            label: 'settings.form.general.shippingTimerConfiguration.firstDisabledDay.label',
            type: InputType.date
          },
          {
            class: 'col-12 mb-4 w-75',
            name: 'lastDisabledDay',
            label: 'settings.form.general.shippingTimerConfiguration.lastDisabledDay.label',
            type: InputType.date
          }
        ]
      }
    ]
  },
  {
    name: 'ceneo',
    type: InputType.Fieldset,
    label: '',
    header: 'settings.form.ceneo.header',
    submitLabel: 'settings.save',
    fields: [
      {
        name: 'mapping',
        type: InputType.Json,
        default: '{}'
      },
      {
        name: 'index',
        type: InputType.SearchPreview,
        default: '{}'
      }
    ]
  },
  {
    name: 'productVariantSelector',
    type: InputType.Fieldset,
    label: '',
    header: 'settings.form.productVariantSelector.header',
    submitLabel: 'settings.save',
    fields: [
      {
        name: 'variant_color',
        type: InputType.Json,
        default: '{}'
      },
      {
        name: 'variant_volume',
        type: InputType.Json,
        default: '{}'
      }
    ]
  },
  {
    name: 'productFiltersDefaults',
    type: InputType.Fieldset,
    label: '',
    header: 'settings.form.productFiltersDefaults.header',
    submitLabel: 'settings.save',
    fields: [
      {
        name: 'defaultPerPage',
        type: InputType.Number,
        default: 32
      },
      {
        name: 'defaultSort',
        type: InputType.Number,
        default: 'cena+'
      }
    ]
  },
  {
    name: 'productFilters',
    type: InputType.Fieldset,
    label: '',
    header: 'settings.form.productFilters.header',
    submitLabel: 'settings.save',
    fields: {
      name: 'data',
      type: InputType.Json
    }
  },
  {
    name: 'productSpecificationProperties',
    type: InputType.Fieldset,
    label: '',
    header: 'settings.form.productSpecificationProperties.header',
    submitLabel: 'settings.save',
    fields: [
      {
        name: 'care_area',
        type: InputType.Text,
        default: 'Obszar pielęgnacji'
      },
      {
        name: 'skin',
        type: InputType.Text,
        default: 'Rodzaj cery'
      },
      {
        name: 'need',
        type: InputType.Text,
        default: 'Potrzeba skóry'
      },
      {
        name: 'type',
        type: InputType.Text,
        default: 'Rodzaj produktu'
      },
      {
        name: 'wiek',
        type: InputType.Text,
        default: 'Wiek'
      },
      {
        name: 'active_ingredients',
        type: InputType.Text,
        default: 'Składniki aktywne'
      },
      {
        name: 'krycie',
        type: InputType.Text,
        default: 'Krycie'
      },
      {
        name: 'day_night',
        type: InputType.Text,
        default: 'Dzień / Noc'
      }
    ]
  }

]
