




















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { ArticleFilterModel } from '../contracts'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<ArticleFilterPickerItem>({
  name: 'ArticleFilterPickerItem',
  components: {}
})
export class ArticleFilterPickerItem extends Vue {
  @Prop({ type: Object, required: true })
  public item!: ArticleFilterModel

  @Prop({ type: Boolean, required: true })
  public isSelected!: boolean

  @Prop({ type: Boolean, required: true })
  public isMultiple!: boolean

  public selected: boolean = this.isSelected

  public get name (): string {
    return this.isMultiple ? 'filter[]' : 'filter'
  }

  public get options (): string {
    if (!this.item.options) {
      return ''
    }

    const maxItems = 10
    const options = Object.values(this.item.options)

    return options.slice(0, maxItems).join(', ') + (options.length > maxItems ? '...' : '')
  }

  @Watch('selected')
  public select () {
    this.$emit('select', this.item)
  }
}

export default ArticleFilterPickerItem
