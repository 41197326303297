// Copyright © 2022 Move Closer

import { TableHead } from '@movecloser/ui-core'

export const sessionsTableHead: TableHead = [
  {
    column: 'name',
    label: 'Urządzenie',
    sortable: false,
    isSorted: false,
    width: '2fr'
  },
  {
    column: 'expireAt',
    label: 'Data wygaśnięcia',
    sortable: false,
    isSorted: false,
    width: '1.6fr'
  },
  {
    column: '',
    label: '',
    sortable: false,
    isSorted: false,
    width: '160px'
  }
]
