// Copyright © 2021 Move Closer

import { MappingConfig, MappingTypes } from '@movecloser/front-core'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const ownerAdapterMap: MappingConfig = {
  id: 'id',
  avatar: 'avatar',
  firstName: 'firstName',
  lastName: 'lastName',
  nickname: {
    type: MappingTypes.Function,
    value: item => {
      if (item.nickname) {
        return item.nickname
      } else {
        return 'Anonim'
      }
    }
  }
}
