// Copyright © 2021 Move Closer

import { ModalRegistry } from '@movecloser/front-core'
import { VueConstructor } from 'vue'

import { PossibleRelatedPicker } from '../../../backoffice'

import CropImageModal from '../components/CropImageModal.vue'
import DirectoryCreateModal from '../components/DirectoryCreateModal.vue'
import DirectoryEditModal from '../components/DirectoryEditModal.vue'
import DirectoryMoveModal from '../components/DirectoryMoveModal.vue'
import FileCreateModal from '../components/FileCreateModal.vue'
import FileEditModal from '../components/FileEditModal.vue'
import FilePickerModal from '../components/FilePickerModal.vue'
import FilesMoveModal from '../components/FilesMoveModal.vue'
import GalleryPickerModal from '../components/GalleryPickerModal.vue'

export enum MediaModal {
  CropImage = 'cropImage',
  DirectoryCreate = 'directoryCreate',
  DirectoryEdit = 'directoryEdit',
  DirectoryMove = 'directoryMove',
  FileCreate = 'fileCreate',
  FileEdit = 'fileEdit',
  FilesMove = 'filesMove'
}

/**
 * Registry of available modals.
 */
export const mediaModal: ModalRegistry<VueConstructor> = {
  [MediaModal.CropImage]: CropImageModal,
  [MediaModal.DirectoryCreate]: DirectoryCreateModal,
  [MediaModal.DirectoryEdit]: DirectoryEditModal,
  [MediaModal.DirectoryMove]: DirectoryMoveModal,
  [MediaModal.FileCreate]: FileCreateModal,
  [MediaModal.FileEdit]: FileEditModal,
  [MediaModal.FilesMove]: FilesMoveModal,
  // Pickers
  [PossibleRelatedPicker.File]: FilePickerModal,
  [PossibleRelatedPicker.Gallery]: GalleryPickerModal
}
