// Copyright © 2021 Move Closer

import { Container, RoutesFactory } from '@movecloser/front-core'

import { Permission } from '../auth/contracts/permissions'

import LayoutEdit from './views/EditLayout.vue'
import LayoutsList from './views/LayoutsList.vue'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (edited)
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl> (original)
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const routesFactory: RoutesFactory = (container: Container) => {
  return [
    {
      path: '/settings/layouts/create',
      name: 'layouts.create',
      component: LayoutEdit,
      meta: {
        permissions: [
          Permission.CreateLayout
        ]
      }
    },
    {
      path: '/settings/layouts/:id/edit',
      name: 'layouts.edit',
      component: LayoutEdit,
      meta: {
        permissions: [
          Permission.EditLayout
        ]
      }
    },
    {
      path: '/settings/layouts',
      name: 'layouts.list',
      component: LayoutsList,
      meta: {
        permissions: [
          Permission.ViewLayout
        ]
      }
    }
  ]
}
