// Copyright © 2021 Move Closer

import { Container, QueryParams } from '@movecloser/front-core'
import { AudioType, DocumentType, ImageType, VideoType } from '@movecloser/ui-core'

import {
  ContentStatus,
  ContentType,
  INodesRepository,
  NodesRepositoryType
} from '../../content/contracts'
import { DictionaryRepositoryType, IDictionaryRepository } from '../../root/contracts/repositories'
import { SetStrategy } from '../../sets/contracts'

export async function loadCategories (container: Container, query?: QueryParams) {
  const repo: INodesRepository = container.get(NodesRepositoryType)

  let q = query
  if (!q) {
    q = {}
  }

  try {
    const nodes = await repo.loadTree({ 'flat-tree': '', perPage: '1000', ...q })

    return [...nodes].map(node => {
      return {
        label: node.title || '',
        value: node.id
      }
    })
  } catch (e) {
    console.debug(e)
    return []
  }
}

export async function loadEditors (container: Container, query?: QueryParams) {
  const repo: IDictionaryRepository = container.get(DictionaryRepositoryType)

  let q = query
  if (!q) {
    q = {}
  }

  try {
    const editors = await repo.loadUsersDictionary({ perPage: '1000', isEditor: '1', ...q })

    return [...editors].map(editor => {
      return {
        label: editor.fullName,
        value: editor.id
      }
    })
  } catch (e) {
    console.debug(e)
    return []
  }
}

export async function loadRoles (container: Container, query?: QueryParams) {
  const repo: IDictionaryRepository = container.get(DictionaryRepositoryType)

  let q = query
  if (!q) {
    q = {}
  }

  try {
    const roles = await repo.loadRolesDictionary({ perPage: '1000', ...q })

    return [...roles].map(role => {
      return {
        label: role.name,
        value: role.id
      }
    })
  } catch (e) {
    console.debug(e)
    return []
  }
}

export async function loadUsers (container: Container, query?: QueryParams) {
  const repo: IDictionaryRepository = container.get(DictionaryRepositoryType)

  let q = query || {}
  if (!q) {
    q = {}
  }

  try {
    const users = await repo.loadUsersDictionary({ perPage: '1000', ...q })

    return [...users].map(user => {
      return {
        label: user.fullName,
        value: user.id
      }
    })
  } catch (e) {
    console.debug(e)
    return []
  }
}

export function loadFileTypesDict () {
  const fileTypes = []

  // Image types
  fileTypes.push(...Object.values(ImageType).map(type => {
    return {
      label: type,
      value: type
    }
  }))

  fileTypes.push(...Object.values(AudioType).map(type => {
    return {
      label: type,
      value: type
    }
  }))

  fileTypes.push(...Object.values(DocumentType).map(type => {
    return {
      label: type,
      value: type
    }
  }))

  fileTypes.push(...Object.values(VideoType).map(type => {
    return {
      label: type,
      value: type
    }
  }))

  return fileTypes
}

export function loadStatusDict () {
  return Object.values(ContentStatus).map(status => {
    return {
      label: `status.${status}`,
      value: status
    }
  })
}

export function loadTypesDict () {
  return Object.values(ContentType).map(type => {
    return {
      label: `type.${type}`,
      value: type
    }
  })
}

export function loadStrategyDict () {
  return Object.values(SetStrategy).map(strategy => {
    return {
      label: `strategy.${strategy}`,
      value: `${strategy}`
    }
  })
}
