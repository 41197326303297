// Copyright © 2021 Move Closer

import { defaultIgnoredQueryParams, FiltersConfig, FilterType } from '@movecloser/ui-core'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const usersFiltersConfig: FiltersConfig = {
  groups: {
    // role: { label: 'Dostęp', key: 'role', type: FilterType.Dictionary, dictionaryLoader: loadRoles },
    email: { label: 'Email', key: 'email', type: FilterType.String }
  },
  ignore: [...defaultIgnoredQueryParams],
  override: { page: '1' },
  leave: ['perPage']
}
