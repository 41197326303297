































import { Component, Mixins, Watch } from 'vue-property-decorator'
import {
  DashmixBreadcrumbsProps,
  DashmixTheme,
  TableHead,
  TableRowAction,
  TableRowElement
} from '@movecloser/ui-core'
import { IModal, ModalType } from '@movecloser/front-core'
import { MetaInfo } from 'vue-meta'
import { VueConstructor } from 'vue'

import { DropdownActions } from '../../shared/contracts/content'
import { HasIdentifier } from '../../shared/contracts/data'
import { Identifier, Inject, PossibleRelatedType, SetType } from '../../../backoffice'
import { Query } from '../../shared/contracts/query'

import { HeaderInterface } from '../../shared/components/InteractiveTable/InteractiveTable.contracts'
import { InteractiveTable } from '../../shared/components/InteractiveTable/InteractiveTable.vue'
import { ModelListHeader } from '../../shared/components/ModelListHeader/ModelListHeader'
import { ModelListHeaderProps } from '../../shared/components/ModelListHeader/ModelListHeader.contracts'

import { initBreadcrumbs } from '../../root/helpers/breadcrumbs'

import { ISetsPermissions, SetsPermissions } from '../config/permissions.mixin'
import { SetData } from '../contracts'
import { ISetsRepository, SetsRepositoryType } from '../contracts/repositories'
import {
  rowComponentConfig,
  SetsListsActions,
  setsRowActionsFactory,
  setsTableHead
} from '../maps/sets'
import { setsFiltersConfig } from '../models/sets.filters'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
@Component({
  name: 'SetsList',
  components: { InteractiveTable, ModelListHeader },
  metaInfo (this: SetsList): MetaInfo {
    return {
      title: `${this.$t(`sets.listTitle.${this.setType}`)}`
    }
  }
})
export class SetsList extends Mixins<ISetsPermissions>(SetsPermissions) {
  @Inject(SetsRepositoryType)
  protected setsRepository!: ISetsRepository

  @Inject(ModalType)
  private modalConnector!: IModal

  public actions: DropdownActions = {
    [SetsListsActions.Preview]: {
      callback: (data: unknown) => {
        this.$router.push({ name: 'sets.edit', params: { id: (data as SetData).id.toString() } })
      }
    },
    [SetsListsActions.Delete]: {
      callback: (data: unknown) => {
        return this.onDelete((data as HasIdentifier).id)
      },
      confirmation: {
        header: 'Usuń listę',
        contentText: 'Czy na pewno chcesz usunąć:',
        theme: DashmixTheme.Danger,
        buttonLabel: 'atoms.delete'
      }
    }
  }

  public isLoading = false
  public filtersConfig = setsFiltersConfig

  public tableHead: TableHead = setsTableHead
  public tableData: TableRowElement[] = []

  public totalPages: number = 0
  public itemsTotal: number = 0

  public get breadcrumbs (): DashmixBreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t(`sets.listTitle.${this.setType}`)}`,
          target: { name: 'sets.list' }
        }
      ],
      root: initBreadcrumbs.root
    }
  }

  public get canCreateSets (): boolean {
    switch (this.setType) {
      case PossibleRelatedType.Articles:
        return this.canCreateArticles
      case PossibleRelatedType.Products:
        return this.canCreateProducts
      default:
        return false
    }
  }

  public get header (): HeaderInterface {
    const payload: Partial<HeaderInterface> = {
      title: 'Listy'
    }

    if (this.canCreateSets) {
      payload.buttonLabel = 'Dodaj listę'
      payload.linkTarget = { name: 'sets.create', params: { type: String(this.setType) } }
    }

    return payload as HeaderInterface
  }

  protected get isSelectable (): boolean {
    switch (this.setType) {
      case PossibleRelatedType.Articles:
        return this.canEditArticles || this.canDeleteArticles
      case PossibleRelatedType.Products:
        return this.canEditProducts || this.canDeleteProducts
      default:
        return false
    }
  }

  public get modelListHeader (): ModelListHeaderProps {
    const props: Partial<ModelListHeaderProps> = {
      title: String(this.$t(`sets.listTitle.${this.setType}`))
    }

    if (this.canCreateSets) {
      props.addLabel = String(this.$t('sets.listButton'))
      props.addTarget = { name: 'sets.create', params: { type: String(this.setType) } }
    }

    return props as ModelListHeaderProps
  }

  public get setType (): SetType {
    if (!this.$route.params.type) {
      return PossibleRelatedType.Articles
    }
    return this.$route.params.type
  }

  public get rowActions (): TableRowAction[] {
    return setsRowActionsFactory(this.domain, this.user, this.setType)
  }

  public get rowComponent (): VueConstructor {
    if (!rowComponentConfig[this.setType]) {
      throw new Error(`No row component register for [${this.setType}]`)
    }
    return rowComponentConfig[this.setType]
  }

  protected get queryParams (): Query {
    return this.$route.query
  }

  mounted () {
    this.loadList(this.queryParams)
  }

  @Watch('queryParams', { deep: true })
  protected onPageChange (query: Query): void {
    this.loadList(query)
  }

  protected loadList (query: Query): void {
    this.isLoading = true
    this.setsRepository.loadCollection(this.setType, query).then(collection => {
      this.isLoading = false

      this.tableData = [...collection].map(model => {
        return {
          id: `${model.id}`,
          selectable: this.isSelectable,
          selected: false,
          data: model
        }
      })

      this.totalPages = Math.ceil(collection.meta.total / collection.meta.per_page)
      this.itemsTotal = collection.meta.total
    }).catch(error => {
      console.log(error)
      this.isLoading = false
      this.tableData = []
    })
  }

  protected onDelete (id: Identifier) {
    return this.setsRepository.delete(id).then(() => {
      this.tableData = this.tableData.filter(rowData => rowData.id !== id.toString())
    })
  }
}

export default SetsList
