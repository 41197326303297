












import { AnyObject } from '@movecloser/front-core'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { ContentStructure } from '@movecloser/page-builder'

import {
  ContainersRegistry,
  ModulesRegistry,
  PageBuilder,
  PageBuilderOperationMode
} from '../../shared/components'
import { IRelatedService } from '../../../contexts'

@Component({
  name: 'StaticBuilder',
  components: { PageBuilder }
})
export class StaticBuilder extends Vue {
  @Prop({ type: Number, required: false, default: 12 })
  public readonly columns!: number

  @Prop({ type: Object, required: true })
  public readonly containers!: ContainersRegistry

  @Prop({ type: String, required: true })
  public readonly contentType!: string

  @Prop({ type: Object, required: false, default: () => ({}) })
  public readonly fromParent!: AnyObject

  @Prop({ type: String, required: false, default: PageBuilderOperationMode.Builder })
  public readonly mode!: PageBuilderOperationMode

  @Prop({ type: Object, required: true })
  public readonly modules!: ModulesRegistry

  @Prop({ type: Boolean, required: false, default: false })
  public readonly noPreview!: boolean

  @Prop({ type: Object, required: true })
  public readonly relatedService!: IRelatedService

  @Prop({ type: Object, required: false, default: () => ({}) })
  public readonly structure!: ContentStructure
}

export default StaticBuilder
