// Copyright © 2021 Move Closer

import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DashmixSelectItem, Hint } from '@movecloser/ui-core'

import { Inject } from '../../../../backoffice'

import { DictionaryRepositoryType, IDictionaryRepository } from '../../../root/contracts/repositories'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component
export class AbstractSelector extends Vue {
  @Prop({ type: Boolean, required: false, default: true })
  public disabled!: boolean

  @Prop({ type: Boolean, required: false, default: true })
  protected isMulti!: boolean

  @Prop({ type: Boolean, required: false, default: true })
  protected taggable!: boolean

  @Prop({ type: Function, required: true })
  protected onSelection!: (value: Hint[]) => void

  @Prop({ type: Array, required: false, default: () => [] })
  protected selected!: Hint[]

  @Inject(DictionaryRepositoryType)
  protected dictRepository!: IDictionaryRepository

  public dictionary: DashmixSelectItem[] | [] = []
  public loading: boolean = false
  public searchParam: string = ''
  public value: Hint[] = []

  mounted () {
    this.loadDictionary()
    this.value = this.selected
  }

  public onClear () {
    this.searchParam = ''
  }

  public onDelete (deletedHint: Hint) {
    this.value = this.value.filter(v => v.value !== deletedHint.value)
    this.applySelection(this.value)
  }

  public onSearch (value: string) {
    this.searchParam = value
  }

  public onSelect (selectedHint: Hint) {
    this.value = [...this.value, selectedHint]
    this.applySelection(this.value)
  }

  protected applySelection (selected: Hint[]) {
    this.onSelection(selected)
  }

  @Watch('searchParam', { deep: false })
  protected onSearchParamsChange (searchParams: string): void {
    this.loadDictionary(searchParams)
  }

  @Watch('selected', { deep: true })
  protected onSelectedChange (selected: Hint[]): void {
    if (JSON.stringify(this.value) !== JSON.stringify(selected)) {
      this.value = selected
    }
  }

  protected loadDictionary (searchParams?: string) {
    // To implement at parent.
  }
}
