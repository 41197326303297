// Copyright © 2021 Move Closer

import {
  ICollection,
  Injectable,
  IResponse,
  Repository,
  ResourceActionFailed
} from '@movecloser/front-core'

import { resolveFromStatus } from '../../shared/exceptions/connector-errors'

import { ArticleFilterData, ArticleFilterModel } from '../contracts/models'
import { IArticleFiltersRepository } from '../contracts/repositories'
import { ArticleFilter } from '../models/filters'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Injectable()
export class ArticleFiltersRepository extends Repository<ArticleFilterData, ArticleFilter> implements IArticleFiltersRepository {
  protected useAdapter: boolean = false
  // protected map: MappingConfig = nodeAdapterMap

  public async list (): Promise<ICollection<ArticleFilterModel>> {
    const response: IResponse = await this.connector.call(
      'articleFilters',
      'list',
      {},
      {}
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeCollection(
      response.data.data,
      ArticleFilter,
      response.data.meta
    )
  }
}
