



















import { Component, PropSync } from 'vue-property-decorator'

import { FormInput } from '../../shared/components/form'

import { ConfigForm } from './_abstract/ConfigForm.vue'

@Component<FreeDeliveryEyeCatcher>({
  name: 'FreeDeliveryEyeCatcher',
  components: { FormInput },
  created () {
    this.freeShippingMinValue = this.payload.toString()
  }
})
export class FreeDeliveryEyeCatcher extends ConfigForm {
  @PropSync('formData', { type: Number, required: true })
  public payload!: number

  public freeShippingMinValue = ''

  public loading: boolean = false

  public onSubmit () {
    this.payload = Number(this.freeShippingMinValue)
    this.$emit('submit', { reproject: true, global: false })
  }
}

export default FreeDeliveryEyeCatcher
