



























import { Component, Prop, Vue } from 'vue-property-decorator'

import { FormInput, FormText } from '../../shared/components/form'

import { DirectoryCreatePayload, DirectoryData, EmptyDirectoryCreatePayload } from '../contracts'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
@Component({
  name: 'DirectoryForm',
  components: { FormInput, FormText }
})
export class DirectoryForm extends Vue {
  @Prop({ type: Object, required: true })
  public data!: DirectoryCreatePayload

  @Prop({ type: Boolean, required: false, default: false })
  public editMode!: boolean

  @Prop({ type: String, required: true })
  public formName!: string

  @Prop({ type: Object, required: false })
  public parentDirectory!: DirectoryData

  public form = { ...EmptyDirectoryCreatePayload }

  public get parentDirectoryName (): string {
    return this.parentDirectory?.name || ''
  }

  mounted () {
    this.form = this.data
  }
}

export default DirectoryForm
