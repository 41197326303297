// Copyright © 2021 Move Closer

import { ComponentObjectPropsOptions, DashmixTheme } from '@movecloser/ui-core'
import { computed, PropType, SetupContext } from '@vue/composition-api'

import { CheckTileProps } from './CheckTile.contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const checkTileProps: ComponentObjectPropsOptions<CheckTileProps> = {
  id: {
    type: String,
    required: true
  },
  name: {
    type: String,
    required: true
  },
  selected: {
    type: [String, Boolean, Number],
    required: false,
    default () {
      return false
    }
  },
  theme: {
    type: String as PropType<DashmixTheme>,
    required: false,
    default: DashmixTheme.Info as DashmixTheme
  },
  value: {
    type: [String, Boolean, Number],
    required: false,
    default () {
      return false
    }
  }
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const useCheckTile = (props: CheckTileProps, ctx: SetupContext) => {
  const isChecked = computed<string|boolean|number>({
    get: () => {
      return props.value === props.selected
    },
    set: (value: string|boolean|number) => {
      if (value) {
        ctx.emit('update:selected', props.value)
      }
    }
  })

  return { isChecked: isChecked }
}
