// Copyright © 2021 Move Closer

import { AbstractModule, ModuleProps } from '@movecloser/page-builder'
import { AnyModule, logger, ModuleConstructor, ModuleDriver, modulesUiRegistry, ModulesUiRegistryEntry } from '../../../../../backoffice'
import { defineComponent, MethodOptions } from '@vue/composition-api'
import { VueConstructor } from 'vue'

import MissingModule from './MissingModule.vue'
import ModuleLabel from './ModuleLabel.vue'
import { PageBuilderOperationMode } from '../PageBuilder.contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
interface ModuleParentBinding {
  resolvedModule: AnyModule
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
interface ModuleMethods extends MethodOptions {
  resolveFormComponent: () => VueConstructor
  resolveUiComponent: () => ModuleConstructor
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const Module = defineComponent<ModuleProps, ModuleParentBinding, unknown, any, ModuleMethods>({
  name: 'Module',
  extends: AbstractModule,
  computed: {
    resolveInnerComponent (): ModuleConstructor {
      if (
        this.elementProps.mode === PageBuilderOperationMode.Builder && this.showControls
      ) {
        // Displays form's icon representation.
        return this.resolveFormComponent()
      } else {
        // Displays UI component that renders
        // client content.
        return this.resolveUiComponent()
      }
    }
  },
  methods: {
    resolveFormComponent (): VueConstructor {
      return ModuleLabel as VueConstructor
    },
    resolveUiComponent (): ModuleConstructor {
      const driver: ModuleDriver = this.resolvedModule.driver
      const component: ModulesUiRegistryEntry | undefined = modulesUiRegistry[driver]
      if (typeof component === 'undefined') {
        logger(`🏷 Module: Failed to resolve the UI component for the [ModuleDriver.${driver}].`, 'error')
        return MissingModule as VueConstructor
      }

      return component.component
    }
  }
})
