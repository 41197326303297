// Copyright © 2021 Move Closer

import { Model } from '@movecloser/front-core'

import { OwnerData, IOwner } from '../contracts/models'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
export class Owner extends Model<OwnerData> implements IOwner {
  protected boot (): void {
    this.initialValues = {}
    this.modelProperties = ['id', 'avatar', 'firstName', 'lastName', 'nickname']
  }

  public fullName (): string {
    if (this._data.lastName && this._data.firstName) {
      return `${this._data.firstName} ${this._data.lastName}`
    }

    return this._data.nickname
  }

  public shortName (): string {
    if (this._data.firstName) {
      return this._data.firstName.split(' ')[0] || ''
    }

    return this._data.nickname
  }
}
