// Copyright © 2021 Move Closer

import { ICollection, QueryParams } from '@movecloser/front-core'

import { Identifier } from '../../../backoffice'

import { MeModel, SessionData, TokenModel } from './models'
import { OAuthPayload } from './data'

export const AuthRepositoryType = Symbol.for('AuthRepository')

export interface IAuthRepository {
  forgotPassword (email: string): Promise<void>
  login (email: string, password: string): Promise<TokenModel>
  authenticate (oauthPayload: OAuthPayload): Promise<TokenModel>
  logout (): Promise<void>
  resolveRedirectionUrl (): Promise<string>
  refresh (token: string): Promise<TokenModel>
  resetPassword (token: string, email: string, password: string, passwordConfirmation: string): Promise<void>
}

export interface IMeRepository {
  deleteAllSession (): Promise<void>
  deleteSession (sessionId: Identifier): Promise<void>
  editAvatar (avatar: File): Promise<void>
  editBasicInformation (nickname?: string, firstName?: string, lastName?: string): Promise<void>
  editPassword (actual: string, password: string, passwordConfirmation: string): Promise<void>
  loadMeProfile (): Promise<MeModel>
  loadMySessions (query?: QueryParams): Promise<ICollection<SessionData>>
}

export const MeRepositoryType = Symbol.for('IMeRepository')
