





























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DashmixIconName, DashmixTheme } from '@movecloser/ui-core'
import { Collection, ICollection, IModal, ModalType } from '@movecloser/front-core'

import { Identifier, Inject, SetType } from '../../../backoffice'

import { AddSetItemIntention } from '../intentions/AddSetItemIntention'
import {
  AddSetItemModalPayload,
  ISetItemsRepository,
  SetItemsRepositoryType,
  SetModel,
  SetItemModel
} from '../contracts'
import { ChangeItemsOrderIntention } from '../intentions/ChangeItemsOrderIntention'
import { SetModal } from '../config/modals'
import { SetDraggableList } from './SetDraggableList.vue'

@Component<SetSidebar>({
  name: 'SetSidebar',
  components: { SetDraggableList },

  mounted () {
    this.loadSetItems()
    this.count = this.set.childrenCount
  }
})
export class SetSidebar extends Vue {
  @Prop({ type: Boolean, required: false, default: true })
  public isEditable?: boolean

  @Prop({ type: Array, required: false, default: () => { return [] } })
  private lockedItems!: SetItemModel[]

  @Prop({ type: Object, required: true })
  public set!: SetModel

  @Prop({ type: Number, required: false })
  public siteId?: Identifier

  @Prop({ type: String, required: true })
  public setType!: SetType

  @Inject(ModalType)
  private modalConnector!: IModal

  @Inject(SetItemsRepositoryType)
  private setItemsRepository!: ISetItemsRepository

  public buttonTheme = DashmixTheme
  public count = 0
  public Icons = DashmixIconName
  public isLoading: boolean = false
  private items: SetItemModel[] = []
  private numOfPages: number = 0
  private page: number = 1
  private perPage: number = 10

  public get filteredList (): SetItemModel[] {
    if (this.items.length > 0 || typeof this.items !== 'undefined') {
      return this.items.map((item: SetItemModel) => {
        item.set('locked', this.lockedItemsIds.includes(item.id))
        return item
      }).sort((a, b) => a.position - b.position)
    } else {
      return []
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/adjacent-overload-signatures
  public set filteredList (collection: SetItemModel[]) {
  }

  public get lockedItemsIds (): Identifier[] {
    return this.lockedItems.map((item) => {
      return item.id
    })
  }

  public handleItemAdded () {
    const payload: AddSetItemModalPayload = {
      onClose: () => this.modalConnector.close(),
      onConfirm: this.addItem,
      setType: this.setType
    }

    this.modalConnector.open(SetModal.AddSetItem, payload, { closableWithOutsideClick: true })
  }

  public async handleReorder ({ newIndex, oldIndex }: { newIndex: number; oldIndex: number }) {
    if (newIndex === oldIndex) {
      return
    }

    this.isLoading = true

    const id = this.filteredList[oldIndex].id
    const intention = new ChangeItemsOrderIntention({
      id: typeof id === 'string' ? parseInt(id) : id,
      position: newIndex + 1
    })

    try {
      await this.setItemsRepository.changeOrder(this.set.id, intention).then(() => {
        const lockedItem: any = {
          position: newIndex,
          item: this.items[this.items.findIndex(item => +item.position === oldIndex)]
        }
        this.lockedItems.push(lockedItem)

        this.items[this.items.findIndex(item => +item.position === oldIndex)].set(
          'position',
          newIndex
        )
        this.moveElement(this.items, oldIndex, newIndex)
        this.items = this.withPosition(this.items)
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoading = false
    }
  }

  public async handleMovingToPosition (args: { newIndex: number; oldIndex: number }): Promise<void> {
    const { newIndex, oldIndex } = args
    if (newIndex === oldIndex) {
      return
    }

    this.isLoading = true

    const id = this.filteredList[oldIndex].id
    const intention = new ChangeItemsOrderIntention({
      id: typeof id === 'string' ? parseInt(id) : id,
      position: newIndex + 1
    })
    try {
      await this.setItemsRepository.changeOrder(this.set.id, intention).then(() => {
        const lockedItem: any = {
          position: newIndex,
          item: this.items[this.items.findIndex(item => +item.position === oldIndex)]
        }
        this.lockedItems.push(lockedItem)
        this.items[this.items.findIndex(item => +item.position === oldIndex)].set(
          'position',
          newIndex
        )
        this.moveElement(this.items, oldIndex, newIndex)
        this.items = this.withPosition(this.items)
      })
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoading = false
    }
  }

  public lockItem (itemId: Identifier): void {
    this.$emit('lockItem', itemId)
  }

  public unlockItem (itemId: Identifier): void {
    this.$emit('unlockItem', itemId)
  }

  protected async addItem (itemId: Identifier) {
    this.page = 1
    this.count += 1
    try {
      await this.setItemsRepository.add(this.set.id, new AddSetItemIntention({ position: 1, id: itemId }))
      this.items = []
      this.loadSetItems(this.page, true)
    } catch (e) {
      console.error(e)
    }
    this.modalConnector.close()
  }

  protected onRemove (itemId: Identifier): void {
    this.page = Math.floor(this.items.findIndex(item => +item.id === itemId) / this.perPage)
    this.items.splice(this.page * this.perPage)
    this.page++
    this.count -= 1
    this.loadSetItems(this.page, this.page !== 1)
  }

  protected switchPage () {
    if (!this.isLoading && this.page < this.numOfPages) {
      this.page++
    }
  }

  protected moveElement (array: any[], from: number, to: number): void {
    array.splice(to, 0, array.splice(from, 1)[0])
  }

  protected loadSetItems (withPage?: number, paginated: boolean = false): void {
    this.isLoading = true
    this.setItemsRepository.loadCollection(
      this.set.id,
      this.setType,
      { page: withPage || this.page, perPage: this.perPage },
      this.siteId
    ).then(
      (setItems: ICollection<SetItemModel>) => {
        this.items = this.withPosition(setItems, paginated)
        this.numOfPages = Math.ceil(this.count / this.perPage)
      }
    ).catch((e) => console.log(e.toString()))
      .finally(() => {
        this.isLoading = false
      })
  }

  protected withPosition (collection: SetItemModel[], paginated: boolean = false) {
    if (paginated) {
      const upcomingList = new Collection<SetItemModel>(
        [...collection].map((item: SetItemModel, index: number) => {
          item.set(
            'position',
            this.filteredList.length > 0
              ? (this.filteredList[this.filteredList.length - 1].position + 1) + index
              : index
          )
          console.log(item)
          return item
        })
      )
      this.items.push(...upcomingList)

      return [...this.items]
    }

    return new Collection<SetItemModel>(
      [...collection].map((item: SetItemModel, index: number) => {
        item.set('position', index)
        return item
      })
    )
  }

  @Watch('page')
  async loadMore (newPage: number) {
    this.loadSetItems(newPage, true)
  }

  @Watch('set')
  async load (set: SetModel) {
    this.loadSetItems(1, false)
    this.count = set.childrenCount
  }
}

export default SetSidebar
