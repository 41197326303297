// Copyright © 2021 Move Closer

import { Component, Prop, Vue } from 'vue-property-decorator'
import { DateTimeType, IDateTime } from '@movecloser/front-core'
import { DashmixIconName, DashmixTheme } from '@movecloser/ui-core'
import { HandleDirective } from 'vue-slicksort'

import { Inject } from '../../../../backoffice'

import { ISetItemsRepository, SetItemData, SetItemsRepositoryType } from '../../contracts'

@Component({
  name: 'AbstractSetItem',
  directives: { handle: HandleDirective }
})
export class AbstractSetItem<M extends SetItemData> extends Vue {
  @Prop({ type: Object, required: false })
  public data!: M['data']

  @Inject(DateTimeType)
  protected readonly dateTime!: IDateTime

  @Inject(SetItemsRepositoryType)
  private readonly setItemsRepository!: ISetItemsRepository

  public DashmixTheme = DashmixTheme
  public Icons = DashmixIconName
}
