// Copyright © 2021 Move Closer

import { ComponentObjectPropsOptions } from '@movecloser/ui-core'
import { computed, SetupContext } from '@vue/composition-api'

import { ModeSwitcherItem, ModeSwitcherProps, PageBuilderOperationMode } from '../PageBuilder.contracts'
import { pageBuilderOperationModeIconRegistry } from '../PageBuilder.config'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
export const modeSwitcherProps: ComponentObjectPropsOptions<ModeSwitcherProps> = {
  controls: {
    type: Boolean,
    required: true
  }
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
export function useModeSwitcher (props: ModeSwitcherProps, ctx: SetupContext) {
  const mode = computed<PageBuilderOperationMode>({
    get () {
      return props.controls ? PageBuilderOperationMode.Builder : PageBuilderOperationMode.Viewer
    },
    set (mode: PageBuilderOperationMode) {
      ctx.emit('update:controls', mode === PageBuilderOperationMode.Builder)
    }
  })

  const options = computed(() => {
    return Object.values(PageBuilderOperationMode).map<ModeSwitcherItem>(mode => ({
      icon: pageBuilderOperationModeIconRegistry[mode],
      label: ctx.root.$i18n.t(`builder.operationMode.${mode}`).toString(),
      value: mode
    }))
  })

  return { mode, options }
}
