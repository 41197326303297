// Copyright © 2022 Move Closer

import { FiltersConfig } from '@movecloser/ui-core'
import { VueConstructor } from 'vue'

import { DropdownActions } from '../../../shared/contracts/content'
import { SiteModel } from '../../../../backoffice'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface AddonConfig {
  component: VueConstructor
  label: string
  name: string
  priority: number
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export type AddonRegistry = Record<string, Omit<AddonConfig, 'name'>>

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface ContentConfig {
  hasParent: boolean
  label: string
  loadWith: string[]
  parentType: string | null
  properties: ContentTypeProperty[]
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface ContentListConfig {
  actions: DropdownActions | null
  filters: FiltersConfig | null
  label: string
  listing: VueConstructor
  loadAs: string | null
  loadWith: string[]
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface ContentTypeConfig extends ContentConfig, Omit<ContentListConfig, 'listing'>, Omit<ContentVariantConfig, 'editor' | 'isSmart'> {
  editor: string | null
  isSmart?: boolean
  label: string
  listing: string
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const ContentTypeManagerType = Symbol.for('IContentTypeManager')

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface ContentTypeProperty {
  label: string
  name: string
  guard?: (site: SiteModel) => boolean
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface ContentVariantConfig {
  editor: VueConstructor | null
  hasPreview: boolean
  isSmart: boolean
  label: string
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface IContentTypeManager {
  allowed (): string[]
  defaultType (): string
  getAddons (filter?: string[]): AddonConfig[]
  getContentConfig (type: string): ContentConfig
  getContentListConfig (type: string): ContentListConfig
  getContentVariantConfig (type: string): ContentVariantConfig
  getLabel (type: string): string
  getParentTypes (type: string): string[]
  getSiblingTypes (type: string): string[]
  isTypeAllowed (candidate: string): boolean
  registerAddons (addons: AddonConfig[]): void
  registerEditor (type: string, component: VueConstructor): void
  registerType (type: string, config?: Partial<ContentTypeConfig>, force?: boolean): void
}
