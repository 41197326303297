


















import { Component, Prop, Vue } from 'vue-property-decorator'

import { Container } from '../../../../../contexts'

import { pageBuilderContainerTypes } from '../PageBuilder.config'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component({ name: 'ContainerWrapper' })
export class ContainerWrapper extends Vue {
  @Prop({ type: Object, required: true })
  protected readonly data!: Container

  public get isDynamic (): boolean {
    return this.type === 'dynamic'
  }

  public get isSnippet (): boolean {
    return this.type === 'snippet'
  }

  public get isSolid (): boolean {
    return this.type === 'solid'
  }

  public get label (): string {
    return this.data.source?.label ?? `${this.data.source?.id}` ?? ''
  }

  public get type (): string {
    if (
      typeof this.data.type !== 'string' ||
      !pageBuilderContainerTypes.includes(this.data.type)
    ) {
      return 'solid'
    }

    return this.data.type
  }
}

export default ContainerWrapper
