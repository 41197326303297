// Copyright © 2021 Move Closer

import { Permission } from '../../auth/contracts/permissions'
import { UserModel } from '../../auth/contracts/models'

export const canViewNavigation = (domain: string, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.ViewNavigation)
}

export const canCreateNavigation = (domain: string, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.CreateNavigation)
}

export const canEditNavigation = (domain: string, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.EditNavigation)
}

export const canDeleteNavigation = (domain: string, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.DeleteNavigation)
}

export const canViewLayout = (domain: string, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.ViewLayout)
}

export const canCreateLayout = (domain: string, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.CreateLayout)
}

export const canEditLayout = (domain: string, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.EditLayout)
}

export const canDeleteLayout = (domain: string, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.DeleteLayout)
}

export const canDeleteSettings = (domain: string, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.DeleteSettings)
}

export const canViewSettings = (domain: string, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.ViewSettings)
}

export const canCreateSettings = (domain: string, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.CreateSettings)
}

export const canEditSettings = (domain: string, user: UserModel | null): boolean => {
  if (!user) return false

  return user.canPerform(domain, Permission.EditSettings)
}
