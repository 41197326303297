





























import { Authentication, AuthServiceType, EventbusType, IEventbus, ResourceActionFailed } from '@movecloser/front-core'
import { DashmixAlertTheme } from '@movecloser/ui-core'
import { Component, Vue } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'

import { Inject } from '../../../backoffice'

import { enterKey, IEnterEvent } from '../../shared/extensions/events'
import { FormInput } from '../../shared/components/form'
import { ISiteResolver, SiteResolverType } from '../../root/services/site-resolver'

import { AuthRepositoryType, IAuthRepository } from '../contracts/repositories'
import { TokenModel, UserModel } from '../contracts/models'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
@Component<LoginPage>({
  name: 'CredentialsLoginPage',
  components: { FormInput },
  metaInfo (this: LoginPage): MetaInfo {
    return {
      title: `${this.$t('auth.login')}`
    }
  },

  mounted () {
    window.addEventListener('keyup', this.listener)
  },
  destroyed () {
    window.removeEventListener('keyup', this.listener)
  }
})
export class LoginPage extends Vue implements IEnterEvent {
  @Inject(AuthRepositoryType)
  protected authRepository!: IAuthRepository

  @Inject(AuthServiceType)
  protected authService!: Authentication<UserModel>

  @Inject(EventbusType)
  private eventBus!: IEventbus

  @Inject(SiteResolverType)
  private siteResolver!: ISiteResolver

  protected listener = enterKey.bind(this)

  public formName: string = 'loginUser'
  public profile = {
    email: '',
    password: ''
  }

  public alertTheme: DashmixAlertTheme = DashmixAlertTheme.Warning
  public hasErrors: boolean = false
  public errors: string = ''
  public sending: boolean = false

  public enterPressed (): void {
    this.loginUser()
  }

  public loginUser (): void {
    this.sending = true

    this.authRepository.login(this.profile.email, this.profile.password)
      .then((model: TokenModel) => {
        this.authService.setUser(model.getUser())
        this.authService.setToken(model.toAuthToken())

        const userPermissions = model.getUser().sitesWithAccess()
        const activeSite = this.siteResolver.getSite()

        if (userPermissions.length === 0) {
          this.$router.push({ name: 'auth.no-access' })
          return
        }

        if (!activeSite || !activeSite.domain || !userPermissions.includes(activeSite.domain)) {
          const newSite = this.siteResolver.findByDomain(userPermissions[0])

          if (!newSite) {
            this.authService.deleteToken()
            this.$router.push({ name: 'auth.login' })
            return
          }

          this.siteResolver.setSite(newSite)
          this.eventBus.emit('ui:context.changed')
        }

        this.$router.push({ name: 'root.dashboard' })
      })
      .catch((e: ResourceActionFailed) => {
        this.errors = e.payload && e.message ? e.message : ''
        this.hasErrors = true
      })
      .finally(() => {
        this.sending = false
      })
  }

  public onAlertClose (): void {
    this.hasErrors = false
  }
}

export default LoginPage

