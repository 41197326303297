// Copyright © 2021 Move Closer

import { Authentication, AuthServiceType, EventbusType, EventPayload, IEventbus } from '@movecloser/front-core'
import { Component, Vue } from 'vue-property-decorator'

import { Inject } from '../../../backoffice'

import { ISiteResolver, SiteResolverType } from '../../root/services/site-resolver'

import { UserModel } from '../contracts/models'

import {
  canCreateAuthors,
  canCreateRoles,
  canCreateUsers,
  canDeleteAuthors,
  canDeleteRoles,
  canDeleteUsers,
  canEditAuthors,
  canEditRoles,
  canEditUsers,
  canViewAuthors,
  canViewRoles,
  canViewUsers
} from '../../users/config/guards'

import {
  canCreateContents,
  canDeleteContents,
  canEditContents,
  canPublishContents,
  canViewContents
} from '../../content/config/guards'
import {
  canDeleteMedia,
  canEditMedia,
  canUploadMedia,
  canViewMedia
} from '../../media/config/guards'

export interface IUserAware {
  canCreateAuthors: boolean
  canDeleteAuthors: boolean
  canEditAuthors: boolean
  canViewAuthors: boolean
  canCreateContents: boolean
  canEditContents: boolean
  canDeleteContents: boolean
  canPublishContents: boolean
  canViewContents: boolean
  canEditMedia: boolean
  canUploadMedia: boolean
  canDeleteMedia: boolean
  canViewMedia: boolean
  canCreateUsers: boolean
  canEditUsers: boolean
  canDeleteUsers: boolean
  canViewUsers: boolean
  canCreateRoles: boolean
  canEditRoles: boolean
  canDeleteRoles: boolean
  canViewRoles: boolean
  domain: string
  user: UserModel | null
}

/**
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component<UserAware>({
  created () {
    this.domain = this.siteResolver.getSite()?.domain || ''
    this.user = this.authService.user

    this.eventBus.handle('ui:context.changed', () => {
      this.domain = this.siteResolver.getSite()?.domain || ''
    })

    this.eventBus.handle('app:authentication', (event: EventPayload<string>) => {
      this.onAuthentication(event)
      this.resolveUser()
    })
  }
})
export class UserAware extends Vue implements IUserAware {
  @Inject(AuthServiceType)
  protected authService!: Authentication<UserModel>

  @Inject(EventbusType)
  private eventBus!: IEventbus

  @Inject(SiteResolverType)
  protected siteResolver!: ISiteResolver

  public domain: string = ''
  public user: UserModel | null = null

  /**
   * Authors permissions
   */
  public get canCreateAuthors () {
    return canCreateAuthors(this.domain, this.authService.user)
  }

  public get canDeleteAuthors () {
    return canDeleteAuthors(this.domain, this.authService.user)
  }

  public get canEditAuthors () {
    return canEditAuthors(this.domain, this.authService.user)
  }

  public get canViewAuthors () {
    return canViewAuthors(this.domain, this.authService.user)
  }

  /**
   * Contents permissions
   */
  public get canCreateContents () {
    return canCreateContents(this.domain, this.authService.user)
  }

  public get canEditContents () {
    return canEditContents(this.domain, this.authService.user)
  }

  public get canDeleteContents () {
    return canDeleteContents(this.domain, this.authService.user)
  }

  public get canPublishContents () {
    return canPublishContents(this.domain, this.authService.user)
  }

  public get canViewContents () {
    return canViewContents(this.domain, this.authService.user)
  }

  /**
   * Media permissions
   */
  public get canUploadMedia () {
    return canUploadMedia(this.domain, this.authService.user)
  }

  public get canDeleteMedia () {
    return canDeleteMedia(this.domain, this.authService.user)
  }

  public get canEditMedia () {
    return canEditMedia(this.domain, this.authService.user)
  }

  public get canViewMedia () {
    return canViewMedia(this.domain, this.authService.user)
  }

  /**
   * Users permissions
   */
  public get canCreateUsers () {
    return canCreateUsers(this.domain, this.authService.user)
  }

  public get canEditUsers () {
    return canEditUsers(this.domain, this.authService.user)
  }

  public get canDeleteUsers () {
    return canDeleteUsers(this.domain, this.authService.user)
  }

  public get canViewUsers () {
    return canViewUsers(this.domain, this.authService.user)
  }

  /**
   * Roles permissions
   */
  public get canCreateRoles () {
    return canCreateRoles(this.domain, this.authService.user)
  }

  public get canEditRoles () {
    return canEditRoles(this.domain, this.authService.user)
  }

  public get canDeleteRoles () {
    return canDeleteRoles(this.domain, this.authService.user)
  }

  public get canViewRoles () {
    return canViewRoles(this.domain, this.authService.user)
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected onAuthentication (event: EventPayload<string>): void {
  }

  protected resolveUser (): void {
    this.user = this.authService.user
  }
}
