// Copyright © 2021 Move Closer

import { ComponentObjectPropsOptions } from '@movecloser/ui-core'
import { PropType } from '@vue/composition-api'

import { PopoverPositions, PopoverProps, Triggers } from './Popover.contracts'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const popoverProps: ComponentObjectPropsOptions<PopoverProps> = {
  position: {
    type: String as PropType<PopoverPositions>,
    required: false,
    default: PopoverPositions.Bottom
  },
  triggerMode: {
    type: String as PropType<Triggers>,
    required: false,
    default: Triggers.Hover
  },
  popoverClass: {
    type: String as PropType<PopoverPositions>,
    required: false
  }
}
