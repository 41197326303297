

















import { DashmixBreadcrumbsProps } from '@movecloser/ui-core'
import { Component, Mixins } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'

import { EditModeLayout } from '../../shared/components/EditModeLayout'
import { FormCheckbox } from '../../shared/components/form'
import { Identifier, Inject, logger } from '../../../backoffice'
import { ISiteResolver, SiteResolverType } from '../../root/services/site-resolver'
import { PropertyItem } from '../../shared/contracts/content'

import { AddContentIntention } from '../intentions/AddContentIntention'
import { ContentBasicsForm } from '../components/ContentBasicsForm.vue'
import { ContentConfig } from '../services/content-type-manager'
import { ContentCreatePayload } from '../contracts/data'
import { ContentRepositoryType, IContentRepository } from '../contracts/repositories'
import { ContentTypeValidator, IContentTypeAware } from '../mixins'
import { initBreadcrumbs } from '../helpers'
import { Properties } from '../contracts/models'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component<Create>({
  name: 'CreateContent',
  components: { ContentBasicsForm, EditModeLayout, FormCheckbox },
  metaInfo (this: Create): MetaInfo {
    return {
      title: this.$t(`${this.contentType}.add`).toString()
    }
  }
})
export class Create extends Mixins<IContentTypeAware>(ContentTypeValidator) {
  @Inject(ContentRepositoryType)
  protected contentRepository!: IContentRepository

  @Inject(SiteResolverType)
  protected siteResolver!: ISiteResolver

  public formName: string = 'createContent'
  public isBooting: boolean = true
  public isSaving: boolean = false
  public payload: ContentCreatePayload | null = null
  public modelProperties: string[] = []

  public get breadcrumbs (): DashmixBreadcrumbsProps {
    return {
      items: [
        {
          label: this.$tc(this.contentConfig.label, 2).toString(),
          target: { name: 'content.list' }
        },
        {
          label: this.$t(`${this.contentType}.add`).toString(),
          target: { name: 'content.create', params: { type: this.contentType } }
        }
      ],
      root: initBreadcrumbs.root
    }
  }

  public get contentOptions (): PropertyItem[] {
    return this.contentConfig.properties.map(p => ({
      key: p.name,
      translationKey: p.label
    }))
  }

  public get contentConfig (): ContentConfig {
    return this.contentTypeManager.getContentConfig(this.contentType)
  }

  public get optionsDictionary () {
    if (!this.contentOptions.length) {
      return []
    }

    return this.contentOptions.map(option => {
      return {
        label: this.$t(option.translationKey).toString(),
        key: option.key
      }
    })
  }

  public get properties (): string[] {
    return this.modelProperties
  }

  public async createNewArticle (): Promise<void> {
    if (!this.payload) {
      return
    }

    this.isSaving = true
    const intention = new AddContentIntention(this.payload)

    try {
      const id: Identifier = await this.contentRepository.create(intention.toRequest())
      await this.$router.push(
        { name: 'content.edit.content', params: { id: `${id}`, type: this.payload.type } }
      )
    } catch (e) {
      logger(e, 'error')
    }

    this.isSaving = false
  }

  protected async onPositiveTypeValidation (): Promise<void> {
    this.payload = { parent: null, slug: '', title: '', type: this.contentType, properties: {} }
    this.isBooting = false
  }

  protected onPropertiesChange (options: string[]) {
    this.setProperties(options)
  }

  protected async onTypeValidationFail (defaultType: string): Promise<void> {
    await this.$router.push({
      name: 'content.create', params: { type: defaultType }
    })
  }

  protected setProperties (options: string[]): void {
    if (!this.payload) {
      return
    }

    this.modelProperties = options
    this.payload.properties = this.contentOptions.map(o => o.key)
      .reduce((agr: Properties, o: string) => {
        agr[o] = options.includes(o)
        return agr
      }, {} as Properties)
  }
}

export default Create

