



















































































import {
  Authentication,
  AuthServiceType,
  DateTimeType,
  ICollection,
  IDateTime,
  IResponse,
  IValidation,
  ResourceActionFailed,
  ValidationType
} from '@movecloser/front-core'
import { Component, Prop } from 'vue-property-decorator'
import { DashmixIconName } from '@movecloser/ui-core'
import { FilePond, FilePondFile } from 'filepond'
import { Location } from 'vue-router'

import { AbstractModal } from '../../shared/components/Modal'
import { ConnectorErrors } from '../../shared/exceptions/connector-errors'
import { DropdownActions } from '../../shared/contracts/content'

import { Inject, IRelatedService, PossibleRelatedType, Related, RelatedServiceType, Identifier } from '../../../backoffice'

import { PickerPayload } from '../../content/contracts'
import { UserModel } from '../../auth/contracts/models'

import { CreateFileIntention } from '../intentions/CreateFileIntention'
import {
  DirectoryData,
  DirectoryModel,
  FileCreatePayload,
  FileModel,
  FileRepositoryType,
  IFileRepository
} from '../contracts'
import { File } from '../models/file'
import { FileCreateForm } from '../components/FileCreateForm.vue'
import { MediaActions } from '../maps/media'
import { MediaView } from './MediaView.vue'
import { SelectedFilesList } from './SelectedFilesList.vue'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
@Component<FilePickerModal>({
  name: 'FilePickerModal',
  components: { FileCreateForm, MediaView, SelectedFilesList },
  mounted () {
    if (this.payload.selected) {
      if (this.payload.multiple) {
        this.selectedIds = (this.payload.selected as Related[])
          .map(s => typeof s.value === 'string' ? parseInt(s.value) : s.value)
      } else {
        const selected: Related = (this.payload.selected as Related)
        this.selectedIds = [
          typeof selected.value === 'string' ? parseInt(selected.value) : selected.value
        ]
      }
    }
  }
})
export class FilePickerModal extends AbstractModal {
  @Prop({ type: Object, required: true })
  public payload!: PickerPayload<FileModel>

  @Inject(AuthServiceType)
  protected authService!: Authentication<UserModel>

  @Inject(DateTimeType)
  protected dateTime!: IDateTime

  @Inject(FileRepositoryType)
  protected fileRepository!: IFileRepository

  @Inject(RelatedServiceType)
  protected relatedService!: IRelatedService

  @Inject(ValidationType)
  protected validation!: IValidation

  public actions: DropdownActions = {
    [MediaActions.OpenDirectory]: {
      callback: (data: unknown) => {
        const model = data as DirectoryModel
        this.openDirectory(model.id)
      }
    }
  }

  public currentDirectory: DirectoryData | null = null
  public duplicateDetected: Identifier | null = null
  public files: FilePondFile[] = []
  public fileMode: string | null = null
  public forms: FileCreatePayload[] = []
  public forceCreate: boolean = false
  public icons = DashmixIconName
  public isLoading = false
  public route: Location = {
    query: { q: '' },
    params: { id: '1' }
  }

  public selected: FileModel[] = []

  public selectedIds: Identifier[] = []
  public tabItems = [
    {
      id: 'pick',
      label: this.$t('media.file.picker.modal.browse')
    },
    {
      id: 'create',
      label: this.$t('media.file.picker.modal.addNewFile')
    },
    {
      id: 'selected',
      label: this.$t('media.file.picker.modal.selected')
    }
  ]

  public activeTab = this.tabItems[0].id

  public get currentDateTime (): string {
    return this.dateTime.nowToFormat('YYYY-MM-DD HH:mm:ss')
  }

  public get isMultiple (): boolean {
    return this.payload.multiple ?? false
  }

  public get isUploadButtonDisabled (): boolean {
    return !this.fileChoosen || (!!this.duplicateDetected && !this.forceCreate)
  }

  public get allowedMediaType (): string {
    return this.payload.config?.allowedMediaType
  }

  public get isSelected (): boolean {
    return this.selected && this.selected.length !== 0
  }

  public get fileChoosen (): boolean {
    return this.files.length > 0
  }

  public get user (): UserModel {
    return this.authService.user as UserModel
  }

  public get filepond (): FilePond {
    return (this.$refs.fileCreateForm as FileCreateForm).filepond as unknown as FilePond
  }

  public get targetDirectoryId (): Identifier {
    return this.currentDirectory?.id || 1
  }

  public apply (): void {
    const result = this.selected.map(item => {
      // TODO: Make this more elegant.
      // this.relatedService.merge(, item.id, {
      //   ...item,
      //   type: item.preset === 'variants' ? 'image' : 'attachment'
      // })

      return {
        type: PossibleRelatedType.File,
        value: item.id
      } as Related
    })

    if (this.payload.onSelection && typeof this.payload.onSelection === 'function') {
      this.payload.onSelection(this.isMultiple ? result : result[0])
    }

    this.$emit('back', { params: this.payload.params ?? {}, selected: this.isMultiple ? result : result[0] })
  }

  public clickSaveFile (): void {
    this.isLoading = true
    if (!this.isFileUploaded(this.files[0]) && !this.duplicateDetected) {
      this.fileMode = 'public'
      this.filepond.processFile()
      return
    }
    this.sendCreateFileForm()
  }

  public close (): void {
    if (this.payload.onClose && typeof this.payload.onClose === 'function') {
      this.payload.onClose()
    }

    this.$emit('back')
  }

  public async createFile (id: Identifier, payload: FileCreatePayload, file: FilePondFile, force: boolean = false):
    Promise<boolean> {
    const intention = new CreateFileIntention(payload)

    return this.fileRepository.createFile(id, intention.toRequest(), force).then((response: IResponse) => {
      const fileModel = File.hydrate<FileModel>({
        ...intention.toModel(),
        id,
        addedBy: this.user,
        file: {
          file: response?.data?.data?.file || URL.createObjectURL(file.file) || '',
          thumbnail: response?.data?.data?.thumbnail || URL.createObjectURL(file.file) || ''
        },
        directory: this.currentDirectory,
        size: file.fileSize,
        mime: file.fileExtension,
        createdAt: this.currentDateTime,
        updatedAt: this.currentDateTime
        // pending: true - set when async turned on
      })

      this.addFileToCurrentDirectory(fileModel)

      if (!this.isMultiple) {
        this.selected = []
      }
      this.selected.push(fileModel)

      return true
    })
      .catch((errors: ResourceActionFailed[]) => {
        for (const error of errors) {
          if (error.status === ConnectorErrors.Unknown) {
            console.error(error)
          } else {
            this.validation.pushErrors(
              'createFile_' + file.id,
              { [error?.payload.name ?? 'lost_error_name']: [error.message] }
            )
          }
        }
        return false
      })
  }

  public isFileUploaded (file: FilePondFile): boolean {
    return !!file?.serverId
  }

  public removeFile (id: Identifier) {
    this.selected.splice(this.selected.findIndex(file => file.id === id), 1)
  }

  public sendCreateFileForm (): void {
    if (!this.duplicateDetected && !this.files[0]?.serverId) {
      this.isLoading = false
      return
    }
    this.createFile(this.duplicateDetected ?? parseInt(this.files[0]?.serverId), this.forms[0],
      this.files[0]).then((success) => {
      if (!success || this.files.length === 0) {
        this.isLoading = false
        return
      }
      if (this.duplicateDetected) {
        this.duplicateDetected = null
        this.forceCreate = false
      }

      this.filepond.removeFile()
      if (this.files.length === 1) {
        this.isLoading = false
        this.activeTab = 'selected'
        return
      }
      this.filepond.processFile(this.files[1])
    })
  }

  protected addFileToCurrentDirectory (newFile: FileModel): void {
    if (this.currentDirectory) {
      this.currentDirectory = {
        ...this.currentDirectory,
        files: [newFile].concat(this.currentDirectory.files) as ICollection<FileModel>
      }
    }
  }

  protected openDirectory (id: Identifier) {
    this.route = { ...this.route, params: { ...this.route.params, id: id.toString() } }
  }

  protected selectFiles (files: FileModel[]) {
    this.selected = files
  }
}

export default FilePickerModal
