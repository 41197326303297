// Copyright © 2022 Move Closer

import { Component } from 'vue-property-decorator'

import { logger } from '../../../backoffice'

import { ContentTypeAware } from './ContentTypeAware'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component<ContentTypeValidator>({
  name: 'ContentTypeValidator',
  created () {
    this.validateType()
  }
})
export class ContentTypeValidator extends ContentTypeAware {
  protected async onPositiveTypeValidation (): Promise<void> {
    throw new Error('[ContentTypeAware]: Method onPositiveTypeValidation has to be implemented in component.')
  }

  protected async onTypeValidationFail (defaultType: string): Promise<void> {
    throw new Error('[ContentTypeAware]: Method onTypeValidationFail has to be implemented in component.')
  }

  protected async validateType (): Promise<void> {
    logger('[ContentTypeAware]: Validating content type.', 'debug')
    if (this.contentTypeManager.isTypeAllowed(this.contentType)) {
      logger('[ContentTypeAware]: Content type validated.', 'debug')
      await this.onPositiveTypeValidation()
      return
    }

    logger('[ContentTypeAware]: Content type validation failed.', 'debug')
    await this.onTypeValidationFail(this.contentTypeManager.defaultType())
  }
}
