// Copyright © 2021 Move Closer

import { ComponentObjectPropsOptions } from '@movecloser/ui-core'
import { computed, PropType, ref, SetupContext } from '@vue/composition-api'

import {
  ModuleComponentsRegistry,
  ResolvedModuleEntry,
  SelectModuleModalPayload,
  SelectModuleModalProps
} from '../PageBuilder.contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const selectModuleModalProps: ComponentObjectPropsOptions<SelectModuleModalProps> = {
  payload: {
    type: Object as PropType<SelectModuleModalPayload>,
    required: true
  }
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export function useSelectionModuleModal (props: SelectModuleModalProps, ctx: SetupContext) {
  const chosenModule = ref<string | null>(null)
  const minQueryLength: number = 3
  const query = ref<string>('')

  const _registry = computed<ModuleComponentsRegistry>(() => {
    return ctx.root.$options.configuration?.byKey('builder.moduleRegistry') || {}
  })

  const isSelected = computed<boolean>(() => !!chosenModule.value)
  const list = computed<ResolvedModuleEntry[]>(() => {
    const list: ResolvedModuleEntry[] = []

    // Let's handle modules' restrictions.
    const allowed: string = props.payload.type || '*'
    const toDisplay = Object.entries(_registry.value).filter(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ([k, m]) => (m.restrictions.allowedTypes.includes('*') || m.restrictions.allowedTypes.includes(allowed)) &&
        !m.restrictions.forbiddenTypes.includes(allowed)
    )

    for (const [k, m] of toDisplay) {
      list.push({
        ...m,
        label: ctx.root.$i18n.t(m.label).toString(),
        value: k
      })
    }

    return list.sort((a, b) => {
      return a.priority > b.priority ? -1 : 1
    })
  })

  const modules = computed<ResolvedModuleEntry[]>(() => {
    return list.value.filter(m => {
      return query.value.length < minQueryLength ||
        m.label.indexOf(query.value) !== -1 ||
        m.label.toLowerCase().indexOf(query.value.toLowerCase()) !== -1
    }).sort((a, b) => {
      return query.value.length < minQueryLength ? 0 : a.label.localeCompare(b.label)
    })
  })

  function addSelected () {
    props.payload.onSelection(chosenModule.value)
    ctx.emit('close')
  }

  return { addSelected, chosenModule, isSelected, modules, query }
}
