

























































import { DashmixIconName } from '@movecloser/ui-core'
import { defineComponent, SetupContext } from '@vue/composition-api'

import { containerDrawerProps, useContainerDrawer } from './ContainerDrawer.hooks'
import { ContainerDrawerProps } from '../PageBuilder.contracts'
import { SnippetSelector } from './SnippetSelector.vue'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const ContainerDrawer = defineComponent({
  name: 'ContainerDrawer',
  props: containerDrawerProps,
  components: { SnippetSelector },
  setup: (props: ContainerDrawerProps, ctx: SetupContext) => ({
    ...useContainerDrawer(props, ctx),
    iconsSet: DashmixIconName
  })
})

export default ContainerDrawer
