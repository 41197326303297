










































import { Component, Prop, Vue } from 'vue-property-decorator'
import { DashmixIconName, DashmixTheme, DropdownItem, TableRowAction } from '@movecloser/ui-core'
import { DateTimeType, IDateTime } from '@movecloser/front-core'
import { HandleDirective } from 'vue-slicksort'

import { ISetsRepository, SetItem, SetsRepositoryType } from '../../sets/contracts'
import { Inject } from '../../../backoffice'

import { FileData, FileThumbnail } from '../contracts'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
@Component<GalleryDraggableItem>({
  name: 'GalleryDraggableItem',
  directives: { handle: HandleDirective },

  mounted () {
    this.payload.title = this.data.title
    this.payload.description = this.data.description
  }
})
export class GalleryDraggableItem extends Vue {
  @Prop({ type: Array, required: true })
  public actions!: TableRowAction[]

  @Prop({ type: Object, required: true })
  public data!: FileData

  @Prop({ type: Boolean, required: false, default: false })
  public isDisabled?: boolean

  @Prop({ type: Number, required: true })
  public position!: number

  @Inject(DateTimeType)
  protected readonly dateTime!: IDateTime

  @Inject(SetsRepositoryType)
  private setsRepository!: ISetsRepository

  public buttonTheme = DashmixTheme
  public icons = DashmixIconName
  public isLoading: boolean = false
  public payload = {
    title: '',
    description: ''
  }

  public get setId () {
    return Number(this.$route.params.id)
  }

  public async editSetItem (itemId: SetItem['id']) {
    this.isLoading = true
    try {
      await this.setsRepository.updateItem(this.setId, itemId, this.payload)
      this.isLoading = false
      this.payload.title = this.payload.title ?? ''
      this.payload.description = this.payload.description ?? ''
    } catch (error) {
      console.log(error)
    } finally {
      this.isLoading = false
    }
  }

  public get dropdownItems (): DropdownItem[] {
    return this.actions
      .filter(action => {
        return typeof action.guard !== 'function' || action.guard(this.data)
      })
      .map(action => ({
        type: 'item',
        icon: action.icon,
        label: action.label,
        onClick: () => {
          this.$emit('do', {
            id: this.data.id,
            action: action.action,
            data: this.data
          })
        }
      }))
  }

  public get file (): FileThumbnail | null {
    const thumbnail = this.data?.file?.thumbnail ?? this.data?.file?.file ?? null
    return {
      thumbnail: thumbnail ?? '',
      type: this.data.mime
    }
  }

  public save (itemId: SetItem['id']): void {
    this.editSetItem(itemId)
  }
}

export default GalleryDraggableItem
