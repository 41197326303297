






























import {
  DashmixBreadcrumbsProps,
  DashmixIconName,
  DashmixTheme,
  TableHead,
  TableRowElement,
  TableSearchBarAction
} from '@movecloser/ui-core'
import { Collection, ICollection } from '@movecloser/front-core'
import { Component, Mixins, Watch } from 'vue-property-decorator'

import { Identifier, Inject } from '../../../backoffice'

import { DropdownActions } from '../../shared/contracts/content'
import { InteractiveTable } from '../../shared/components/InteractiveTable/InteractiveTable.vue'
import { ModelListHeader } from '../../shared/components/ModelListHeader'
import { ModelListHeaderProps } from '../../shared/components/ModelListHeader/ModelListHeader.contracts'

import { IRolesRepository, RolesRepositoryType } from '../contracts/repositories'
import { IUsersPermissions, UsersPermissions } from '../config/permissions.mixin'
import { RoleData, RoleModel } from '../contracts/models'
import { RolesListActions, rolesTableHead } from '../maps/roles'
import RolesTableRow from '../components/RolesTableRow.vue'

/**
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 */
@Component({
  name: 'RolesList',
  components: { InteractiveTable, ModelListHeader }
})
export class RolesList extends Mixins<IUsersPermissions>(UsersPermissions) {
  @Inject(RolesRepositoryType)
  private rolesRepository!: IRolesRepository

  public actions: DropdownActions = {
    [RolesListActions.Delete]: {
      callback: (data: unknown) => {
        const model = data as RoleModel
        this.deleteRole(model.id)
      },
      confirmation: {
        header: 'actions.delete.header',
        contentText: 'actions.delete.contentText',
        theme: DashmixTheme.Danger,
        buttonLabel: 'atoms.delete'
      }
    },
    [RolesListActions.Edit]: {
      callback: (data: unknown) => {
        this.$router.push({
          name: 'users.roles.edit',
          params: { id: (data as RoleData).id.toString() }
        })
      }
    }
  }

  public breadcrumbs: DashmixBreadcrumbsProps = {
    items: [
      {
        label: `${this.$t('roles.list.title')}`,
        target: { name: 'users.roles' }
      }
    ],
    root: {
      label: `${this.$t('root.views.root')}`,
      target: { name: 'root.dashboard' }
    }
  }

  public get header (): ModelListHeaderProps {
    const header: Partial<ModelListHeaderProps> = {
      title: String(this.$t('roles.list.title'))
    }

    if (this.canCreateRoles) {
      header.addLabel = String(this.$t('roles.list.create'))
      header.addTarget = { name: 'users.roles.create' }
    }

    return header as ModelListHeaderProps
  }

  public isLoading: boolean = false
  public itemsTotal: number = 0
  public roles: ICollection<RoleModel> = new Collection([])

  public get rowActions (): TableSearchBarAction[] {
    const actions = []

    if (this.canEditRoles) {
      actions.push(
        {
          label: String(this.$t('atoms.edit')),
          icon: DashmixIconName.PenSolid,
          isBulkAction: false,
          action: RolesListActions.Edit
        }
      )
    }

    if (this.canDeleteRoles) {
      actions.push(
        {
          label: String(this.$t('atoms.delete')),
          icon: DashmixIconName.TrashAltSolid,
          needsDoubleClick: true,
          isBulkAction: true,
          action: RolesListActions.Delete,
          theme: DashmixTheme.Danger,
          guard: (data: unknown) => {
            return !(data as RoleModel).isLocked
          }
        }
      )
    }

    return actions
  }

  public rowComponent = RolesTableRow
  public tableData: TableRowElement[] = []
  public tableHead: TableHead = rolesTableHead.map(role => {
    return {
      ...role,
      label: `${this.$t(role.label)}`
    }
  })

  public totalPages: number = 0

  public get query () {
    return this.$route.query
  }

  mounted () {
    this.loadRoles()
  }

  public async deleteRole (id: Identifier) {
    await this.rolesRepository.delete(id)
    for (let i = 0; i < this.tableData.length; i++) {
      if (this.tableData[i].id === `${id}`) {
        this.tableData.splice(i, 1)
        --this.itemsTotal
        break
      }
    }
  }

  @Watch('query')
  public async loadRoles () {
    this.isLoading = true
    const collection = await this.rolesRepository.loadCollection(this.query)
    this.roles = collection
    this.isLoading = false
    // TODO why .meta throws undefined?
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.totalPages = Math.ceil(collection._meta.total / collection._meta.per_page)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.itemsTotal = collection._meta.total
    this.tableData = [...collection].map(this.modelToTableData)
  }

  private modelToTableData (model: RoleModel): TableRowElement {
    return {
      id: String(model.id),
      selectable: !model.isLocked && (this.canEditRoles || this.canDeleteRoles),
      data: model
    }
  }
}

export default RolesList
