// Copyright © 2021 Move Closer

import {
  ApiConnectorType,
  AppModule,
  Container,
  IConfiguration,
  IConnector, IModal,
  Interfaces, ModalType,
  Module
} from '@movecloser/front-core'

import {
  IUserRepository,
  IRolesRepository,
  UserRepositoryType,
  RolesRepositoryType
} from './contracts/repositories'
import { RolesRepository } from './repositories/roles'
import { UserRepository } from './repositories/user'
import { resources } from './config/resources'
import { routesFactory } from './config/routes'
import { usersModal } from './config/modals'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
@AppModule({
  name: 'users',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  providers: (config: IConfiguration) => {
    return (bind: Interfaces.Bind) => {
      bind<IUserRepository>(UserRepositoryType).to(UserRepository)
      bind<IRolesRepository>(RolesRepositoryType).to(RolesRepository)
    }
  },
  boot (container: Container) {
    const connector: IConnector = container.get(ApiConnectorType)

    const modalConnector: IModal = container.get(ModalType)
    modalConnector.register(usersModal)

    connector.useResources(resources)
  },
  routes: routesFactory
})
export class UsersModule extends Module {}
