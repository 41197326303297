// Copyright © 2022 Move Closer

import { Model } from '@movecloser/front-core'

import { IProductFilter, ProductFilterData } from '../contracts'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
export class ProductFilter extends Model<ProductFilterData> implements IProductFilter {
  protected boot (): void {
    this.initialValues = {}

    this.modelProperties = [
      'id',
      'isAttribute',
      'isMulti',
      'label',
      'name',
      'options',
      'queryParam'
    ]
  }

  public displayName (): string {
    return this._data.name
  }
}
