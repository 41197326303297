































import { Component, Provide, Vue, Watch } from 'vue-property-decorator'
import { DashmixBreadcrumbsProps, TableHead } from '@movecloser/ui-core'
import { IModal, ModalType, QueryParams } from '@movecloser/front-core'
import { TableRowElement } from '@movecloser/ui-core/lib/abstract/components/Table'

import { Identifier, Inject, logger } from '../../../backoffice'
import { InteractiveTable } from '../../shared/components/InteractiveTable/InteractiveTable.vue'
import { initBreadcrumbs } from '../../content/helpers'
import { HeaderInterface, Loader, ModelListHeader } from '../../shared/components'
import { Modals } from '../../shared/config/modals'

import { IMeRepository, MeRepositoryType } from '../contracts/repositories'
import { SessionTableRow } from '../components/SessionTableRow.vue'
import { sessionsTableHead } from '../maps/sessions'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component<MySessionsView>({
  name: 'MySessionsView',
  components: {
    InteractiveTable,
    ModelListHeader,
    Loader
  },
  created (): void {
    this.loadSessions()
  }
})
export class MySessionsView extends Vue {
  @Inject(MeRepositoryType)
  protected meRepository!: IMeRepository

  @Inject(ModalType)
  protected modalConnector!: IModal

  public isLoading: boolean = false
  public itemsTotal: number = 0
  public pending: boolean = false
  public tableData: TableRowElement[] = []
  public tableHead: TableHead = sessionsTableHead
  public total: number = 0
  public totalPages: number = 0
  public rowComponent = SessionTableRow

  public get breadcrumbs (): DashmixBreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t('auth.me.sessions')}`,
          target: { name: 'auth.myProfileName' }
        }
      ],
      root: initBreadcrumbs.root
    }
  }

  public get header (): HeaderInterface {
    const payload: Partial<HeaderInterface> = {
      title: `${this.$t('content.article.listTitle')}`
    }

    return payload as HeaderInterface
  }

  public get queryParams (): QueryParams {
    return this.$route.query as unknown as QueryParams
  }

  public onEndAllClick (): void {
    this.pending = true

    this.modalConnector.open(Modals.Confirm, {
      content: {
        buttonLabel: this.$t('atoms.delete'),
        contentText: this.$t('auth.me.session.endAll.text'),
        header: this.$t('auth.me.session.endAll.header'),
        theme: 'danger'
      },
      onConfirm: () => {
        this.endAllSessions()
        this.modalConnector.close()
      },
      onClose: () => {
        this.modalConnector.close()
        this.pending = false
      }
    })
  }

  @Provide()
  public endSession (sessionId: Identifier, onSuccess?: () => void): void {
    this.meRepository.deleteSession(sessionId).then(() => {
      if (typeof onSuccess === 'function') {
        onSuccess()
      }
    }).catch(e => logger(e))
  }

  private endAllSessions (): void {
    this.pending = true

    this.meRepository.deleteAllSession().catch(e => {
      logger(e)
    }).finally(() => {
      this.pending = false
    })
  }

  private loadSessions (): void {
    this.isLoading = true
    this.meRepository.loadMySessions(this.queryParams).then((collection) => {
      this.tableData = [...collection].map(model => {
        return {
          id: `${model.id}`,
          selectable: true,
          data: model
        }
      })

      this.itemsTotal = collection.meta.total
      this.totalPages = Math.ceil(collection.meta.total / collection.meta.per_page)
    }).catch(e => logger(e))
      .finally(() => {
        this.isLoading = false
      })
  }

  @Watch('queryParams', { deep: true })
  protected onPageChange (): void {
    this.loadSessions()
  }
}

export default MySessionsView
