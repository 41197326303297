// Copyright © 2021 Move Closer

import { ContentStatus, ContentVariantStatusGroups } from '../contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const variantStatusGroups: ContentVariantStatusGroups = {
  [ContentStatus.Draft]: {
    group: ContentStatus.Draft,
    includes: [ContentStatus.Draft]
  },
  [ContentStatus.InAcceptance]: {
    group: ContentStatus.InAcceptance,
    includes: [ContentStatus.InAcceptance]
  },
  [ContentStatus.Accepted]: {
    group: ContentStatus.Accepted,
    includes: [ContentStatus.Accepted]
  },
  [ContentStatus.Published]: {
    group: ContentStatus.Published,
    includes: [ContentStatus.Published]
  },
  [ContentStatus.Archived]: {
    group: ContentStatus.Archived,
    includes: [ContentStatus.Archived, ContentStatus.Rejected]
  }
}

export enum VariantLabel {
  Urgent = 'urgent',
  Live = 'live',
  Interview = 'interview',
  Comment = 'comment',
  Nonstandard = 'nonstandard'
}
