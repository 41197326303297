// Copyright © 2022 Move Closer

import { AppModule, Container, Module } from '@movecloser/front-core'

import { ContentTypeManagerType, IContentTypeManager } from '../content/shared'

import { articleContentType, articleContentTypeConfig, articlesAddons } from './config/content-types'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@AppModule({
  name: 'articles',
  boot (container: Container) {
    const manager: IContentTypeManager = container.get(ContentTypeManagerType)
    manager.registerAddons(articlesAddons)
    manager.registerType(articleContentType, articleContentTypeConfig)
  }
})
export class ArticlesModule extends Module {}
