// Copyright © 2021 Move Closer

import { Component } from 'vue-property-decorator'

import { IUserAware, UserAware } from '../../auth/shared/user-aware.abstract'

import {
  canDeleteMedia,
  canEditMedia,
  canUploadMedia,
  canViewMedia,
  canEditGallery,
  canCreatGallery,
  canDeleteGallery,
  canViewGallery
} from './guards'

export interface IMediaPermissions extends IUserAware {
  canEditMedia: boolean
  canUploadMedia: boolean
  canDeleteMedia: boolean
  canViewMedia: boolean

  canEditGallery: boolean
  canCreatGallery: boolean
  canDeleteGallery: boolean
  canViewGallery: boolean
}

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component<MediaPermissions>({})
export class MediaPermissions extends UserAware implements IMediaPermissions {
  /**
   * Media permissions
   */
  public get canUploadMedia () {
    return canUploadMedia(this.domain, this.authService.user)
  }

  public get canDeleteMedia () {
    return canDeleteMedia(this.domain, this.authService.user)
  }

  public get canEditMedia () {
    return canEditMedia(this.domain, this.authService.user)
  }

  public get canViewMedia () {
    return canViewMedia(this.domain, this.authService.user)
  }

  /**
   * Gallery permissions
   */
  public get canEditGallery () {
    return canEditGallery(this.domain, this.authService.user)
  }

  public get canCreatGallery () {
    return canCreatGallery(this.domain, this.authService.user)
  }

  public get canDeleteGallery () {
    return canDeleteGallery(this.domain, this.authService.user)
  }

  public get canViewGallery () {
    return canViewGallery(this.domain, this.authService.user)
  }
}
