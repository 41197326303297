// Copyright © 2021 Move Closer

import { MappingConfig, MappingTypes } from '@movecloser/front-core'

import { contentStructureAdapterMap } from '../../../backoffice'

import { ownerAdapterMap } from '../../root/models/owner.adapter'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const variantAdapterMap: MappingConfig = {
  ...contentStructureAdapterMap,
  addons: {
    type: MappingTypes.Function,
    value: item => {
      try {
        return Array.isArray(item.addons) ? item.addons : JSON.parse(item.addons)
      } catch (e) {
        return []
      }
    }
  },
  content: 'contentId',
  createdAt: 'createdAt',
  editor: {
    type: MappingTypes.Adapter,
    map: ownerAdapterMap,
    value: 'editor'
  },
  grid: 'grid',
  id: 'id',
  lockedAt: 'lockedAt',
  name: 'name',
  properties: 'properties',
  status: 'status',
  title: 'title',
  updatedAt: 'updatedAt',
  url: {
    type: MappingTypes.Function,
    value: item => {
      return item.status === 'draft' ? null : item.url
    }
  }
}
