




















import { Component, Provide } from 'vue-property-decorator'
import { TableHead } from '@movecloser/ui-core'
import { TableRowElement } from '@movecloser/ui-core/lib/abstract/components/Table'

import { Identifier, Inject, logger } from '../../../backoffice'
import { HeaderInterface, ModelListHeader } from '../../shared/components'

import { AbstractWidget } from '../../root/shared/AbstractWidget'

import { UserModel } from '../contracts/models'

import { IMeRepository, MeRepositoryType } from '../contracts/repositories'
import { SessionTableRow } from '../components/SessionTableRow.vue'
import { sessionsTableHead } from '../maps/sessions'

/**
 *
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component<SessionsWidget>({
  name: 'SessionsWidget',
  components: {
    ModelListHeader
  }
})
export class SessionsWidget extends AbstractWidget {
  @Inject(MeRepositoryType)
  protected meRepository!: IMeRepository

  public isLoading: boolean = false
  public perPage: number = 5
  public tableData: TableRowElement[] = []
  public tableHead: TableHead = sessionsTableHead
  public total: number = 0
  public rowComponent = SessionTableRow

  public get header (): HeaderInterface {
    const payload: Partial<HeaderInterface> = {
      title: `${this.$t('content.article.listTitle')}`
    }

    return payload as HeaderInterface
  }

  @Provide()
  public endSession (sessionId: Identifier, onSuccess?: () => void): void {
    this.meRepository.deleteSession(sessionId).then(() => {
      if (typeof onSuccess === 'function') {
        onSuccess()
      }
    }).catch(e => logger(e))
  }

  protected fetchData (): void {
    this.loadSessions()
  }

  private loadSessions (): void {
    this.isLoading = true
    this.meRepository.loadMySessions({ perPage: this.perPage }).then((collection) => {
      this.tableData = [...collection].map(model => {
        return {
          id: `${model.id}`,
          selectable: true,
          data: model
        }
      })
      this.total = collection.meta.total
    }).catch(e => logger(e))
      .finally(() => {
        this.isLoading = false
      })
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected checkPermission (domain: string, user: UserModel | null): boolean {
    return true
  }
}

export default SessionsWidget
