






















import { Component, Mixins, Prop } from 'vue-property-decorator'
import { DashmixIconName } from '@movecloser/ui-core'
import { DropdownItem } from '@movecloser/ui-core/lib/abstract/components/Dropdown'

import { Identifier } from '../../../backoffice'

import { UserData } from '../../auth/contracts/models'

import { ContentPermissions, IContentPermissions } from '../mixins'
import { ContentStatus, SaveVariantMode, VariantModel } from '../contracts'

@Component({
  name: 'VariantActions'
})
export class VariantActions extends Mixins<IContentPermissions>(ContentPermissions) {
  @Prop({ type: Object, required: true })
  protected active!: VariantModel

  @Prop({ type: Object, required: false, default: null })
  protected authUser!: UserData|null

  @Prop({ type: Boolean, required: false })
  public isDisabled!: boolean

  @Prop({ type: Boolean, required: true })
  public isGeneratingPreview!: boolean

  public icons = DashmixIconName

  public get saveOptions (): DropdownItem[] {
    const options: DropdownItem[] = []

    if (this.active.status === ContentStatus.Draft) {
      options.push(
        {
          type: 'item',
          icon: DashmixIconName.UserShieldSolid,
          label: `${this.$t('content.saveThenAccept')}`,
          onClick: () => this.saveChanges(SaveVariantMode.Acceptance),
          theme: 'default'
        }
      )
    }

    if (
      this.canPublishContents &&
      ![ContentStatus.Archived, ContentStatus.Rejected, ContentStatus.Unpublished].includes(this.active.status)
    ) {
      options.push(
        {
          type: 'item',
          icon: DashmixIconName.PaperPlaneSolid,
          label: `${this.$t(`content.${this.active.status === ContentStatus.Draft ? 'saveThenPublish' : 'publish'}`)}`,
          onClick: () => this.saveChanges(SaveVariantMode.Publication),
          theme: 'primary'
        }
      )
    }

    if (this.canPublishContents && this.active.status === ContentStatus.Published) {
      options.push(
        {
          type: 'divider'
        }, {
          type: 'item',
          icon: DashmixIconName.EyeSlashSolid,
          label: `${this.$t('content.unpublish')}`,
          onClick: this.unpublish,
          theme: 'danger'
        }
      )
    }

    return options
  }

  public generatePreview (): void {
    if (this.active.url) {
      window.open(this.active.url)
    } else {
      this.$emit('previewVariant')
    }
  }

  public isEditable (): boolean {
    return this.active.isEditable(this.authUser?.id as unknown as Identifier)
  }

  public saveChanges (action: SaveVariantMode = SaveVariantMode.None): void {
    this.$emit('saveVariant', { postSave: action })
  }

  public unpublish (): void {
    this.$emit('unpublish')
  }
}

export default VariantActions
