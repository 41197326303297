
































import { Component, Prop, Vue } from 'vue-property-decorator'
import { TableRowAction } from '@movecloser/ui-core'

import { Loader } from '../../shared/components/Loader'

import { FileModel, MediaTableRowElement } from '../contracts'
import { TilesGridItem } from './TilesGridItem.vue'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component({
  name: 'TilesGrid',
  components: { Loader, TilesGridItem }
})
export class TilesGrid extends Vue {
  @Prop({ type: Array, required: true })
  public directories!: MediaTableRowElement[]

  @Prop({ type: Array, required: true })
  public files!: MediaTableRowElement[]

  @Prop({ type: Boolean })
  public isEditable?: boolean

  @Prop({ type: Boolean, default: false })
  public isLoading!: boolean

  @Prop({ type: Boolean })
  public isMultiple?: boolean

  @Prop({ type: Boolean })
  public isSelectable?: boolean

  @Prop({ type: Map })
  public selected?: Map<string, FileModel>

  @Prop({ type: Array })
  public actions?: TableRowAction[]

  public toggle (item: FileModel, checked: boolean) {
    const files: Map<string, FileModel> = this.selected ?? new Map()

    if (this.isMultiple) {
      if (checked) {
        files.set(`${item.id}`, item)
      } else {
        files.delete(`${item.id}`)
      }
    } else {
      files.clear()
      files.set(`${item.id}`, item)
    }

    this.$emit('updateSelection', files)
  }
}

export default TilesGrid
