// Copyright © 2022 Move Closer

import { Methods, ResourcesRegistry } from '@movecloser/front-core'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (edited)
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl> (original)
 */
// TODO: Change csrf to `true` when API is deployed.
export const resources: ResourcesRegistry = {
  layouts: {
    prefix: 'api/management',
    methods: {
      delete: {
        url: 'layouts/{layoutId}',
        params: ['layoutId'],
        method: Methods.Delete,
        auth: true,
        meta: { csrf: true }
      },
      get: {
        url: 'layouts/{layoutId}',
        params: ['layoutId'],
        method: Methods.Get,
        auth: true
      },
      list: {
        url: 'layouts',
        method: Methods.Get,
        auth: true
      },
      create: {
        url: 'layouts',
        method: Methods.Post,
        auth: true,
        shorthand: 'editLayout',
        meta: { csrf: true, toast: true }
      },
      update: {
        url: 'layouts/{layoutId}',
        params: ['layoutId'],
        method: Methods.Put,
        auth: true,
        shorthand: 'editLayout',
        meta: { csrf: true, toast: true }
      }
    }
  }
}
