// Copyright © 2021 Move Closer

import { AbstractBreadcrumbsItem, DashmixBreadcrumbsProps } from '@movecloser/ui-core'

import { rootBreadcrumb } from '../../root/helpers/breadcrumbs'

import { ContentModel, ParentData } from '../contracts/models'

/**
 * Creates Link[] for breadcrumbs component.
 *
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export function createBreadcrumbsFromContent (content: ContentModel, routeName: string,
  queryKey: string, elementRouteName: string = ''): AbstractBreadcrumbsItem[] {
  const items: AbstractBreadcrumbsItem[] = [{
    label: content.title,
    target: { name: elementRouteName, params: { id: `${content.id}` } }
  }]

  let parent: ParentData|null = content.parent
  while (parent) {
    items.unshift({
      label: parent.name,
      target: { name: routeName, query: { [queryKey]: `${parent.id}` } }
    })

    parent = parent.parent
  }

  return items
}

export function createRootBreadcrumbs () {
  //
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const initBreadcrumbs: DashmixBreadcrumbsProps = {
  items: [],
  root: rootBreadcrumb,
  showOnMobile: true
}
