// Copyright © 2021 Move Closer

import { computed, ComputedRef, onUnmounted, Ref, ref, watch } from '@vue/composition-api'
import { Emit } from '@movecloser/ui-core'
import { IValidation, ValidationType } from '@movecloser/front-core'
import { Subscription } from 'rxjs'

import { resolve } from '../../../../../backoffice'

import { FormProps } from './FormField.contracts'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
export const FormFieldAbstract = {
  name: 'FormFieldAbstract',
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    form: {
      type: String,
      required: true
    },
    helper: {
      type: String,
      required: false
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    model: {
      required: true
    },
    name: {
      type: String,
      required: true
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}

export function FormFieldAbstractSetup (props: FormProps, emit: Emit) {
  const errors: Ref<Array<string>> = ref([])

  const validation: IValidation = resolve(ValidationType)
  const subscription: Subscription = validation.onErrors(props.form, props.name, (newErrors: string[]) => {
    errors.value = newErrors
  })

  const displayLabel: ComputedRef<boolean> = computed(() => Boolean(props.label))
  const hasErrors: ComputedRef<boolean> = computed(() => errors.value.length > 0)

  const value = computed({
    get: () => {
      return props.model
    },
    set: (value): void => {
      errors.value = []
      emit('update:model', value)
    }
  })

  onUnmounted(() => {
    subscription.unsubscribe()
  })

  watch(() => props.model, () => {
    errors.value = []
  })

  return {
    errors,
    displayLabel,
    hasErrors,
    value
  }
}

export default FormFieldAbstract
