
import { ModulesFormRegistryEntry } from '../../../contexts'

import { reviewsTeaserFactory } from '../ReviewsTeaser.factory'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
export const reviewsTeaserModuleEntry: ModulesFormRegistryEntry = {
  component: () => import(
    /* webpackChunkName: "form/core" */ './ReviewsTeaser.form.vue'
  ),
  factory: reviewsTeaserFactory,
  icon: require('../reviewsTeaser.svg'),
  label: 'modulesForms.ReviewsTeaser._name'
}
