































import { Authentication, AuthServiceType, EventbusType, IEventbus, IModal, IValidation, ModalType, ResourceActionFailed, ValidationType } from '@movecloser/front-core'
import { Component, Vue } from 'vue-property-decorator'
import { DashmixAlertTheme } from '@movecloser/ui-core'

import { FormInput } from '../../shared/components/form/Input/Input'
import { Inject } from '../../../backoffice'
import { Modals } from '../../shared/config/modals'

import { ISiteResolver, SiteResolverType } from '../../root/services/site-resolver'
import { SiteModel } from '../../root/contracts/models'

import { AuthRepositoryType, IAuthRepository } from '../contracts/repositories'
import { TokenModel, UserModel } from '../contracts/models'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
@Component({
  name: 'LoginModal',
  components: { FormInput }
})
export class LoginModal extends Vue {
  @Inject(AuthRepositoryType)
  protected authRepository!: IAuthRepository

  @Inject(AuthServiceType)
  protected authService!: Authentication<UserModel>

  @Inject(EventbusType)
  private eventBus!: IEventbus

  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(ValidationType)
  protected validation!: IValidation

  @Inject(SiteResolverType)
  private siteResolver!: ISiteResolver

  public alertTheme: DashmixAlertTheme = DashmixAlertTheme.Warning
  public errors: string = ''
  public hasErrors: boolean = false
  public formName: string = 'loginUser'
  public profile = {
    email: '',
    password: ''
  }

  public sending: boolean = false

  public cancel (): void {
    this.authService.deleteToken()

    this.$router.push({ name: 'auth.login' })
  }

  public loginUser (): void {
    this.sending = true

    this.authRepository.login(this.profile.email, this.profile.password)
      .then((model: TokenModel) => {
        this.authService.setUser(model.getUser())
        this.authService.setToken(model.toAuthToken())

        const userPermissions = model.getUser().sitesWithAccess()
        const activeSite = this.siteResolver.getSite()
        this.modalConnector.close()

        if (!activeSite || !activeSite.domain || !userPermissions.includes(activeSite?.domain)) {
          const newSite = userPermissions.length ? this.siteResolver.findByDomain(userPermissions[0]) : null

          if (!newSite) {
            this.authService.deleteToken()
            this.$router.push({ name: 'auth.login' })
            return
          }

          this.modalConnector.open(Modals.ForceChangeSite, {
            onConfirm: () => this.onForceChangeSite(newSite),
            newSite: newSite.domain
          })
        }
      })
      .catch((e: ResourceActionFailed) => {
        this.errors = e.payload && e.message ? e.message : ''
        this.hasErrors = true
      })
      .finally(() => {
        this.sending = false
      })
  }

  public onAlertClose (): void {
    this.hasErrors = false
  }

  private onForceChangeSite (newSite: SiteModel) {
    this.siteResolver.setSite(newSite)
    this.eventBus.emit('ui:context.changed')
  }
}

export default LoginModal
