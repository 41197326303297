// Copyright © 2021 Move Closer

import {
  ICollection,
  IResponse,
  Injectable,
  MappingConfig,
  Repository,
  ResourceActionFailed
} from '@movecloser/front-core'

import { Identifier } from '../../../backoffice'
import { Query } from '../../shared/contracts/query'
import { resolveFromStatus } from '../../shared/exceptions/connector-errors'

import { retrieveResult } from '../../root/helpers/process'

import { Directory } from '../models/directory'
import { DirectoryCreatePayload, DirectoryEditPayload, MediaMovePayload } from '../contracts'
import { DirectoryData, DirectoryModel, DirectoryNodeData, MediaData } from '../contracts/models'
import { IDirectoryRepository } from '../contracts/repositories'
import { directoryAdapterMap } from '../models/directory.adapter'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
@Injectable()
export class DirectoryRepository extends Repository<DirectoryData, Directory> implements IDirectoryRepository {
  protected map: MappingConfig = directoryAdapterMap
  protected useAdapter = false

  protected handleErrors = (response: IResponse) => {
    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response),
        {}
      )
    }
  }

  public async createDirectory (payload: DirectoryCreatePayload): Promise<Identifier> {
    const response: IResponse = await this.connector.call(
      'directory',
      'create',
      {},
      {
        parent: payload.parent ?? 1,
        name: payload.name
      }
    )

    this.handleErrors(response)

    return retrieveResult(response).id
  }

  public async deleteDirectory (id: Identifier): Promise<void> {
    const response: IResponse = await this.connector.call(
      'directory',
      'delete',
      { id: id }
    )

    this.handleErrors(response)
  }

  public async loadDirectory (id?: Identifier, query?: Query): Promise<ICollection<DirectoryModel>> {
    const response: IResponse = await this.connector.call(
      'directory',
      id === null ? 'root' : 'get',
      { id: id || '' },
      query
    )

    this.handleErrors(response)

    return this.composeCollection(
      [response.data.data],
      Directory,
      response.data.meta
    )
  }

  public async loadDirectoryTree (query?: Query): Promise<ICollection<DirectoryNodeData>> {
    const response: IResponse = await this.connector.call(
      'directory',
      'tree',
      {},
      query
    )

    this.handleErrors(response)

    return response.data.data
  }

  public async moveDirectory (id: Identifier, payload: MediaMovePayload): Promise<IResponse> {
    const response: IResponse = await this.connector.call(
      'directory',
      'move',
      { id: id },
      payload
    )

    this.handleErrors(response)

    return response
  }

  public async updateDirectory (id: Identifier, payload: DirectoryEditPayload): Promise<IResponse> {
    const response: IResponse = await this.connector.call(
      'directory',
      'update',
      { id: id },
      {
        name: payload.name
      }
    )

    this.handleErrors(response)

    return response
  }

  public async search (query: Query): Promise<ICollection<DirectoryModel>> {
    const response: IResponse = await this.connector.call(
      'directory',
      'list',
      {},
      query
    )

    this.handleErrors(response)

    return this.composeCollection(
      [{
        id: 1,
        name: 'Katalog główny',
        directories: response.data.data.filter((data: MediaData) => data.type === 'directory'),
        files: response.data.data.filter((data: MediaData) => data.type === 'file')
      }],
      Directory,
      response.data.meta
    )
  }
}
