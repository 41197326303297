












import { Component, PropSync, Prop, Vue } from 'vue-property-decorator'
import { DashmixSelectItem } from '@movecloser/ui-core'

import { IconsRegistry } from '../../../front/shared/icons/icons.contracts'
// import { iconsRegistry as baseIconsRegistry } from '../../../front/shared/icons'

import { IconSelectItem } from './partials/IconSelectItem.vue'
// import { iconsRegistry } from '../../../front/shared/icons'

/**
 * Component to select BootstrapIcon
 *
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component<IconSelect>({
  name: 'IconSelect',
  async created () {
    this.baseIconsRegistry = (await import('../../../front/shared/icons')).iconsRegistry
  }
})
export class IconSelect extends Vue {
  /**
   * @see BootstrapIcon.name
   */
  @PropSync('iconName', { type: String, required: false })
  public name?: string

  /**
   * Determines whenever icon once is chosen can be unset
   */
  @Prop({ type: Boolean, required: false, default: false })
  public canBeUnset!: string

  /**
   * Determines label size
   */
  @Prop({ type: Boolean, required: false, default: false })
  public small!: boolean

  private baseIconsRegistry: IconsRegistry | null = null

  /**
   * Empty select option - allow to unset icon
   */
  private readonly emptyOption: DashmixSelectItem = {
    label: {
      constructor: IconSelectItem,
      props: {
        label: this.$t('modulesForms._helpers.iconName.none').toString()
      }
    },
    value: ''
  }

  /**
   * Icons options for DashmixSelect
   *
   * @see iconsRegistry
   * @see DashmixSelectProps.options
   */
  public get iconsOption (): DashmixSelectItem[] {
    const iconsRegistry = this.$configuration?.byKey<IconsRegistry>('iconsRegistry', false,
      this.baseIconsRegistry) ?? {}

    const options = Object.entries(iconsRegistry).map(([key, value]) => {
      return {
        label: {
          constructor: IconSelectItem,
          props: {
            icon: value.component,
            label: value.label
          }
        },
        value: key
      } as DashmixSelectItem
    })

    if (!this.canBeUnset) {
      return options
    }

    options.push(this.emptyOption)

    return options
  }
}

export default IconSelect
