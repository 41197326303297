





























































































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { DashmixIconName, SizeMap } from '@movecloser/ui-core'
import { AnyObject, IModal, ModalType } from '@movecloser/front-core'

import { Inject, Identifier, RelatedType, Related, IRelatedService, RelatedServiceType, PossibleRelatedPicker, PossibleRelatedType } from '../../../backoffice'
import { FormCheckbox, FormInput } from '../../shared/components/form'
import { FormTypeahead } from '../../shared/components/FromTypeahead/FormTypeahead'

import { UserPayload } from '../contracts/data'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'UserForm',
  components: { FormTypeahead, FormCheckbox, FormInput }
})
export class UserForm extends Vue {
  @Prop({ type: String, required: true })
  public form!: string

  @Prop({ type: Boolean, required: false, default: false })
  public isEditMode!: boolean

  @Prop({ type: Boolean, required: false })
  public isSaving!: boolean

  @PropSync('formData', { type: Object, required: true })
  public payload!: UserPayload

  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(RelatedServiceType)
  protected relatedService!: IRelatedService

  public readonly Icons = DashmixIconName

  public changePassword: boolean = false
  public isLoading: boolean = true

  public get fullName () {
    return this.payload.firstName + this.payload.lastName
  }

  public deleteAvatar () {
    this.$emit('avatarChosen', null)
  }

  public openPicker () {
    let selected
    if (this.payload.avatar && this.payload.avatar.id) {
      selected = {
        value: this.payload.avatar.id,
        type: PossibleRelatedType.File
      }
    }
    this.modalConnector.open(PossibleRelatedPicker.File, {
      config: { type: 'photo' },
      onClose: () => this.modalConnector.close(),
      onSelection: this.setAvatar,
      selected: selected
    }, { size: SizeMap.Large })
  }

  public routeToList () {
    this.$router.push({ name: 'users.list' })
  }

  protected async setAvatar (photoData: Related<Related, Identifier>) {
    const file: AnyObject = await this.relatedService.describe({ type: PossibleRelatedType.File, value: photoData.value })
    this.$emit('avatarChosen', { id: file.id, url: file.url })
  }
}

export default UserForm
