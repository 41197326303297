// Copyright © 2021 Move Closer

import { computed, ComputedRef, PropType, ref, Ref } from '@vue/composition-api'

import { NavigationHook, NavigationItem, NavigationProps } from './Navigation.contracts'
import { ComponentObjectPropsOptions } from '@movecloser/ui-core'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const navigationProps: ComponentObjectPropsOptions<NavigationProps> = {
  items: {
    type: Array as PropType<NavigationItem[]>,
    required: true
  }
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const useNavigation = (props: NavigationProps): NavigationHook => {
  const availableItems: ComputedRef<NavigationItem[]> = computed(() => props.items)
  const openedItem: Ref<number> = ref(0)
  const openAll: Ref<boolean> = ref(false)
  const isAllOpen: ComputedRef<boolean> = computed(() => {
    return openAll.value
  })

  function clickItem (index: number) {
    // if item is opened, close it.
    if (openedItem.value === index) {
      openedItem.value = 0
    } else {
      openedItem.value = index
    }
  }

  function isOpened (index: number) {
    return index === openedItem.value
  }

  function toggleAllOpen () {
    openAll.value = !openAll.value
  }

  return {
    availableItems,
    clickItem,
    isAllOpen,
    isOpened,
    toggleAllOpen,
    openedItem
  }
}
