// Copyright © 2021 Move Closer

import { ICollection, Injectable, MappingConfig, Repository, ResourceActionFailed } from '@movecloser/front-core'

import { resolveFromStatus } from '../../shared/exceptions/connector-errors'

import { ISite, SiteData, SiteModel } from '../contracts/models'
import { ISitesRepository } from '../contracts/repositories'
import { Site } from '../models/site'
import { siteAdapterMap } from '../models/site.adapter'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Injectable()
export class SitesRepository extends Repository<SiteData, ISite> implements ISitesRepository {
  protected map: MappingConfig = siteAdapterMap
  protected useAdapter: boolean = true

  public async loadCollection (): Promise<ICollection<SiteModel>> {
    const response = await this.connector.call(
      'sites',
      'list'
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeCollection(
      response.data.data,
      Site,
      response.data.meta
    )
  }
}
