




















import { Component, Prop, Vue } from 'vue-property-decorator'
import { DashmixIconName } from '@movecloser/ui-core'
import { IModal, ModalType } from '@movecloser/front-core'

import { FormInput } from '../../shared/components/form'
import { Inject } from '../../../backoffice'

import { DirectoryCreateModalPayload } from '../contracts/components'
import { DirectoryCreatePayload, EmptyDirectoryCreatePayload } from '../contracts'
import { DirectoryForm } from './DirectoryForm.vue'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
@Component({
  name: 'DirectoryCreateModal',
  components: { FormInput, DirectoryForm }
})
export class DirectoryCreateModal extends Vue {
  @Prop({ type: Object, required: true })
  public payload!: DirectoryCreateModalPayload

  @Inject(ModalType)
  protected modalConnector!: IModal

  public icons = DashmixIconName
  public isLoading: boolean = false
  public form: DirectoryCreatePayload = { ...EmptyDirectoryCreatePayload }
  public formName: string = 'createDirectory'
  public selected: string = ''

  mounted () {
    this.form.parent = this.payload.parentDirectory.id
  }

  public close (): void {
    this.$emit('close')
  }

  public sendForm (): void {
    this.payload.createDirectory(this.form)
  }
}

export default DirectoryCreateModal
