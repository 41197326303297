


















import { Component } from 'vue-property-decorator'

import { AbstractSetItem } from './AbstractSetItem'
import { AnyObject } from '@movecloser/front-core'
import { Inject, IRelatedService, isRelated, RelatedServiceType } from '../../../../backoffice'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component<ProductsSetItem>({
  name: 'ArticlesSetItem',
  mounted () {
    if (!this.data.category || !isRelated(this.data.category)) {
      return
    }

    this.getCategoryFromRelated()
  }
})
export class ProductsSetItem extends AbstractSetItem<any> {
  @Inject(RelatedServiceType)
  protected readonly relatedService!: IRelatedService

  public category: null | AnyObject = null

  public get displayName (): string {
    return `${this.data.name} (${this.data.sku})`
  }

  public async getCategoryFromRelated (): Promise<void> {
    try {
      this.category = await this.relatedService.describe(this.data.category)
    } catch (e) {
      console.warn(e)
    }
  }
}

export default ProductsSetItem
