// Copyright © 2021 Move Closer

import { Container, RoutesFactory } from '@movecloser/front-core'

import { Permission } from '../../auth/contracts/permissions'

import EditProduct from '../views/EditProduct.vue'
import ProductsList from './../views/ProductsList.vue'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const routesFactory: RoutesFactory = (container: Container) => {
  return [
    {
      path: '/products/:id/edit',
      name: 'edit',
      component: EditProduct,
      meta: {
        permissions: [
          Permission.EditProducts
        ]
      }
    },
    {
      path: '/products',
      name: 'list',
      component: ProductsList,
      meta: {
        permissions: [
          Permission.ViewProducts
        ]
      }
    }
  ]
}
