// Copyright © 2021 Move Closer

import { Model } from '@movecloser/front-core'

import { Identifier } from '../../../backoffice'

import { RoleData } from '../contracts/models'

/**
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 */
export class Role extends Model<RoleData> {
  protected boot (): void {
    this.modelProperties = [
      'id',
      'name',
      'permissions',
      'updatedAt',
      'usersCount',
      'isLocked'
    ]
  }

  public displayName (): string {
    return this._data.name
  }

  public identifier (): Identifier {
    return this._data.id
  }
}
