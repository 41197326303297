// Copyright © 2021 Move Closer

import { Model } from '@movecloser/front-core'

import { Identifier } from '../../../backoffice'
import { GalleryData, IGallery } from '../contracts'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
export class Gallery extends Model<GalleryData> implements IGallery {
  protected boot (): void {
    this.initialValues = {}

    this.modelProperties = [
      'editor',
      'childrenCount',
      'createdAt',
      'id',
      'title',
      'description',
      'updatedAt'
    ]
  }

  public authorName (): string {
    return this._data.author.fullName()
  }

  public displayName (): string {
    return this._data.title
  }

  public getItemCount (): number {
    return this._data.properties.items.length
  }

  public identifier (): Identifier {
    return this._data.id
  }
}
