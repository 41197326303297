



























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { AnyObject } from '@movecloser/front-core'
import {
  DashmixIconName,
  TableRowAction
} from '@movecloser/ui-core'

import { Inject, IRelatedService, isRelated, Related, RelatedServiceType } from '../../../backoffice'

import { FormCheckbox, FormInput, FormSelect } from '../../shared/components/form'
import { Loader } from '../../shared/components/Loader/Loader'
import { FileData, FileThumbnail } from '../../media/contracts'

/**
 * @author Zofia Madej<zofia.madej@movecloser.pl>
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component<ProductGalleryItem>({
  name: 'ProductGalleryItem',
  components: { Loader, FormCheckbox, FormInput, FormSelect },
  mounted () {
    this.getFile()
  }
})
export class ProductGalleryItem extends Vue {
  @Prop({ type: Object, required: true })
  public image!: Related

  @Inject(RelatedServiceType)
  public readonly relatedService!: IRelatedService

  public error: boolean = false
  public file: null | FileData = null

  @Prop({ type: Array })
  public actions?: TableRowAction[]

  public Icons = DashmixIconName

  @Prop({ type: Boolean, required: false })
  public isVideo?: false

  @Watch('file', { immediate: true })
  public get thumbnail (): FileThumbnail | null {
    if (!this.file) {
      this.$emit('showButton', true)
      return null
    }
    this.$emit('showButton', false)

    const data = this.file as AnyObject
    const thumbnail = data?.url
    return {
      thumbnail: thumbnail || '',
      type: data.mime
    }
  }

  public getFile () : void {
    if (!isRelated(this.image)) {
      return
    }

    this.getFileData()
  }

  @Watch('image')
  public async getFileData () {
    try {
      if (this.image === null) {
        this.file = null
      } else {
        this.file = await this.relatedService.resolve<FileData>(this.image)
      }
    } catch (e) {
      this.error = true
      console.warn(e)
    }
  }
}

export default ProductGalleryItem
