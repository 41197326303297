





























import { FormInput } from '../../../../shared/components'
import { defineComponent, SetupContext } from '@vue/composition-api'
import { FieldsetProps } from './Fieldset.contracts'
import { fieldsetInputProps, useFieldset } from './Fieldset.hooks'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Fieldset: any = defineComponent({
  name: 'Fieldset',
  components: {
    FormInput,
    Field: () => import('../Field/Field.vue'),
    FieldsetElement: () => import('../Fieldset/Fieldset.vue')
  },
  props: fieldsetInputProps,
  setup (props: FieldsetProps, ctx: SetupContext) {
    return useFieldset(props, ctx.emit)
  }
})
export default Fieldset
