

















import { Component } from 'vue-property-decorator'
import { Hint } from '@movecloser/ui-core'

import { Identifier, Inject } from '../../../../backoffice'

import {
  DictionaryRepositoryType,
  IDictionaryRepository
} from '../../../root/contracts/repositories'
import { AbstractAddon, AddonErrors } from '../../../content/shared'

import { Addon } from '../../config/content-types'
import { AuthorAddonData } from '../../contracts'

import AuthorSelector from './AuthorSelector.vue'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component({
  name: 'AuthorAddon',
  components: { AddonErrors, AuthorSelector }
})
export class AuthorAddon extends AbstractAddon {
  @Inject(DictionaryRepositoryType)
  protected dictRepository!: IDictionaryRepository

  public value: Hint[] = []

  public onSelect (selectedHint: Hint[]) {
    this.value = selectedHint
    this.onAuthorSelected(this.value)
  }

  protected getVariantProperty (): void {
    const authorFromModel = this.variant.getProperty<AuthorAddonData>(Addon.Author)

    if (!authorFromModel) {
      this.value = []
      return
    }

    if (authorFromModel.id) {
      this.value = [
        {
          value: authorFromModel.id,
          label: authorFromModel.fullName
        }
      ]
    }
  }

  protected onAuthorSelected (value: Hint[]): void {
    if (value.length === 0) {
      this.variant.setProperty<AuthorAddonData>(Addon.Author, {} as AuthorAddonData)
      return
    }

    if (!value[0].value) {
      this.variant.setProperty<AuthorAddonData>(Addon.Author, {} as AuthorAddonData)
      return
    }

    this.variant.setProperty<AuthorAddonData>(Addon.Author, {
      id: value[0].value as unknown as Identifier,
      fullName: String(value[0].label)
    })

    this.onChange(this.variant)
  }
}

export default AuthorAddon
