// Copyright © 2021 Move Closer

import { DashmixIconName, DashmixTheme, TableHead, TableSearchBarAction } from '@movecloser/ui-core'

import { canDeleteNavigation, canEditNavigation } from '../config/guards'
import { UserModel } from '../../auth/contracts/models'

export const navigationTableHead: TableHead = [
  {
    column: 'id',
    label: 'ID',
    sortable: false,
    isSorted: false,
    width: '1fr'
  },
  {
    column: 'name',
    label: 'Nazwa',
    sortable: true,
    isSorted: false,
    width: '4fr'
  },
  {
    column: 'driver',
    label: 'Rodzaj',
    sortable: false,
    isSorted: false,
    width: '2fr'
  },
  {
    column: 'links',
    label: 'Ilość linków',
    sortable: false,
    isSorted: false,
    width: '2fr'
  }
]

export enum NavigationActions {
  Delete = 'delete',
  Edit = 'edit'
}

export const navigationRowActionsFactory = (domain: string, user: UserModel | null): TableSearchBarAction[] => {
  const actions = []

  if (canEditNavigation(domain, user)) {
    actions.push(
      {
        label: 'Edytuj',
        icon: DashmixIconName.PenSolid,
        isBulkAction: false,
        action: NavigationActions.Edit
      }
    )
  }

  if (canDeleteNavigation(domain, user)) {
    actions.push(
      {
        label: 'Usuń',
        icon: DashmixIconName.TrashAltSolid,
        isBulkAction: true,
        action: NavigationActions.Delete,
        theme: DashmixTheme.Danger
      }
    )
  }

  return actions
}
