// Copyright © 2021 Move Closer

import { AnyObject } from '@movecloser/front-core'
import { ComputedRef, Ref, WritableComputedRef } from '@vue/composition-api'
import { DashmixIconName, DashmixSelectItem } from '@movecloser/ui-core'
import { BuilderConfig, ContentStructure, ElementIdentifier } from '@movecloser/page-builder'

import {
  AnyContainer,
  AnyModule,
  ContentType,
  IRelatedService,
  ModuleConstructor,
  ModuleContentResolver,
  ModuleFactory,
  ModuleFormRestrictions,
  ModuleVersion,
  PickerCallback
} from '../../../../backoffice'
import { Container } from '../../../../contexts'

import { VariantModel } from '../../../content/contracts'

// TODO: Cleanup
type ContainerContent = any
type LayoutBreakpoint = any

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface ContainerDrawerProps {
  relatedService: IRelatedService
  source: ContainerContent
  variant: VariantModel
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export type ContainersRegistry = Record<ElementIdentifier, AnyContainer>

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface FormDrawerProps {
  relatedService: IRelatedService
  source: AnyModule
  variant: VariantModel
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface ModeSwitcherItem {
  icon: string | symbol
  label: string
  value: string
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface ModeSwitcherProps {
  controls: boolean
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export type ModuleComponentsRegistry = Record<string, ModulesRegistryEntry>

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export type ModuleFactoriesRegistry = Record<string, ModuleFactory>

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export type ModuleResolversRegistry = Record<string, ModuleContentResolver<AnyObject, AnyObject>>

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export type ModulesRegistry = Record<ElementIdentifier, AnyModule>

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export type ModulesRenderRegistry = Record<string, ModuleConstructor>

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface ModulesRegistryEntry {
  image: string
  label: string
  priority: number
  restrictions: ModuleFormRestrictions
}

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
export type ModuleSourceComponentsRegistry = Record<string, ModuleConstructor | null>

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export type ModulesVersionsRegistry = Record<string, Record<string, string>>

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface PageBuilderConfig {
  componentFactories: ModuleFactoriesRegistry
  containerComponent: ModuleConstructor
  containerForm: ModuleConstructor | null
  formComponents: ModulesRenderRegistry
  moduleRegistry: ModuleComponentsRegistry
  sourceComponents?: ModuleSourceComponentsRegistry
  uiComponents: ModulesRenderRegistry
  versionsRegistry: ModulesVersionsRegistry
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
// eslint-disable-next-line
export type PageBuilderEventCallback = (event: any) => void

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export enum PageBuilderOperationMode {
  Builder = 'builder',
  Viewer = 'viewer'
}

/**
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
export type PageBuilderOperationModeIconRegistry = Record<PageBuilderOperationMode, DashmixIconName>

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface PageBuilderProps {
  columns: number
  containers: ContainersRegistry
  contentType: ContentType
  fromParent: AnyObject
  mode: PageBuilderOperationMode
  modules: ModulesRegistry
  noPreview: boolean
  relatedService: IRelatedService
  structure: ContentStructure
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface ResolvedModuleEntry extends ModulesRegistryEntry {
  value: string
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface SelectModuleModalPayload {
  onSelection: (driver: string | null) => void
  type?: string
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface SelectModuleModalProps {
  payload: SelectModuleModalPayload
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface SnippetSelectorProps {
  container: Container
  pickRelated: PickerCallback
  relatedService: IRelatedService
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface UseContainerFormProvides extends UseModuleDependenciesProvides {
  applyChanges: () => void
  copyAnchor: (id: string) => void
  container: Ref<ContainerContent | null>
  errors: Ref<string[]>
  isContainerCreated: ComputedRef<boolean>
  isOpen: ComputedRef<boolean>
  selectedForm: Ref<ModuleConstructor | string>
  type: WritableComputedRef<string>
  typeOptions: ComputedRef<DashmixSelectItem[]>
  validating: Ref<boolean>
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface UseFormDrawerProvides extends UseModuleDependenciesProvides {
  applyChanges: () => void
  content: ComputedRef<AnyObject>
  errors: Ref<string[]>
  isOpen: ComputedRef<boolean>
  module: Ref<AnyModule | null>
  selectedForm: Ref<ModuleConstructor | string | null>
  selectedName: Ref<string>
  sourceComponentsRegistry: ModuleSourceComponentsRegistry
  version: ComputedRef<ModuleVersion | undefined>
  validating: Ref<boolean>
  versionsRegistry: Record<string, Record<string, string>>
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface UseModuleDependenciesProvides {
  pickRelated: PickerCallback
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export interface UsePageBuilderProvides {
  _containers: ComputedRef<ContainersRegistry>
  _modules: ComputedRef<ModulesRegistry>
  _structure: ComputedRef<ContentStructure>
  breakpoint: Ref<LayoutBreakpoint>
  breakpointOptions: DashmixSelectItem[]
  changeBreakpoint: () => void
  config: ComputedRef<Partial<BuilderConfig>>
  containerComponent: ComputedRef<ModuleConstructor | null>
  containerToEdit: Ref<ElementIdentifier | null>
  editContainer: (container: AnyContainer) => void
  editModule: (module: AnyModule) => void
  elementProps: ComputedRef<unknown>
  isBuilderActive: ComputedRef<boolean>
  isMobile: Ref<boolean>
  isPreviewReady: Ref<boolean>
  isSticky: Ref<boolean>
  listeners: Record<string, PageBuilderEventCallback>
  moduleToEdit: Ref<ElementIdentifier | null>
  previewContainers: Ref<ContainersRegistry>
  previewModules: Ref<ModulesRegistry>
  replicateContainer: (toReplicate: AnyContainer) => AnyContainer
  replicateModules: (toReplicate: AnyModule[]) => AnyModule[]
  showControls: Ref<boolean>
}
