// Copyright © 2021 Move Closer
import { ICollection, IResponse, QueryParams } from '@movecloser/front-core'

import { Identifier } from '../../../backoffice'
import { Query } from '../../shared/contracts/query'

import { AddGalleryItemIntention } from '../intentions/AddGalleryItemIntention'
import { ChangeItemsOrderIntention } from '../intentions/ChangeItemsOrderIntention'
import { CreateGalleryIntention } from '../intentions/CreateGalleryIntention'
import {
  DirectoryCreatePayload, DirectoryEditPayload, FileEditPayload,
  FileCreatePayload, MediaMovePayload, FileCropPayload
} from '../contracts/data'
import { DirectoryModel, DirectoryNodeData, FileModel, GalleryModel } from '../contracts/models'
import { EditGalleryIntention } from '../intentions/EditGalleryIntention'

export const DirectoryRepositoryType = Symbol.for('IDirectoryRepository')
export const FileRepositoryType = Symbol.for('IFileRepository')
export const GalleryRepositoryType = Symbol.for('IGalleryRepository')

export interface IDirectoryRepository {
  createDirectory (payload: DirectoryCreatePayload): Promise<Identifier>
  deleteDirectory (id: Identifier): Promise<void>
  loadDirectory (id?: Identifier, query?: Query): Promise<ICollection<DirectoryModel>>
  loadDirectoryTree (query?: Query): Promise<ICollection<DirectoryNodeData>>
  moveDirectory (id: Identifier, payload: MediaMovePayload): Promise<IResponse>
  search (query: Query): Promise<ICollection<DirectoryModel>>
  updateDirectory (id: Identifier, payload: DirectoryEditPayload): Promise<IResponse>
}

export interface IFileRepository {
  createFile (id: Identifier, payload: FileCreatePayload, force: boolean): Promise<IResponse>
  cropFile (id: Identifier, payload: FileCropPayload): Promise<void>
  deleteFile (id: Identifier): Promise<void>
  downloadFile (id: Identifier): Promise<any>
  loadAbandonedList (query: Query): Promise<ICollection<FileModel>>
  loadFile (id: Identifier): Promise<FileModel>
  loadGalleryItems (id: Identifier, query?: QueryParams): Promise<ICollection<FileModel>>
  moveFile (id: Identifier, payload: MediaMovePayload): Promise<IResponse>
  updateFile (id: Identifier, payload: FileEditPayload): Promise<IResponse>
}

export interface IGalleryRepository {
  addItems (id: Identifier, intentions: AddGalleryItemIntention[]): Promise<void>
  changeOrder (id: Identifier, intention: ChangeItemsOrderIntention): Promise<void>
  create (intention: CreateGalleryIntention): Promise<Identifier>
  delete (id: Identifier): Promise<void>
  load (id: Identifier): Promise<GalleryModel>
  loadCollection (query: Query): Promise<ICollection<GalleryModel>>
  removeItem (id: Identifier, itemId: Identifier): Promise<void>
  update (id: Identifier, intention: EditGalleryIntention): Promise<void>
}
