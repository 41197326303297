
































import { DashmixIconName } from '@movecloser/ui-core'
import { defineComponent, SetupContext } from '@vue/composition-api'

import { CheckTile } from '../../CheckTile'

import { selectModuleModalProps, useSelectionModuleModal } from './SelectModuleModal.hooks'
import { SelectModuleModalProps } from '../PageBuilder.contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl
 */
export const SelectModuleModal = defineComponent({
  name: 'SelectModuleModal',
  components: { CheckTile },
  props: selectModuleModalProps,

  setup (props: SelectModuleModalProps, ctx: SetupContext) {
    return { ...useSelectionModuleModal(props, ctx), iconsSet: DashmixIconName }
  }
})

export default SelectModuleModal
