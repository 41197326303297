// Copyright © 2021 Move Closer

export enum Permission {
  CreateAuthors = 'authors.create',
  DeleteAuthors = 'authors.delete',
  EditAuthors = 'authors.edit',
  ViewAuthors = 'authors.view',

  AcceptContents = 'contents.accept',
  CreateContents = 'contents.create',
  DeleteContents = 'contents.delete',
  EditContents = 'contents.edit',
  PublishContents = 'contents.publish',
  ViewContents = 'contents.view',

  ViewProducts = 'product.view',
  EditProducts = 'product.edit',

  UploadMedia = 'media.upload',
  DeleteMedia = 'media.delete',
  EditMedia = 'media.edit',
  ViewMedia = 'media.view',

  CreateUsers = 'users.create',
  EditUsers = 'users.edit',
  ViewUsers = 'users.view',
  DeleteUsers = 'users.delete',
  // ViewUsersLogs = 'logs.view',

  CreateRoles = 'roles.create',
  EditRoles = 'roles.edit',
  DeleteRoles = 'roles.delete',
  ViewRoles = 'roles.view',

  CreateNavigation = 'navigation.create',
  EditNavigation = 'navigation.edit',
  DeleteNavigation = 'navigation.delete',
  ViewNavigation = 'navigation.view',

  CreateLayout = 'layout.create',
  EditLayout = 'layout.edit',
  DeleteLayout = 'layout.delete',
  ViewLayout = 'layout.view',

  EditArticleSet = 'articleSet.edit',
  DeleteArticleSet = 'articleSet.delete',
  CreateArticleSet = 'articleSet.create',
  ViewArticleSet = 'articleSet.view',

  EditProductSet = 'productSet.edit',
  DeleteProductSet = 'productSet.delete',
  CreateProductSet = 'productSet.create',
  ViewProductSet ='productSet.view',

  EditSettings = 'setting.edit',
  DeleteSettings = 'setting.delete',
  CreateSettings = 'setting.create',
  ViewSettings ='setting.view',

  EditGallery = 'gallery.edit',
  DeleteGallery = 'gallery.delete',
  CreateGallery= 'gallery.create',
  ViewGallery ='gallery.view'
}

export interface SplitPermission {
  resource: string
  action: string
}

export const splitPermission = (permission: Permission): SplitPermission => {
  const [resource, action] = permission.split('.')
  return { resource, action }
}
