// Copyright © 2021 Move Closer

import {
  ICollection,
  Injectable,
  IResponse,
  MappingConfig,
  Repository,
  ResourceActionFailed
} from '@movecloser/front-core'

import { Identifier } from '../../../backoffice'
import { Query } from '../../shared/contracts/query'
import { resolveFromStatus } from '../../shared/exceptions/connector-errors'

import { IProductsRepository, ProductData, ProductModel } from './../contracts'
import { Product } from './../models/product'
import { productAdapterMap } from './../models/product.adapter'

/**
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 * @author Michał Rossian <michal.rossian@moveclosermichal.pl>
 */
@Injectable()
export class ProductsRepository extends Repository<ProductData, Product> implements IProductsRepository {
  protected map: MappingConfig = productAdapterMap
  protected useAdapter = true

  // TODO fix this any type when task manager is ready

  public async load (id: ProductData['id'], siteId?: Identifier): Promise<ProductModel> {
    let payload

    if (siteId) {
      payload = { siteId }
    }

    const response: IResponse = await this.connector.call(
      'products',
      'item',
      { id },
      payload
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeModel(response.data.data, Product) as unknown as ProductModel
  }

  public async loadCollection (query: Query): Promise<ICollection<ProductModel>> {
    const response: IResponse = await this.connector.call(
      'products',
      'list',
      {},
      { ...query }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeCollection(
      response.data.data,
      Product,
      response.data.meta
    )
  }

  public async update (id: ProductData['id'], payload: ProductData): Promise<void> {
    const response: IResponse = await this.connector.call(
      'products',
      'update',
      { id },
      payload
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }
  }
}
