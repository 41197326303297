// Copyright © 2021 Move Closer

import {
  IVariantStatusService,
  VariantStatusService,
  VariantStatusServiceType
} from './services/variantStatus'
import {
  ApiConnectorType,
  AppModule,
  Container,
  IConfiguration,
  IConnector, IModal,
  Interfaces, ModalType,
  Module
} from '@movecloser/front-core'

import { ISiteService, SiteServiceType } from '../../backoffice'

import { contentModals } from './config/modals'
import { contentAddons, contentTypes, editorRegistry, listingsRegistry } from './config/content-types'
import { resources } from './config/resources'
import { routesFactory } from './config/routes'

import { AcceptanceRepository } from './repositories/acceptance'
import {
  AcceptanceRepositoryType,
  ArticleFiltersRepositoryType,
  ContentRepositoryType,
  IAcceptanceRepository,
  IArticleFiltersRepository,
  IContentRepository,
  INodesRepository,
  IVariantsRepository,
  NodesRepositoryType,
  VariantsRepositoryType
} from './contracts/repositories'
import { ContentRepository } from './repositories/content'
import { ContentTypeManager, ContentTypeManagerType, IContentTypeManager } from './services/content-type-manager'
import { NodesRepository } from './repositories/nodes'
import { VariantsRepository } from './repositories/variants'
import { ArticleFiltersRepository } from './repositories/filters'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@AppModule({
  name: 'content',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  providers: (config: IConfiguration) => {
    return (bind: Interfaces.Bind) => {
      bind<IAcceptanceRepository>(AcceptanceRepositoryType).to(AcceptanceRepository)
      bind<IContentRepository>(ContentRepositoryType).to(ContentRepository)
      bind<IContentTypeManager>(ContentTypeManagerType).toDynamicValue((context: Interfaces.Context) => {
        return new ContentTypeManager(
          context.container.get<ISiteService>(SiteServiceType),
          contentTypes,
          editorRegistry,
          listingsRegistry,
          contentAddons
        )
      }).inSingletonScope()
      bind<IArticleFiltersRepository>(ArticleFiltersRepositoryType).to(ArticleFiltersRepository)
      bind<INodesRepository>(NodesRepositoryType).to(NodesRepository)
      bind<IVariantsRepository>(VariantsRepositoryType).to(VariantsRepository)
      bind<IVariantStatusService>(VariantStatusServiceType).toDynamicValue((context: Interfaces.Context) => {
        return new VariantStatusService(
          context.container.get<IVariantsRepository>(VariantsRepositoryType),
          context.container.get<IModal>(ModalType),
          process.env.VUE_APP_ENV !== 'local' ? 10000 : (1000 * 60 * 5)
        )
      })
    }
  },
  boot (container: Container) {
    const connector: IConnector = container.get(ApiConnectorType)
    connector.useResources(resources)

    const modalConnector: IModal = container.get(ModalType)
    modalConnector.register(contentModals)
  },
  routes: routesFactory
})
export class ContentModule extends Module {}
