









































import { Component, Mixins, Prop, Provide, Watch } from 'vue-property-decorator'
import { ContainersRegistry, ContentStructure, ModulesRegistry } from '@movecloser/page-builder'
import { AnyObject } from '@movecloser/front-core'

import { Identifier, IRelatedService, IS_SMART_MODULES_MODE_KEY } from '../../../backoffice'

import { PageBuilder } from '../../shared/components/PageBuilder'
import { Property } from '../../shared/components/Property'

import { UserData } from '../../auth/contracts/models'

import { ContentTypeAware, IContentTypeAware } from '../mixins'
import { ContentVariantConfig } from '../services/content-type-manager'
import { OnDirtyCallback, OnVariantChangeCallback } from '../contracts'
import { VariantModel } from '../contracts/models'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component<Variant>({
  name: 'Variant',
  components: { PageBuilder, Property },
  created () {
    this.containers = this.variant.containers
    this.modules = this.variant.modules
    this.structure = this.variant.structure
  }
})
export class Variant extends Mixins<IContentTypeAware>(ContentTypeAware) {
  @Prop({ type: Object, required: false, default: null })
  protected authUser!: UserData | null

  @Prop({ type: Object, required: false, default: null })
  public errors!: AnyObject

  @Prop({ type: Function, required: true })
  protected onDirty!: OnDirtyCallback

  @Prop({ type: Function, required: true })
  protected onVariantChange!: OnVariantChangeCallback

  @Prop({ type: Object, required: true })
  public readonly relatedService!: IRelatedService

  @Prop({ type: Object, required: true })
  public variant!: VariantModel

  public containers: ContainersRegistry = {}
  public modules: ModulesRegistry = {}
  public structure: ContentStructure = {}
  public variantErrors: AnyObject = {}

  public get editorConfig (): ContentVariantConfig {
    return this.contentTypeManager.getContentVariantConfig(this.contentType)
  }

  public get title (): string {
    return this.variant.title
  }

  public set title (value: string) {
    this.variant.set('title', value)
    this.onVariantChange(this.variant)
  }

  public get titleError (): string | null {
    return this.variantErrors && this.variantErrors.title && this.variantErrors.title.length > 0
      ? this.variantErrors.title[0] : null
  }

  public isEditable (): boolean {
    return this.variant.isEditable(this.authUser ? (this.authUser.id as Identifier) : null)
  }

  @Provide(IS_SMART_MODULES_MODE_KEY)
  public isSmartType (): boolean {
    return this.editorConfig.isSmart
  }

  @Watch('errors', { deep: true })
  public onErrorsChange (err: AnyObject): void {
    if (err && err.variant && err.variant[this.variant.id]) {
      this.variantErrors = err.variant[this.variant.id]
    } else {
      this.variantErrors = {}
    }
  }

  public onContainersChange (changed: ContainersRegistry): void {
    this.containers = changed

    this.variant.set('containers', this.containers)
    this.onVariantChange(this.variant)
  }

  public onModulesChange (changed: ModulesRegistry): void {
    this.modules = changed

    this.variant.set('modules', this.modules)
    this.onVariantChange(this.variant)
  }

  public onStructureChange (changed: ContentStructure): void {
    this.structure = changed

    this.variant.set('structure', this.structure)
    this.onVariantChange(this.variant)
  }
}

export default Variant
