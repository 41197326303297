// Copyright © 2021 Move Closer

import { ModalRegistry } from '@movecloser/front-core'
import { VueConstructor } from 'vue'

import { PossibleRelatedPicker } from '../../../backoffice'

import AddSetItemModal from '../components/AddSetItemModal.vue'
import ResetListItemsModal from '../components/ResetListItemsModal.vue'
import ArticlesPickerModal from '../components/SetPicker/ArticlesPickerModal'
import ProductsPickerModal from '../components/SetPicker/ProductsPickerModal'

export enum SetModal {
  AddSetItem = 'addSetItem',
  ResetListItemsModal = 'resetListItemsModal'
}

/**
 * Registry of available modals.
 */
export const setModals: ModalRegistry<VueConstructor> = {
  [SetModal.AddSetItem]: AddSetItemModal,
  [SetModal.ResetListItemsModal]: ResetListItemsModal,

  [PossibleRelatedPicker.Articles]: ArticlesPickerModal,
  [PossibleRelatedPicker.Products]: ProductsPickerModal
}
