


















































































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

import { AnyModule, ModuleMode, ModuleSourceOptions } from '../../../contexts'
import { ModuleSourceComponentsRegistry } from '../../../backoffice/shared/components'

import { ContentAlignment, VersionSelector } from './partials'
import { ModuleVersions, nameOfModule } from './ModuleSettings.contracts'
import { ModeSwitch } from './partials/ModeSwitch'

/**
 * Component capable to render module's form along with its:
 * version, title, visibility and alignment
 *
 * Component to be used only in `FormDrawer`
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component({
  name: 'ModuleSettings',
  components: { ContentAlignment, ModeSwitch, VersionSelector }
})
export class ModuleSettings extends Vue {
  @PropSync('module', { type: Object, required: true })
  public _module!: AnyModule

  /**
   * Whether module can be switched to dynamic mode
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly allowModeSwitch?: boolean

  /**
   * List of errors to render
   */
  @Prop({ type: Array, required: false })
  public readonly errors?: string[]

  @Prop({ type: Object, required: true })
  public readonly moduleSourceRegistry!: ModuleSourceComponentsRegistry

  /**
   * Registry of versions of any module
   */
  @Prop({ type: Object, required: true })
  public readonly moduleVersionsRegistry!: ModuleVersions<string>

  public moduleModes = [ModuleMode.Default, ModuleMode.Dynamic]

  /**
   * Check whether module has errors to render
   */
  public get hasErrors (): boolean {
    return typeof this.errors !== 'undefined' && Array.isArray(this.errors) && this.errors.length > 0
  }

  /**
   * Check whether module should have version
   */
  public get hasVersion (): boolean {
    return typeof this.moduleVersionsRegistry[this._module.driver] !== 'undefined'
  }

  /**
   * Retrieves component to define source
   */
  public get sourceComponent () {
    return this.moduleSourceRegistry[this._module.driver]
  }

  /**
   * Check whether module is switched to dynamic
   */
  public get isDynamic (): boolean {
    return this.mode === 'dynamic'
  }

  /**
   * Gets appropriate name module based on driver
   */
  public get moduleName (): string {
    return nameOfModule(this._module.driver)
  }

  public get mode (): ModuleMode {
    return this._module.source?.type ?? ModuleMode.Default
  }

  public set mode (value: ModuleMode) {
    this._module = {
      ...this._module,
      source: {
        ...(this._module.source ?? {}),
        type: value
      }
    }
  }

  public get sourceOptions (): ModuleSourceOptions | null {
    return this._module.source?.options ?? null
  }

  public set sourceOptions (value: ModuleSourceOptions | null) {
    this._module = {
      ...this._module,
      source: {
        type: ModuleMode.Dynamic,
        ...(this._module.source ?? {}),
        options: value ?? undefined
      }
    }
  }

  public get version (): string {
    return this._module.version ?? ''
  }

  public set version (value: string) {
    this._module = {
      ...this._module,
      version: value
    }
  }
}

export default ModuleSettings
