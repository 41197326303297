// Copyright © 2021 Move Closer

import { ModalRegistry } from '@movecloser/front-core'
import { VueConstructor } from 'vue'

export enum UsersModal {
}

export const usersModal: ModalRegistry<VueConstructor> = {
}
