














import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DashmixSelectItem, Hint } from '@movecloser/ui-core'

import { Identifier, Inject, logger } from '../../../../backoffice'

import { DictionaryRepositoryType, IDictionaryRepository } from '../../../root/contracts/repositories'

import { Addon } from '../../config/content-types'
import { ContentType, OnVariantChangeCallback, VariantModel } from '../../contracts'
import {
  ContentTypeManagerType,
  IContentTypeManager
} from '../../services/content-type-manager'
import { mockedDirtyCallback, mockedVariantChangeCallback } from '../../helpers/components'

type LayoutAddonData = {
  [key: string]: { id: Identifier; name: string }
}

type LayoutHintsMap = { [key: string]: Hint[] }

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<LayoutAddon>({
  name: 'LayoutAddon',
  mounted () {
    this.getVariantProperty()
    this.getLayoutsDictionary()
  }
})
export class LayoutAddon extends Vue {
  @Prop({ type: Boolean, required: false, default: true })
  public disabled!: boolean

  @Prop({ type: Function, required: false, default: mockedDirtyCallback })
  protected onChange!: OnVariantChangeCallback

  @Prop({ type: Object, required: false, default: mockedVariantChangeCallback })
  protected variant!: VariantModel

  @Inject(ContentTypeManagerType)
  public readonly contentTypeManager!: IContentTypeManager

  @Inject(DictionaryRepositoryType)
  protected dictRepository!: IDictionaryRepository

  public ContentType = ContentType

  public layoutsDictionary: DashmixSelectItem[] | [] = []
  public loading: boolean = false
  public searchParam: string = ''
  public value: LayoutHintsMap = {}

  public get allowedTypes (): string[] {
    return this.contentTypeManager.allowed()
  }

  public onClear () {
    this.searchParam = ''
  }

  public onDelete (type: ContentType) {
    delete this.value[type]
    this.value = { ...this.value }
    this.onLayoutSelected(type, this.value)
  }

  public onSearch (value: string) {
    this.searchParam = value
  }

  public onSelect (type: ContentType, selectedHint: Hint) {
    this.value = { ...this.value, [type]: [selectedHint] }
    this.onLayoutSelected(type, this.value)
  }

  protected async getLayoutsDictionary (searchParams?: string): Promise<void> {
    this.loading = true

    try {
      const dict = await this.dictRepository.loadLayoutsDictionary({ q: searchParams || '' })

      this.layoutsDictionary = [...dict].map(model => {
        return {
          value: model.id,
          label: model.fullName
        }
      })
    } catch (e) {
      logger(e, 'warn')
    }

    this.loading = false
  }

  protected getVariantProperty (): void {
    const layoutFromModel = this.variant.getProperty<LayoutAddonData>(Addon.Layout)

    if (!layoutFromModel) {
      this.value = {}
      return
    }

    for (const t of this.allowedTypes) {
      const layoutForType: { id: Identifier; name: string } | undefined = layoutFromModel[t]

      this.value[t] = []
      if (layoutForType) {
        this.value[t].push({
          value: layoutForType.id,
          label: layoutForType.name
        })
      }
    }
  }

  protected onLayoutSelected (type: ContentType, value: LayoutHintsMap): void {
    const addonPayload: LayoutAddonData = {} as LayoutAddonData

    for (const t in value) {
      if (value.hasOwnProperty(t)) {
        const hintForType: Hint[] | undefined = value[t]

        if (hintForType && hintForType[0]) {
          addonPayload[t] = {
            id: hintForType[0].value as unknown as Identifier,
            name: hintForType[0].label
          }
        }
      }
    }

    this.variant.setProperty<LayoutAddonData>(Addon.Layout, addonPayload)

    this.onChange(this.variant)
  }

  @Watch('searchParam', { deep: false })
  protected onSearchParamsChange (searchParams: string): void {
    this.getLayoutsDictionary(searchParams)
  }

  @Watch('variant', { deep: true })
  protected onVariantChange (newVariant: VariantModel, oldVariant: VariantModel) {
    if (JSON.stringify(newVariant.toObject()) !== JSON.stringify(oldVariant.toObject())) {
      this.getVariantProperty()
    }
  }
}

export default LayoutAddon
