// Copyright © 2021 Move Closer

export interface IOption {
  key: string
  value: string
}

export enum InputType {
  button = 'button',
  Checkbox = 'checkbox',
  color = 'color',
  date = 'date',
  datetimelocal = 'datetime-local',
  email = 'email',
  file = 'file',
  Fieldset = 'fieldset',
  hidden = 'hidden',
  image = 'image',
  month = 'month',
  Number = 'number',
  password = 'password',
  radio = 'radio',
  range = 'range',
  reset = 'reset',
  search = 'search',
  submit = 'submit',
  tel = 'tel',
  Text = 'text',
  TextArea = 'TextArea',
  Json = 'json',
  SearchPreview = 'SearchPreview',
  time = 'time',
  url = 'url',
  week = 'week',
  H5 = 'h5'
}

export type IFields = IField | IFieldset | IElement

export interface IFieldProps extends IElement {
  model: string | object
}

export interface IField extends IElement {
  id?: string
  options?: IOption[]
  placeholder?: string
  name: string
  value?: string | number
  default?: string | number
}

export interface IElement extends Object {
  class?: string
  label?: string
  type: InputType
}

export interface IFieldset extends IElement {
  name: string
  fields: IFields [] | IFields
  type: InputType.Fieldset
}

export interface IForm extends IFieldset {
  header?: string
  submitLabel: string
}
