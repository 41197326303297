// Copyright © 2021 Move Closer

import { Component, Prop, Vue } from 'vue-property-decorator'

import { PickerPayload } from '../../../content/contracts'

import { PossibleRelatedType } from '../../../../backoffice'

import { SetModel } from '../../contracts'
import AbstractSetPickerModal from './AbstractSetPickerModal.vue'

@Component({
  name: 'ProductsPickerModal',
  extends: AbstractSetPickerModal
})
export class ProductsPickerModal extends Vue {
  @Prop({ type: Object, required: true })
  public payload!: PickerPayload<SetModel>

  public setType = PossibleRelatedType.Products
}

export default ProductsPickerModal
