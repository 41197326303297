







































import { Component, Mixins, Prop } from 'vue-property-decorator'
import { DateTimeType, EventbusType, IDateTime, IEventbus } from '@movecloser/front-core'
import { DashmixButtonVariantMap, DashmixIconName, DashmixTheme } from '@movecloser/ui-core'
import { Fragment } from 'vue-fragment'

import { Inject } from '../../../backoffice'
import { Popover } from '../../shared/components/Popovers/Popover'

import { IUsersPermissions, UsersPermissions } from '../config/permissions.mixin'
import { RolesPopover } from '../components/RolesPopover.vue'
import { UserModel } from '../contracts/models'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'UsersTableRow',
  components: { Fragment, Popover, RolesPopover }
})
export class UsersTableRow extends Mixins<IUsersPermissions>(UsersPermissions) {
  @Prop({ type: Object, required: true })
  public data!: UserModel

  @Inject(DateTimeType)
  protected dateTime!: IDateTime

  @Inject(EventbusType)
  protected eventBus!: IEventbus

  public readonly icons = DashmixIconName
  public readonly themes = DashmixTheme
  public readonly buttonVariant = DashmixButtonVariantMap

  public getStatusClass (): string {
    if (this.data.isBlocked) {
      return 'bg-danger'
    }

    return this.data.isActive ? 'bg-success' : 'bg-warning'
  }
}

export default UsersTableRow

