
















import { Component, Prop, Vue } from 'vue-property-decorator'
import { IModal, ModalType } from '@movecloser/front-core'

import { Inject } from '../../../backoffice'

import { ForceSiteChangePayload } from '../contracts/components'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'ForceSiteChangeModal'
})
export class ForceSiteChangeModal extends Vue {
  @Inject(ModalType)
  protected modalConnector!: IModal

  @Prop({ type: Object, required: true })
  public payload!: ForceSiteChangePayload

  public onConfirm () {
    this.payload.onConfirm()
    this.modalConnector.close()
  }
}

export default ForceSiteChangeModal
