// Copyright © 2021 Move Closer

import { ICollection } from '@movecloser/front-core'

import { Identifier } from '../../../backoffice'
import { Query } from '../../shared/contracts/query'

import { ProductData, ProductFilterModel, ProductModel } from './models'

export interface IProductFiltersRepository {
  list (): Promise<ICollection<ProductFilterModel>>
}

export interface IProductsRepository {
  load (id: ProductData['id'], siteId?: Identifier): Promise<ProductModel>
  loadCollection (query: Query, siteId?: Identifier): Promise<ICollection<ProductModel>>
  update (setId: ProductData['id'], payload: ProductData): Promise<void>
}

export const ProductFiltersRepositoryType = Symbol.for('IProductFiltersRepository')
export const ProductsRepositoryType = Symbol.for('IProductsRepository')
