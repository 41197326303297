
















import { Component, Prop, Vue } from 'vue-property-decorator'

import { CheckTile } from '../../shared/components/CheckTile'

import { SiteData, SiteLogoVariant } from '../contracts/models'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component({
  name: 'SiteSelectItem',
  components: { CheckTile }
})
export class SiteSelectItem extends Vue {
  @Prop({ type: String, required: true })
  protected selected!: string

  @Prop({ type: Object, required: true })
  public site!: SiteData

  public get elementId (): string {
    return `site-${this.site.domain}`
  }

  public get isChecked (): string {
    return this.selected
  }

  public set isChecked (value: string) {
    this.$emit('update:selected', value)
  }

  public get siteLogo (): string | null {
    return this.site.logo[SiteLogoVariant.Light] || null
  }
}

export default SiteSelectItem
