








import { Component, Mixins, Prop } from 'vue-property-decorator'
import { DashmixAccordionItem } from '@movecloser/ui-core'

import { Identifier, IRelatedService } from '../../../backoffice'

import { UserData } from '../../auth/contracts/models'

import { ContentTypeAware, IContentTypeAware } from '../mixins'
import { OnVariantChangeCallback, VariantErrors, VariantModel } from '../contracts'

import { AddonLabel } from './addons/AddonLabel.vue'

@Component({
  name: 'VariantAddons'
})
export class VariantAddons extends Mixins<IContentTypeAware>(ContentTypeAware) {
  @Prop({ type: Object, required: true })
  protected active!: VariantModel

  @Prop({ type: Object, required: false, default: null })
  protected authUser!: UserData | null

  @Prop({ type: Boolean, required: false })
  public isDisabled!: boolean

  @Prop({ type: Object, required: false })
  public errors!: VariantErrors

  @Prop({ type: Function, required: true })
  protected onChange!: OnVariantChangeCallback

  @Prop({ type: Object, required: true })
  public readonly relatedService!: IRelatedService

  public get accordionItems (): DashmixAccordionItem[] {
    return this.contentTypeManager.getAddons(this.active.addons)
      .map<DashmixAccordionItem>(i => {
        return {
          label: {
            component: AddonLabel,
            props: {
              label: this.$t(i.label).toString(),
              hasErrors: this.checkForErrors(i.name)
            }
          },
          id: i.name,
          component: i.component,
          props: {
            disabled: !this.isEditable,
            errors: this.getErrors(i.name),
            formName: `addons-${i.name}`,
            onChange: this.onChange,
            relatedService: this.relatedService,
            variant: this.active
          }
        }
      })
  }

  public get isEditable (): boolean {
    return this.active.isEditable(this.authUser?.id as unknown as Identifier)
  }

  protected checkForErrors (addonName: string): boolean {
    if (!this.errors) {
      return false
    }

    for (const errorKey of Object.keys(this.errors)) {
      if (errorKey.startsWith(`properties.${addonName}.`)) {
        return true
      }
    }

    return false
  }

  protected getErrors (addonName: string) {
    if (!this.errors) {
      return
    }

    const addonError: string[] = []

    for (const errorKey of Object.keys(this.errors)) {
      if (errorKey.startsWith(`properties.${addonName}.`)) {
        addonError.push(...this.errors[errorKey])
      }
    }

    return addonError
  }
}

export default VariantAddons
