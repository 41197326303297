// Copyright © 2021 Move Closer

import { RoutesFactory } from '@movecloser/front-core'

import DashboardView from '../views/DashboardView.vue'
import InitView from '../views/InitView.vue'
import NotFoundView from '../views/NotFoundView.vue'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const routesFactory: RoutesFactory = () => {
  return [
    {
      path: '/',
      name: 'init',
      component: InitView,
      meta: {
        noSiteId: true
      },
      redirect: {
        name: 'root.dashboard'
      }
    },
    {
      path: '/',
      name: 'dashboard',
      component: DashboardView
    },
    {
      path: '*',
      name: 'not-found',
      component: NotFoundView,
      meta: {
        noSiteId: true
      }
    }
  ]
}
