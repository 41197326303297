// Copyright © 2022 Move Closer

import { Methods, ResourcesRegistry } from '@movecloser/front-core'

// TODO: Change csrf to `true` when API is deployed.
export const resources: ResourcesRegistry = {
  auth: {
    prefix: 'api',
    methods: {
      account: {
        url: 'access/account',
        method: Methods.Post,
        shorthand: 'registerUser',
        meta: { csrf: true, extendsSession: false }
      },
      forgotPassword: {
        url: 'access/password/send',
        method: Methods.Post,
        shorthand: 'forgotPasswordForm',
        meta: { extendsSession: false, omitXsite: true }
      },
      resetPassword: {
        url: 'access/password/reset',
        method: Methods.Post,
        shorthand: 'resetPasswordForm',
        meta: { extendsSession: false, omitXsite: true }
      },
      authenticate: {
        url: 'access/oauth',
        method: Methods.Post
      },
      redirect: {
        url: 'access/redirect',
        method: Methods.Post
      },
      refresh: {
        url: 'access/token/refresh',
        method: Methods.Post,
        auth: true,
        shorthand: 'refreshToken',
        meta: { extendsSession: false }
      },
      removeToken: {
        url: 'access/token',
        method: Methods.Delete,
        auth: true,
        shorthand: 'logoutUser',
        meta: { extendsSession: false }
      },
      token: {
        url: 'access/token',
        method: Methods.Post,
        shorthand: 'loginUser',
        meta: { extendsSession: false }
      },
      me: {
        url: 'me',
        method: Methods.Get
      }
    }
  },
  me: {
    prefix: 'api',
    methods: {
      editBasicInformation: {
        url: 'me/profile',
        method: Methods.Put,
        auth: true,
        meta: { csrf: true, toast: true }
      },
      editPassword: {
        url: 'me/password',
        method: Methods.Put,
        auth: true,
        meta: { csrf: true, toast: true }
      },
      updateAvatar: {
        url: 'me/avatar',
        method: Methods.Post,
        auth: true,
        meta: { csrf: true, toast: true }
      },
      load: {
        url: 'me',
        method: Methods.Get,
        auth: true
      },
      sessions: {
        url: 'management/me/sessions',
        method: Methods.Get,
        auth: true,
        meta: { csrf: true, toast: false }
      },
      deleteSession: {
        url: 'management/me/sessions/{id}',
        params: ['id'],
        method: Methods.Delete,
        auth: true,
        meta: { csrf: true, toast: true }
      }
    }
  }
}
