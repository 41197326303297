// Copyright © 2021 Move Closer

import { Component } from 'vue-property-decorator'

import { IUserAware, UserAware } from '../../auth/shared/user-aware.abstract'

import {
  canCreateRoles,
  canDeleteRoles,
  canEditRoles,
  canEditUsers,
  canViewRoles,
  canViewUsers,
  canCreateAuthors,
  canCreateUsers,
  canDeleteUsers,
  canEditAuthors,
  canDeleteAuthors,
  canViewAuthors
} from './guards'

export interface IUsersPermissions extends IUserAware {
  canEditUsers: boolean
  canViewUsers: boolean
  canCreateUsers: boolean
  canDeleteUsers: boolean
  canCreateRoles: boolean
  canEditRoles: boolean
  canDeleteRoles: boolean
  canViewRoles: boolean
  // canCreateAuthors: boolean
  // canDeleteAuthors: boolean
  // canEditAuthors: boolean
  // canViewAuthors: boolean
}

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component<UsersPermissions>({})
export class UsersPermissions extends UserAware implements IUsersPermissions {
  /**
   * Users permissions
   */
  public get canEditUsers () {
    return canEditUsers(this.domain, this.authService.user)
  }

  public get canViewUsers () {
    return canViewUsers(this.domain, this.authService.user)
  }

  public get canCreateUsers () {
    return canCreateUsers(this.domain, this.authService.user)
  }

  public get canDeleteUsers () {
    return canDeleteUsers(this.domain, this.authService.user)
  }

  /**
   * Roles permissions
   */
  public get canCreateRoles () {
    return canCreateRoles(this.domain, this.authService.user)
  }

  public get canEditRoles () {
    return canEditRoles(this.domain, this.authService.user)
  }

  public get canDeleteRoles () {
    return canDeleteRoles(this.domain, this.authService.user)
  }

  public get canViewRoles () {
    return canViewRoles(this.domain, this.authService.user)
  }

  /**
   * Authors permissions
   */
  public get canCreateAuthors () {
    return canCreateAuthors(this.domain, this.authService.user)
  }

  public get canDeleteAuthors () {
    return canDeleteAuthors(this.domain, this.authService.user)
  }

  public get canEditAuthors () {
    return canEditAuthors(this.domain, this.authService.user)
  }

  public get canViewAuthors () {
    return canViewAuthors(this.domain, this.authService.user)
  }
}
