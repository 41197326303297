













import { Component, Prop, Vue } from 'vue-property-decorator'

/**
 * Generic form component that helps in creating the `<fieldset>` element.
 *
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component<FormFieldset>({ name: 'FormFieldset' })
export class FormFieldset extends Vue {
  /**
   * Text to render inside the `<legend>` element.
   */
  @Prop({ type: String, required: false })
  public readonly legend?: string

  /**
   * Determines whether the component has been provided with the correct `legend` @Prop.
   */
  public get hasLegend (): boolean {
    return typeof this.legend === 'string' && this.legend.length > 0
  }
}

export default FormFieldset
