// Copyright © 2021 Move Closer

import {
  ICollection,
  Injectable,
  IResponse,
  MappingConfig,
  Repository,
  ResourceActionFailed
} from '@movecloser/front-core'

import { resolveFromStatus } from '../../shared/exceptions/connector-errors'
import { Query } from '../../shared/contracts/query'

import { Acceptance } from '../models/acceptance'
import { acceptanceAdapterMap } from '../models/acceptance.adapter'
import { AcceptanceData, AcceptanceModel, IAcceptance, IAcceptanceRepository } from '../contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
@Injectable()
export class AcceptanceRepository extends Repository<AcceptanceData, IAcceptance> implements IAcceptanceRepository {
  protected map: MappingConfig = acceptanceAdapterMap
  protected useAdapter = true

  public async loadCollection (types: string| string[], query: Query = {}): Promise<ICollection<AcceptanceModel>> {
    const response: IResponse = await this.connector.call(
      'contentAcceptance',
      'list',
      {},
      {
        type: Array.isArray(types) ? types.join(',or:') : types,
        ...query
      }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response),
        response.data.data
      )
    }

    return this.composeCollection(
      response.data.data,
      Acceptance,
      response.data.meta
    )
  }

  public async load (id: AcceptanceData['id']): Promise<AcceptanceModel> {
    const response: IResponse = await this.connector.call('contentAcceptance', 'get', { id })

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response),
        response.data.data
      )
    }

    return this.composeModel(
      response.data.data,
      Acceptance
    )
  }

  public async accept (id: AcceptanceData['id']): Promise<void> {
    return this.toggleAcceptance(id, 'accept')
  }

  public async reject (id: AcceptanceData['id'], message: string): Promise<void> {
    return this.toggleAcceptance(id, 'reject', message)
  }

  public async return (id: AcceptanceData['id'], message: string): Promise<void> {
    return this.toggleAcceptance(id, 'return', message)
  }

  protected async toggleAcceptance (id: AcceptanceData['id'], action: string, note: string = 'Ok'): Promise<void> {
    const response: IResponse = await this.connector.call(
      'contentAcceptance',
      'accept',
      { id },
      { action, note }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response),
        response.data.data
      )
    }
  }
}
