// Copyright © 2021 Move Closer

import {
  ApiConnectorType,
  AppModule,
  Container,
  IConfiguration,
  IConnector, IModal,
  Interfaces, ModalType,
  Module
} from '@movecloser/front-core'

import {
  ISetItemsRepository,
  ISetsRepository,
  SetItemsRepositoryType,
  SetsRepositoryType
} from './contracts/repositories'

import { resources } from './config/resources'
import { routesFactory } from './config/routes'
import { setModals } from './config/modals'
import { SetItemsRepository } from './repositories/setItems'
import { SetsRepository } from './repositories/sets'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@AppModule({
  name: 'sets',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  providers: (config: IConfiguration) => {
    return (bind: Interfaces.Bind) => {
      bind<ISetItemsRepository>(SetItemsRepositoryType).to(SetItemsRepository)
      bind<ISetsRepository>(SetsRepositoryType).to(SetsRepository)
    }
  },
  boot (container: Container) {
    const connector: IConnector = container.get(ApiConnectorType)
    connector.useResources(resources)

    const modalConnector: IModal = container.get(ModalType)
    modalConnector.register(setModals)
  },
  routes: routesFactory
})
export class SetsModule extends Module {}
