// Copyright © 2021 Move Closer

import { ComponentObjectPropsOptions } from '@movecloser/ui-core'
import { onBeforeMount, onMounted, PropType, SetupContext } from '@vue/composition-api'

import { BreakpointsInterface, getBreakpoints } from '../../../support/breakpoints'

import { Item, Node } from '../TreeViewer.contracts'
import { ItemsListProps } from './ItemsList.contracts'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const itemsListProps: ComponentObjectPropsOptions<ItemsListProps> = {
  /**
   * List of items
   */
  items: {
    type: Array as PropType<Node[]>,
    required: true
  },

  /**
   * Tree depth level
   */
  treeLevel: {
    type: Number,
    required: true
  },

  /**
   * Checking is last to add right border
   */
  isLast: {
    type: Boolean,
    required: false,
    default: false
  },

  /**
   * Selected item
   */
  selectedItem: {
    type: Object as PropType<Item>,
    required: false,
    default: () => {
      return {}
    }
  }
}

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 * @param props
 * @param ctx
 */
export const useItemsList = (props: ItemsListProps, ctx: SetupContext) => {
  const { emit } = ctx
  const screenWidth = window.innerWidth
  let breakpoints: BreakpointsInterface = {}

  const click = (item: Node) => {
    emit('select', item, props.treeLevel)
  }

  const getCSSClass = () => {
    if (screenWidth < breakpoints.Sm && props.isLast) {
      return 'no-border'
    }
    if (screenWidth < breakpoints.Lg && props.treeLevel >= 1 && props.isLast) {
      return 'no-border'
    }
    if (screenWidth > breakpoints.Lg && props.isLast && props.treeLevel >= 2) {
      return 'no-border'
    }
    return ''
  }

  const setInitialScrollPosition = () => {
    // Check that selectedItem exist
    if (props.selectedItem && props.selectedItem.value) {
      // Get DOM elements of selectedItem and its parent Element
      const selectedItemHTMLElement = document.getElementById(props.selectedItem.value.toString())
      const parentHTMLElement = selectedItemHTMLElement?.parentElement

      // Check that DOM elements exist
      if (selectedItemHTMLElement && parentHTMLElement) {
        // Saving bottom position of selecting element to variable
        const selectedItemBottomPosition = selectedItemHTMLElement.offsetTop - parentHTMLElement.offsetTop +
          selectedItemHTMLElement.clientHeight

        // Checking that bottom position of selecting element is grater then parent element haight.
        // If it is its mean the element is not show, so needs to scroll
        if (selectedItemBottomPosition > parentHTMLElement.clientHeight) {
          parentHTMLElement.scroll({
            top: selectedItemBottomPosition,
            left: 0,
            behavior: 'smooth'
          })
        }
      }
    }
  }

  onBeforeMount(() => {
    breakpoints = getBreakpoints()
  })

  onMounted(setInitialScrollPosition)

  return {
    click,
    getCSSClass
  }
}
