// Copyright © 2023 Move Closer

export const configuration = {
  structure: {},
  options: {
    // Warning, property name must be the same as component name
    // ContainerForm: {
    //   backgroundVariants: ['default', 'small', 'cropped'],
    //   backgroundColorPalette: {
    //     white: '#fff',
    //     gray: '#F5F5F5'
    //   }
    // },
    // TextModuleForm: {
    //   allowedColors: ['primary', 'gray']
    // }
  }
}
