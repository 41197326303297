// Copyright © 2022 Move Closer

import { Methods, ResourcesRegistry } from '@movecloser/front-core'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
export const resources: ResourcesRegistry = {
  directory: {
    prefix: 'api/management/storage',
    methods: {
      create: {
        url: 'directories',
        method: Methods.Post,
        auth: true,
        meta: { csrf: true, toast: true }
      },
      delete: {
        url: 'directories/{id}',
        method: Methods.Delete,
        params: ['id'],
        auth: true,
        meta: { csrf: true, toast: true }
      },
      update: {
        url: 'directories/{id}',
        method: Methods.Put,
        params: ['id'],
        auth: true,
        meta: { csrf: true, toast: true }
      },
      get: {
        url: 'directories/{id}',
        method: Methods.Get,
        params: ['id'],
        auth: true
      },
      list: {
        url: 'files',
        method: Methods.Get,
        params: [],
        auth: true
      },
      root: {
        url: 'directories',
        method: Methods.Get,
        params: [],
        auth: true
      },
      tree: {
        url: 'directories/nodes',
        method: Methods.Get,
        auth: true
      },
      upload: {
        url: 'directories/{id}/files',
        method: Methods.Post,
        params: ['id'],
        auth: true,
        meta: { csrf: true }
      },
      move: {
        url: 'directories/{id}/move',
        method: Methods.Put,
        params: ['id'],
        auth: true,
        meta: { csrf: true, toast: true }
      }
    }
  },
  file: {
    prefix: 'api/management/storage',
    methods: {
      create: {
        url: 'files/save/{id}',
        method: Methods.Post,
        params: ['id'],
        auth: true,
        meta: { csrf: true, toast: true }
      },
      delete: {
        url: 'files/{id}',
        method: Methods.Delete,
        params: ['id'],
        auth: true,
        meta: { csrf: true, toast: true }
      },
      forceCreate: {
        url: 'files/{id}/duplicate',
        method: Methods.Post,
        params: ['id'],
        auth: true,
        meta: { csrf: true }
      },
      get: {
        url: 'files/{id}',
        method: Methods.Get,
        params: ['id'],
        auth: true
      },
      list: {
        url: 'files/',
        method: Methods.Get,
        auth: true
      },
      modify: {
        url: 'files/{id}/modify',
        method: Methods.Put,
        params: ['id'],
        auth: true,
        meta: { csrf: true, toast: true }
      },
      move: {
        url: 'files/{id}/move',
        method: Methods.Put,
        params: ['id'],
        auth: true,
        meta: { csrf: true }
      },
      update: {
        url: 'files/{id}',
        method: Methods.Put,
        params: ['id'],
        auth: true,
        meta: { csrf: true, toast: true }
      }
    }
  },
  gallery: {
    prefix: 'api/management',
    methods: {
      addItems: {
        url: 'sets/{id}/items/bulk',
        method: Methods.Post,
        params: ['id'],
        auth: true,
        meta: { csrf: true, toast: true }
      },
      create: {
        url: 'sets',
        method: Methods.Post,
        auth: true,
        meta: { csrf: true, toast: true }
      },
      delete: {
        url: 'sets/{id}',
        method: Methods.Delete,
        params: ['id'],
        auth: true,
        meta: { csrf: true, toast: true }
      },
      get: {
        url: 'sets/{id}',
        method: Methods.Get,
        params: ['id'],
        auth: true
      },
      itemList: {
        url: 'sets/{id}/items',
        method: Methods.Get,
        params: ['id'],
        auth: true
      },
      list: {
        url: 'sets',
        method: Methods.Get,
        auth: true
      },
      moveItem: {
        url: 'sets/{id}/items',
        method: Methods.Put,
        params: ['id'],
        auth: true,
        meta: { csrf: true, toast: true }
      },
      removeItem: {
        url: 'sets/{id}/items/{itemId}',
        method: Methods.Delete,
        params: ['id', 'itemId'],
        auth: true,
        meta: { csrf: true, toast: true }
      },
      reorderItems: {
        url: 'sets/{id}/items',
        method: Methods.Put,
        params: ['id'],
        auth: true,
        meta: { csrf: true, toast: true }
      },
      update: {
        url: 'sets/{id}',
        method: Methods.Put,
        params: ['id'],
        auth: true,
        meta: { csrf: true, toast: true }
      }
    }
  },
  storage: {
    prefix: 'api/storage',
    methods: {
      download: {
        url: 'files/{id}/download',
        method: Methods.Get,
        params: ['id'],
        auth: true
      }
    }
  }
}
