






















































import { DashmixBreadcrumbsProps, DashmixIconName, DashmixSelectItem } from '@movecloser/ui-core'
import { Component, Vue } from 'vue-property-decorator'
import { AnyObject, IModal, ModalType } from '@movecloser/front-core'
import { MetaInfo } from 'vue-meta'

import { EditModeLayout, Loader } from '../../shared/components'
import { FullScreenLoader } from '../../shared/layouts'
import { initBreadcrumbs } from '../../content/helpers'
import { Inject, IRelatedService, RelatedServiceType, Variant } from '../../../backoffice'
import { Sidebar } from '../../shared/layouts/partials/Sidebar'

import {
  DictionaryRepositoryType,
  IDictionaryRepository
} from '../../root/contracts/repositories'
import { FormSelect } from '../../shared/components/form'
import {
  IProductsRepository,
  ProductData,
  ProductModel,
  ProductsRepositoryType
} from '../contracts'
import { ProductForm } from '../components/ProductForm.vue'
import { VariantForm } from '../components/VariantForm.vue'

/**
 * @author Zofia Madej <zofia.madej@movecloser.pl>
 */
@Component<EditProduct>({
  name: 'EditProduct',
  components: { EditModeLayout, FullScreenLoader, Sidebar, ProductForm, Loader, FormSelect },

  created () {
    this.loadProduct()
  },

  metaInfo (this: EditProduct): MetaInfo {
    return {
      title: this.productName
    }
  }
})
export class EditProduct extends Vue {
  @Inject(DictionaryRepositoryType)
  protected dictRepository!: IDictionaryRepository

  @Inject(ModalType)
  private modalConnector!: IModal

  @Inject(RelatedServiceType)
  public readonly relatedService!: IRelatedService

  @Inject(ProductsRepositoryType)
  private productsRepository!: IProductsRepository

  public activeVariantTab: string = ''
  public formName: string = 'editProduct'
  public payload: ProductData | null = null
  public isLoading: boolean = false
  public isSaving: boolean = false
  public reset: boolean = false
  public resort: boolean = false
  public product: ProductModel | null = null
  public productName: string = this.product?.name ?? ''
  public Icons = DashmixIconName

  public get breadcrumbs (): DashmixBreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t('products.listTitle')}`,
          target: { name: 'products.list' }
        },
        {
          label: `${this.$t('products.editTitle', { title: this.productName })}`,
          target: { name: 'products.edit' }
        }
      ],
      root: initBreadcrumbs.root
    }
  }

  public get canEdit () {
    return true
  }

  public get productId () {
    return Number(this.$route.params.id)
  }

  public async editProduct () {
    if (!this.payload) {
      return
    }

    this.isSaving = true

    try {
      await this.productsRepository.update(this.productId, this.payload)
      this.productName = this.payload.name ?? ''
    } catch (e) { console.warn(e) } finally {
      this.isSaving = false
    }
  }

  public loadProduct () {
    this.isLoading = true

    this.productsRepository.load(this.productId)
      .then(async (product: ProductModel) => {
        this.product = product

        this.payload = product.toObject()
        this.productName = this.product.sku
        if (!product.variants || !product.variants[0]) {
          return
        }
        this.activeVariantTab = product.variants[0].sku
      })
      .catch(error => {
        console.warn(error)
      })
      .finally(() => {
        this.isLoading = false
      })
  }

  public get variants (): Variant<string>[] { // todo: temp, handle legacy data format
    if (!this.payload?.variants) {
      return []
    }

    if (typeof this.payload.variants === 'object') {
      return Object.values(this.payload?.variants)
    }

    return this.payload.variants
  }

  public get tabs () {
    if (!this.payload || !this.payload.variants) {
      return []
    }
    return this.variants.map((v: AnyObject) => {
      return {
        component: VariantForm,
        props: {
          variant: v,
          form: 'edit-variant',
          editable: true,
          onVariantChange: this.onVariantChange
        },
        tab: {
          id: v.sku,
          label: v.sku
        }
      }
    })
  }

  protected onVariantChange (changed: Variant<string>): void {
    if (!this.payload) return
    const products: Record<string, Variant<string>> = {}
    for (const index in this.variants) {
      if (this.variants[index].sku === changed.sku) {
        products[index] = changed
      } else {
        products[index] = this.variants[index]
      }
    }
    this.payload.variants = products
  }

  protected openProduct (): void {
    if (this.payload && this.payload.url) {
      window.open(this.payload.url)
    }
  }

  public get activenessOptions (): DashmixSelectItem[] {
    return [
      {
        label: String(this.$t('products.edit.isVisible.true')),
        value: true
      }, {
        label: String(this.$t('products.edit.isVisible.false')),
        value: false
      }
    ]
  }
}

export default EditProduct

