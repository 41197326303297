
































import { Component, Mixins } from 'vue-property-decorator'
import {
  DashmixBreadcrumbsProps,
  DashmixTheme,
  TableHead,
  TableRowAction,
  TableRowElement
} from '@movecloser/ui-core'

import { Identifier, Inject } from '../../../backoffice'

import { DropdownActions } from '../../shared/contracts/content'
import { HasIdentifier } from '../../shared/contracts/data'
import { HeaderInterface } from '../../shared/components/InteractiveTable/InteractiveTable.contracts'
import { InteractiveTable } from '../../shared/components/InteractiveTable/InteractiveTable.vue'
import { ModelListHeader } from '../../shared/components/ModelListHeader/ModelListHeader'
import { ModelListHeaderProps } from '../../shared/components/ModelListHeader/ModelListHeader.contracts'

import { initBreadcrumbs } from '../../root/helpers/breadcrumbs'

import NavigationTableRow from '../components/NavigationTableRow.vue'
import { INavigationRepository, NavigationData, NavigationRepositoryType } from '../contracts'
import { ISettingsPermissions, SettingsPermissions } from '../config/permissions'
import {
  NavigationActions,
  navigationRowActionsFactory,
  navigationTableHead
} from '../maps/navigation'

@Component({
  name: 'Navigation',
  components: {
    InteractiveTable,
    ModelListHeader
  }
})
export class Navigation extends Mixins<ISettingsPermissions>(SettingsPermissions) {
  @Inject(NavigationRepositoryType)
  private navigationRepository!: INavigationRepository

  public isLoading = false

  public get header (): HeaderInterface {
    const header: Partial<HeaderInterface> = {
      title: 'Nawigacje'
    }

    if (this.canCreateNavigation) {
      header.buttonLabel = 'Dodaj nawigację'
      header.linkTarget = { name: 'settings.navigation.create' }
    }

    return header as HeaderInterface
  }

  public get modelListHeader (): ModelListHeaderProps {
    const header: Partial<ModelListHeaderProps> = {
      title: String(this.$t('settings.navigation.title'))
    }

    if (this.canCreateNavigation) {
      header.addLabel = String(this.$t('settings.navigation.add'))
      header.addTarget = { name: 'settings.navigation.create' }
    }

    return header as ModelListHeaderProps
  }

  public tableHead: TableHead = navigationTableHead
  public rowComponent = NavigationTableRow
  public tableData: TableRowElement[] = []

  public totalPages: number = 0
  public itemsTotal: number = 0

  public get rowActions (): TableRowAction[] {
    return navigationRowActionsFactory(this.domain, this.user)
  }

  public actions: DropdownActions = {
    [NavigationActions.Edit]: {
      callback: (data: unknown) => {
        this.$router.push({
          name: 'settings.navigation.edit',
          params: { id: (data as NavigationData).id.toString() }
        })
      }
    },
    [NavigationActions.Delete]: {
      callback: (data: unknown) => {
        return this.onDelete((data as HasIdentifier).id)
      },
      confirmation: {
        header: this.$t('settings.navigation.delete.confirmation.header').toString(),
        contentText: this.$t('actions.delete.contentText').toString(),
        theme: DashmixTheme.Danger,
        buttonLabel: 'atoms.delete'
      }
    }
  }

  public get breadcrumbs (): DashmixBreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t('settings.navigation.title')}`,
          target: { name: 'settings.navigation' }
        }
      ],
      root: initBreadcrumbs.root
    }
  }

  mounted () {
    this.loadList()
  }

  protected loadList (): void {
    this.isLoading = true
    this.navigationRepository.list().then(collection => {
      this.isLoading = false

      this.tableData = [...collection].map(model => {
        return {
          id: `${model.id}`,
          selectable: true,
          selected: false,
          data: model
        }
      })

      this.totalPages = Math.ceil(collection.meta.total / collection.meta.per_page)
      this.itemsTotal = collection.meta.total
    }).catch(error => {
      console.log(error)
      this.isLoading = false
    })
  }

  protected onDelete (id: Identifier) {
    return this.navigationRepository.delete(id).then(() => {
      this.tableData = this.tableData.filter(rowData => rowData.id !== id.toString())
    })
  }
}

export default Navigation
