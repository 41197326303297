














import { Component } from 'vue-property-decorator'
import { Hint } from '@movecloser/ui-core'

import { Identifier, Inject } from '../../../../backoffice'

import { DictionaryRepositoryType, IDictionaryRepository } from '../../../root/contracts/repositories'
import { AbstractAddon, AddonErrors } from '../../../content/shared'

import { TagsAddonBadge } from '../../contracts'
import { TagSelector } from './TagSelector.vue'

/**
 * @author  Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'TagsAddon',
  components: { AddonErrors, TagSelector }
})
export class TagsAddon extends AbstractAddon {
  @Inject(DictionaryRepositoryType)
  protected dictRepository!: IDictionaryRepository

  public value: Hint[] = []

  protected getVariantProperty (): void {
    const tagsFromModel = this.variant.getProperty<TagsAddonBadge[]>('tags')

    if (!tagsFromModel) {
      this.value = []
      return
    }

    this.value = tagsFromModel.map(t => {
      return {
        value: t.id,
        label: t.name
      }
    })
  }

  protected onTagSelected (value: Hint[]): void {
    this.variant.setProperty<TagsAddonBadge[]>('tags', value.map(v => {
      if (v.value) {
        return { id: v.value as Identifier, name: String(v.label) }
      } else {
        return { name: String(v.label) }
      }
    }))

    this.onChange(this.variant)
  }
}

export default TagsAddon
