






























import { Component, Mixins, Watch } from 'vue-property-decorator'
import {
  DashmixBreadcrumbsProps,
  TableHead,
  TableRowAction,
  TableRowElement
} from '@movecloser/ui-core'
import { IModal, ModalType } from '@movecloser/front-core'
import { MetaInfo } from 'vue-meta'
import { DropdownActions } from '../../shared/contracts/content'
import { Inject, logger, RelatedLoader, RelatedLoaderType } from '../../../backoffice'
import { Query } from '../../shared/contracts/query'

import { HeaderInterface } from '../../shared/components/InteractiveTable/InteractiveTable.contracts'
import { InteractiveTable } from '../../shared/components/InteractiveTable/InteractiveTable.vue'
import { ModelListHeader } from '../../shared/components/ModelListHeader/ModelListHeader'
import { ModelListHeaderProps } from '../../shared/components/ModelListHeader/ModelListHeader.contracts'

import { initBreadcrumbs } from '../../root/helpers/breadcrumbs'

import { IProductsPermissions, ProductsPermissions } from '../config/permissions.mixin'
import { IProductsRepository, ProductsRepositoryType } from '../contracts/repositories'
import { ProductData } from '../contracts'
import {
  ProductsListsActions,
  productsRowActionsFactory,
  productsTableHead
} from '../maps/products'
import { ProductTableRow } from '../components/ProductTableRow.vue'
import { setsFiltersConfig } from '../models/products.filters'

/**
 * @author Michał Rossian <michal.rossian@movecloser.pl>
 */
@Component<ProductsList>({
  name: 'ProductsList',
  components: { InteractiveTable, ModelListHeader, ProductTableRow },

  metaInfo (this: ProductsList): MetaInfo {
    return {
      title: `${this.$t('products.listTitle')}`
    }
  },
  mounted () {
    this.loadList(this.queryParams)
  }
})
export class ProductsList extends Mixins<IProductsPermissions>(ProductsPermissions) {
  @Inject(ProductsRepositoryType)
  protected productsRepository!: IProductsRepository

  @Inject(RelatedLoaderType)
  protected relatedLoader!: RelatedLoader

  @Inject(ModalType)
  private modalConnector!: IModal

  public rowComponent = ProductTableRow

  public actions: DropdownActions = {
    [ProductsListsActions.Preview]: {
      callback: (data: unknown) => {
        this.$router.push({
          name: 'products.edit',
          params: {
            id:
              (data as ProductData).id.toString()
          }
        })
      }
    }
  }

  public isLoading = false
  public filtersConfig = setsFiltersConfig

  public tableHead: TableHead = productsTableHead
  public tableData: TableRowElement[] = []

  public totalPages: number = 0
  public itemsTotal: number = 0

  public get breadcrumbs (): DashmixBreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t('products.listTitle')}`,
          target: { name: 'products.list' }
        }
      ],
      root: initBreadcrumbs.root
    }
  }

  public get header (): HeaderInterface {
    const payload: Partial<HeaderInterface> = {
      title: 'Produkty'
    }

    return payload as HeaderInterface
  }

  public get modelListHeader (): ModelListHeaderProps {
    const props: Partial<ModelListHeaderProps> = {
      title: String(this.$t('products.listTitle'))
    }

    return props as ModelListHeaderProps
  }

  public get rowActions (): TableRowAction[] {
    return productsRowActionsFactory(this.domain, this.user)
  }

  protected get queryParams (): Query {
    return this.$route.query
  }

  @Watch('queryParams', { deep: true })
  protected onPageChange (query: Query): void {
    this.loadList(query)
  }

  protected async loadList (query: Query): Promise<void> {
    this.isLoading = true
    const collection = await this.productsRepository.loadCollection(query)

    try {
      this.isLoading = false
      this.tableData = [...collection].map(model => {
        return {
          id: `${model.id}`,
          selectable: true,
          selected: false,
          data: model
        }
      })

      this.totalPages = Math.ceil(collection.meta.total / collection.meta.per_page)
      this.itemsTotal = collection.meta.total
    } catch (e) {
      logger(e, 'error')
      this.isLoading = false
      this.tableData = []
    }
  }
}

export default ProductsList
