





























import { Component, PropSync } from 'vue-property-decorator'

import { FormInput } from '../../shared/components/form'

import { ConfigForm } from './_abstract/ConfigForm.vue'
import { GeneralSettings } from '../contracts'

@Component<GeneralSettingsForm>({
  name: 'GeneralSettingsForm',
  components: { FormInput }
})
export class GeneralSettingsForm extends ConfigForm {
  @PropSync('formData', { type: Object, required: true })
  public payload!: GeneralSettings

  public readonly formName = 'generalSettings'

  public loading: boolean = false

  public onSubmit () {
    this.$emit('submit', { reproject: true, global: true })
  }
}

export default GeneralSettingsForm
