// Copyright © 2022 Move Closer

import { ModalRegistry } from '@movecloser/front-core'
import { VueConstructor } from 'vue'

import LoginModal from '../components/LoginModal.vue'

export enum AuthModals {
  Login = 'login'
}

/**
 * Registry of available modals.
 */
export const authModals: ModalRegistry<VueConstructor> = {
  [AuthModals.Login]: LoginModal
}
