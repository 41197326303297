// Copyright © 2021 Move Closer

import { defineComponent, SetupContext } from '@vue/composition-api'

import { FormFieldAbstract } from '../form/Abstract'

import { FormTypeaheadProps } from './FormTypeahead.contracts'
import { formTypeaheadProps, useFormTypeahead } from './FormTypeahead.hook'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const FormTypeahead = defineComponent({
  name: 'FormTypeahead',
  components: {},
  extends: FormFieldAbstract,
  props: formTypeaheadProps,
  emits: ['cleared', 'selected', 'searched', 'deleted'],
  setup (props: FormTypeaheadProps, ctx: SetupContext) {
    return useFormTypeahead(props, ctx)
  },
  template: `
    <div >
      <slot name="label">
        <label :for="name" v-if="displayLabel" :require="required">{{ label }}</label>
      </slot>

      <DsTypeahead
        :class="classObject"
        v-bind="{ name, hints, selected: value, loading, disabled, isMulti: multiple, clearable, noResults, newResult,
        taggable, size, showSelection, useDebounce, badgeMaxLength, badgeTheme, badgeVariant }"
        @selected="(selectedHint) => $emit('selected', selectedHint)"
        @searched="(selectedValue) => $emit('searched', selectedValue)"
        @deleted="(deletedHint) => $emit('deleted', deletedHint)"
        @cleared="() => $emit('cleared')" />

      <div class="invalid-feedback animated fadeIn" v-if="hasErrors">{{ errors[0] }}</div>
      <small class="text-muted" v-if="helper">{{ helper }}</small>

    </div>
  `
})

export default FormTypeahead
