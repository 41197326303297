// Copyright © 2022 Move Closer

import { ContentTypeConfig, defaultEditor, List } from '../../content/shared'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
export const snippetContentType: string = 'snippet'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
export const snippetContentTypeConfig: Partial<ContentTypeConfig> = {
  editor: defaultEditor,
  label: 'snippets.contentTypes.snippet',
  listing: List.Collection,
  hasParent: false,
  hasPreview: false,
  parentType: 'page',
  properties: []
}
