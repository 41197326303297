// Copyright © 2022 Move Closer

import { Model } from '@movecloser/front-core'

import { IArticleFilter, ArticleFilterData } from '../contracts'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
export class ArticleFilter extends Model<ArticleFilterData> implements IArticleFilter {
  protected boot (): void {
    this.initialValues = {}

    this.modelProperties = [
      'id',
      'isAttribute',
      'isMulti',
      'label',
      'name',
      'options',
      'queryParam'
    ]
  }

  public displayName (): string {
    return this._data.name
  }
}
