









import { Component } from 'vue-property-decorator'
import { Hint } from '@movecloser/ui-core'

import { Typeahead } from '../../../shared/components/Typeahead'

import { AbstractSelector } from '../../../content/shared'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component({
  name: 'AuthorSelector',
  components: { Typeahead }
})
export class AuthorSelector extends AbstractSelector {
  protected applySelection (selected: Hint[]) {
    this.onSelection(selected)
  }

  protected loadDictionary (searchParams?: string) {
    // this.loading = true
    //
    // this.dictRepository.loadAuthorsDictionary({ q: searchParams || '' }).then(dict => {
    //   this.dictionary = [...dict].map(model => {
    //     return {
    //       value: model.id,
    //       label: model.fullName
    //     }
    //   })
    // }).finally(() => {
    //   this.loading = false
    // })
  }
}

export default AuthorSelector
