// Copyright © 2021 Move Closer

import { Container, RoutesFactory } from '@movecloser/front-core'

import { Permission } from '../../auth/contracts/permissions'

import CreateRole from '../views/CreateRole.vue'
import EditRole from '../views/EditRole.vue'
import RolesList from '../views/RolesList.vue'
import UsersList from '../views/UsersList.vue'
import AuthorsList from '../views/AuthorsList.vue'
import CreateAuthor from '../views/CreateAuthor.vue'
import CreateUser from '../views/CreateUser.vue'
import EditAuthor from '../views/EditAuthor.vue'
import EditUser from '../views/EditUser.vue'
// import UsersLogsList from './views/UsersLogsList.vue'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const routesFactory: RoutesFactory = (container: Container) => {
  return [
    // todo edit 2 razy
    {
      path: '/users/:id/edit',
      name: 'edit',
      component: EditUser,
      meta: {
        permissions: [
          Permission.EditUsers
        ]
      }
    },
    {
      path: '/users/create',
      name: 'create',
      component: CreateUser,
      meta: {
        permissions: [
          Permission.CreateUsers
        ]
      }
    },
    {
      path: '/users',
      name: 'list',
      component: UsersList,
      meta: {
        permissions: [
          Permission.ViewUsers
        ]
      }
    },
    // {
    //   path: '/authors',
    //   name: 'authors.list',
    //   component: AuthorsList,
    //   meta: {
    //     permissions: [
    //       Permission.ViewAuthors
    //     ]
    //   }
    // },
    // {
    //   path: '/authors/create',
    //   name: 'authors.create',
    //   component: CreateAuthor,
    //   meta: {
    //     permissions: [
    //       Permission.CreateAuthors
    //     ]
    //   }
    // },
    // {
    //   path: '/authors/:id/edit',
    //   name: 'authors.edit',
    //   component: EditAuthor,
    //   meta: {
    //     permissions: [
    //       Permission.EditAuthors
    //     ]
    //   }
    // },
    {
      path: '/roles',
      name: 'roles.list',
      component: RolesList,
      meta: {
        permissions: [
          Permission.ViewRoles
        ]
      }
    },
    {
      path: '/roles/:id/edit',
      name: 'roles.edit',
      component: EditRole,
      meta: {
        permissions: [
          Permission.EditRoles
        ]
      }
    },
    {
      path: '/roles/create',
      name: 'roles.create',
      component: CreateRole,
      meta: {
        permissions: [
          Permission.CreateRoles
        ]
      }
    }
  ]
}
