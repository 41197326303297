// Copyright © 2021 Move Closer

import { computed, ComputedRef, defineComponent } from '@vue/composition-api'

import { FormFieldAbstract, FormFieldAbstractSetup } from '../Abstract'
import { FormInputProps } from './Input.contracts'

/**
 * Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
export const FormInput = defineComponent({
  name: 'FormInput',
  extends: FormFieldAbstract,
  template: `
    <div>
      <label :for="name" v-if="displayLabel" :require="required">{{ label }}</label>

      <slot name="beforeInput" />

      <DsInputGroup :class="[ classInput, classObject ]">
        <DsInput :model.sync="value"
                  v-bind="{ type, name, placeholder, autocomplete, readonly, disabled, min, max, step, errors }"
                  v-focus="autofocus"
                  :class="[ classInput, classObject ]" @blur="$emit('blur')" @focus="$emit('focus')" />

          <template v-if="this.$slots.prependText" #prependText>
            <slot name='prependText'/>
          </template>

          <template v-if="this.$slots.prepend" #prepend>
            <slot name='prepend'/>
          </template>

          <template v-if="this.$slots.appendText" #appendText>
            <slot name='appendText'/>
          </template>

          <template v-if="this.$slots.append" #append>
            <slot name='append'/>
          </template>
      </DsInputGroup>

      <slot name="afterInput" />

      <div class="invalid-feedback animated fadeIn" v-if="hasErrors">{{ errors[0] }}</div>
      <small class="text-muted" v-if="helper">{{ helper }}</small>

    </div>

  `,
  props: {

    /**
     * Input autocomplete
     */
    autocomplete: {
      type: String,
      required: false
    },

    /**
     * Input autofocus
     */
    autofocus: {
      type: Boolean,
      required: false,
      default: false
    },

    /**
     * Input class
     */
    classInput: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    },
    min: {
      type: Number,
      required: false
    },
    max: {
      type: Number,
      required: false
    },

    /**
     * Input placeholder
     */
    placeholder: {
      type: String,
      required: false,
      default: ''
    },

    /**
     * Input type
     */
    type: {
      type: String,
      required: false,
      default: 'text'
    },
    step: {
      type: Number,
      required: false
    }
  },
  emits: ['input'],

  setup (props: FormInputProps, { emit }) {
    const {
      errors,
      displayLabel,
      hasErrors,
      value
    } = FormFieldAbstractSetup(props, emit)

    const classObject: ComputedRef<object> = computed(() => {
      return {
        'is-invalid': hasErrors.value
      }
    })

    return {
      classObject,
      errors,
      displayLabel,
      hasErrors,
      value
    }
  }
})
export default FormInput
