// Copyright © 2023 Move Closer

import { ModuleSize } from '@movecloser/page-builder'

import { abstractModuleFactory, getDefaultModuleAlignment } from '../abstract/form'
import { ModuleBase, ModuleFactory, Related } from '../../contexts'

import { AvailableModuleDriver } from '../allowed'
import { WhereToBuyModule, WhereToBuyModuleContent } from './WhereToBuy.contracts'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export const whereToBuyContentFactory = (): WhereToBuyModuleContent => ({
  shops: {} as Related,
  brands: []
})

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl> (original)
 */
export const whereToBuyFactory: ModuleFactory<WhereToBuyModule> = {
  ...abstractModuleFactory,
  createInitialSize (): ModuleSize {
    return {
      colSpan: 6,
      isResizable: true,
      maxColSpan: 12,
      maxRowSpan: 12,
      minColSpan: 4,
      minRowSpan: 2,
      rowSpan: 3
    }
  },
  createModule (): ModuleBase<WhereToBuyModule> {
    return {
      alignment: getDefaultModuleAlignment(),
      content: whereToBuyContentFactory(),
      driver: AvailableModuleDriver.WhereToBuy
    }
  }
}
