// Copyright © 2021 Move Closer

import { IModuleConstructor } from '@movecloser/front-core'

import { ArticlesModule } from '@module/articles/module'
import { AuthModule } from '@module/auth/module'
import { ContentModule } from '@module/content/module'
import { LayoutsModule } from '@module/layouts/module'
import { MediaModule } from '@module/media/module'
import { ProductsModule } from '@module/products/module'
import { RootModule } from '@module/root/module'
import { SettingsModule } from '@module/settings/module'
import { SetsModule } from '@module/sets/module'
import { SnippetsModule } from '@module/snippets/module'
import { StaticModule } from '@module/static/module'
import { UsersModule } from '@module/users/module'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const modules: IModuleConstructor[] = [
  // Here you can register new modules of the app.
  // Follow IModuleConstructor type: new() => Module
  ArticlesModule,
  AuthModule,
  ContentModule,
  LayoutsModule,
  MediaModule,
  ProductsModule,
  SettingsModule,
  SetsModule,
  SnippetsModule,
  StaticModule,
  UsersModule,
  // RootModule needs to be last, as it contains a top-level 'catch-all' route
  RootModule
]
