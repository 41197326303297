// Copyright © 2021 Move Closer

import { Methods, ResourcesRegistry } from '@movecloser/front-core'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 *
 * Note: This file contains global resources' definitions. Use it only for services & non-contextual endpoints.
 * Otherwise, you can register your set via boot method inside module.
 */
export const resources: ResourcesRegistry = {
  // Global resources goes here...
  //
  related: {
    prefix: 'api/management',
    methods: {
      list: {
        url: 'related',
        method: Methods.Get,
        auth: true
      }
    }
  }
}
