
















import { Authentication, AuthServiceType } from '@movecloser/front-core'
import { Component, Vue } from 'vue-property-decorator'

import { Inject } from '../../../backoffice'

import { UserModel } from '../contracts/models'

@Component<NoAccessView>({
  name: 'NoAccessView'
})
export class NoAccessView extends Vue {
  @Inject(AuthServiceType)
  private authService!: Authentication<UserModel>

  public goBackToLogin (): void {
    this.authService.deleteToken()

    this.$router.push({ name: 'auth.login' })
  }
}

export default NoAccessView
