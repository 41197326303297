// Copyright © 2021 Move Closer

import { ModalRegistry } from '@movecloser/front-core'
import { VueConstructor } from 'vue'

import { ConfirmModal } from '../components/ConfirmModal'
import { ModalConfiguration, ModalSize } from '../components/Modal'
import { SelectModuleModal } from '../components/PageBuilder'

import ForceSiteChangeModal from '../../root/components/ForceSiteChangeModal.vue'
import SiteSelectionModal from '../../root/components/SiteSelectionModal.vue'

export enum Modals {
  Confirm = 'confirm',
  ForceChangeSite = 'forceChangeSite',
  SelectModuleModal = 'selectModuleModal',
  SiteSelection = 'siteSelection'
}

/**
 * Registry of available modals.
 */
export const modals: ModalRegistry<VueConstructor> = {
  [Modals.Confirm]: ConfirmModal,
  [Modals.ForceChangeSite]: ForceSiteChangeModal,
  [Modals.SelectModuleModal]: SelectModuleModal as VueConstructor,
  [Modals.SiteSelection]: SiteSelectionModal
}

/**
 * Default config for modals.
 */
export const modalConfig: ModalConfiguration = {
  closableWithOutsideClick: false,
  height: ModalSize.auto,
  width: ModalSize.sm
}
