



















import { Component, Prop, Vue, Inject as InjectVue } from 'vue-property-decorator'
import { DateTimeType, IDateTime } from '@movecloser/front-core'
import { Fragment } from 'vue-fragment'

import { Inject } from '../../../backoffice'

import { EndSessionCallback } from '../contracts/data'
import { SessionData } from '../contracts/models'

@Component({
  name: 'SessionTableRow',
  components: { Fragment }
})
export class SessionTableRow extends Vue {
  @Prop({ type: Object, required: true })
  public data!: SessionData

  @Inject(DateTimeType)
  protected dateTime!: IDateTime

  @InjectVue()
  public readonly endSession!: EndSessionCallback

  public isPending: boolean = false

  public logOut (): void {
    this.isPending = true

    const onSuccess = () => {
      this.isPending = false
    }

    this.endSession(this.data.id, onSuccess)
  }
}

export default SessionTableRow
