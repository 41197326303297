

























































































































































































































import { Component, Prop, Vue, PropSync } from 'vue-property-decorator'
import { ConjunctionOperator, IModal, ModalType } from '@movecloser/front-core'
import { DashmixIconName, DashmixTheme, SizeMap } from '@movecloser/ui-core'
import { Identifier, Inject, Related } from '../../../backoffice'

import { Iterator } from '../../../modules/partials/Iterator'
import FormText from '../../shared/components/form/TextArea/TextArea'
import { Node } from '../../shared/components'

import { FormCheckbox, FormInput } from '../../shared/components/form'
import { Loader } from '../../shared/components/Loader/Loader'

import { mediaTypes } from '../../media/contracts'
import { ProductGalleryItem } from './ProductGalleryItem.vue'

import { EditProductIntentionPayload } from '../intentions/EditProductIntention'
import { ProductFormPartial } from './ProductFormPartial.vue'

/**
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 *
 */
@Component<ProductForm>({
  name: 'ProductForm',
  components: {
    Loader,
    FormCheckbox,
    FormInput,
    FormText,
    Iterator,
    ProductGalleryItem,
    ProductFormPartial
  },

  created () {
    this.title = this._payload.title || ''
  },
  mounted () {
    this.initializeFormFields()
  }
})
export class ProductForm extends Vue {
  @Prop({ type: Boolean, required: false, default: true })
  public editable!: boolean

  @Prop({ type: String, required: true })
  public form!: string

  @Prop({ type: Boolean, required: true, default: false })
  public isEditMode!: boolean

  @Prop({ type: Boolean, required: false })
  public isLoading!: boolean

  @Prop({ type: Boolean, required: true })
  public isSaving!: boolean

  @PropSync('payload', { type: Object, required: true })
  public _payload!: EditProductIntentionPayload

  @Prop({ type: String, required: false, default: '' })
  public productName!: string

  @Inject(ModalType)
  private modalConnector!: IModal

  public buttonTheme = DashmixTheme
  public conjunction: ConjunctionOperator = ConjunctionOperator.And
  public Icons = DashmixIconName
  public isEditable: Record<string, boolean> = {}

  public shouldRefresh: boolean = false
  public title?: string = ''
  public formName: string = 'editProduct'

  // todo tlumaczenie
  public get formTitle (): string {
    return `${this.$t('products.edit.form.header', { title: this.productName })}`
  }

  public get columnSectionMediaType (): string {
    if (!this._payload.specification.columnSection.mediaType) {
      return 'image'
    }

    return this._payload.specification.columnSection.mediaType
  }

  public set columnSectionMediaType (mediaType: string) {
    this._payload = {
      ...this._payload,
      specification: {
        ...this._payload.specification,
        columnSection: {
          ...this._payload.specification.columnSection,
          image: null,
          mediaType
        }
      }
    }

    this.isEditable = {
      ...this.isEditable,
      columnSectionImage: true
    }
  }

  public addNewLabel (): string {
    return ''
  }

  public get icon (): DashmixIconName | undefined {
    return DashmixIconName.PlusSolid
  }

  public addNewObject (): Record<string, string> {
    return { name: '', value: '', icon: '' }
  }

  protected async deleteHeroCoverItem () {
    this._payload.specification.heroSection.cover = null
    this._payload = {
      ...this._payload,
      specification: {
        ...this._payload.specification,
        heroSection: {
          ...this._payload.specification.heroSection,
          cover: null
        }
      }
    }

    this.isEditable = {
      ...this.isEditable,
      heroSectionCover: true
    }
  }

  protected async deleteHeroVideoItem () {
    this._payload.specification.heroSection.video = null
    this._payload = {
      ...this._payload,
      specification: {
        ...this._payload.specification,
        heroSection: {
          ...this._payload.specification.heroSection,
          video: null
        }
      }
    }

    this.isEditable = {
      ...this.isEditable,
      heroSectionVideo: true
    }
  }

  protected async deleteStepSectionImage () {
    this._payload.specification.stepSection.image = null
    this._payload = {
      ...this._payload,
      specification: {
        ...this._payload.specification,
        heroSection: {
          ...this._payload.specification.stepSection,
          image: null
        }
      }
    }

    this.isEditable = {
      ...this.isEditable,
      stepSection: true
    }
  }

  protected async deleteColumnSectionImage () {
    this._payload.specification.columnSection.image = null
    this._payload = {
      ...this._payload,
      specification: {
        ...this._payload.specification,
        heroSection: {
          ...this._payload.specification.columnSection,
          image: null
        }
      }
    }

    this.isEditable = {
      ...this.isEditable,
      columnSectionImage: true
    }
  }

  protected async deleteColumnSectionItemIcon (index: number) {
    this._payload.specification.columnSection.items[index].icon = null
    const items = this._payload.specification.columnSection.items.map((item: Node, i: number) => {
      if (index === i) {
        return {
          ...item,
          icon: null
        }
      } else {
        return item
      }
    })

    this._payload = {
      ...this._payload,
      specification: {
        ...this._payload.specification,
        columnSection: {
          ...this._payload.specification.columnSection,
          items
        }
      }
    }
  }

  public initializeFormFields () {
    if (!Object.keys(this._payload.specification).length) {
      this._payload.specification = {
        heroSection: {
          heading: '',
          subheading: '',
          content: '',
          cover: null,
          video: null
        },
        boxSection: {
          heading: '',
          items: []
        },
        columnSection: {
          heading: '',
          image: null,
          mediaType: 'image',
          items: []
        },
        stepSection: {
          heading: '',
          image: null,
          items: []
        }
      }
    }

    this.isEditable = {
      columnSectionImage: !this._payload.specification.columnSection?.image,
      heroSectionCover: !this._payload.specification.heroSection?.cover,
      heroSectionVideo: !this._payload.specification.heroSection?.video,
      stepSection: !this._payload.specification.stepSection?.image
    }
  }

  public onLabelChange (value: string, index: number): void {
    const labelsCopy = [...this._payload.specification.stepSection.items]
    labelsCopy[index] = value

    this._payload.specification.stepSection.items = labelsCopy
  }

  public onColumnSectNameChange (value: string, index: number): void {
    const labelsCopy = [...this._payload.specification.columnSection.items]
    labelsCopy[index].name = value
    this._payload.specification.columnSection.items = labelsCopy
  }

  public onColumnSectValueChange (value: string, index: number): void {
    const labelsCopy = [...this._payload.specification.columnSection.items]
    labelsCopy[index].value = value
    this._payload.specification.columnSection.items = labelsCopy
  }

  public onBoxSectNameChange (value: string, index: number): void {
    const labelsCopy = [...this._payload.specification.boxSection.items]
    labelsCopy[index].name = value
    this._payload.specification.boxSection.items = labelsCopy
  }

  public onBoxSectValueChange (value: string, index: number): void {
    const labelsCopy = [...this._payload.specification.boxSection.items]
    labelsCopy[index].value = value
    this._payload.specification.boxSection.items = labelsCopy
  }

  public onTitleChange (value: string) {
    this.title = value
    this.$emit('title-updated', this.title)
  }

  public openFilePicker (callback: (
    data: Related<[], Identifier>[],
    index?: number
  ) => void, img: boolean, index?: number) {
    this.modalConnector.open('file', {
      multiple: false,
      onSelection: (data: Related<[], Identifier>[]) => callback(data, index),
      onClose: () => this.modalConnector.close(),
      config: {
        allowedMediaType: img ? mediaTypes.Image : mediaTypes.Video
      }
    }, { size: SizeMap.XLarge })
  }

  public showButton (section: string, show: boolean): void {
    this.isEditable[section] = show
  }

  protected async addHeroImg (data: Related<[], Identifier>[]) {
    this._payload.specification.heroSection.cover = data
    this._payload = { ...this._payload }
  }

  protected async addHeroVideo (data: Related<[], Identifier>[]) {
    this._payload.specification.heroSection.video = data
    this._payload = { ...this._payload }
  }

  protected async addColumnSectionImg (data: Related<[], Identifier>[]) {
    this._payload.specification.columnSection.image = data
    this._payload = { ...this._payload }
  }

  protected async addColumnSectionItemIcon (data: Related<[], Identifier>[], index: number) {
    this._payload.specification.columnSection.items[index].icon = data
    this._payload = { ...this._payload }
  }

  protected async addStepSectionImg (data: Related<[], Identifier>[]) {
    this._payload.specification.stepSection.image = data
    this._payload = { ...this._payload }
  }
}

export default ProductForm
