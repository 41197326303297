







import { Component, Vue } from 'vue-property-decorator'
import { IModal, ModalType } from '@movecloser/front-core'
import { DashmixIconName } from '@movecloser/ui-core'

import { Inject } from '../../../backoffice'
import { Modals } from '../../shared/config/modals'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component({
  name: 'SiteSelector'
})
export class SiteSelector extends Vue {
  @Inject(ModalType)
  private modalConnector!: IModal

  public openModal () {
    this.modalConnector.open(Modals.SiteSelection)
  }

  public icon = DashmixIconName
}

export default SiteSelector
