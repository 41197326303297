









































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { DashmixIconName, DashmixTheme } from '@movecloser/ui-core'

import { FormText, FormInput } from '../../shared/components/form'

import { CreateGalleryIntentionPayload } from '../intentions/CreateGalleryIntention'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
@Component({
  name: 'GalleryForm',
  components: { FormInput, FormText }
})
export class GalleryForm extends Vue {
  @PropSync('payload', { type: Object, required: true })
  public data!: CreateGalleryIntentionPayload

  @Prop({ type: Boolean, required: false, default: false })
  public editMode!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public isLoading!: boolean

  @Prop({ type: String, required: true })
  public formName!: string

  public icons = DashmixIconName
  public buttonTheme = DashmixTheme
}

export default GalleryForm
