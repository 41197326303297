// Copyright © 2021 Move Closer

import { CreateUserIntention } from '../intentions/CreateUserIntention'
import { EditUserIntention } from '../intentions/EditUserIntention'
import { CreateAuthorIntention } from '../intentions/CreateAuthorIntention'
import { EditAuthorIntention } from '../intentions/EditAuthorIntention'

import { CreateRoleIntention } from '../intentions/createRole'
import { UpdateRoleIntention } from '../intentions/updateRole'
import { ICollection } from '@movecloser/front-core'

import { Identifier } from '../../../backoffice'
import { Query } from '../../shared/contracts/query'

import {
  AuthorModel,
  RoleData,
  RoleModel,
  UserModel,
  UsersInfoData
} from './models'

export interface IRolesRepository {
  create (intention: CreateRoleIntention): Promise<string>
  delete (id: Identifier): Promise<void>
  load (id: RoleData['id']): Promise<RoleModel>
  loadCollection (query?: Query): Promise<ICollection<RoleModel>>
  update (id: Identifier, intention: UpdateRoleIntention): Promise<void>
}

export interface IUserRepository {
  create (intention: CreateUserIntention): Promise<void>
  delete (id: Identifier): Promise<void>
  edit (id: Identifier, intention: EditUserIntention): Promise<void>
  load (id: Identifier): Promise<UserModel>
  loadCollection (query: Query): Promise<ICollection<UserModel>>
  loadUsersInfo (): Promise<UsersInfoData>
  switchActive(id: Identifier, isActive: boolean, model: UserModel): Promise<UserModel>
}

export interface IAuthorRepository {
  create (intention: CreateAuthorIntention): Promise<void>
  delete (id: Identifier): Promise<void>
  edit (id: Identifier, intention: EditAuthorIntention): Promise<void>
  load (id: Identifier): Promise<AuthorModel>
  loadCollection (query: Query): Promise<ICollection<AuthorModel>>
}

export const RolesRepositoryType = Symbol.for('IRoleRepository')
export const UserRepositoryType = Symbol.for('IUserRepository')
export const AuthorRepositoryType = Symbol.for('IAuthorRepository')
