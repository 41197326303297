// Copyright © 2022 Move Closer

import { AppModule, Container, Module } from '@movecloser/front-core'

import { ContentTypeManagerType, IContentTypeManager } from '../content/shared'

import { snippetContentType, snippetContentTypeConfig } from './config/content-types'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@AppModule({
  name: 'snippets',
  boot (container: Container) {
    const manager: IContentTypeManager = container.get(ContentTypeManagerType)
    manager.registerType(snippetContentType, snippetContentTypeConfig)
  }
})
export class SnippetsModule extends Module {}
