// Copyright © 2022 Move Closer

import uiCore from '@movecloser/ui-core/lib/libraries/dashmix/locales/pl.json'
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { merge } from 'lodash'

import atomsPL from '@/assets/langs/pl/atoms.json'
import commonPl from '@/assets/langs/pl/common.json'

import articlesPL from '@backoffice/articles/locales.json'
import contentPl from '@backoffice/content/config/content.json'
import mediaPL from '@backoffice/media/config/media.json'
import productsPL from '@backoffice/products/locales.json'
import settingsPL from '@backoffice/settings/config/settings.json'
import setsPL from '@backoffice/sets/config/sets.json'
import snippetsPL from '@backoffice/snippets/locales.json'
import staticPL from '@backoffice/static/locales.json'
import usersPl from '@backoffice/users/config/users.json'

import { backofficeAppPL } from '@/shared/core/src/locales/pl/backoffice'

Vue.use(VueI18n)

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const i18n = new VueI18n({
  locale: 'pl',
  fallbackLocale: 'pl',
  silentTranslationWarn: true,
  messages: {
    pl: {
      ...merge(
        uiCore,
        backofficeAppPL,
        atomsPL,
        commonPl,
        articlesPL,
        contentPl,
        mediaPL,
        productsPL,
        setsPL,
        settingsPL,
        snippetsPL,
        staticPL,
        usersPl
      )
    }
  }
})
