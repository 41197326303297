
































































import { Component, Prop, PropSync, Ref, Vue } from 'vue-property-decorator'
import { Authentication, AuthServiceType, DateTimeType, IDateTime } from '@movecloser/front-core'
import { DashmixAlertTheme, DashmixIconName } from '@movecloser/ui-core'
import { FilePond, FilePondFile } from 'filepond'

import { Identifier, Inject } from '../../../backoffice'

import { FormInput, FormText } from '../../shared/components/form'
import { FileUploader } from '../../shared/components/FileUploader/FileUploader'
import { UserModel } from '../../auth/contracts/models'

import {
  EmptyFileCreatePayload,
  FileCreatePayload,
  FilePondErrorDescription,
  FileRepositoryType,
  FileThumbnail,
  IFileRepository,
  mediaTypes
} from '../contracts'
import { FileForm } from './FileForm.vue'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
@Component({
  name: 'FileCreateForm',
  components: { FormInput, FormText, FileForm, FileUploader }
})
export class FileCreateForm extends Vue {
  @PropSync('loading', { type: Boolean, required: true })
  public isLoading!: boolean

  @PropSync('formsModel', { type: Array, required: true })
  public forms!: FileCreatePayload[]

  @PropSync('filePondFiles', { type: Array, required: true })
  public files!: FilePondFile[]

  @PropSync('fileModeModel', { type: String, required: false })
  public fileMode!: string | null

  @PropSync('duplicateDetectedModel', { type: Number, required: false })
  public duplicateDetected!: Identifier | null

  @PropSync('forceCreateModel', { type: Boolean, required: false, default: false })
  public forceCreate!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public pickerMode!: boolean

  @Prop({ type: Number, required: true })
  public directory!: Identifier

  @Prop({ type: Boolean, required: false, default: true })
  public isMultiple!: boolean

  @Prop({ type: String, required: false, default: mediaTypes.File })
  public allowedMediaType!: string

  @Inject(AuthServiceType)
  protected authService!: Authentication<UserModel>

  @Inject(DateTimeType)
  protected dateTime!: IDateTime

  @Inject(FileRepositoryType)
  protected fileRepository!: IFileRepository

  @Ref('fileCreateContainerRef')
  protected fileCreateContainerRef!: HTMLDivElement

  public alertTheme = DashmixAlertTheme.Danger
  public icons = DashmixIconName
  public processFileError: string | null = null
  public selected: string = ''

  public get filepond (): FilePond {
    const uploader = this.$refs.fileuploader as Vue
    return uploader.$refs.filepond as unknown as FilePond
  }

  public get multipleMode (): boolean {
    return this.files.length > 1
  }

  mounted () {
    this.clearUploadError()
  }

  public clearUploadError (): void {
    this.processFileError = null
  }

  public getFileThumbnail (file: FilePondFile): FileThumbnail | null {
    return {
      thumbnail: URL.createObjectURL(file.file) || '',
      type: file.fileExtension || ''
    }
  }

  public getFormName (index: Identifier): string {
    if (this.files.length > 0) {
      return 'createFile_' + this.files[index as number].id
    } else {
      return 'createFile_'
    }
  }

  public handleFieldCopy (event: { name: keyof FileCreatePayload; overWrite: boolean }, index: Identifier): void {
    for (const form of this.forms) {
      if (!event.overWrite && form[event.name] !== '') {
        continue
      }
      form[event.name] = this.forms[index as number][event.name] as never
    }
  }

  public onAddFile (error: FilePondErrorDescription, file: FilePondFile): void {
    if (!error) {
      this.forms.unshift({
        ...EmptyFileCreatePayload,
        directory: this.directory
      })
    }
  }

  public onProcessFile (error: FilePondErrorDescription, file: FilePondFile): void {
    if (!error) {
      const index = this.files.findIndex(f => f.id === file.id)
      this.files.splice(index, 1, file)
      this.$emit('fileProcessingFinished')
    } else {
      if (error.code === 409) {
        this.duplicateDetected = (error?.body as { data: {id: Identifier }}).data.id || null
        this.$emit('fileDuplicateDetected', file)
      } else {
        this.processFileError = error.body as string
      }
      this.isLoading = false
      this.fileCreateContainerRef.scrollIntoView()
    }
  }

  public onProcessFileRevert (): void {
    this.fileMode = null
    this.duplicateDetected = null
    this.clearUploadError()
  }

  public onUpdateFiles (files: FilePondFile[]): void {
    for (const [index, file] of files.entries()) {
      if (typeof this.forms[index] === 'undefined') {
        this.forms[index] = {} as FileCreatePayload
      }
      this.forms[index].title = file.filename.substr(0, file.filename.lastIndexOf('.')) || file.filename
    }
    this.files = files
    this.duplicateDetected = null
    this.clearUploadError()
  }

  public onRemoveFile (error: FilePondErrorDescription, file: FilePondFile): void {
    if (error) {
      return
    }
    const index = this.files.findIndex(f => f.id === file.id)
    this.files.splice(index, 1)
    this.forms.splice(index, 1)
    this.duplicateDetected = null
    this.clearUploadError()
  }
}

export default FileCreateForm
