// Copyright © 2021 Move Closer

import { defineComponent } from '@vue/composition-api'
import { useModal } from './Modal.hooks'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const Modal = defineComponent({
  name: 'Modal',
  setup () {
    return useModal()
  },

  template: `
    <DsModal :class="config.className || ''" :isOpen="isOpen" :size="modalSize" @close="clickedOutside">
      <template v-slot:default="{ class: classStack }">
        <component :is="component" :class="classStack" :payload="payload"
                   @back="openPrevious" @close="closeModal" @swap="swapModal" />
      </template>
    </DsModal>
  `
})
