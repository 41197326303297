// Copyright © 2022 Move Closer

import { ModalRegistry } from '@movecloser/front-core'
import { VueConstructor } from 'vue'

import { PossibleRelatedPicker } from '../../../backoffice'

import ArticlePickerModal from '../components/ArticlePickerModal.vue'
import ArticleFilterPickerModal from '../components/ArticleFilterPickerModal.vue'
import ConfirmAcceptanceModal from '../components/ComfirmAcceptanceModal.vue'
import ContentPickerModal from '../components/ContentPickerModal.vue'
import ContentTakeOverModal from '../components/ContentTakeOverModal.vue'
import LinkPickerModal from '../components/LinkPickerModal.vue'
import LockConflictModal from '../components/LockConflictModal.vue'
import MetaAddonModal from '../components/addons/MetaAddonModal.vue'
import PageListsModal from '../components/PageListsModal.vue'
import PreventLoosingDataModal from '../components/PreventLoosingDataModal.vue'
import RemoveConflictModal from '../components/RemoveConflictModal.vue'
import SortContentModal from '../components/SortContentModal.vue'
import VariantPreviewDecisionModal from '../components/VariantPreviewDecisionModal.vue'
import SnippetPickerModal from '../components/SnippetPickerModal.vue'
import CategoryPickerModal from '../components/CategoryPickerModal.vue'

export enum ContentModals {
  ConfirmAcceptance = 'confirmAcceptance',
  ContentLists = 'contentLists',
  ContentTakeOver = 'contentTakeOver',
  LockConflict = 'lockConflict',
  MetaAddonModal = 'metaAddon',
  PickLink = 'pickLink',
  PreventLoosingData = 'preventLoosingDataModal',
  RemoveConflictModal = 'removeConflictModal',
  SortContent = 'sortContent',
  VariantPreviewDecision = 'variantPreviewDecision'
}

/**
 * Registry of available modals.
 */
export const contentModals: ModalRegistry<VueConstructor> = {
  [ContentModals.ConfirmAcceptance]: ConfirmAcceptanceModal,
  [ContentModals.ContentLists]: PageListsModal,
  [ContentModals.ContentTakeOver]: ContentTakeOverModal,
  [ContentModals.LockConflict]: LockConflictModal,
  [ContentModals.MetaAddonModal]: MetaAddonModal,
  [ContentModals.PickLink]: LinkPickerModal,
  [ContentModals.PreventLoosingData]: PreventLoosingDataModal,
  [ContentModals.RemoveConflictModal]: RemoveConflictModal,
  [ContentModals.SortContent]: SortContentModal,
  [ContentModals.VariantPreviewDecision]: VariantPreviewDecisionModal,
  // Pickers
  [PossibleRelatedPicker.ArticleFilter]: ArticleFilterPickerModal,
  [PossibleRelatedPicker.Category]: CategoryPickerModal,
  [PossibleRelatedPicker.Content]: ArticlePickerModal,
  [PossibleRelatedPicker.Link]: ContentPickerModal,
  [PossibleRelatedPicker.Snippet]: SnippetPickerModal
}
