













import { defineComponent, SetupContext } from '@vue/composition-api'

import { modeSwitcherProps, useModeSwitcher } from './ModeSwitcher.hooks'
import { ModeSwitcherProps } from '../PageBuilder.contracts'

export const ModeSwitcher = defineComponent({
  name: 'ModeSwitcher',
  props: modeSwitcherProps,
  setup (props: ModeSwitcherProps, ctx: SetupContext) {
    return useModeSwitcher(props, ctx)
  }
})

export default ModeSwitcher
