// Copyright © 2021 Move Closer

import { RawLocation } from 'vue-router'

export enum ModelListHeaderMode {
  Button = 'button',
  Link = 'link'
}

export interface ModelListHeaderProps {
  addLabel: string|null
  addTarget: RawLocation
  mode: ModelListHeaderMode
  withoutIcon: boolean
  pending: boolean
  showAdd: boolean
  title: string
}
