



































import { Component } from 'vue-property-decorator'
import { DateTimeType, IDateTime, IModal, ModalType } from '@movecloser/front-core'
import { DashmixIconName } from '@movecloser/ui-core'

import { Identifier, Inject } from '../../../backoffice'
import { Loader } from '../../shared/components/Loader'

import AbstractWidget from '../../root/shared/AbstractWidget'
import { UserModel } from '../../auth/contracts/models'

import { ContentModel, ContentRepositoryType, ContentType, IContentRepository } from '../contracts'
import { ContentModals } from '../config/modals'
import { ContentTypeManagerType, IContentTypeManager } from '../services/content-type-manager'
import { canEditContents, canViewContents } from '../config/guards'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<HomePageWidget>({
  name: 'HomePageWidget',
  components: { Loader },

  created (): void {
    this.loadList()
  }
})
export class HomePageWidget extends AbstractWidget {
  @Inject(ContentRepositoryType)
  protected readonly contentRepository!: IContentRepository

  @Inject(ContentTypeManagerType)
  public readonly contentTypeManager!: IContentTypeManager

  @Inject(DateTimeType)
  protected readonly dateTime!: IDateTime

  @Inject(ModalType)
  protected readonly modalConnector!: IModal

  public data: ContentModel | null = null
  public icon = DashmixIconName
  public isLoading: boolean = false

  public routeToEditPage (): void {
    const siteModel = this.siteResolver.getSite()
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const parentId = siteModel?.rootContent
    if (parentId) {
      this.$router.push(
        {
          name: 'content.edit.content',
          params: { id: parentId.toString(), type: 'page' }
        }
      )
    }
  }

  public showSets (): void {
    this.modalConnector.open(ContentModals.ContentLists, {
      siteId: this.siteResolver.getSite()?.id,
      slug: '/',
      title: this.$t('dashboard.homePageWidget.label')
    }, {
      closableWithOutsideClick: true
    })
  }

  public canEditContents (): boolean {
    return canEditContents(this.domain, this.user)
  }

  private loadList (): void {
    this.isLoading = true

    let parentId: Identifier = 0
    const siteModel = this.siteResolver.getSite()
    if (siteModel) {
      parentId = siteModel?.rootContent
    }

    const types = this.contentTypeManager.getSiblingTypes(ContentType.Page)
    this.contentRepository.loadNode(types, parentId, {}).then((collection) => {
      this.isLoading = false
      this.data = collection
    })
  }

  protected checkPermission (domain: string, user: UserModel | null): boolean {
    return canViewContents(domain, user)
  }
}

export default HomePageWidget
