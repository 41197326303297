// Copyright © 2022 Move Closer

import { ModalRegistry } from '@movecloser/front-core'
import { PossibleRelatedPicker } from '../../../backoffice'
import { VueConstructor } from 'vue'

import ProductCategoryAddonModal from '../components/addons/ProductCategoryAddonModal.vue'
import ProductFilterPickerModal from '../components/pickers/ProductFilterPickerModal.vue'
import ProductPickerModal from '../components/pickers/ProductPickerModal.vue'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export enum ProductsModals {
  BrandModal = 'brandModal',
  ProductCategoryModal = 'productCategoryModal'
}

/**
 * Registry of available modals.
 */
export const productModals: ModalRegistry<VueConstructor> = {
  [ProductsModals.ProductCategoryModal]: ProductCategoryAddonModal,

  [PossibleRelatedPicker.Product]: ProductPickerModal,
  [PossibleRelatedPicker.ProductFilter]: ProductFilterPickerModal
}
