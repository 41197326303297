// Copyright © 2022 Move Closer

import { Injectable } from '@movecloser/front-core'
import merge from 'lodash/merge'
import { VueConstructor } from 'vue'

import { ISiteService } from '../../../../backoffice'

import {
  AddonConfig,
  AddonRegistry,
  ContentConfig, ContentListConfig,
  ContentTypeConfig,
  ContentVariantConfig,
  IContentTypeManager
} from './service.contracts'
import { defaultContentType, defaultContentTypeConfig, defaultEditor, defaultListing } from './service.defaults'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Injectable()
export class ContentTypeManager implements IContentTypeManager {
  protected addons: AddonRegistry = {}
  protected defaultContentType: string = defaultContentType
  protected editorRegistry: Record<string, VueConstructor>
  protected listRegistry: Record<string, VueConstructor>
  protected typeRegistry: Record<string, ContentTypeConfig> = {}

  public constructor (
    protected readonly siteService: ISiteService,
    initRegistry: Record<string, ContentTypeConfig>,
    initEditors: Record<string, VueConstructor>,
    initListings: Record<string, VueConstructor>,
    initAddons: AddonRegistry
  ) {
    this.addons = initAddons
    this.editorRegistry = initEditors
    this.listRegistry = initListings

    for (const [key, config] of Object.entries(initRegistry)) {
      this.registerType(key, config)
    }
  }

  public allowed (): string[] {
    return Object.keys(this.typeRegistry)
  }

  public defaultType (): string {
    return this.defaultContentType
  }

  public getAddons (filter?: string[]): AddonConfig[] {
    const addons: AddonConfig[] = []

    for (const [name, addon] of Object.entries(this.addons)) {
      if (Array.isArray(filter) && !filter.includes(name)) {
        continue
      }

      addons.push({
        component: addon.component,
        label: addon.label,
        name: name,
        priority: addon.priority
      })
    }

    return addons.sort((a, b) => a.priority > b.priority ? 1 : -1)
  }

  public getContentConfig (type: string): ContentConfig {
    this.throwWhenUnknownType(type)

    const config: ContentTypeConfig = this.typeRegistry[type]

    return {
      hasParent: config.hasParent,
      label: config.label,
      loadWith: config.loadWith,
      parentType: config.parentType,
      properties: config.properties
    }
  }

  public getContentListConfig (type: string): ContentListConfig {
    this.throwWhenUnknownType(type)

    const config: ContentTypeConfig = this.typeRegistry[type]

    return {
      actions: config.actions,
      filters: config.filters,
      label: config.label,
      listing: this.resolveListing(config.listing),
      loadAs: config.loadAs,
      loadWith: config.loadWith
    }
  }

  public getContentVariantConfig (type: string): ContentVariantConfig {
    this.throwWhenUnknownType(type)

    const config: ContentTypeConfig = this.typeRegistry[type]

    return {
      editor: config.editor !== null ? this.resolveEditor(config.editor) : null,
      hasPreview: config.hasPreview,
      isSmart: config.isSmart ?? false,
      label: config.label
    }
  }

  public getLabel (type: string): string {
    this.throwWhenUnknownType(type)

    return this.typeRegistry[type].label
  }

  public getParentTypes (type: string): string[] {
    let resolvedType = this.getContentConfig(type)

    if (resolvedType.parentType !== null && resolvedType.parentType !== type) {
      type = resolvedType.parentType
      resolvedType = this.getContentConfig(type)
    }

    return [type, ...resolvedType.loadWith]
  }

  public getSiblingTypes (type: string): string[] {
    let resolvedType = this.getContentListConfig(type)

    if (resolvedType.loadAs && resolvedType.loadAs !== type) {
      type = resolvedType.loadAs
      resolvedType = this.getContentListConfig(type)
    }

    return [type, ...resolvedType.loadWith]
  }

  public isTypeAllowed (candidate: string): boolean {
    return candidate in this.typeRegistry
  }

  public registerAddons (addons: AddonConfig[]): void {
    for (const a of addons) {
      if (typeof a.name !== 'string' || a.name.length === 0) {
        continue
      }

      if (a.priority < 1 || a.priority > 10) {
        throw new Error(
          '[ContentTypeManager]: Addon priority can have value between 1 & 10.'
        )
      }

      const key: string = a.name
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      delete a.name
      this.addons[key] = a
    }
  }

  public registerEditor (type: string, component: VueConstructor): void {
    if (type in this.editorRegistry && typeof this.editorRegistry[type] !== 'undefined') {
      throw new Error(
        `[ContentTypeManager]: There's already registered editor of type (${type}). Editor override is forbidden.`
      )
    }

    this.editorRegistry[type] = component
  }

  public registerType (type: string, config: Partial<ContentTypeConfig> = {}, force: boolean = false): void {
    if (type in this.typeRegistry && typeof this.typeRegistry[type] !== 'undefined' && !force) {
      throw new Error(
        `[ContentTypeManager]: There's already registered content type (${type}). Use option [force] in case of override.`
      )
    }

    this.typeRegistry[type] = this.combineConfig(config)
    if (config.loadAs && config.loadAs.length > 0 && typeof this.typeRegistry[config.loadAs] !== 'undefined') {
      this.typeRegistry[config.loadAs].loadWith.push(type)
    }
  }

  protected resolveEditor (editor: string): VueConstructor {
    if (!(editor in this.editorRegistry) || typeof this.editorRegistry[editor] === 'undefined') {
      editor = defaultEditor
    }

    if (typeof this.editorRegistry[editor] === 'undefined') {
      throw new Error(
        `[ContentTypeManager]: Missing default editor (${editor})`
      )
    }

    return this.editorRegistry[editor]
  }

  protected resolveListing (listing: string): VueConstructor {
    if (!(listing in this.listRegistry) || typeof this.listRegistry[listing] === 'undefined') {
      listing = defaultListing
    }

    if (typeof this.listRegistry[listing] === 'undefined') {
      throw new Error(
        `[ContentTypeManager]: Missing default listing (${listing})`
      )
    }

    return this.listRegistry[listing]
  }

  protected throwWhenUnknownType (type: string): void {
    if (!(type in this.typeRegistry) || typeof this.typeRegistry[type] === 'undefined') {
      throw new Error(
        `[ContentTypeManager]: Unknown type given (${type}).`
      )
    }
  }

  private combineConfig (given: Partial<ContentTypeConfig>): ContentTypeConfig {
    // TODO: Fix siteService.
    const base = defaultContentTypeConfig() // this.siteService.getActiveSite())
    return merge(base, given)
  }
}
