













import { Component } from 'vue-property-decorator'
import { DashmixButtonVariantMap, DashmixTheme } from '@movecloser/ui-core'
import { IModal, ModalType } from '@movecloser/front-core'

import { Inject } from '../../../../backoffice'
import { ModalSize } from '../../../shared/components/Modal'

import { AbstractAddon, AddonErrors } from '../../../content/shared'

import { Addon } from '../../config/content-types'
import { ProductCategoryAddonData } from '../../contracts/addons'
import { ProductsModals } from '../../config/modals'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@Component({
  name: 'ProductCategoryAddon',
  components: { AddonErrors }
})
export class ProductCategoryAddon extends AbstractAddon {
  @Inject(ModalType)
  protected modalConnector!: IModal

  public buttonTheme = DashmixTheme
  public buttonVariant = DashmixButtonVariantMap

  public get addonData (): ProductCategoryAddonData {
    return {
      infoBarEntries: [],
      specificationColors: {},
      defaultImages: {},
      ...(this.variant.getProperty<ProductCategoryAddonData>(Addon.ProductCategory) ?? {})
    }
  }

  public set addonData (data: ProductCategoryAddonData) {
    this.variant.setProperty<ProductCategoryAddonData>(Addon.ProductCategory, data)
    this.onChange(this.variant)
  }

  public openModal () {
    this.modalConnector.open(ProductsModals.ProductCategoryModal, {
      addonData: this.addonData,
      disabled: this.disabled,
      onChange: (data: ProductCategoryAddonData) => { this.addonData = data }
    }, { size: ModalSize.xl })
  }
}

export default ProductCategoryAddon
