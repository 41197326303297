
























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DashmixIconName, Hint } from '@movecloser/ui-core'
import { Collection, ICollection, IModal, ModalType } from '@movecloser/front-core'

import {
  AllowedContentType,
  IRelatedService,
  Inject,
  PossibleRelatedType,
  Related,
  RelatedServiceType
} from '../../../backoffice'
import { Loader } from '../../shared/components/Loader'

import {
  ContentModel,
  ContentRepositoryType,
  IContentRepository,
  PickerPayload
} from '../contracts'

@Component<SnippetPickerModal>({
  name: 'SnippetPickerModal',
  components: { Loader },

  mounted () {
    this.loadContents()
  }
})
export class SnippetPickerModal extends Vue {
  @Prop({ type: Object, required: true })
  public payload!: PickerPayload<ContentModel>

  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(ContentRepositoryType)
  private contentRepository!: IContentRepository

  @Inject(RelatedServiceType)
  protected relatedService!: IRelatedService

  protected items!: ICollection<ContentModel>
  public isLoading: boolean = false
  public itemOptions: Hint[] = []
  public searchParam: string = ''
  public selectedOption: Hint[] = []
  public icons = DashmixIconName

  public get isReady () {
    return this.selectedOption.length > 0
  }

  public apply (): void {
    if (!this.selectedOption[0]) {
      return
    }

    if (this.payload.onSelection && typeof this.payload.onSelection === 'function') {
      const result = this.selectedOption.map(item => (
        { type: PossibleRelatedType.Snippet, value: item.value } as Related
      ))

      this.payload.onSelection(result[0])
    }

    this.modalConnector.close()
  }

  public close (): void {
    if (this.payload.onClose && typeof this.payload.onClose === 'function') {
      this.payload.onClose()
    }
    this.modalConnector.close()
  }

  public loadContents (searchParams?: string) {
    this.isLoading = true
    this.contentRepository.loadCollection(AllowedContentType.Snippet, searchParams ? { q: searchParams } : {})
      .then((contents: ICollection<ContentModel>) => {
        this.itemOptions = SnippetPickerModal.contentsToHints(new Collection(contents.filter((item) => {
          return item.status !== 'draft'
        })))
      })
      .finally(() => {
        this.isLoading = false
      })
  }

  public onClear () {
    this.searchParam = ''
  }

  public onDelete () {
    this.selectedOption = []
  }

  public onSearch (value: string) {
    this.searchParam = value
  }

  public onSelect (selectedHint: Hint) {
    this.selectedOption = [selectedHint]
  }

  @Watch('searchParam')
  protected onSearchParamsChange (searchParams: string): void {
    this.loadContents(searchParams)
  }

  public static contentsToHints (contents: ICollection<ContentModel>): Hint[] {
    return [...contents].map(
      (content: ContentModel) => {
        return {
          value: content.id,
          label: content.title
        }
      }
    )
  }
}

export default SnippetPickerModal
