// Copyright © 2021 Move Closer

import { defineComponent, ref, Ref } from '@vue/composition-api'

import { Header } from './partials/Header'
import { Sidebar } from './partials/Sidebar'
import { DashmixIconName } from '@movecloser/ui-core'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const DashboardLayout = defineComponent({
  name: 'DashboardLayout',
  components: { Header, Sidebar },
  setup () {
    const isSidebarOpen: Ref<boolean> = ref(false)

    function closeSidebar () {
      isSidebarOpen.value = false
    }

    function openSidebar () {
      isSidebarOpen.value = true
    }

    return { closeSidebar, DashmixIconName, isSidebarOpen, openSidebar }
  },
  template: `
    <div id="page-container"
         :class="{'sidebar-o-xs' : isSidebarOpen}"
         class="sidebar-o sidebar-dark sidebar-mini side-scroll page-header-fixed page-header-dark">
    <Header>
      <template v-slot:left>
        <DsButton :icon="DashmixIconName.BarsSolid" theme="dual" class='d-lg-none' @click="openSidebar" />
        <slot name="header-left" />
      </template>

      <template v-slot:right>
        <slot name="header-right" />
        <!--<UserMenu />-->
      </template>
    </Header>

    <Sidebar>
      <template v-slot:header>
        <!--Logo-->
        <slot name="logo" />
        <!-- END Logo -->

        <div>
          <!-- Close Sidebar, Visible only on mobile screens -->
          <a class="d-lg-none text-white ml-2" @click="closeSidebar">
            <i class="fa fa-times-circle" />
          </a>
          <!-- END Close Sidebar -->
        </div>
      </template>

      <slot name="sidebar" />
    </Sidebar>

    <main id="main-container" class="container bg-body-dark px-0">
      <div class="content">
        <slot/>
      </div>
    </main>
    </div>`
})

export default DashboardLayout
