// Copyright © 2021 Move Closer

import { permissionsFactory } from '../config/permissionsFactory'
import { Container, RoutesFactory } from '@movecloser/front-core'

import CreateSet from '../views/CreateSet.vue'
import EditSet from '../views/EditSet.vue'
import SetsList from '../views/SetsList.vue'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const routesFactory: RoutesFactory = (container: Container) => {
  return [
    {
      path: '/sets/:type/:id/edit',
      name: 'edit',
      component: EditSet,
      meta: {
        permissions: permissionsFactory
      }
    },
    {
      path: '/sets/:type/create',
      name: 'create',
      component: CreateSet,
      meta: {
        permissions: permissionsFactory
      }
    },
    {
      path: '/sets/:type',
      name: 'list',
      component: SetsList,
      meta: {
        permissions: permissionsFactory
      }
    },
    {
      path: '/sets',
      redirect: '/sets/offers'
    }
  ]
}
