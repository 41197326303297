// Copyright © 2021 Move Closer

import { ICollection, Injectable, Repository, ResourceActionFailed } from '@movecloser/front-core'

import { Identifier, resolveFromStatus } from '../../../backoffice'
import { Query } from '../../shared/contracts/query'

import { DictData, DictModel } from '../contracts/models'
import { Dict } from '../models/dict'
import { IDictionaryRepository } from '../contracts/repositories'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */

@Injectable()
export class DictRepository extends Repository<DictData, Dict> implements IDictionaryRepository {
  public async loadDepartmentsDictionary (query: Query): Promise<ICollection<DictModel>> {
    const response = await this.connector.call(
      'dict',
      'departments',
      {},
      query
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeCollection(
      response.data.data,
      Dict,
      response.data.meta
    )
  }

  public async loadCategoryDictionary (query: Query): Promise<ICollection<DictModel>> {
    const response = await this.connector.call(
      'dict',
      'category',
      {},
      query
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeCollection(
      response.data.data,
      Dict,
      response.data.meta
    )
  }

  public async loadLayoutsDictionary (query: Query): Promise<ICollection<DictModel>> {
    const response = await this.connector.call(
      'dict',
      'layouts',
      {},
      {
        scope: 'typeahead',
        ...query
      }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeCollection(
      response.data.data,
      Dict,
      response.data.meta
    )
  }

  public async loadRolesDictionary (query: Query): Promise<ICollection<DictModel>> {
    const response = await this.connector.call(
      'dict',
      'roles',
      {},
      {
        scope: 'typeahead',
        ...query
      }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeCollection(
      response.data.data,
      Dict,
      response.data.meta
    )
  }

  public async loadUsersDictionary (query: Query): Promise<ICollection<DictModel>> {
    const response = await this.connector.call(
      'dict',
      'users',
      {},
      {
        scope: 'typeahead',
        ...query
      }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeCollection(
      response.data.data,
      Dict,
      response.data.meta
    )
  }

  public async loadUser (id: Identifier): Promise<DictModel> {
    const response = await this.connector.call(
      'dict',
      'user',
      { id },
      {
        scope: 'typeahead'
      }
    )

    if (!response.isSuccessful()) {
      throw new ResourceActionFailed(
        response.errors?.message,
        resolveFromStatus(response)
      )
    }

    return this.composeModel(
      response.data.data,
      Dict
    )
  }
}
