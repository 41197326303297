// Copyright © 2021 Move Closer

import { AuthHeader, Headers } from '@movecloser/front-core'

import { FilePondOptions } from 'filepond'

export function composeUploaderConfig (config: any, tokenHeader: AuthHeader, siteHeader: Headers): FilePondOptions {
  const configCopy = JSON.parse(JSON.stringify(config))
  configCopy.server.process.headers = {
    ...configCopy.server.process.headers,
    ...tokenHeader,
    ...siteHeader
  }

  configCopy.server.process.onload = (res: string) => {
    return JSON.parse(res).data.id
  }
  configCopy.server.process.onerror = (res: string) => {
    return JSON.parse(res)?.errors?.file[0] ?? JSON.parse(res).message ?? JSON.parse(res)
  }

  return configCopy
}
