// Copyright © 2021 Move Closer

import { Identifier } from '../../../backoffice'

import { Roles, SimpleRole } from '../contracts/models'

export const mapRolesToIntention = (roles: Roles) => {
  return Object.keys(roles).reduce((result: Record<string, Identifier[]>, k: string) => {
    result[k] = roles[k].map((simpleRole: SimpleRole) => simpleRole.id)

    return result
  }, {} as Record<string, Identifier[]>)
}
