
































import {
  Authentication,
  AuthServiceType,
  EventbusType,
  EventPayload,
  IEventbus,
  IModal,
  ModalType
} from '@movecloser/front-core'
import { UsersModal } from '../config/modals'
import { Component, Mixins, Watch } from 'vue-property-decorator'

import { DashmixTheme, TableSearchBarAction } from '@movecloser/ui-core'
import {
  TableHead,
  TableHeadElement,
  TableRowElement
} from '@movecloser/ui-core/lib/abstract/components/Table'

import { DropdownActions } from '../../shared/contracts/content'
import { HeaderInterface } from '../../shared/components/InteractiveTable/InteractiveTable.contracts'
import { HasIdentifier } from '../../shared/contracts/data'
import { Identifier, Inject } from '../../../backoffice'
import { InteractiveTable } from '../../shared/components/InteractiveTable/InteractiveTable.vue'
import { ModelListHeader } from '../../shared/components/ModelListHeader/ModelListHeader'
import { Query } from '../../shared/contracts/query'

import { AuthRepositoryType, IAuthRepository } from '../../auth/contracts/repositories'
import { initBreadcrumbs } from '../../root/helpers/breadcrumbs'

import { IUserRepository, UserRepositoryType } from '../contracts/repositories'
import { IUsersPermissions, UsersPermissions } from '../config/permissions.mixin'
import { UsersTableRow } from '../components/UsersTableRow.vue'
import { Roles, UserData, UserModel } from '../contracts/models'
import { usersFiltersConfig } from '../models/user.filters'
import { UserListsActions, userRowActionsFactory, usersTableHead } from '../maps/users'
import { User } from '../models/user'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component<UsersList>({
  name: 'UsersList',
  components: { InteractiveTable, ModelListHeader }
})
export class UsersList extends Mixins<IUsersPermissions>(UsersPermissions) {
  @Inject(AuthServiceType)
  protected authService!: Authentication<UserModel>

  @Inject(AuthRepositoryType)
  protected authRepository!: IAuthRepository

  @Inject(EventbusType)
  protected eventBus!: IEventbus

  @Inject(ModalType)
  protected modalConnector!: IModal

  @Inject(UserRepositoryType)
  protected userRepository!: IUserRepository

  public isLoading = false

  public get header (): HeaderInterface {
    const header: Partial<HeaderInterface> = {
      title: 'users.listTitle'
    }

    if (this.canCreateUsers) {
      header.buttonLabel = 'users.addUser'
      header.linkTarget = { name: 'users.create' }
    }

    return header as HeaderInterface
  }

  public readonly filtersConfig = usersFiltersConfig
  public rowComponent = UsersTableRow
  public tableHead: TableHead = usersTableHead.map((item: TableHeadElement) => {
    return {
      ...item,
      label: `${this.$t(item.label)}`
    }
  })

  public tableData: TableRowElement[] = []

  public totalPages: number = 0
  public itemsTotal: number = 0

  public rowActions: TableSearchBarAction[] = []

  public actions: DropdownActions = {
    [UserListsActions.Unlock]: {
      callback: (data: unknown) => {
        return this.switchBlock(data as UserModel)
      },
      confirmation: {
        header: 'users.unlock.header',
        contentText: 'users.unlock.text',
        theme: DashmixTheme.Success,
        buttonLabel: 'users.unlock.button'
      }
    },
    [UserListsActions.Edit]: {
      callback: (data: unknown) => {
        this.$router.push({ name: 'users.edit', params: { id: (data as UserData).id.toString() } })
      }
    },
    [UserListsActions.Delete]: {
      callback: (data: unknown) => {
        return this.onDelete((data as HasIdentifier).id)
      },
      confirmation: {
        header: 'users.delete.header',
        contentText: 'actions.delete.contentText',
        theme: DashmixTheme.Danger,
        buttonLabel: 'atoms.delete'
      }
    },
    [UserListsActions.ResetPassword]: {
      callback: (data: unknown) => {
        return this.onReset(data as UserData)
      },
      confirmation: {
        header: 'users.reset.header',
        contentText: 'users.reset.text',
        theme: DashmixTheme.Info,
        buttonLabel: 'users.reset.button'
      }
    },
    [UserListsActions.Block]: {
      callback: (data: unknown) => {
        return this.switchBlock(data as UserModel)
      },
      confirmation: {
        header: 'users.block.header',
        contentText: 'users.block.text',
        theme: DashmixTheme.Danger,
        buttonLabel: 'users.block.button'
      }
    }
  }

  public get breadcrumbs () {
    return {
      items: [
        {
          label: `${this.$t('users.listTitle')}`,
          target: { name: 'users.list' }
        }
      ],
      root: initBreadcrumbs.root
    }
  }

  public get queryParams (): Query {
    return this.$route.query
  }

  mounted () {
    this.loadList(this.queryParams)

    this.rowActions = userRowActionsFactory(this.domain, this.user).map(a => {
      if (a.action === UserListsActions.Unlock) {
        a.guard = (data: unknown) => {
          const model = data as UserModel
          if (this.authService.user && model.id === this.authService.user.id) {
            return false
          }
          return model.isBlocked
        }
      }

      if (a.action === UserListsActions.Block) {
        a.guard = (data: unknown) => {
          const model = data as UserModel
          if (this.authService.user && model.id === this.authService.user.id) {
            return false
          }
          return !model.isBlocked
        }
      }

      return a
    })
  }

  protected loadList (query: Query): void {
    this.isLoading = true
    this.userRepository.loadCollection(query).then(collection => {
      this.isLoading = false

      this.tableData = [...collection].map(model => {
        return {
          id: `${model.id}`,
          selectable: this.canDeleteUsers || this.canEditUsers,
          data: model
        }
      })

      this.totalPages = Math.ceil(collection.meta.total / collection.meta.per_page)
      this.itemsTotal = collection.meta.total
    }).catch(error => {
      console.warn(error)
      this.isLoading = false
    })
  }

  public switchBlock (model: UserModel) {
    this.userRepository.switchActive(model.id as Identifier, !model.isBlocked, model)
      .then(newModel => {
        this.tableData = this.tableData.map(rowElement => {
          if (`${rowElement.id}` === `${model.id}`) {
            return {
              ...rowElement,
              data: newModel
            }
          }
          return rowElement
        })
      })
      .catch(error => {
        console.log(error)
      })
  }

  public onDelete (id: Identifier) {
    this.isLoading = true
    this.userRepository.delete(id)
      .then(() => {
        this.tableData = this.tableData.filter(rowElement => `${rowElement.id}` !== `${id}`)
      })
      .catch(error => {
        console.debug(error)
      }).finally(() => {
        this.isLoading = false
      })
  }

  public onReset (data: UserData) {
    this.isLoading = true
    this.authRepository.forgotPassword(data.email)
      .catch(error => {
        console.debug(error)
      }).finally(() => {
        this.isLoading = false
      })
  }

  protected onRoleChange (id: Identifier, newRoles: Roles) {
    this.tableData = this.tableData.map(row => {
      const user = { ...row.data as UserData }
      if (user.id === id) {
        user.roles = newRoles
      }

      row.data = User.hydrate(user)
      return { ...row }
    })
  }

  @Watch('queryParams', { deep: true })
  protected onQueryChange (query: Query): void {
    this.loadList(query)
  }
}

export default UsersList
