






























import { DashmixBreadcrumbsProps } from '@movecloser/ui-core'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { AnyObject, IModal, ModalType } from '@movecloser/front-core'
import { MetaInfo } from 'vue-meta'

import { Identifier, Inject, IRelatedService, PossibleRelatedType, RelatedServiceType, SetType } from '../../../backoffice'

import { EditModeLayout } from '../../shared/components/EditModeLayout/EditModeLayout'
import { FullScreenLoader } from '../../shared/layouts/FullScreenLoader'
import { initBreadcrumbs } from '../../content/helpers'
import { Loader } from '../../shared/components/Loader'
import { Sidebar } from '../../shared/layouts/partials/Sidebar'

import {
  DictionaryRepositoryType,
  IDictionaryRepository
} from '../../root/contracts/repositories'

import { EditSetIntention, EditSetIntentionPayload } from '../intentions/EditSetIntention'
import {
  ISetItemsRepository,
  ISetsRepository,
  SetItemsRepositoryType,
  SetModel,
  SetsRepositoryType,
  SetStrategy
} from '../contracts'
import { SetForm } from '../components/SetForm.vue'
import { SetSidebar } from '../components/SetSidebar.vue'

/**
 * @author Łukasz Jakubowski <lukasz.jakubowski@movecloser.pl>
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<EditSet>({
  name: 'EditSet',
  components: { EditModeLayout, SetSidebar, FullScreenLoader, Sidebar, SetForm, Loader },

  created () {
    this.loadSet()
  },

  metaInfo (this: EditSet): MetaInfo {
    return {
      title: this.setName
    }
  }
})
export class EditSet extends Vue {
  @Inject(DictionaryRepositoryType)
  protected dictRepository!: IDictionaryRepository

  @Inject(ModalType)
  private modalConnector!: IModal

  @Inject(RelatedServiceType)
  public readonly relatedService!: IRelatedService

  @Inject(SetItemsRepositoryType)
  private setItemsRepository!: ISetItemsRepository

  @Inject(SetsRepositoryType)
  private setsRepository!: ISetsRepository

  public formName: string = 'editSet'
  public payload: EditSetIntentionPayload | null = null
  public isLoading: boolean = false
  public isSaving: boolean = false
  public reset: boolean = false
  public resort: boolean = false
  private set: SetModel | null = null
  public setName: string = this.set?.title ?? ''

  public get breadcrumbs (): DashmixBreadcrumbsProps {
    return {
      items: [
        {
          label: `${this.$t(`sets.listTitle.${this.setType}`)}`,
          target: { name: 'sets.list' }
        },
        {
          label: this.setName,
          target: { name: 'sets.create' }
        }
      ],
      root: initBreadcrumbs.root
    }
  }

  public get setType (): SetType {
    if (!this.$route.params.type) {
      return PossibleRelatedType.Articles
    }
    return this.$route.params.type as unknown as SetType
  }

  public get canEdit () {
    if (!this.payload) {
      return false
    }

    return this.payload.strategy === SetStrategy.Manual || this.payload.strategy === SetStrategy.Automatic
  }

  public get setId () {
    return Number(this.$route.params.id)
  }

  public async editSet () {
    if (!this.payload) {
      return
    }

    this.isSaving = true

    try {
      await this.setsRepository.update(this.setId, new EditSetIntention(this.payload))
      this.setName = this.payload.title ?? ''

      if (this.resort) {
        await this.setsRepository.refresh(this.setId)
        this.loadSet()
        this.resort = false
      }
    } catch {} finally {
      this.isSaving = false
    }
  }

  public loadSet () {
    this.isLoading = true

    this.setsRepository.load(this.setId)
      .then(async (set: SetModel) => {
        this.set = set
        const setObject = set.toObject()

        this.payload = {
          ...setObject,
          author: setObject.editor.id
        }
        this.setName = this.set.title

        if (!this.payload || !this.payload.properties || !Array.isArray(this.payload.properties.src)) {
          return
        }

        // TODO - get source label from API
        for (const s of this.payload.properties.src) {
          const sources = await this.relatedService.resolve({
            type: s.type,
            value: Number(s.value)
          }) as AnyObject

          switch (s.type) {
            case 'author':
              s.label = sources.nickname
              break
            case 'tag':
              s.label = sources.name
              break
            default:
              s.label = sources.label
              break
          }
        }
      })
      .catch(error => {
        console.warn(error)
      })
      .finally(() => {
        this.isLoading = false
      })
  }

  public handleSourcesUpdate (newPayload: EditSetIntentionPayload) {
    this.payload = newPayload
  }

  public handleTitleUpdate (newTitle: string) {
    if (!this.payload) {
      return
    }

    this.payload.title = newTitle
  }

  public handleReset (value: boolean) {
    this.reset = value
  }

  public handleResort (value: boolean) {
    this.resort = value
  }

  public lock (itemId: Identifier) {
    this.setItemsRepository.lock(this.setId, +itemId).then(() => {
      this.loadSet()
    })
  }

  public unlock (itemId: Identifier): void {
    this.setItemsRepository.unlock(this.setId, +itemId).then(() => {
      this.loadSet()
    })
  }

  @Watch('reset')
  private async onReset (value: boolean): Promise<void> {
    if (value) {
      await this.setsRepository.reset(this.setId)
      this.loadSet()
      this.reset = false
    }
  }
}

export default EditSet

