var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"media-view col-12 p-0"},[(_vm.searchable)?[_c('DsTableSearchBar',{attrs:{"search-value":_vm.q,"searchPlaceholder":_vm.$t('media.list.searchPlaceholder'),"actions":_vm.rowActions,"isProcessing":_vm.isLoading,"selected":_vm.selectedRows},on:{"search":_vm.search,"bulk":_vm.doBulkActions}},[[_c('DsFilters',{attrs:{"config":_vm.filtersConfig,"query":_vm.queryParams,"container":_vm.$container},on:{"setQuery":_vm.setQuery}})]],2)]:_vm._e(),(!_vm.isBackButtonHidden)?_c('DsButton',{attrs:{"label":_vm.$t('media.list.goUp'),"icon":_vm.icons.LevelUpAltSolid,"theme":"primary","variant":"icon","size":"medium"},on:{"click":function($event){return _vm.goToParentDirectory()}}}):_vm._e(),(_vm.tileMode)?[_c('TilesGrid',_vm._b({on:{"updateSelection":_vm.updateSelection,"do":_vm.doAction}},'TilesGrid',{
                actions: _vm.rowActions,
                directories: _vm.directoriesData,
                files: _vm.filesData,
                isEditable: _vm.isEditable,
                isLoading: _vm.isLoading,
                isSelectable: _vm.isSelectable,
                isMultiple: _vm.isMultiple,
                selected: _vm.files
                },false))]:_vm._e(),(!_vm.tileMode)?[_c('DsTable',_vm._b({attrs:{"showSelection":_vm.selectColumn,"showActions":_vm.actionColumn},on:{"selected":_vm.onSelection,"do":_vm.doAction},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var data = ref.data;
return [_c(_vm.rowComponent,{tag:"component",attrs:{"data":data},on:{"do":_vm.doAction}})]}}],null,false,59355662)},'DsTable',{
                actions: _vm.rowActions,
                head: _vm.tableHead,
                isLoading: _vm.isLoading,
                rows: _vm.tableData,
                rowsCount: _vm.tableData.length
              },false),[_c('EmptyTable',{class:{'d-none' : _vm.isLoading },attrs:{"slot":"emptyMessage"},slot:"emptyMessage"})],1)]:_vm._e(),_c('DsPagination',{attrs:{"currentPage":_vm.currentPage,"perPage":_vm.perPage,"pagesTotal":_vm.pagesTotal,"itemsTotal":_vm.itemsTotal},on:{"update:currentPage":_vm.updateCurrentPage,"update:perPage":_vm.updatePerPage},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('div',{staticClass:"right-group"},[_vm._v(" Wszystkich elementów: "),_c('b',[_vm._v(_vm._s(_vm.itemsTotal))])])]},proxy:true}])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }