// Copyright © 2021 Move Closer

import { MappingConfig } from '@movecloser/front-core'

export const productAdapterMap: MappingConfig = {
  id: 'id',
  isVisible: 'isVisible',
  name: 'name',
  sku: 'sku',
  category: 'category',
  updatedAt: 'updatedAt',
  variants: 'variants',
  url: 'url'
}
