// Copyright © 2022 Move Closer

import { ModalRegistry } from '@movecloser/front-core'
import { VueConstructor } from 'vue'

import { PossibleRelatedPicker } from '../../../backoffice'

import NavigationPicker from '../components/NavigationPicker.vue'

/**
 * Registry of available modal names.
 */
export enum SettingsModal {
  //
}

/**
 * Registry of available modals.
 */
export const settingsModals: ModalRegistry<VueConstructor> = {
  [PossibleRelatedPicker.Navigation]: NavigationPicker
}
