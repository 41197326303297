// Copyright © 2021 Move Closer

import { AbstractIntention } from '@movecloser/front-core'

import { SetType } from '../../../backoffice'

import { SetProperties, SetStrategy } from '../contracts'

export interface CreateSetIntentionPayload {
  author?: number
  title: string
  type: SetType
  strategy: SetStrategy
  properties: SetProperties
}

export class CreateSetIntention extends AbstractIntention<CreateSetIntentionPayload> {
  protected map = {
    author: 'author',
    title: 'title',
    type: 'type',
    strategy: 'strategy',
    properties: 'properties'
  }
}
