
















import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DashmixIconName } from '@movecloser/ui-core'

import { NavigationItem, NavigationModel } from '../contracts'
import { NavigationList } from './NavigationList.vue'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component<NavigationSidebar>({
  name: 'NavigationSidebar',
  components: { NavigationList },

  mounted () {
    this.countLinks()
  }
})
export class NavigationSidebar extends Vue {
  @Prop({ type: Object, required: true })
  public navigation!: NavigationModel

  public count = 0
  public Icons = DashmixIconName
  public isLoading: boolean = false

  public countLinks (): void {
    if (!this.navigation.links) {
      return
    }

    this.count = NavigationSidebar.calcLinks(this.navigation.links)
  }

  private static calcLinks (navigationList: NavigationItem[]): number {
    let total = navigationList.length

    for (const nav of navigationList) {
      if (nav.children && Array.isArray(nav.children) && nav.children.length > 0) {
        total += NavigationSidebar.calcLinks(nav.children)
      }
    }

    return total
  }

  @Watch('navigation')
  private onNavigationChange () {
    this.countLinks()
  }
}

export default NavigationSidebar
