// Copyright © 2021 Move Closer

import { DashmixIconName, DashmixTheme, SizeMap } from '@movecloser/ui-core'
import { defineComponent, SetupContext } from '@vue/composition-api'

import { inputTypeaheadProps, useInputTypeahead } from './InputTypeahead.hook'
import { InputTypeaheadProps } from './InputTypeahead.contracts'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
export const InputTypeahead = defineComponent({
  name: 'InputTypeahead',
  props: inputTypeaheadProps,
  emits: ['onClear', 'onInput', 'focus', 'blur'],

  setup (props: InputTypeaheadProps, ctx: SetupContext) {
    return {
      ...useInputTypeahead(props, ctx),
      buttonTheme: DashmixTheme,
      icons: DashmixIconName,
      sizes: SizeMap
    }
  },

  template: `
    <DsInputGroup :class="disabled ? '--disabled' : ''" :size="size" >
      <template v-slot:prependText>
        <DsIcon :icon="loading ? icons.SpinnerSolid : icons.SearchSolid" class="typeahead__search-icon" />
      </template>

      <DsInput :model.sync="value"
                v-bind="{ name, placeholder, disabled, size }"
                v-focus="autofocus"
                @focus="() => $emit('focus')" @blur="() => $emit('blur')"  />

      <template v-if="clearable && !disabled">
          <DsButton variant="icon" :icon="icons.TimesSolid" :theme="buttonTheme.Dark" :size="sizes.Small"
                     @click="() => $emit('onClear')" v-slot:appendText />
      </template>
    </DsInputGroup>
  `
})
