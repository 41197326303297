




























































import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { DashmixIconName, DropdownItem, DashmixTheme, DashmixSelectItem } from '@movecloser/ui-core'

import { FormInput, FormText, FormSelect } from '../../shared/components/form'
import { Inject } from '../../../backoffice'

import { DirectoryRepositoryType, FileCreatePayload, FileEditPayload, IDirectoryRepository } from '../contracts'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
@Component<FileForm>({
  name: 'FileForm',
  components: { FormInput, FormText, FormSelect },
  mounted () {
    if (!this.abandonedMode) {
      return
    }

    this.directoryRepository.loadDirectoryTree({ 'flat-tree': '' }).then(collection => {
      this.options = [...collection].map(node => {
        return {
          value: node.id,
          label: node.name
        }
      })
    })
  }
})
export class FileForm extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  public abandonedMode!: boolean

  @PropSync('formModel', { type: Object, required: true })
  public form!: FileEditPayload | FileCreatePayload

  @Prop({ type: Boolean, required: false, default: false })
  public isLoading!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public showCopyButton!: boolean

  @Prop({ type: String, required: true })
  public formName!: string

  @Inject(DirectoryRepositoryType)
  protected directoryRepository!: IDirectoryRepository

  public icons = DashmixIconName
  public options: DashmixSelectItem[] = []

  public getDropdownItems (name: string): DropdownItem[] {
    return [
      {
        label: 'Kopiuj do wszystkich plików gdzie puste pole',
        icon: DashmixIconName.Edit,
        type: 'item',
        onClick: () => {
          this.$emit('copy', { name: name, overWrite: false })
        }
      },
      {
        label: 'Kopiuj do wszystkich plików (nadpisz)',
        icon: DashmixIconName.EditSolid,
        type: 'item',
        onClick: () => {
          this.$emit('copy', { name: name, overWrite: true })
        },
        theme: DashmixTheme.Info
      }
    ]
  }
}

export default FileForm
