import { render, staticRenderFns } from "./PermissionsListItem.vue?vue&type=template&id=f88d257c&scoped=true&"
import script from "./PermissionsListItem.vue?vue&type=script&lang=ts&"
export * from "./PermissionsListItem.vue?vue&type=script&lang=ts&"
import style0 from "./PermissionsListItem.vue?vue&type=style&index=0&id=f88d257c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f88d257c",
  null
  
)

export default component.exports