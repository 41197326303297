// Copyright © 2021 Move Closer

import { AbstractIntention } from '@movecloser/front-core'

import { DirectoryCreatePayload } from '../contracts'

/**
 * @author Jan Dobrowolski <jan.dobrowolski@movecloser.pl>
 */
export class CreateDirectoryIntention extends AbstractIntention<DirectoryCreatePayload> {
  protected map = {
    directory: 'directory',
    name: 'name',
    parent: 'parent'
  }
}
