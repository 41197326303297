// Copyright © 2022 Move Closer

import { CSRFConfig, Methods } from '@movecloser/front-core'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const csrf: CSRFConfig = {
  paramName: 'X-CSRF',
  sendAsBody: false,
  source: { connection: 'xCore', url: 'api/csrf/token', method: Methods.Post }
}
