// Copyright © 2022 Move Closer

import { AddonConfig, ContentTypeConfig, defaultEditor } from '../../content/services/content-type-manager'

import ProductCategoryAddon from '../components/addons/ProductCategoryAddon.vue'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export enum Addon {
  ProductCategory = 'product-category'
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const categoryContentType: string = 'product-category'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const categoryContentTypeConfig: Partial<ContentTypeConfig> = {
  editor: defaultEditor,
  label: 'products.contentTypes.category',
  loadAs: 'page',
  hasParent: true,
  hasPreview: true,
  parentType: null,
  properties: []
}

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const productsAddons: AddonConfig[] = [
  { component: ProductCategoryAddon, label: 'products.addonsLabels.productCategory', name: Addon.ProductCategory, priority: 2 }
]

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const productContentType: string = 'product'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
export const productContentTypeConfig: Partial<ContentTypeConfig> = {
  editor: defaultEditor,
  label: 'products.contentTypes.product',
  loadAs: 'page',
  hasParent: true,
  hasPreview: true,
  isSmart: true,
  parentType: null,
  properties: []
}
