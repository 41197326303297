// Copyright © 2022 Move Closer

import { AppModule, Container, Module } from '@movecloser/front-core'

import { ContentTypeManagerType, IContentTypeManager } from '../content/shared'

import { staticAddons, staticContentType, staticContentTypeConfig, staticEditor } from './config/content-types'
import StaticBuilder from './components/StaticBuilder.vue'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 */
@AppModule({
  name: 'static',
  boot (container: Container) {
    const manager: IContentTypeManager = container.get(ContentTypeManagerType)
    manager.registerAddons(staticAddons)
    manager.registerEditor(staticEditor, StaticBuilder)
    manager.registerType(staticContentType, staticContentTypeConfig)
  }
})
export class StaticModule extends Module {}
