












import { Component, Prop, Vue } from 'vue-property-decorator'

import { Identifier } from '../../../backoffice'
import { Property } from '../../shared/components/Property'

import { Roles, SimpleRole } from '../contracts/models'

/**
 * @author Olga Milczek <olga.milczek@movecloser.pl>
 */
@Component({
  name: 'RolesPopover',
  components: { Property }
})
export class RolesPopover extends Vue {
  @Prop({ type: Object, required: true })
  public roles!: Roles

  @Prop({ type: Number, required: true })
  public userId!: Identifier

  public getRole (role: SimpleRole[]): string {
    return role.map(r => r.name).join(', ')
  }
}

export default RolesPopover
